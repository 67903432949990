import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventParticipantlistComponent } from './event-participantlist.component';



@NgModule({
  declarations: [EventParticipantlistComponent],
  imports: [
    CommonModule
  ]
})
export class EventParticipantlistModule { }
