import { Component, OnInit ,OnDestroy, Input} from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal ,NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css','./video-popup.component.scss']
})
export class VideoPopupComponent implements OnInit , OnDestroy{
  @Input() public user;

  vimeoUrl = 'https://vimeo.com/197933516';
  youtubeUrl = 'https://www.youtube.com/watch?v=iHhcHTlGtRs';
  dailymotionUrl =
    'https://www.dailymotion.com/video/x20qnej_red-bull-presents-wild-ride-bmx-mtb-dirt_sport';
 
  vimeoId = '197933516';
  youtubeId = 'iHhcHTlGtRs';
  dailymotionId = 'x20qnej';
  safeURL: any;
  urldummy: any;
  isSpfMember: boolean;
  singleDonationExist: any;
  constructor(private userIdle: UserIdleService, private _sanitizer: DomSanitizer, public activeModal: NgbActiveModal,
    public modalService : NgbModal) { }

  ngOnInit(): void {
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.singleDonationExist = currentUser.singleDonationExist;
    console.log("idle video onint")
    localStorage.setItem('idleTimeCheck',"0");
    this.userIdle.stopWatching();
    this.userIdle.stopTimer();
    //youtube
    // var str = "https://www.youtube.com/watch?v=RBumgq5yVrA";
    var str = this.user.url
    console.log("url----",str)
    if(str.includes('youtube')){
      var res = str.split("=");
      var URL= "https://www.youtube.com/embed/"+res[1]+"?autoplay=1&loop=1&autopause=0&muted=1";
      console.log("utube link------",URL)
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(URL);
    }else if(str.includes('vimeo')){
      const re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
      const matches = re.exec(str);
      var vimeo =  "https://player.vimeo.com/video/"+matches[1]+"?autoplay=1&loop=1&autopause=0&muted=1";
      console.log("utube link------",vimeo)
  
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(vimeo);
    }
    
 //vimeo
  // const url = "https://vimeo.com/347119375"
  //   const re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
  //   const matches = re.exec(url);
  //   var vimeo =  "https://player.vimeo.com/video/"+matches[1]+"?autoplay=1&loop=1&autopause=0&muted=1";
  //   console.log("utube link------",vimeo)

  //   this.urldummy = this._sanitizer.bypassSecurityTrustResourceUrl(vimeo);


  }

  ngOnDestroy() {
    // ...
    // console.log("idle on ideo estroy--------")
    localStorage.setItem('idleTimeCheck',"1");
    this.userIdle.resetTimer();
    this.userIdle.startWatching();
  
  }
  close(){
    this.activeModal.close({ 'result': true });
    }

}
