import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MySubscriptionComponent } from './my-subscription.component';
import { MainComponent } from '../main.component';
import { MainModule } from '../main.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

const routes: Routes = [
  {
      'path': '',
      'component': MainComponent,
      'children': [
          {
              'path': '',
              'component': MySubscriptionComponent,
          },
      ],
  },
];

@NgModule({
  declarations: [MySubscriptionComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes), 
    MainModule,
    MatTableModule,
    MatPaginatorModule
  ]
})
export class MySubscriptionModule { }
