import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SavedcardlistComponent } from './savedcardlist.component';
import { NgxBraintreeModule } from 'ngx-braintree';


@NgModule({
  declarations: [SavedcardlistComponent],
  imports: [
    CommonModule,
    NgxBraintreeModule
  ],
  exports:[SavedcardlistComponent]
})
export class SavedcardlistModule { }
