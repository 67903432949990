import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { CustomField } from './custom-fields.base';
import { Helpers } from '../../helpers';
import { ActivatedRoute } from '@angular/router';
// import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
// import { DatePipe } from '@angular/common';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';


declare let $: any;
@Component({
    selector: 'app-custom-fields',
    templateUrl: './custom-fields.component.html',
    styles: [`.errorMessage{
    color: #ff572e;
  }
  ::ng-deep bs-datepicker-container {
    background: #fff;
    box-shadow: -1px 2px 17px -7px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -1px 2px 17px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 2px 17px -7px rgba(0, 0, 0, 0.75);
    padding: 15px;
}

::ng-deep .bs-datepicker-head {
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
}

::ng-deep bs-datepicker-container button {
    border: 1px solid #ccc;
    background: #fff;
}
::ng-deep bs-datepicker-container  table td.ng-star-inserted {
    cursor: pointer;
}
::ng-deep  bs-datepicker-container  table td.ng-star-inserted:hover {
    background:#f2f2f2;
}
::ng-deep bs-datepicker-container table th,
::ng-deep bs-datepicker-container table td.ng-star-inserted {
    padding: 0 5px;
}

::ng-deep bs-datepicker-container table td.ng-star-inserted {
    text-align: center;
}

::ng-deep bs-datepicker-container table td span.is-other-month {
    display: none;
}
  
  `]
})
export class CustomFieldsComponent implements OnInit {
    @Input() custom: CustomField<any>;
    @Input() form: FormGroup;
    @Output() customChange: EventEmitter<any> = new EventEmitter();
    paymentPage: boolean = false;
    valid:boolean = true;
    myDateValue: Date;
    // bsConfig: Partial<BsDatepickerConfig>;
    @Input() selectedStartDate;
    datePic:boolean  = false;
    myDpOptions: IAngularMyDpOptions = {
        dateRange: false,
        dateFormat: 'mmm-dd-yyyy',
        firstDayOfWeek: 'su'
        // other options are here...
    };

    myDateInit: boolean = true;
    model: IMyDateModel = null;
    isValid() {
        // console.log("inside isvalid",this.form.controls[this.custom.key].valid) 
        if(!this.form.controls[this.custom.key].valid){
            this.valid = false;
        }
        return this.form.controls[this.custom.key].valid; }

    constructor(private activatedRoute: ActivatedRoute) {
        this.myDateValue = new Date();
    }

    ngOnInit() {
        
        // this.bsConfig = Object.assign({}, { containerClass: 'theme-green custom', showWeekNumbers: false ,dateInputFormat: 'DD MMM YYYY'});
        if (this.myDateInit) {
            // Initialize to specific date (14.05.2019) with IMyDate object
            this.model = {
                isRange: false, singleDate: {}
            };
        }
        else {
            // Initialize to today with javascript date object
            this.model = { isRange: false, singleDate: { jsDate: new Date() } };
        }
    }
    changeEvent(value, option) {
        // console.log("chnage event-----",value,option)
        if (this.custom.controlType == "textboxdatepicker"){
                // setting datepiker value to form for validity
                this.datePic = true;
                this.form.controls[this.custom.key].setValue(value.singleDate.formatted);
                // console.log("date piker value------",value.singleDate.formatted)
                this.datePic = true;
            
                this.customChange.emit({
                    'currentvalue': value.singleDate.formatted,
                    ...option
        
                });
            
        }else{
            this.datePic = false;
            this.customChange.emit({
                'currentvalue': value,
                ...option
    
            });
        }
        
        this.isValid();
    }

    animateLabel(event) {
        console.log(this.custom)
        if(this.custom.controlType == "textboxdatepicker"){
            $("#"+ this.custom.key).keypress(function (e) {
                return false;
              });
              $("#"+ this.custom.key).keydown(function (e) {
                // console.log(e.keyCode);
                // if (e.keyCode == 8 || e.keyCode == 46) {
                //   return true
                // } else {
                //   return false;
                // }
                return false;

              });
        }
        event.parentNode.classList.add('is-focused');
       
    }

    animateLabelRemove(e) {
        console.log(e.value, "AHGKND");
        if (e.value == '') {
            e.parentNode.classList.remove('is-focused');
        }
    }

}
