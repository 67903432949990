import { Component, OnInit, ViewEncapsulation,ElementRef,ViewChild ,Input} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal ,NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import { CustomValidator } from '../../../../_services/custom-validation/CustomValidator.validator';
// import * as $ from 'jquery';
declare let $: any;

class ImageSnippet {
    constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'app-contact-us-popup',
  templateUrl: './contact-us-popup.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css', './contact-us-popup.component.scss'],
})
export class ContactUsPopupComponent implements OnInit {
  
    createForm;
    countryList = [];
    causeList = [
        { Id: 0, Name: "test option zero" },
        { Id: 1, Name: "test option one" },
        { Id: 2, Name: "test option two" },
    ];
    FamilyMembersList = [];
    selectedContactId: any;
    selectedMemberId: any;
    countryId: any;
    memberData;
    memberList;
    memberProfileData;
    isSpfMember:boolean;
    contact: any;
    contactInfoList = [];
    contactList: any[];
    // memberId: any;
    routeParam: any;
    prayerRequestForm;
    imageUrl
    selectedFile: ImageSnippet;
    @ViewChild("fileInput") fileInput: ElementRef;
    @Input() memberId;
    @Input() type;
    testimonyForm;
    fileName;
    isloading:boolean= false;
    isloadingprayer:boolean = false;
    isloadingtestimony:boolean = false;
    singleDonationExist:boolean = false;
    buttonContactSubitDiable:boolean = false;
    buttonPrayerSubitDiable:boolean = false;
    buttonTestimonySubitDiable:boolean = false;
    response;
    subDomain='';
    ValidationMessage : any = {
        photo: [{ type: "extension", message: "Uploaded file is not a valid image. Only JPEG, JPG and PNG files are allowed" }],
        testimony : [{type: "required", message : "Testimony is required"}],
        preyerRequest : [{type: "required", message : "Prayer request is required"}],
        contactReason : [{type: "required", message : "contact Reason is required"}],
        Msg : [{type: "required", message : "Message field is required"}],

        }
    tab: number = 1;

    constructor(public formBuilder: FormBuilder, public _crud: CommonCrudService,public activeRoute:ActivatedRoute, public activeModal: NgbActiveModal,
                public modalService : NgbModal) {
        this.formInit();
        this.prayerFormInit();
        this.testimonyFormInit();
    }

  ngOnInit(): void {

    // this.memberId = localStorage.getItem('memberId');
    this.selectedMemberId = this.memberId;
    this.getFamilyMembers();
    this.contactInfo();
    this.getMemberData();
        $('.radiotabs > a').on('click', function(e) {
            // ALERT("hiii");
            $(this).parent().find('input').prop('checked', false);
            $(this).find('input').prop('checked', true);
        });

        /*----Placeholder Up---*/
        $(".member-wrp input").on('focus', function() {
            $(this).parent(".focus-label").addClass("is-focused");
        });
        $(".member-wrp input").focusout(function() {
            if ($(this).val() == "") {
                $(this).parents(".focus-label").removeClass("is-focused");
            }
        });
        // console.log("type-----",this.type)
        this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        this.singleDonationExist = currentUser.singleDonationExist;
        // getting subdomain
        var fullUrl = window.location.host;
        console.log("full url name-----",fullUrl)
        //window.location.host is subdomain.domain.com
        var parts = fullUrl.split('.')
        this.subDomain = parts[0]
        console.log("sub name-----",this.subDomain)
        var domain = parts[1]
        console.log("domain name-----",domain)
        
  }
  animateLabel(event) {
    event.parentNode.classList.add('is-focused');
}

animateLabelRemove(e) {
    // console.log(e.value, "AHGKND");
    if (e.value == '') {
        e.parentNode.classList.remove('is-focused');
    }
}
formInit() {
    this.createForm = this.formBuilder.group({
        Member: ['',Validators.required],
        Message: ['',Validators.required],
        contactReason: ['',Validators.required]

    });

}


create() {
    //api integraetion
    // console.log(this.createForm);
    this.buttonContactSubitDiable = true;
    let Step2Fields = [
        this.createForm.controls['Member'],
        this.createForm.controls['Message'],
        this.createForm.controls['contactReason'],
        
      ]
        const invalid = []
        const controls = Step2Fields;
        let i: any;
        for (i in controls) {
            controls[i].markAsDirty();
            if (controls[i].invalid) {
                invalid.push(i);
            }
        }
        if (invalid[0] == undefined) {
            this.isloading = true;
            let data = {
                url: "frontend-site/sent-contact-info",
                params: { MemberId: this.createForm.controls['Member'].value, ContactUsInfoId:  this.createForm.controls['contactReason'].value, Message: this.createForm.controls['Message'].value  }
            }
            this._crud.postData(data).subscribe(data => {
                if(data['status'] == 'error'){
                    this.buttonContactSubitDiable = false;
                    this.isloading = false;
                  }else{
                    //call successpopup
                    data['msg'] = data['data'];
                    this.successPopup(data);
                  }
            });
        }else{
            this.buttonContactSubitDiable = false;
            this.isloading = false;
        }
}

getFamilyMembers() {
    //integrate with api
    let param = {
        url: "frontend-site/list-family-members-with-primary-member", params: { PrimaryMemberId: this.memberId }
    }
    this._crud.getData(param).subscribe(res => {
        this.memberData = res['data'];
        this.selectedMemberId = res['data'][0]['MemberId'];
        // console.log("list-family-members-with-primary-member-",this.memberData);
        this.memberData.forEach(element => {
            this.FamilyMembersList.push({ Id: element.MemberId, Name: element.MemberName });
            // console.log("family member-----",this.FamilyMembersList);
        });
        this.memberList = this.FamilyMembersList;

    });
}
contactInfo(){
    let param = {
        url: "frontend-site/contact-us-info"
    }
    this._crud.getData(param).subscribe(res => {
        // console.log("contactinfo data----",res['data']);
        this.contact = res['data'];
        this.selectedContactId = res['data'][0]['ContactUsInfoId'];
        this.contact.forEach(element => {
            this.contactInfoList.push({ Id: element.ContactUsInfoId, Name: element.Title , UniqueValue: element.UniqueValue});
            // console.log("family member-----",this.FamilyMembersList);
        });
this.contactList = this.contactInfoList;
    });
}
selectedMember(value){
// console.log(value);
}
selectedReason(value){
// console.log(value);
}
close(){
this.activeModal.close({ 'result': true });
}
successPopup(data){
    console.log("type-----",this.type);
    this.response = {title: data['status'],message: data['msg']}
    if(this.type === 'prayer' && data['status'] == 'success'){
        this.response = {title: data['status'],message: 'Your prayer request has been successfully submitted'}
    } else if(this.type === 'testimony' && data['status'] == 'success'){
        this.response = {title: data['status'],message: 'Thank you for sharing your testimony'}
    }else if(this.type === 'contact' && data['status'] == 'success'){
        this.response = {title: data['status'],message: 'Your message has been sent successfully'}
    }
    const modalRef = this.modalService.open(SuccessPopupComponent, {size: "md"});
    modalRef.componentInstance.user = this.response;
    modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
          if (result.result && data['status'] == 'success') {
              // /clearing all forms.
              this.activeModal.close({ 'result': true });
          }
        this.isloading = false;
        this.isloadingprayer = false;
        this.isloadingtestimony = false;
      });
}
tabSwitch(tab){
    // console.log("tab -indu---",tab)
    this.type = tab;
    if(tab == 'prayer'){
        this.tab = 2;
        this.prayerFormInit();
    }else if(tab == 'testimony'){
        this.tab = 3;

        this.testimonyFormInit();
    }else if(tab == 'contact'){
        this.tab = 1;

    }
}
prayerFormInit(){
    this.prayerRequestForm = this.formBuilder.group({
        member :[this.selectedMemberId,Validators.required],
        preyerRequest : ['',Validators.required],
        // photo :[''],
        publishPermission:[false]
    
      });
}
testimonyFormInit(){
    this.testimonyForm = this.formBuilder.group({
        member :[this.selectedMemberId,Validators.required],
        testimony : ['',Validators.required],
        photo :['',CustomValidator.imageExtensionValidator(["jpeg", "png", "jpg"])],
        publishPermission:[false]
    
      });
}
fileChange(imageInput: any){
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.imageUrl = (<FileReader>event.target).result;
      this.selectedFile = new ImageSnippet(event.target.result, file);
    //   console.log(this.selectedFile.file);
      this.fileName = this.selectedFile.file.name;
    });
  
    reader.readAsDataURL(file);
  
  }
  save(type){
        let param = this.RequestPostParams(type);
        if(type == 'prayer'){
            let Step2Fields = [
                this.prayerRequestForm.controls['preyerRequest']
            ]
            const invalid = []
            const controls = Step2Fields;
            let i: any;
            for (i in controls) {
                controls[i].markAsDirty();
                if (controls[i].invalid) {
                    invalid.push(i);
                }
            }
            if (invalid[0] == undefined) {
                this.isloadingprayer = true;
                this.buttonPrayerSubitDiable = true
                this._crud.postRequest(null,param).subscribe(res => { 
                    // console.log("res---",res);
                    this.successPopup(res);
                })
            }
        }else{
            let Step2Fields = [
                this.testimonyForm.controls['testimony']
            ]
            const invalid = []
            const controls = Step2Fields;
            let i: any;
            for (i in controls) {
                controls[i].markAsDirty();
                if (controls[i].invalid) {
                    invalid.push(i);
                }
            }
            if (invalid[0] == undefined && this.testimonyForm.valid) {
                this.isloadingtestimony = true;
                this.buttonTestimonySubitDiable = true
            this._crud.postRequest(this.selectedFile?this.selectedFile.file:'',param).subscribe(res => { 
                // console.log("res---",res);
                this.successPopup(res);
              });
            }  
        }
        
  }
  RequestPostParams(type){
      if(type == 'prayer'){
        let request = {};
        // request['image'] = this.selectedFile.file;
        request['type'] = 'Prayer';
        request['prayerRequest'] = this.prayerRequestForm.controls['preyerRequest'].value;
        request['publishPermission'] = (this.prayerRequestForm.controls['publishPermission'].value ? 1 : 0);
        //  change this shalom world beta api url to live url
        if(this.subDomain == '' || this.subDomain == undefined){
            request['url'] =  "https://swprayer.org/wp-json/v1/prayer/request";
        }else{
            request['url'] =  "https://qabackend.swprayer.org/wp-json/v1/prayer/request";
        }
        request['data'] = this.memberProfileData;
        return request;
      }else if(type == 'testimony'  || this.subDomain == undefined){
        let request = {};
        request['image'] = this.selectedFile?this.selectedFile.file:'';
        request['type'] = 'Testimony';
        request['testimony'] = this.testimonyForm.controls['testimony'].value;
        request['publishPermission'] = (this.testimonyForm.controls['publishPermission'].value ? 1 : 0);
        console.log("testmony---",this.memberProfileData)
        //  change this shalom world beta api url to live url
        if(this.subDomain == ''){
            request['url'] =  "https://swprayer.org/wp-json/v1/testimonialrequest";
        }else{
            request['url'] =  "https://qabackend.swprayer.org/wp-json/v1/testimonialrequest";
        }
        request['data'] = this.memberProfileData;
        return request;
      }
   
  }
  getMemberData(){
    let params = {
        url: "frontend-site/view-member", params: { MemberId: this.selectedMemberId }
    }
    this._crud.getData(params).subscribe(res =>{
        this.memberProfileData = res['data']['MemberProfile'];
    });
  }
  
}
