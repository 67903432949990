import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { CommonCrudService } from 'src/app/_services/common-crud.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-event-participantdetail',
    templateUrl: './event-participantdetail.component.html',
    styleUrls: ['./event-participantdetail.component.scss']
})
export class EventParticipantdetailComponent implements OnInit {
    isSpfMember;
    singleDonationExist: boolean = false;
    participantForm;
    @Input() eventForm: FormGroup;
    @Input() eventId;
    @Input() IsAccommodationRequired;
    @Input() streamList;
    @Input() isAgeRequired;
    @Input() SalutationList;
    @Input() Genders;
    @Input() memberId;
    ValidationMessage = {
        'Fname': [
            { type: "required", message: "Please enter your first name" }, { type: "hasnuber", message: "Numbers not allowed" },
            { type: "hasspecl", message: "Special charecters not allowed" },
            { type: "hasnumSpcl", message: "Numbers & special charecters not alowed" },
            { type: 'maxlength', message: 'First name can only have a maximum of 100 characters' }
        ],
        'Lname': [
            { type: "required", message: "Please enter your last name" }, { type: "hasnuber", message: "Numbers not allowed" },
            { type: "hasspecl", message: "Special charecters not allowed" },
            { type: "hasnumSpcl", message: "Numbers & special charecters not alowed" },
            { type: 'maxlength', message: 'Last name can only have a maximum of 100 characters' }
        ],
        'Age': [
            { type: "required", message: "Please enter age" },
            { pattern: "required", message: "Please enter numbers" },
        ],
        'StreamId': [
            { type: "required", message: "Stream is required" }
        ],
    }
    minAge: any;
    maxAge: any;
    minAgeValidation: boolean = false;
    maxAgeValidation: boolean = false;
    errorMsgforStream: string;
    iserrorMsgforStream: boolean = false;
    constructor(public formBuilder: FormBuilder, public _crud: CommonCrudService, public activeModal: NgbActiveModal, public modalService: NgbModal) { }

    ngOnInit(): void {
        
        let participantData
                    participantData = JSON.parse(localStorage.getItem("participant"))
        this.streamList.forEach(elementStream => {
            let lengthOfParticipants = 0
            if(participantData != null){
                participantData.forEach(element => {
                    if(element[0].ParticipantDetail.StreamId == elementStream.Id){
                        lengthOfParticipants++;
                    }
                });
                if(lengthOfParticipants == elementStream.MaxParticipantCountPerRegistration){

elementStream.disabled = true

                }else{
                   
                    elementStream.disabled = false


                }
            }else{
            elementStream.disabled = false
            }
        });
        this.isSpfMember = (localStorage.getItem('IsSpfMember') == 'true' ? true : false);
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        this.singleDonationExist = currentUser.singleDonationExist;
        this.formInit();
        console.log("streamList", this.streamList);
    }
    animateLabel(event) {
        event.parentNode.classList.add('is-focused');
    }

    animateLabelRemove(e) {
        // console.log(e.value, "AHGKND");
        if (e.value == '') {
            e.parentNode.classList.remove('is-focused');
        }
    }
    formInit() {
        this.participantForm = this.formBuilder.group({
            Key: [0],
            IsSystemMember: [0],
            ParticipantDetail: this.formBuilder.array([
                this.initParticipantDetailsField()
            ]),
        })
        this.participantForm.controls['ParticipantDetail'].controls[0].controls['Age'].disable();
    }
    initParticipantDetailsField() {
        return this.formBuilder.group({
            StreamId: [null, Validators.required],
            SalutationId: [null],
            Fname: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
            Lname: ['', [Validators.required, Validators.maxLength(100)]],
            Age: this.isAgeRequired ? ['', Validators.compose([Validators.required, Validators.pattern(/^[0-9]*$/)])] : ['', Validators.pattern(/^[0-9]*$/)],
            Gender: [null, Validators.required],
            Cost: ['', Validators.required]
        });
    }
    setTitle() {

    }
    firstNameFetch(fname, i) {
        var regex1 = /^[a-zA-Z-.,' ]{1,100}$/
        var isValid1 = regex1.test(fname);
        if (!isValid1) {
            console.log("not valid fnmae---", this.participantForm.controls['ParticipantDetail'].controls[i].controls['Fname']);
            var regex2 = /^(?=.*[0-9]).*$/
            var isValid2 = regex2.test(fname);
            // var regex3 = /^(?=.*[!@#%()<>{}]).*$/
            var regex3 = /^(?=.*[!~^&*_=+@#%()<>\[\]:;"?\/\|{}]).*$/
            var isValid3 = regex3.test(fname);

            if (isValid2 && isValid3) {
                console.log("contain nubers and spcl");
                this.participantForm.controls['ParticipantDetail'].controls[i].controls['Fname'].setErrors({ 'hasnumSpcl': true });
            } else if (isValid2) {
                console.log("contain nubers");
                this.participantForm.controls['ParticipantDetail'].controls[i].controls['Fname'].setErrors({ 'hasnuber': true });
            }
            else if (isValid3) {
                console.log("contain spcl");
                this.participantForm.controls['ParticipantDetail'].controls[i].controls['Fname'].setErrors({ 'hasspecl': true })
            } else if (fname.length > 100) {
                console.log("limit reached");
                this.participantForm.controls['ParticipantDetail'].controls[i].controls['Fname'].setErrors({ 'maxlength': true })
            }
        } else {
            console.log("valid name");
        }
    }
    lastNameFetch(lname, i) {
        var regex1 = /^[a-zA-Z-.,' ]{1,100}$/
        var isValid1 = regex1.test(lname);
        if (!isValid1) {
            console.log("not valid fnmae---");
            var regex2 = /^(?=.*[0-9]).*$/
            var isValid2 = regex2.test(lname);
            var regex3 = /^(?=.*[!~^&*_=+@#%()<>\[\]:;"?\/\|{}]).*$/
            var isValid3 = regex3.test(lname);
            if (isValid2 && isValid3) {
                console.log("contain nubers and spcl");
                this.participantForm.controls['ParticipantDetail'].controls[i].controls['Lname'].setErrors({ 'hasnumSpcl': true });
            } else if (isValid2) {
                console.log("contain nubers");
                this.participantForm.controls['ParticipantDetail'].controls[i].controls['Lname'].setErrors({ 'hasnuber': true });
            }
            else if (isValid3) {
                console.log("contain spcl");
                this.participantForm.controls['ParticipantDetail'].controls[i].controls['Lname'].setErrors({ 'hasspecl': true })
            } else if (lname.length > 100) {
                console.log("limit reached");
                this.participantForm.controls['ParticipantDetail'].controls[i].controls['Lname'].setErrors({ 'maxlength': true })
            }
        } else {
            console.log("valid name");
        }
    }


    calculatePrice() {
        console.log("inside calculate price",)
        let param = {};
        param['url'] = 'frontend-site/event/calculate-price';
        param['params'] = {};
        param['params']['EventId'] = this.eventId,
            param['params']['IsAccommodationRequired'] = (this.IsAccommodationRequired) ? 1 : 0;
        param['params']['ParticipantData'] = [];
        param['params']['SpecialRequest'] = [];

        let index = 0;
        for (const formGroup of this.participantForm.controls.ParticipantDetail.controls) {
            if (!(formGroup.get('StreamId').value.trim() == '')) {
                param['params']['ParticipantData'].push({
                    "Key": index,
                    'IsSystemMember': '0',
                    'MemberId': this.memberId,
                    'ParticipantDetail': {
                        'StreamId': formGroup.get('StreamId').value,
                        'Fname': formGroup.get('Fname').value,
                        'Lname': formGroup.get('Lname').value,
                        'SalutationId': formGroup.get('SalutationId').value,
                        'Age': formGroup.get('Age').value,
                        'Gender': formGroup.get('Gender').value
                    }
                });
            }
            index++;

        }

        // for (let i in this.specialRequestForm.value) {
        //     param['params']['SpecialRequest'].push({
        //         "Id": i,
        //         "Value": (this.specialRequestForm.value[i]) ? 1 : 0
        //     });
        // }

        if (this.participantForm.controls.ParticipantDetail.controls[0].controls.StreamId.value.trim() != '') {
            this._crud.postData(param)
                .subscribe(data => {
                    // console.log("calcualte prce----------->",data);
                    if (data['status'] != undefined && data['status'] == 'error') {

                        // this.errorFound = true;
                        // this.errorMessage = data['msg'];
                        // this.emptyParticipant = '';
                        this.eventForm.get('PaymentProcessParams').get('NetAmountToPay').setValue('');
                    } else {
                        // this.errorFound = false;
                        // this.errorMessage = '';
                        // issue place form get
                        for (let i in data['data']['Data']) {
                            // console.log("data1-----------",data['data']['Data'][i]);
                            // console.log("data1  i -----------",i);
                            this.eventForm.get('ParticipantDetails').get('ParticipantDetail')['controls'][data['data']['Data'][i].Key].get('Cost').setValue(data['data']['Data'][i].ParticipantDetail.Price);
                        }
                        this.eventForm.get('PaymentProcessParams').get('NetAmountToPay').setValue(data['data'].TotalPrice);
                        // console.log(this.eventForm.get('PaymentProcessParams').get('NetAmountToPay').value);
                        // this.discountCalculate();
                        if (data['data'].TotalPrice > 0) {
                            for (let i in data['data']['Data']) {

                                this.eventForm.get('ParticipantDetails').get('ParticipantDetail')['controls'][data['data']['Data'][i].Key].get('Cost').setValue(data['data']['Data'][i].ParticipantDetail.Price);
                            }
                            this.eventForm.get('PaymentProcessParams').get('NetAmountToPay').setValue(data['data'].TotalPrice);
                            // this.discountCalculate();
                            // this.zeroAmount = 0;
                        } else if (data['data'].TotalPrice == 0) {
                            console.log("zero amount");
                            // this.zeroAmount = 1;
                            // this.selectedPaymentMethod = 'zero'
                        }
                        else {

                            // $('.m-checkbox #' + this.SelectedRequestId).prop('checked', false);
                            // this.specialRequestForm.controls[this.SelectedRequestId].setValue('');

                        }
                    }

                    setTimeout(() => {

                    }, 0);
                }, error => {
                    console.log("Some error tiggered" + error)
                });
        }
    }
    addParticipant() {
        console.log(this.participantForm.controls.ParticipantDetail.controls[0].controls);
        let step2Fields = [
            // this.DonationForm.controls['SubscriberInfo'].controls['SalutationId'],
            this.participantForm.controls.ParticipantDetail.controls[0].controls['Fname'],
            this.participantForm.controls.ParticipantDetail.controls[0].controls['Lname'],
            this.participantForm.controls.ParticipantDetail.controls[0].controls['Age'],
            this.participantForm.controls.ParticipantDetail.controls[0].controls['StreamId'],


        ]
        const invalid = [];
        const controls = step2Fields;
        let i: any;
        for (i in controls) {
            controls[i].markAsDirty();
            if (controls[i].invalid) {
                invalid.push(i);
            }
        }
        if (invalid[0] == undefined && this.maxAgeValidation == false && this.minAgeValidation == false && this.iserrorMsgforStream == false) {
            let index = 0;
            let param = {};
            // param['url'] = 'frontend-site/event/calculate-price';
            param['params'] = {};
            // param['params']['EventId'] = this.eventId,
            // param['params']['IsAccommodationRequired'] = (this.IsAccommodationRequired) ? 1 : 0;
            param['params']['ParticipantData'] = [];
            // param['params']['SpecialRequest'] = [];
            for (const formGroup of this.participantForm.controls.ParticipantDetail.controls) {
                if (!(formGroup.get('StreamId').value.trim() == '')) {
                    param['params']['ParticipantData'].push({
                        "Key": index,
                        'IsSystemMember': '0',
                        'MemberId': this.memberId,
                        'ParticipantDetail': {
                            'StreamId': formGroup.get('StreamId').value,
                            'Fname': formGroup.get('Fname').value,
                            'Lname': formGroup.get('Lname').value,
                            'SalutationId': formGroup.get('SalutationId').value,
                            'Age': formGroup.get('Age').value,
                            'Gender': formGroup.get('Gender').value,
                            'Cost': null
                        }
                    });
                }
                index++;

            }
            var allEntries = JSON.parse(localStorage.getItem("participant"));
            if (allEntries == null) {
                localStorage.setItem("participant", JSON.stringify([param['params']['ParticipantData']]))
            } else {
                allEntries.push(param['params']['ParticipantData']);
                localStorage.setItem("participant", JSON.stringify(allEntries))
            }
            // this.activeModal.dismiss()
            this.close(true);
        } else {
            console.log("invalid")
        }
    }
    straemSelected(streamId) {
        let selectedStream = this.streamList.filter(x => { return x.Id == streamId })
//         let participantData
//                     participantData = JSON.parse(localStorage.getItem("participant"))
//                     let lengthOfParticipants = 0
//                     if(participantData != null){
//                         participantData.forEach(element => {
//                             console.log(selectedStream[0].MaxParticipantCountPerRegistration)
//                             if(element[0].ParticipantDetail.StreamId == streamId){
//                                 lengthOfParticipants++;
//                             }
//                         });
//                         if(lengthOfParticipants == selectedStream[0].MaxParticipantCountPerRegistration || lengthOfParticipants > selectedStream[0].MaxParticipantCountPerRegistration){
// console.log("max stream");
// this.errorMsgforStream = "maximum count reached"
// this.iserrorMsgforStream = true

//                         }else{
//                             this.aftStreamSelected(selectedStream)
//                             this.iserrorMsgforStream = false

//                         }
//                     }else{
//                        this.aftStreamSelected(selectedStream)
//                     }
                    
                    this.aftStreamSelected(selectedStream)

    }
    aftStreamSelected(selectedStream){
        this.participantForm.controls['ParticipantDetail'].controls[0].controls['Age'].enable();
        this.minAge = selectedStream[0].MinimumAge
        this.maxAge = selectedStream[0].MaximumAge
    }
    ageForStream(age) {
        console.log(this.minAge)
        if (this.minAge && this.maxAge) {

            if (age < this.minAge) {
                this.minAgeValidation = true
                this.maxAgeValidation = false

            } else if (age > this.maxAge) {
                this.minAgeValidation = false
                this.maxAgeValidation = true

            } else {
                this.minAgeValidation = false
                this.maxAgeValidation = false
            }

        }
    }
    close(value) {
        this.activeModal.close({ 'result': value });
    }
    keyPressNumbers(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        // Only Numbers 0-9
        if ((charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        } else {
            return true;
        }
    }
}
