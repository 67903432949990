import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonCrudService } from 'src/app/_services/common-crud.service';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent implements OnInit {
  memberId: string;
  @Input() public countryId;
  addressCreateForm: any;
  StatesList: any;
  state: any;
  response: { title: any; message: any; };
  ValidationMessage: any = {
    Address1: [{ type: "required", message: "Address Line 1 is required" }],
    Address2: [{ type: "required", message: "Address 2 is required" }],
    'City': [
      { type: 'required', message: 'Please enter your city' },
      { type: 'pattern', message: 'Please enter your city without special characters' },
      { type: 'maxlength', message: 'City can only have a maximum of 100 characters' },
  ],
      CountryId: [{ type: "required", message: "Country is required" }],
    StateId: [{ type: "required", message: "State is required" }],
    Zip: [
        // { type: "required", message: "Postal Code is required" },
        { type: "pattern", message: "Only Alpha-Numeric, hyphen and space characters are allowed" },
        { type: "minlength", message: "Postal Code should have minimum 3 characters" },
        { type: "maxlength", message: " Postal Code may have 10 characters" },
    ]
};

  constructor(private formBuilder: FormBuilder,public _crud: CommonCrudService,public modalService: NgbModal,public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.getAllStates(this.countryId);
    this.formInit();
    this.formInit();
    this.memberId = localStorage.getItem('memberId');
  }
  formInit() {
    this.addressCreateForm = this.formBuilder.group({
        AddressType: [1, Validators.required],
        Address1: ["", Validators.required],
        Address2: [""],
        City: ['', Validators.compose([Validators.required, Validators.pattern("[a-zA-Z0-9&' ,.\-]*$"),Validators.maxLength(100)])],
        CountryId: [this.countryId, Validators.required],
        StateId: [this.state, Validators.required],
        Zip: ["", Validators.compose([Validators.required, Validators.pattern('^[A-Za-z0-9 -]+$'), Validators.maxLength(10), Validators.minLength(3)])]
    });
  
  
  }
  animateLabel(event) {
    event.parentNode.classList.add('is-focused');
}

animateLabelRemove(e) {
    // console.log(e.value, "AHGKND");
    if (e.value == '') {
        e.parentNode.classList.remove('is-focused');
    }
}
getAllStates(countryId) {
  let params = {}
  params["params"] = {
      CountryId: countryId
  }
  params["url"] = "frontend-site/fetch-states/" + countryId;
  this._crud.getData(params).subscribe(data => {
      // console.log('func3');
      // console.log('data3', data['data']);
      this.StatesList = data['data'];
  })

}
save(){
  if (this.addressCreateForm.valid) {

  this.addressCreateForm.controls['CountryId'].setValue(this.countryId);
  // console.log("form data", this.addressCreateForm.value);
  this._crud.addressMemberCreate(this.addressCreateForm.value, this.memberId).subscribe(data => {
    // console.log("data",data);
    if(data['status']== "success"){
      this.successPopup(data);

    }

  })
  }
}
successPopup(data) {
  this.response = {title: data['status'],message: data['msg']}
   
   //   this.modalService.show(SuccessPopupComponent, {initialState})
   const modalRef = this.modalService.open(SuccessPopupComponent, {
       size: "md",
   });
   modalRef.componentInstance.user = this.response;
   modalRef.result.then(
    result => {
      // console.log("result-----modal",result)
        if (result.result) {
            // /clearing all forms.
            this.activeModal.close({ 'result': true });
        }
    });
}
}
