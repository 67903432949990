import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { CustomValidator } from '../../../../_services/custom-validation/CustomValidator.validator';
import { RecaptchaErrorParameters } from "ng-recaptcha";
import { SuccessPopupComponent } from '../../main/success-popup/success-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare let $: any;

@Component({
  selector: 'app-member-register',
  templateUrl: './member-register.component.html',
  styleUrls: ['./member-register.component.scss']
})
export class MemberRegisterComponent implements OnInit {

  registerForm
  CountryList: [];
  countryId;
  password;
  email;
  memberId;
  errorShow: boolean = false;
  errorMsg;
  userLocation;
  countryName;
  isloading:boolean = false;
  totalChar: boolean = false;
  atleaatAlpha: boolean = false;
  atleastDigit: boolean = false;
  step2Fields: any[];

  ValidationMessage: any = {
    Fname: [{ type: "required", message: "Please enter your first name" }, { type: "hasnuber", message: "Numbers not allowed" },
    { type: "hasspecl", message: "Special charecters not allowed" },
    { type: "hasnumSpcl", message: "Numbers & special charecters not alowed" },
    { type: 'maxlength', message: 'First name can only have a maximum of 100 characters' }],
    Lname: [{ type: "required", message: "Please enter your last name" }, { type: "hasnuber", message: "Numbers not allowed" },
    { type: "hasspecl", message: "Special charecters not allowed" },
    { type: "hasnumSpcl", message: "Numbers & special charecters not alowed" },
    { type: 'maxlength', message: 'Last name can only have a maximum of 100 characters' }],
    CountryId: [{ type: "required", message: "Country is required" }],

    Mobile: [
      { type: 'required', message: 'Please enter your phone number' },
      { type: 'pattern', message: 'Please enter a valid phone number using only numbers' }
    ],

    Email: [
      { type: 'required', message: 'Please enter your email address' },
      { type: 'pattern', message: 'Please enter a valid email address' }
    ],
    ConfirmEmail: [
      { type: 'required', message: 'Please enter your email address' },
      { type: 'pattern', message: 'Please enter a valid email address' }
    ],
    Password: [{ type: "required", message: "Please enter your password" },
    { type: "pattern", message: "Password didn't meet required criteria" },
      // { type: "minlength", message: "Please enter valid password " }

    ],
    ConfirmPassword: [{ type: "required", message: "Please enter your password" },
    { type: "pattern", message: "Numbers,space and special characters not allowed" },
    { type: "minlength", message: "Please enter valid confirm password " }
    ]

  };
  captcha: boolean;
  captcharesponse: string = '';
  constructor(public FormBuilder: FormBuilder, public _crud: CommonCrudService, public route: Router,public modalService : NgbModal) {
    this.formInit();
  }

  ngOnInit() {
    $('.radiotabs > a').on('click', function (e) {
      // ALERT("hiii");
      $(this).parent().find('input').prop('checked', false);
      $(this).find('input').prop('checked', true);
    });

    /*----Placeholder Up---*/
    $(".member-wrp input").on('focus', function () {
      $(this).parent(".focus-label").addClass("is-focused");
    });
    $(".member-wrp input").focusout(function () {
      if ($(this).val() == "") {
        $(this).parents(".focus-label").removeClass("is-focused");
      }
    });
    //tab switch toggle password
    $('input[name=confemail]').on('keydown', function (evt) {
      if (evt.originalEvent.key === 'Tab') {
        evt.preventDefault();
        if ($('.dropdown').find('.dropdown-menu').is(":hidden")) {
          $('.dropdown-toggle').dropdown('toggle');
        }
      }
    });
    this.getCountryList();
    this.getUserLocation();
  }

  animateLabel(event) {
    event.parentNode.classList.add('is-focused');
  }

  animateLabelRemove(e) {
    // console.log(e.value, "AHGKND");
    if (e.value == '') {
      e.parentNode.classList.remove('is-focused');
    }
  }
  formInit() {
    this.registerForm = this.FormBuilder.group({
      Fname: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],

      Lname: ['', [Validators.required, Validators.maxLength(100)]],
      CountryId: [this.countryId, Validators.required],
      Mobile: ['', Validators.compose([Validators.required, Validators.pattern('(?:[0-9]●?){6,14}[0-9]')])],

      Email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ])],
      ConfirmEmail: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ])],
      Password: ['', Validators.compose([Validators.required])],
      ConfirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.pattern("^[a-zA-Z1-9-']+")])]


    });
  }

  submit() {
    this.step2Fields = [

      this.registerForm.controls['Fname'],
      this.registerForm.controls['Lname'],
      this.registerForm.controls['CountryId'],
      this.registerForm.controls['Mobile'],
      this.registerForm.controls['Email'],
      this.registerForm.controls['ConfirmEmail'],
      this.registerForm.controls['Password'],
      this.registerForm.controls['ConfirmPassword'],
    ]


    const invalid = [];
    const controls = this.step2Fields;
    let i: any;
    for (i in controls) {
      controls[i].markAsDirty();
      if (controls[i].invalid) {
        invalid.push(i);
      }
    }
    console.log("inside submit form-invalid---",invalid);
    if (this.captcharesponse == '') {
      this.captcha = true
      console.log("error msg")
    } else {
      if (invalid[0] == undefined) {
        this.isloading = true;
        this.captcha = false
        console.log("api")
        // console.log("inside submit form----");
        let params = {
          url: "frontend-site/member/create", params: this.getParams()
        }
        this._crud.postData(params).subscribe(res => {
          this.isloading = false;
          // console.log("response meber register------->",res);
          if (res['status'] == 'success') {
            this.memberId = res['data']['MemberId'];
            //redirect to login page
            res['msg']="Your myShalom account has been created successfully. A verification link has been sent to your email. Please click the link to activate your myShalom account.";
            this.successPopup(res);
          } else {
            this.errorShow = true;
            this.errorMsg = res['msg'];
          }
        });
      }

    }

  }
  getParams() {

    let param = {};
    param['FirstName'] = this.registerForm.controls.Fname.value;
    param['LastName'] = this.registerForm.controls.Lname.value;
    param['CountryId'] = this.registerForm.controls.CountryId.value;
    param['MobileNumber'] = this.registerForm.controls.Mobile.value;
    console.log("email----------", this.registerForm.controls.Email.value,)
    param['Email'] = this.registerForm.controls.Email.value;
    param['ConfirmEmail'] = this.registerForm.controls.ConfirmEmail.value;
    param['Password'] = this.registerForm.controls.Password.value;
    param['ConfirmPassword'] = this.registerForm.controls.ConfirmPassword.value;
    param['IsCreateAccount'] = '1';
    // console.log("fsfs=========",param['firstname'])
    return param;

  }
  // getCountryList(pcid){
  //     let params = {
  //         url: 'frontend-site/fetch-countries/' + pcid
  //     }
  //     this._crud.getData(params).subscribe(res=>{
  //         this.CountryList = res['data'];
  //     })
  // }
  getCountryList() {
    this._crud.getCountries().subscribe(
      res => {

        this.CountryList = res['data']['CountryList'];
      },
      error => {
        console.log("Some error tiggered" + error);
      }
    );
  }
  onCountryChange(countryId) {
    // console.log("on cuntry hnge---",countryId)
    this.registerForm.controls.CountryId.setValue(countryId);
  }
  checkPassword(cpassword = null) {
    // console.log("inside con pasword-----",cpassword);
    // CustomValidator.passwordsMatch(this.password,cpassword);
    if (cpassword) {
      this.registerForm.get('ConfirmPassword').setValidators(Validators.compose([Validators.required, CustomValidator.passwordsMatch(this.password, cpassword).bind(this)]));
      this.registerForm.get('ConfirmPassword').updateValueAndValidity();
      this.ValidationMessage.ConfirmPassword = [{ type: "passwordsMatch", message: "The passwords you entered do not match" }];
    }

  }
  passwordFetch(pass) {
    //show toogle box check
    if ($('.dropdown').find('.dropdown-menu').is(":hidden")) {
      $('.dropdown-toggle').dropdown('toggle');
    }
    this.password = pass;
    //atleast 8 or more characters
    var regex1 = /^(?=.{8,}$).*$/
    var isValid1 = regex1.test(this.password);
    if (!isValid1) {
      this.totalChar = false;
      this.registerForm.controls['Password'].setErrors({ 'pattern': true });
      // console.log("not 8 or more Characters.");
    } else {
      this.totalChar = true;
      // console.log("Dcontains contain at least 8 Characters.");
    }
    //upper case and lower case characters
    var regex2 = /^(?=.*[a-z]).*$/
    var regex3 = /^(?=.*[A-Z]).*$/
    var isValid2 = (regex2.test(this.password) && regex3.test(this.password));
    if (!isValid2) {
      this.atleaatAlpha = false;
      this.registerForm.controls['Password'].setErrors({ 'pattern': true });
      // console.log("not upper and lower case.");
    } else {
      this.atleaatAlpha = true;
      // console.log("yes upper and lower case.");
    }
    //atleast one digit
    var regex4 = /^(?=.*[0-9]).*$/
    var isValid3 = regex4.test(this.password);
    if (!isValid3) {
      this.atleastDigit = false;
      this.registerForm.controls['Password'].setErrors({ 'pattern': true });
      // console.log("not one digit");
    } else {
      this.atleastDigit = true;
      // console.log("yes one digit");
    }



    this.checkPassword(this.registerForm.controls['ConfirmPassword'].value);
    // console.log("pass-----",this.password);
  }

  fetchEmail(Email) {
    this.email = Email;
    this.fetchConfirmEmail(this.registerForm.controls['ConfirmEmail'].value);
  }
  fetchConfirmEmail(ConfirmEmail = null) {
    if (ConfirmEmail) {
      this.registerForm.get('ConfirmEmail').setValidators(Validators.compose([Validators.required, CustomValidator.passwordsMatch(this.email, ConfirmEmail).bind(this)]));
      this.registerForm.get('ConfirmEmail').updateValueAndValidity();
      this.ValidationMessage.ConfirmEmail = [{ type: "passwordsMatch", message: "The emails you entered do not match" }];
    }

  }
  async getUserLocation() {
    let params = {
      url: 'frontend-site/user-location'
    }
    this._crud.getData(params).subscribe(data => {

      this.userLocation = data['data'];
      // console.log(this.userLocation);

      this.countryId = this.userLocation.CountryId
      this.countryName = this.userLocation.CountryName
      // this.getAllStates(this.countryId);
      // this.getAllCountries(this.userLocation.PrimaryCountryId);
      // this.getFooterText(this.userLocation.PrimaryCountryId);
    })

  }
  public resolved(captchaResponse: string): void {
    this.captcharesponse = captchaResponse == null ? '' : "yes"
    this.captcha = captchaResponse == null ? true : false
    console.log(`Resolved captcha with response: `, captchaResponse);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.captcha = true
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }
  firstNameFetch(fname) {
    var regex1 = /^[a-zA-Z-.,' ]{1,100}$/
    var isValid1 = regex1.test(fname);
    if (!isValid1) {
      console.log("not valid fnmae---");
      var regex2 = /^(?=.*[0-9]).*$/
      var isValid2 = regex2.test(fname);
      //   var regex3 = /^(?=.*[!@#%()<>{}]).*$/
      // var regex3 = /^(?=.*[!@#%()<>\[\]:;"?\/\|{}]).*$/
      var regex3 = /^(?=.*[!~^&*_=+@#%()<>\[\]:;"?\/\|{}]).*$/
      var isValid3 = regex3.test(fname);

      if (isValid2 && isValid3) {
        console.log("contain nubers and spcl");
        this.registerForm.controls['Fname'].setErrors({ 'hasnumSpcl': true });
      } else if (isValid2) {
        console.log("contain nubers");
        this.registerForm.controls['Fname'].setErrors({ 'hasnuber': true });
      }
      else if (isValid3) {
        console.log("contain spcl");
        this.registerForm.controls['Fname'].setErrors({ 'hasspecl': true })
      } else if (fname.length > 100) {
        console.log("limit reached");
        this.registerForm.controls['Fname'].setErrors({ 'maxlength': true })
      }
    } else {
      console.log("valid name");
    }

  }
  lastNameFetch(lname) {
    var regex1 = /^[a-zA-Z-.,' ]{1,100}$/
    var isValid1 = regex1.test(lname);
    if (!isValid1) {
      console.log("not valid fnmae---");
      var regex2 = /^(?=.*[0-9]).*$/
      var isValid2 = regex2.test(lname);
      //   var regex3 = /^(?=.*[!@#%()<>\[\]:;"?\/\|{}]).*$/
      var regex3 = /^(?=.*[!~^&*_=+@#%()<>\[\]:;"?\/\|{}]).*$/
      var isValid3 = regex3.test(lname);
      if (isValid2 && isValid3) {
        console.log("contain nubers and spcl");
        this.registerForm.controls['Lname'].setErrors({ 'hasnumSpcl': true });
      } else if (isValid2) {
        console.log("contain nubers");
        this.registerForm.controls['Lname'].setErrors({ 'hasnuber': true });
      }
      else if (isValid3) {
        console.log("contain spcl");
        this.registerForm.controls['Lname'].setErrors({ 'hasspecl': true })
      } else if (lname.length > 100) {
        console.log("limit reached");
        this.registerForm.controls['Lname'].setErrors({ 'maxlength': true })
      }
    } else {
      console.log("valid name");
    }
  }
  successPopup(data) {
    let response = {title: data['status'],message: data['msg']}
    const modalRef = this.modalService.open(SuccessPopupComponent, {size: "lg"});
    modalRef.componentInstance.user = response;
    modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
          if (result.result && data['status']=='success') {
              this.route.navigateByUrl('/member-login');
              modalRef.close({ 'result': true });
          }
      });
  }
}
