import { ConditionalExpr } from '@angular/compiler';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonCrudService } from 'src/app/_services/common-crud.service';
import { SharedService } from 'src/app/_services/shared.service';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import * as braintree from 'braintree-web';

@Component({
    selector: 'app-donation-payment',
    templateUrl: './donation-payment.component.html',
    styleUrls: ['../../../../../assets/member/css/style.css', './donation-payment.component.scss']
})
export class DonationPaymentComponent implements OnInit {

    TabActiveIndex: number = 0;
    routeParam: any;
    countryId: string;
    primaryCountryId: string;
    accounttypeList: { value: string; name: string; }[];
    selectedCauseId: any;
    donationForm: any;
    selectedMemberId: any;
    causeId: any;
    activeRecurringDate: any;
    SelectedPaymentOptionId: any;
    PaymentOptionValueId: any;
    initialSelectedAmount: any;
    initialFrequency: any;
    Currency: any;
    memberId: any;
    message: any;
    flag: number = 0;
    dataShared: any;
    days: { dayId: string; dayName: string; }[];
    isCauseSelected: boolean = false;
    causeName: any;
    state: any;
    paymentOptionsList: any;
    AcceptAnyAmount: any;
    currencyList: any;
    currencyPaymentList: any;
    frequencyList: any[];
    frequencyOptions: any[];
    hostedFieldsInstance: braintree.HostedFields;
    ValidationMessage: any = {

        'Address1': [
            { type: 'required', message: 'Please enter your street address' },
            { type: 'maxlength', message: 'Street address can only have a maximum of 100 characters' },
        ],
        'Address2': [
            { type: 'maxlength', message: 'Apt, Suite, Bldg can only have a maximum of 100 characters' },
        ],
        'City': [
            { type: 'required', message: 'Please enter your city' },
            { type: 'pattern', message: 'Please enter your city without special characters' },
            { type: 'maxlength', message: 'City can only have a maximum of 100 characters' },
        ],
        'State': [
            { type: 'required', message: 'Please select your state' }
        ],
        "Zip": [],
        'AccountNumber': [
            { type: "required", message: 'Please enter your bank account number' },
            { type: 'pattern', message: 'Please enter a valid account number' }
        ],
        'RoutingNumber': [],
        'AccountHolder': [
            { type: "required", message: 'Please enter the name on your bank account' },
            { type: 'pattern', message: 'Please enter a valid name on account' }
        ],
        'BankName': [
            { type: "required", message: 'Please enter the name of your bank' },
            { type: 'maxlength', message: 'Bank name can only have a maximum of 100 characters' }
        ],
        'Amount': [
            { type: 'required', message: "" },
            { type: 'maxlength', message: 'Amount can have a maximum of 12 characters' },
            { type: 'pattern', message: 'Please enter a valid Amount using only digits' }
        ]
    }
    memberData: any;
    FamilyMembersList: any = [];
    memberList: any;
    StatesList: any;
    ServerDate: number;
    recurringDay: any[];
    showPayNow: number;
    causeList: any[];
    InitialMethodId: any;
    countryName: any;
    PaymentMethods: any;
    PayNow: string;
    selectedFrequencyName: any;
    paymentOption: any;
    selectedFrequencyId: any;
    otheramoutValidation: boolean;
    maxOtherAmount: boolean;
    selectedPaymentOption: any;
    isLoading: boolean;
    paymentSettingId: any;
    text: any;
    selectedPaymentMethod: any;
    IsSavedAchPresent: boolean = false;
    savedAchCardButtonShow: boolean;
    savedCards: any;
    showNewCardOption: boolean;
    savedCreditCardButtonShow: any;
    selectedSavedCard: boolean = false;
    postDataJsonPrepare: any;
    formResult: Object;
    Step2Fields: any;
    isSavedCard: boolean;
    donationAmount: any;
    response: { title: any; message: any; };
    selectedPaymenttext: any;
    paymentGatewayErrorMsg: string;
    noFieldsCardNumber: number;
    noFieldsDate: number;
    noFieldsCvv: number;
    clientToken: any;
    brainTreeError: string;
    cardholdersName: any;
    decimalVal: boolean = false;
    decimalError: boolean = false;
    paymentMethodError: boolean = false;
    savedCreditCards: any;
    shownewCreditcardOption: boolean = false;
    buttonStatus: boolean = false;
    addNewCard: boolean = false;
    hideBillingAddress:boolean = false;
    loaderCreditsaved: boolean;
    loaderCreditNew: boolean;
    loaderAchSaved: boolean;
    isSpfMember:boolean;
    singleDonationExist:boolean = false;
    oneTimeHide:boolean  = true;
    cancelStatus: boolean = false;
    threeDSecure:  braintree.threeDSecure;
    threedCountryList: any;
    constructor(public sharedService: SharedService, public formBuilder: FormBuilder, public _crud: CommonCrudService, public activeRoute: ActivatedRoute, public modalService: NgbModal, public router: Router, private el: ElementRef) {

        this.countryId = localStorage.getItem('countryId')
        this.primaryCountryId = localStorage.getItem('primaryCountryId')
        this.formInit();
    }
    ngOnInit(): void {
        this.routeParam = this.activeRoute.snapshot;

        //   console.log("#@#@#@#....<", this.currentMsgFromChild1ToChild2);
        this.accounttypeList = [
            {
                value: 'checking',
                name: 'Checking'
            },
            {
                value: 'savings',
                name: 'Savings'
            }
        ];
        /*----Placeholder Up---*/
        // $(".member-wrp input").on('focus', function() {
        //     $(this).parent(".focus-label").addClass("is-focused");
        // });
        // $(".member-wrp input").focusout(function() {
        //     if ($(this).val() == "") {
        //         $(this).parents(".focus-label").removeClass("is-focused");
        //     }
        // });
this.getThreedsecureCountries();
        console.log("params-----", this.routeParam);
        if (this.routeParam.queryParams['causeId']) {
            // console.log("44444");
            this.selectedCauseId = this.routeParam.queryParams['causeId'];
            let event = { Id: this.routeParam.queryParams['causeId'], Name: this.routeParam.queryParams['causeName'] }
            this.onCauseChange(event);
        }
        if (this.routeParam.queryParams['memberId']) {
            // console.log("44444",this.routeParam.queryParams['memberId']);
            this.selectedMemberId = this.routeParam.queryParams['memberId'];
        }
        if (this.routeParam.queryParams['isSpfmember'] && this.flag < 5) {
            this.flag = this.flag + 1;
            this.dataShared = this.sharedService.currentMessage.subscribe(message => this.message = message)
            console.log("default cause", this.message);

            let event = { Id: this.message['causeId'], Name: this.message['causeName'] }
            this.selectedCauseId = this.message['causeId']
            this.onCauseChange(event);
        }
        console.log("onetimehode-----",this.routeParam.queryParams['onetimeHide'])
        if ( this.routeParam.queryParams['onetimeHide'] == 'true') {
            this.oneTimeHide = true;
        }else{

            this.oneTimeHide = false;
        }
        console.log(" this.oneTimeHide", this.oneTimeHide)
        this.memberId = this.routeParam.params['memberId'];

        this.getFamilyMembers();
        this.getAllStates(this.countryId);
        this.getCauseList();
        this.getCurrentDate();
        this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        this.singleDonationExist = currentUser.singleDonationExist;
        // this.getUserLocation();
        this.sharedService.fetchPathData('payment', 'donation');
        this.days = [{ dayId: '1', dayName: '1' }, { dayId: '2', dayName: '2' }, { dayId: '3', dayName: '3' },
        { dayId: '4', dayName: '4' }, { dayId: '5', dayName: '5' }, { dayId: '6', dayName: '6' },
        { dayId: '7', dayName: '7' }, { dayId: '8', dayName: '8' }, { dayId: '9', dayName: '9' }, { dayId: '10', dayName: '10' },
        { dayId: '11', dayName: '11' },
        {
            dayId: '12',
            dayName: '12'
        },
        {
            dayId: '13',
            dayName: '13'
        },
        {
            dayId: '14',
            dayName: '14'
        },
        {
            dayId: '15',
            dayName: '15'
        },
        {
            dayId: '16',
            dayName: '16'
        },
        {
            dayId: '17',
            dayName: '17'
        },
        {
            dayId: '18',
            dayName: '18'
        },
        {
            dayId: '19',
            dayName: '19'
        },
        {
            dayId: '20',
            dayName: '20'
        },
        {
            dayId: '21',
            dayName: '21'
        },
        {
            dayId: '22',
            dayName: '22'
        },
        {
            dayId: '23',
            dayName: '23'
        },
        {
            dayId: '24',
            dayName: '24'
        },
        {
            dayId: '25',
            dayName: '25'
        },
        {
            dayId: '26',
            dayName: '26'
        },
        {
            dayId: '27',
            dayName: '27'
        },
        {
            dayId: '28',
            dayName: '28'
        },
        ]
    }
    animateLabel(event) {
        event.parentNode.classList.add('is-focused');
    }

    animateLabelRemove(e) {
        // console.log(e.value, "AHGKND");
        if (e.value == '') {
            e.parentNode.classList.remove('is-focused');
        }
    }
    formInit() {
        this.donationForm = this.formBuilder.group({
            Member: [this.selectedMemberId],
            causeId: [this.causeId],
            Date: [this.activeRecurringDate],
            CausePaymentOptionId: [this.SelectedPaymentOptionId],
            CausePaymentOptionValueId: [this.PaymentOptionValueId],
            Amount: [this.initialSelectedAmount, Validators.compose([Validators.minLength(1), Validators.maxLength(12), Validators.pattern('^[0-9.]*$')])],
            Frequency: [this.initialFrequency],
            OtherAmount: [null],
            CurrencyISOCode: [this.Currency],
            PaymentProcessParams: this.getPaymentParams(),
            BillingAddress: this.getBillingAddress(),
            AddNewCard: [false]

        });
    }
    getPaymentParams(): FormGroup {
        return this.formBuilder.group({
            PaymentMethodId: [this.InitialMethodId],
            ProceedWithoutPaymentProcess: [null],
            PaymentFields: this.getPaymentFields(),
        })
    }
    getPaymentFields() {
        let fbGroup = this.formBuilder.group({
            AccountNumber: [null],
            RoutingNumber: [null],
            AccountHolder: [null],
            BankName: [null],
            AccountType: [null],
            PayNow: [0],
            BillingDate: [this.activeRecurringDate],
            Nonce: [''],
            ChooseCard: [''],
            SaveCardForFuture: ["1"],

        })
        setTimeout(() => {
        }, 0)
        return fbGroup;
    }
    onCauseChange(event) {
        this.Currency = null
        this.causeId = event.Id;
        this.causeName = event.Name;
        this.SelectedPaymentOptionId = null;
        this.getPaymentOptions(null, this.countryId);
        this.isCauseSelected = true;
        this.selectedFrequencyId = null
    }
    getBillingAddress(): FormGroup {
        let fbGroup = this.formBuilder.group({
            AddressId: [''],
            Address1: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
            Address2: ['', Validators.maxLength(100)],
            City: ['', Validators.compose([Validators.required, Validators.pattern("[a-zA-Z0-9&' ,.\-]*$"), Validators.maxLength(100)])],
            StateId: [this.state, Validators.required],
            CountryId: [this.countryId, Validators.required],
            Zip: [''],
        })
        return fbGroup;
    }
    getPaymentOptions(event?: any, countryId?: string, countryName?: string) {
        if (event = undefined) {
            this.countryName = event.options[event.selectedIndex].getAttirbute(countryName);
            this.countryId = event.value;
        }
        else {
            this.countryName = countryName;
            this.countryId = countryId;
        }
        let params = {}
        params["params"] = {
            PrimaryCountryId: this.primaryCountryId,
            CauseName: this.causeName,
            CauseId: this.causeId,
            CountryId: this.countryId
        }
        params["url"] = 'frontend-site/member-donation/payment-options'
        this._crud.getData(params).subscribe(data => {
            this.paymentOptionsList = data['data'];
            if (data['data'].AcceptAnyAmount) {
                this.AcceptAnyAmount = data['data'].AcceptAnyAmount;
            }
            this.causeId = this.paymentOptionsList.CauseId;
            this.getAllPaymentMethods(this.countryId);
            this.currencyList = data['data'].CurrencyCodeList;

            if (this.routeParam.queryParams['isSpfmember'] && this.flag < 5) {
                this.flag = this.flag + 1;
                this.getCurrencyOptions(this.message['CurrencyISOCode']);
            } else {
                this.getCurrencylist(this.memberId)

            }
            this.currencyPaymentList = data['data'].PaymentOptionList;
            if (this.currencyList.length) {

            }
            else {
                this.frequencyList = [];
                this.frequencyOptions = [];
            }
        });
        let countryCode =  localStorage.getItem('countryCode')

        if (countryCode == 'US') {
            this.donationForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{5,5}$/)]));
            this.donationForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
            this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code with five digits" }];
        }else if (countryCode == 'CA') {//} else if (this.countryId == 'gQFUOwHXczkw') { canada
        this.donationForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required,Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{6,7}$/)]));
        this.donationForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
        this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" },{ type: "pattern", message: "Please enter a valid postal code" }];
    }else if (countryCode == 'AU') {//  australia
        this.donationForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required,Validators.pattern(/^(?!0+$)\d{4,4}$/)]));
        this.donationForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
        this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" },{ type: "pattern", message: "Please enter a valid postal code" }];
    }else if (countryCode== 'GB') {//uk
        this.donationForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required,Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{5,8}$/)]));
        this.donationForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
        this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" },{ type: "pattern", message: "Please enter a valid postal code" }];
    }else if (countryCode == 'IE') {//ireland
        this.donationForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required,Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{7,8}$/)]));
        this.donationForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
        this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" },{ type: "pattern", message: "Please enter a valid postal code" }];
    } else {
            this.donationForm.controls['BillingAddress'].get('Zip').setValidators(Validators.pattern(/^(?!0+$)[0-9A-Za-z\s\-]{3,10}$/));
            this.donationForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
            this.ValidationMessage.Zip = [{ type: "pattern", message: "Please enter a valid postal code" }];
        }
    }
    billingZipValidation(value){
        let countryCode =  localStorage.getItem('countryCode')
        if (countryCode == 'CA') {
        var arr = value
        if(arr.length == 6 && !arr.match(" ") ){
    console.log("true 1")
        }else if(arr.length == 7 && arr[3]== " " && arr.split(" ").length == 2){
            console.log("true 2")
        }else{
            this.donationForm.controls['BillingAddress'].controls['Zip'].setErrors({ 'pattern': true })
            console.log("false ")
    
        }
    }else  if (countryCode== 'GB') {//uk
        var arr = value
        if(arr.length == 5  && !arr.match(" ") ){
    console.log("true 1")
        }else if(arr.length > 5 && arr.length < 9 ){
            if(arr.match(" ")){
    if((arr[2]== " " || arr[3]== " " || arr[4]== " ") && arr.split(" ").length == 2){
    console.log("true 2", arr.split(" ").length)
    
    }else{
        this.donationForm.controls['BillingAddress'].controls['Zip'].setErrors({ 'pattern': true })
    console.log("false ") 
    }
            }
        }else{
            this.donationForm.controls['BillingAddress'].controls['Zip'].setErrors({ 'pattern': true })
            console.log("false ")
    
        }
    }else  if (countryCode == 'IE') {//ireland
        var arr = value
        if(arr.length == 7 && !arr.match(" ") ){
    console.log("true 1")
        }else if(arr.length == 8 && arr[3]== " " && arr.split(" ").length == 2 ){
            console.log("true 2")
        }else{
            this.donationForm.controls['BillingAddress'].controls['Zip'].setErrors({ 'pattern': true })
            console.log("false ")
    
        }
    }
    }
    getFamilyMembers() {
        let param = {
            url: "frontend-site/list-family-members-with-primary-member", params: { PrimaryMemberId: this.memberId }
        }
        this._crud.getData(param).subscribe(res => {
            this.memberData = res['data'];
            if (this.selectedMemberId == undefined) {
                this.selectedMemberId = res['data'][0]['MemberId'];

            }
            this.getSelectedMemberAddress(this.selectedMemberId);
            //   console.log("member data----",this.selectedMemberId);
            this.memberData.forEach(element => {
                this.FamilyMembersList.push({ Id: element.MemberId, Name: element.MemberName });
                // console.log("family member-----",this.FamilyMembersList);
            });
            this.memberList = this.FamilyMembersList;
            // console.log("family member-----",this.memberList);

        });
    }
    getSelectedMemberAddress(memberId) {
        let param = {
            url: "frontend-site/member-donation/fetch-address", params: { MemberId: memberId }
        }
        this._crud.getData(param).subscribe(res => {

            // console.log("member data----",res['data']);
            if (res['data']) {
                this.hideBillingAddress = true;
                this.donationForm.controls.BillingAddress.controls.AddressId.setValue(res['data']['AddressId']);
                this.donationForm.controls.BillingAddress.controls.Address1.setValue(res['data']['Address1']);
                this.donationForm.controls.BillingAddress.controls.Address2.setValue(res['data']['Address2']);
                this.donationForm.controls.BillingAddress.controls.City.setValue(res['data']['City']);
                this.donationForm.controls.BillingAddress.controls.StateId.setValue(res['data']['StateId']);
                this.donationForm.controls.BillingAddress.controls.Zip.setValue(res['data']['Zip']);
                this.donationForm.controls.BillingAddress.controls.CountryId.setValue(this.countryId);


                document.getElementById("Add1").classList.add("is-focused");
                if (res['data']['Address2']) {
                    document.getElementById("Add2").classList.add("is-focused");
                } else {
                    document.getElementById("Add2").classList.remove("is-focused");
                }
                document.getElementById("City").classList.add("is-focused");
                if (res['data']['Zip']) {
                    document.getElementById("Code").classList.add("is-focused");
                } else {
                    document.getElementById("Code").classList.remove("is-focused");
                }
            } else {
                this.hideBillingAddress = false;
                this.donationForm.controls.BillingAddress.controls.AddressId.setValue('');
                this.donationForm.controls.BillingAddress.controls.Address1.setValue('');
                this.donationForm.controls.BillingAddress.controls.Address2.setValue('');
                this.donationForm.controls.BillingAddress.controls.City.setValue('');
                this.donationForm.controls.BillingAddress.controls.StateId.setValue(null);
                this.donationForm.controls.BillingAddress.controls.Zip.setValue('');
                document.getElementById("Add1").classList.remove("is-focused");
                document.getElementById("Add2").classList.remove("is-focused");
                document.getElementById("City").classList.remove("is-focused");
                document.getElementById("Code").classList.remove("is-focused");
            }


        });
    }
    getAllStates(countryId) {
        let params = {}
        params["params"] = {
            CountryId: countryId
        }
        params["url"] = "frontend-site/fetch-states/" + countryId;
        this._crud.getData(params).subscribe(data => {

            this.StatesList = data['data'];
        })

    }
    getCauseList() {
        let param = {
            url: "frontend-site/cause/list", params: { CountryId: this.countryId }
        }
        this._crud.getData(param).subscribe(res => {
            //   console.log(res);
            let data = res['data'];
            let cauList = [];
            data.forEach(element => {
                cauList.push({ Id: element.CauseId, Name: element.CauseName, Description: element.CauseDescription })
            });
            this.causeList = cauList;
        });
    }
    getCurrentDate() {
        let params = {}
        params["url"] = "frontend-site/donation/current-date";
        params["params"] = {
            Format: "d"
        };
        this._crud.getData(params).subscribe(data => {
            //   console.log('ServerDate', data['data']);
            this.ServerDate = parseInt(data['data']);
            this.recurringDate();
        })
    }
    recurringDate() {
        this.recurringDay = [];
        console.log(this.days.length, "day");
        for (let i in this.days) {
            this.recurringDay.push({ Id: parseInt(this.days[i].dayId), Name: this.days[i].dayName });
        }
        if (this.ServerDate <= 28) {
            if (this.recurringDay.some(x => x.Id === this.ServerDate)) {
                this.activeRecurringDate = this.ServerDate;
                console.log(this.activeRecurringDate, "Server");
                console.log(this.recurringDay, "Recurringgg");
                this.donationForm.controls['Date'].setValue(this.activeRecurringDate)
            }
        } else {
            this.showPayNow = 1;
            this.activeRecurringDate = 1;
            this.donationForm.controls['Date'].setValue(this.activeRecurringDate)

        }
    }
    getAllPaymentMethods(countryId) {
        let params = {}
        params["params"] = {
            sourceType: 1,
            countryId: this.paymentOptionsList.PrimaryCountry, //countryId,
            sourceId: this.causeId,
            type:null
        }
        params["url"] = "frontend-site/fetch-available-payment-methods";
        this._crud.getData(params).subscribe(data => {
            // console.log(data['data'], 'get all PaymenthMethods');
            // this.formInit();
            this.PaymentMethods = data['data'].PaymentMethods;
            this.PaymentMethods.forEach((element, i) => {
                if (element.PaymentMethod == 'ACH') {
                    if ((this.countryId == 'BprLdASASGl0' && this.Currency == 'USD') || (this.countryId == 'gQFUOwHXczkw' && this.Currency == 'CAD')) {
                        // element.text = "Bank Transfer (ACH)"
                    } else {
                        this.PaymentMethods.splice(i, 1);
                    }

                }
            });
            this.PaymentMethods.forEach(element => {
                if (element.PaymentMethod == 'ACH') {
                    element.text = "Bank Transfer (ACH)"
                } else if (element.PaymentMethod == 'Credit/Debit') {
                    element.text = "Credit / Debit Card"
                    // this.savedCreditCard()
                } else {
                    element.text = element.PaymentMethod;
                    // this.text = element.BankAccountDetails;
                }
            });
            let braintree = data['data'].PaymentMethods.filter(x => { return x.UniqueName == "credit_or_debt" })
            this.clientToken = braintree[0].CountryPaymentSettingId;
        });

    }
    getCurrencyOptions(currency) {
        let swapFrequencyList = [];
        this.donationForm.controls['OtherAmount'].setValue(null);
        console.log('currency 1', currency);
        this.Currency = currency;
        this.paymentOption = this.paymentOptionsList.PaymentOptionList.filter(x => { return x.CurrencyCode == currency });
        this.selectedPaymentOption = this.paymentOption[0];
        if (this.selectedPaymentOption) {
            this.minAmount = this.selectedPaymentOption.MinimumAmount;
        }
        this.SelectedPaymentOptionId = this.paymentOption[0].PaymentOptionId;
        this.donationForm.controls['CausePaymentOptionId'].setValue(this.SelectedPaymentOptionId);
        if (this.paymentOption) {
            this.frequencyList = this.paymentOption[0].FrequencyList
            console.log('frequencyList get curncy', this.frequencyList)
            // swap frequencylist
            this.frequencyList.forEach(element => {
                if (element.Name == 'Monthly') {
                    element.Name = 'Recurring (Monthly)'
                }
            });
            if (this.frequencyList.length > 1) {

                if (this.frequencyList[0].Name == 'Onetime') {
                    swapFrequencyList[0] = this.frequencyList[1];
                    swapFrequencyList[1] = this.frequencyList[0];
                    // console.log("sssssssss", swapFrequencyList);
                    this.frequencyList = swapFrequencyList;
                }
            }
            if (this.routeParam.queryParams['isSpfmember'] == 'false' && this.frequencyList.length > 1 && this.flag < 5) {
                this.flag = this.flag + 1;

                // this.frequencyList.splice(1, 1);
            }
            // else if (this.routeParam.queryParams['isSpfmember'] == 'false' && this.frequencyList.length > 1 && this.causeId == "158198755174B") {
            //     this.frequencyList.splice(1, 1);

            // }
            else {

                this.flag = this.flag + 1;
            }
            // code to remove one time option
            if (this.routeParam.queryParams['onetimeHide'] == 'true' && this.frequencyList.length > 1) {
                this.frequencyList.splice(1, 1);

            }
            if (this.routeParam.queryParams['isSpfmember'] && this.flag < 5) {
                this.flag = this.flag + 1;
                let frequency = {
                    id: this.message['Frequency'],
                    checked: false
                }
                this.getFrequencyOptions(frequency);
            } 
            //selectedFrequencyId
            else if(this.selectedFrequencyId != undefined && this.selectedFrequencyId != null){

                let frequency = {
                    id: this.selectedFrequencyId,
                    checked: false
                }
                this.getFrequencyOptions(frequency);
            }
            else {

                let frequency = {
                    id: this.frequencyList[0].Id,
                    checked: false
                }
                this.getFrequencyOptions(frequency);

            }
        }
        let params = {}
        params["params"] = {
            sourceType: 1,
            countryId: this.paymentOptionsList.PrimaryCountry, //this.countryId,
            sourceId: this.causeId,
            type:null
        }

        params["url"] = "frontend-site/fetch-available-payment-methods";
        this._crud.getData(params).subscribe(data => {
            this.PaymentMethods = data['data'].PaymentMethods;
            this.PaymentMethods.forEach((element, i) => {
                if (element.PaymentMethod == 'ACH') {
                    if ((this.countryId == 'BprLdASASGl0' && this.Currency == 'USD') || (this.countryId == 'gQFUOwHXczkw' && this.Currency == 'CAD')) {
                        // element.text = "Bank Transfer (ACH)"
                    } else {
                        this.PaymentMethods.splice(i, 1);
                    }

                }
            });
            this.PaymentMethods.forEach((element, i) => {
                if (element.PaymentMethod == 'ACH') {
                    element.text = "Bank Transfer (ACH)"


                } else if (element.PaymentMethod == 'Credit/Debit') {
                    element.text = "Credit / Debit Card"
                } else {
                    element.text = element.PaymentMethod;
                    // this.text = element.BankAccountDetails;
                }
            });
        })
    }
    getFrequencyOptions(frequency) {
        // console.log("get frequecy-----",frequency);
        this.PaymentOptionValueId = null;
        this.selectedFrequencyId = frequency.id;
        if (frequency.id == "any_amount") {
            if (frequency.checked == true) {
                this.PaymentOptionValueId = null;
                this.donationForm.controls['CausePaymentOptionValueId'].setValue(null);
                this.donationForm.controls['Amount'].setValue(null);
                this.frequencyOptions = [];
                this.selectedFrequencyName == this.frequencyList[0].Name;
            }
            else {
                this.donationForm.controls['Amount'].setValue(null);
                let frequency = {
                    id: this.frequencyList[0].Id,
                    checked: false,
                }
                this.getFrequencyOptions(frequency);
            }
        } else if (frequency.checked == true) {
            let selectedfrequency = this.frequencyList.filter(x => { return x.Id == frequency.id })
            this.selectedFrequencyName = selectedfrequency[0].Name;
        } else {
            let selectedfrequency = this.frequencyList.filter(x => { return x.Id == frequency.id })
            this.selectedFrequencyName = selectedfrequency[0].Name;
            console.log('selectedFrequencyName', this.selectedFrequencyName);
            if (this.selectedFrequencyName == "Onetime") {
                this.showPayNow = 0;

            }
            else {
                if (this.activeRecurringDate < this.ServerDate) {
                    this.showPayNow = 1;

                }
                else {
                    this.showPayNow = 0;
                    this.PayNow = '0';

                }
            }
            this.frequencyOptions = this.paymentOption[0].PaymentOptions.filter(x => { return x.FrequencyId == frequency.id });
            // console.log('frequencyOptions', this.frequencyOptions)
            this.getAmount(this.frequencyOptions[0].Amount, true);
        }
    }
    getAmount(amount, fromoninit = null) {
        console.log("hiiiiiiiiiiii", amount);
        this.otheramoutValidation = false;
        this.maxOtherAmount = false;
        this.initialSelectedAmount = amount
        // this.donationAmount = Math.floor(this.initialSelectedAmount);
        this.donationAmount = this.initialSelectedAmount;

        this.donationForm.controls['Amount'].setValue(this.initialSelectedAmount);
        this.donationForm.controls['OtherAmount'].setValue(null);
        // document.getElementById("otherAmount").classList.remove("is-focused");
        let amountOption = this.frequencyOptions.filter(x => { return x.Amount == amount });
        this.PaymentOptionValueId = amountOption[0].PaymentOptionValueId;
        if (this.routeParam.queryParams['isSpfmember'] && this.flag < 5) {
            this.flag = this.flag + 1;
            if (this.message['Amount'] != null) {
                let amountOption = this.frequencyOptions.filter(x => { return x.Amount == this.message['Amount'] });
                this.PaymentOptionValueId = amountOption[0].PaymentOptionValueId;
                this.initialSelectedAmount = this.message['Amount'];
                // this.donationAmount = Math.floor(this.initialSelectedAmount);
                this.donationAmount = this.initialSelectedAmount;

                this.donationForm.controls['Amount'].setValue(this.initialSelectedAmount);
            }
            else {
                this.donationForm.controls['OtherAmount'].setValue(this.message['OtherAmount']);
                // document.getElementById("otherAmount").classList.add("is-focused");
                this.upfunction(this.message['OtherAmount'])
            }

        } else if (this.routeParam.queryParams['amount'] && fromoninit) {
            this.donationForm.controls['OtherAmount'].setValue(this.routeParam.queryParams['amount']);
            this.upfunction(this.routeParam.queryParams['amount'])
        }
        this.donationForm.controls['CausePaymentOptionValueId'].setValue(this.PaymentOptionValueId);
        this.initialFrequency = this.selectedFrequencyId;
        this.donationForm.controls['Frequency'].setValue(this.initialFrequency)
    }
    // upfunction(e) {
    //     console.log(this.donationForm.controls['OtherAmount'].value)
    //     var matches = this.donationForm.controls['OtherAmount'].value.match('^[0-9]*$');
    //     var numberValue = Number(this.minAmount);
    //     this.donationForm.controls['Amount'].setValue(null);
    //     if (matches == null || this.donationForm.controls['OtherAmount'].value < numberValue) {
    //         this.otheramoutValidation = true;
    //         this.maxOtherAmount = false;
    //     } else if (this.donationForm.controls['OtherAmount'].value > 999999999999) {
    //         this.maxOtherAmount = true;
    //         this.otheramoutValidation = false;
    //     } else {
    //         this.otheramoutValidation = false;
    //         this.maxOtherAmount = false;
    //         this.donationAmount = this.donationForm.controls['OtherAmount'].value
    //     }
    // }
    upfunction(e) {
        // var matches = this.DonationForm.controls['OtherAmount'].value.match('^[0-9]*$');
        var matches = this.donationForm.controls['OtherAmount'].value.match('^(?![0.]*$)\\d{0,8}(?:\\.\\d{0,8})?$');
        var numberValue = Number(this.minAmount);
        this.donationForm.controls['Amount'].setValue(null);

        if (this.donationForm.controls['OtherAmount'].value < numberValue) {
            this.decimalVal = false;
            this.otheramoutValidation = true;
            this.maxOtherAmount = false;
            this.decimalError = false;
        } else if (matches == null) {
            let othamount = this.donationForm.controls['OtherAmount'].value;
            // if(othamount.endsWith('.') || othamount.split('.').length > 2){
            //     this.decimalError = true;
            //     this.decimalVal = false;
            // }else{
            //     this.decimalError = false;
            //     this.decimalVal = true;
            // }

            if (this.donationForm.controls['OtherAmount'].value > 99999999) {
                console.log("matches--- nulll and gtryetr other maount")
                this.maxOtherAmount = true;
                this.otheramoutValidation = false;
                this.decimalVal = true;
                this.decimalError = false;
            } else {
                console.log("matches--- nulll")
                this.decimalVal = false;
                this.decimalError = true;
                this.otheramoutValidation = false;
                this.maxOtherAmount = false;
            }
        } else if (this.donationForm.controls['OtherAmount'].value > 99999999) {
            this.maxOtherAmount = true;
            this.otheramoutValidation = false;
            this.decimalVal = true;
            this.decimalError = false;
        } else {
            this.otheramoutValidation = false;
            this.maxOtherAmount = false;
            this.decimalVal = false;
            this.decimalError = false;
            this.donationAmount = this.donationForm.controls['OtherAmount'].value
        }
    }
    minAmount(minAmount: any) {
        throw new Error('Method not implemented.');
    }
    getCurrencylist(memberId) {
        let param = {
            url: "frontend-site/member/get-currency/" + memberId
        }
        this._crud.getData(param).subscribe(data1 => {


            if ((this.currencyList.filter(x => { return x.CurrencyCode == data1['data']['CurrencyCode'] })).length > 0) {
                console.log("first if", data1['data']['CurrencyCode']);
                this.getCurrencyOptions(data1['data']['CurrencyCode']);
            } else if ((this.currencyList.filter(x => { return x.CurrencyCode == data1['data']['PrimaryCountryCurrencyCode'] })).length > 0) {
                console.log("second if", data1['data']['PrimaryCountryCurrencyCode']);
                this.getCurrencyOptions(data1['data']['PrimaryCountryCurrencyCode']);

            } else {
                console.log("third", this.currencyList[0].CurrencyCode)
                this.getCurrencyOptions(this.currencyList[0].CurrencyCode);
            }
        });
    }
    onMemberChange(value) {
        this.selectedMemberId = value.Id;
        console.log(this.selectedMemberId)
        this.getSelectedMemberAddress(this.selectedMemberId);
        this.getCurrencylist(this.selectedMemberId)
    }
    checkPayNow(date) {
        this.activeRecurringDate = date.Id;
        // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['BillingDate'].setValue(date.Id);
        if (this.activeRecurringDate < this.ServerDate) {
            this.showPayNow = 1;
            // console.log("3");

            this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(1);
        }
        else {
            this.showPayNow = 0;
            this.PayNow = '0';
            this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(0);
        }
    }
    setPaynow(value) {
        if (value == true) {
            // console.log("4");

            this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(1);
        } else if (value == false) {
            this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(0);

        }
    }
    getClientToken(CountryPaymentSettingId) {
        let params = {
            url: "frontend-site/client-token/" + CountryPaymentSettingId
        }
        this._crud.getData(params).subscribe(data => {
            // console.log("clint token data-----------",data.ClientToken)
            let token = data['data'].ClientToken;
            console.log("token----", token)
            this.createBraintreeUI(token);

        })
    }
    onPaymentMethodChange(paymentMethod) {
        this.isLoading = false;
        this.addNewCard = false;
        this.donationForm.controls.AddNewCard.setValue(false);
        this.paymentMethodError = false;
        // console.log(paymentMethod, "HAAAALOAAAA");
        let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == paymentMethod });
        this.paymentSettingId = selectedId[0].CountryPaymentSettingId;
        this.InitialMethodId = selectedId[0].PaymentMethodId;
        this.selectedPaymenttext = selectedId[0].text;
        // console.log(this.InitialMethodId, "Initial");
        let details = this.PaymentMethods.filter(x => { return x.UniqueName == paymentMethod });
        let PaymentFields = this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'];
        this.selectedPaymentMethod = paymentMethod;
        if (this.selectedPaymentMethod == "ach" || this.selectedPaymentMethod == "credit_or_debt") {
            if (this.selectedPaymentMethod == "credit_or_debt") {
                PaymentFields.get('ChooseCard').setValidators(Validators.required);

                // this.getClientToken(this.clientToken)
            }else{
                this.loaderAchSaved = true

            }
            // this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(1);
            this.donationForm.controls['PaymentProcessParams'].controls['ProceedWithoutPaymentProcess'].setValue(0);
        } else {
            this.text = (details[0].BankAccountDetails).replaceAll("|", "");
            this.selectedPaymentMethod = "manual_payment"
            this.addNewCard = false;
            this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(0);
            this.donationForm.controls['PaymentProcessParams'].controls['ProceedWithoutPaymentProcess'].setValue(1);
        }
        if (this.selectedPaymentMethod == "ach") {
            PaymentFields.get('ChooseCard').setValidators(Validators.required);

            PaymentFields.get('AccountHolder').setValidators(Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z '&-]+$/)]));
            PaymentFields.get('AccountNumber').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{6,17}$/)]));
            // PaymentFields.get('BankName').setValidators(Validators.compose([Validators.required, Validators.maxLength(100)]));
            PaymentFields.get('AccountType').setValidators([Validators.required]);
            if (this.countryId == 'BprLdASASGl0') {
                PaymentFields.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{9,9}$/)]));
                PaymentFields.get('RoutingNumber').updateValueAndValidity();
                this.ValidationMessage.RoutingNumber = [
                    { type: "required", message: 'Please enter a valid routing number' },
                    { type: 'pattern', message: 'Please enter a valid routing number with nine digits' }
                ]
            } else if (this.countryId == 'gQFUOwHXczkw') {
                PaymentFields.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{8,8}$/)]));
                PaymentFields.get('RoutingNumber').updateValueAndValidity();
                this.ValidationMessage.RoutingNumber = [
                    { type: "required", message: 'Please enter a valid routing number' },
                    { type: 'pattern', message: 'Please enter a valid routing number with eight digits' }
                ]
            } else {
                PaymentFields.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')]));
                PaymentFields.get('RoutingNumber').updateValueAndValidity();
                this.ValidationMessage.RoutingNumber = [
                    { type: "required", message: 'Please enter your bank routing number' },
                    { type: 'pattern', message: 'Please enter a valid routing number using only numbers' }
                ]
            }
        }
        else {
            PaymentFields.get('AccountHolder').setValidators(null);
            PaymentFields.get('RoutingNumber').setValidators(null);
            PaymentFields.get('AccountNumber').setValidators(null);
            PaymentFields.get('BankName').setValidators(null);
            PaymentFields.get('AccountType').setValidators(null);
        }
        PaymentFields.get('AccountHolder').updateValueAndValidity();
        PaymentFields.get('RoutingNumber').updateValueAndValidity();
        PaymentFields.get('AccountNumber').updateValueAndValidity();
        PaymentFields.get('BankName').updateValueAndValidity();
        PaymentFields.get('AccountType').updateValueAndValidity();
        // console.log(selectedId);
        if (this.selectedPaymentMethod !== "credit_or_debt") {
            let params = {};
            params['url'] = 'frontend-site/member/client-token/' + selectedId[0].CountryPaymentSettingId + '/' + this.selectedMemberId;
            params['params'] = {};
            return this._crud.getData(params)
                .subscribe(data => {
                    if (data['status'] == "success") {
                        // console.log("ach saved cards-------",data['data']['SavedCards']);
                        if (data['data']['SavedCards'].length > 0) {
                            console.log("saved card present---------------");
                            this.IsSavedAchPresent = true;
                            this.savedAchCardButtonShow = true;
                            this.savedCards = data['data']['SavedCards'];
                            this.loaderAchSaved = false
                        } else {
                            this.savedAchCardButtonShow = false;
                            this.showNewCardOption = true;
                            this.IsSavedAchPresent = false;
                            this.addNewCard = true
                            this.loaderAchSaved = false

                        }

                    }
                }, error => {
                    console.log("Some error tiggered" + error)
                });
        } else {
            // let params = {};
            // params['url'] = 'frontend-site/member/credit-card-exist' + '/' + this.routeParam.params['memberId'];
            // params['params'] = {};
            // return this._crud.getData(params).subscribe(response => {
            //     console.log("saved credit card exit for use-----", response);
            //     this.savedCreditCardButtonShow = response['data'];
            // })
            this.savedCreditCard()
        }

    }
    savedCreditCard() { // member/saved-cards/{member_id}
        console.log("craeting clint token")
        this.loaderCreditsaved = true
        let params = {};
        params['url'] = 'frontend-site/member/saved-cards/' + this.selectedMemberId + '/' + this.paymentSettingId;
        params['params'] = {};
        this._crud
            .getData(params)
            .subscribe(data => {
                console.log(data['data']['SavedCards'], "maped clint response")
                this.savedCreditCards = data['data']['SavedCards']
                if (data['data']['SavedCards'] != null && this.savedCreditCards.length > 0) {
                    this.shownewCreditcardOption = true
                    this.loaderCreditsaved = false
                } else {
                    this.shownewCreditcardOption = false
                    console.log("hereeeeeeeeeeeeeeeeeeeeeee")
                    this.addNewCard = true
                    this.getClientToken(this.clientToken)
                }
                // return res.data.ClientToken;
            });
    }
    onSelectedSavedcardChange(cardDetails) {
        // console.log("inside saved card change------->",cardDetails,this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard']);
        // this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].setValue(cardDetails)
        this.showNewCardOption = false;
        this.selectedSavedCard = true;
        this.addNewCard = false;
        this.donationForm.controls['AddNewCard'].setValue(false)
    }
    listsavedAchAccount() {
        this.IsSavedAchPresent = true;
        this.showNewCardOption = false;
    }
    AddNewAchAccount() {
        this.showNewCardOption = true;
        this.isSavedCard = true
        this.selectedSavedCard = false;
        this.IsSavedAchPresent = false;
        //clearing new accunt card ach values on tab switch
        //if values are present in the new card form animate the placeholdr up,to avoid overlaping 
        if (this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder').value) {
            // console.log("accunt holder",document.getElementById("ach_person_name"))
            // document.getElementById("ach_person_name").classList.add("is-focused");
            this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder').setValue('');
            // this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder').setValidators(null);
        }
        if (this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber').value) {
            // console.log("accunt holder routing")
            // document.getElementById("ach_routing_number").classList.add("is-focused");
            this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber').setValue('');
            // this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber').setValidators(null);
        }
        if (this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber').value) {
            // console.log("accunt number",document.getElementById("ach_account_number")) 
            // document.getElementById("account_number").classList.add("is-focused");
            this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber').setValue('');
            // this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber').setValidators(null);

        }
        if (this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType').value) {
            this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType').setValue('');
        }
    }
    submitForm(value = null) {
        this.buttonStatus = true;
        if (this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].value == true) {
            this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(1)
        } else if (this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].value == false) {
            this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(0)
        }
        console.log('formDetails', this.donationForm.value, value);
        if (this.selectedPaymentMethod == 'credit_or_debt') {
            if (value == 'savedCreditcard') {
                let lengthPri =  this.threedCountryList.filter(x => { return x == this.primaryCountryId })
                let lengthCoun =  this.threedCountryList.filter(x => { return x == this.countryId })
                if(lengthPri.length > 0 && lengthCoun.length > 0){
                    // this.isLoading = true
                    let param = {
                        url: "frontend-site/client-token/" + this.clientToken
                    }
                    this._crud.getData(param).subscribe(data => {
                        // console.log("clint token data-----------",data.ClientToken)
                        let token = data['data'].ClientToken;
                        console.log("token----", token)
            
                       

           

                let params = {
                    url: "frontend-site/fetch-payment-method-nonce/" + this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value
                }
                this._crud.getData(params).subscribe(res => {
            
                    console.log("cause list----", res['data']);
                    var threeDSecureParameters = {
                        amount: this.donationForm.controls['Amount'].value != null?this.donationForm.controls['Amount'].value:this.donationForm.controls['OtherAmount'].value,
                        nonce:res['data']['PaymentMethodNonce'], // Example: hostedFieldsTokenizationPayload.nonce
                        bin: res['data']['Bin'], // Example: hostedFieldsTokenizationPayload.details.bin
                       
                        onLookupComplete: function (data, next) {
                            // use `data` here, then call `next()`
                            next();
                          }
                        };
                        braintree.client.create({
                            authorization: token
                        }).then((clientInstance) => {
                    braintree.threeDSecure.create({
                        version: 2, // Will use 3DS2 whenever possible
                        client: clientInstance
                      }).then((threeDSecureInstance) => {
                          this.threeDSecure = threeDSecureInstance;
                   
                        const self = this
                        this.threeDSecure.on('customer-canceled', function () {
                            // the customer canceled the 3D Secure challenge
                        self.cancelStatus = true
                          });
                        this.threeDSecure.verifyCard(threeDSecureParameters).then((response) => {
                                          // submit authenticated response.nonce to the server from here
                                          console.log("3d secure response", response)
                                          this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(response.nonce);
      
                                            if(this.cancelStatus == true){
                                                this.buttonStatus = false
                                                      this.isLoading = false
                                              }else{
                                                  this.finalSubmitForm(value)  
                                                                                                              }
                                          
                        }).catch(function (error) {
                            // Handle error
                            self.buttonStatus = false
                            self.isLoading = false
                            console.log("verify card error handling",error.details.originalError.details.originalError.error.message)
                            let popup={}
                            popup['status'] = "error"
                            popup['msg'] = error.details.originalError.details.originalError.error.message
                            self.successPopup(popup);
                             
                                            });
                                        });
                                    });
                });
            })
            }else{
                this.finalSubmitForm(value)
            }
            } else {
                if(this.hostedFieldsInstance == undefined){
                    this.buttonStatus = false;

                }else{
                    this.hostedFieldsInstance.tokenize({ cardholderName: this.cardholdersName }).then((payload) => {
                        console.log('payload', payload);
                        // this.finalSubmitForm(value)
                        // submit payload.nonce to the server from here
                        let lengthPri =  this.threedCountryList.filter(x => { return x == this.primaryCountryId })
                        let lengthCoun =  this.threedCountryList.filter(x => { return x == this.countryId })
                        if(lengthPri.length > 0 && lengthCoun.length > 0){
            
                      //   if(this.primaryCountryId == 'BprLdASASGl0' && this.countryId == 'BprLdASASGl0'){
                          this.isLoading = true
                          var threeDSecureParameters = {
                              amount: this.donationForm.controls['Amount'].value != null?this.donationForm.controls['Amount'].value:this.donationForm.controls['OtherAmount'].value,
                              nonce: payload.nonce, // Example: hostedFieldsTokenizationPayload.nonce
                              bin: payload.details.bin, // Example: hostedFieldsTokenizationPayload.details.bin
                             
                              onLookupComplete: function (data, next) {
                                  // use `data` here, then call `next()`
                                  next();
                                }
                              };
                              const self = this
                              this.threeDSecure.on('customer-canceled', function () {
                                  // the customer canceled the 3D Secure challenge
                              self.cancelStatus = true
                                });
                              this.threeDSecure.verifyCard(threeDSecureParameters).then((response) => {
                                                // submit authenticated response.nonce to the server from here
                                                console.log("3d secure response", response)
                                                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(response.nonce);
            
                                                  if(this.cancelStatus == true){
                                                      this.buttonStatus = false
                                                            this.isLoading = false
                                                    }else{
                                                        this.finalSubmitForm(value)  
                                                                                                                    }
                                                
                              }).catch(function (error) {
                                  // Handle error
                                  self.buttonStatus = false
                                  self.isLoading = false
                                  console.log("verify card error handling",error.details.originalError.details.originalError.error.message)
                                  let popup={}
                                  popup['status'] = "error"
                                  popup['msg'] = error.details.originalError.details.originalError.error.message
                                  self.successPopup(popup);
                                   
                                                  });
                          }else{
                              console.log('payload --> with out 3d security', payload);  
                              this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(payload.nonce);
                              this.finalSubmitForm(value)                          
                          }
                    }).catch((error) => {
                        this.buttonStatus = false;
                        console.log('error_detail', error)
                        this.brainTreeError = '';
                        // perform custom validation here or log errors
                        if (this.donationForm.controls['Amount'].value == null && this.otheramoutValidation == false && this.maxOtherAmount == false && this.decimalVal == false && this.decimalError == false) {
                            this.donationForm.controls['Amount'].setValue(this.donationForm.controls['OtherAmount'].value)
                            this.donationForm.controls['CausePaymentOptionValueId'].setValue(null);
                        }
                        // let Step2Fields = [
                        //     this.DonationForm.controls['Amount'],
                        //     this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['BillingDate'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['FirstName'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['LastName'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['PrimaryPhone'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['SecondaryPhone'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['Email'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['BillingAddress'].controls['Address1'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['BillingAddress'].controls['Address2'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['BillingAddress'].controls['City'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['BillingAddress'].controls['CountryId'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['BillingAddress'].controls['StateId'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['BillingAddress'].controls['Zip'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['Address1'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['Address2'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['City'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['CountryId'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['StateId'],
                        //     this.DonationForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['Zip'],
                        //     this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountHolder'],
                        //     this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['RoutingNumber'],
                        //     this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountNumber'],
                        //     // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['BankName'],
                        //     this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountType'],
                        // ]
                        // console.log(this.DonationForm, "TEST");
                        // const invalid = []
                        // const controls = Step2Fields;
                        // let i: any;
                        // for (i in controls) {
                        //     controls[i].markAsDirty();
                        //     if (controls[i].invalid) {
                        //         invalid.push(i);
                        //     }
                        // }
                        // this.focusCall();
                        switch (error.code) {
    
                            case 'HOSTED_FIELDS_FIELDS_EMPTY':
                                // occurs when none of the fields are filled in
                                console.error('All fields are empty! Please fill out the form.');
                                // this.brainTreeError = 'All fields are empty! Please fill out the form.'
                                this.noFieldsCardNumber = 1;
                                this.noFieldsDate = 1;
                                this.noFieldsCvv = 1;
                                break;
                            case 'HOSTED_FIELDS_FIELDS_INVALID':
                                // occurs when certain fields do not pass client side validation
                                console.error('Some fields are invalid:', error.details.invalidFieldKeys);
                                // this.brainTreeError = 'Some fields are invalid'
                                // you can also programtically access the field containers for the invalid fields
                                // error.details.invalidFields.forEach(function(fieldContainer) {
                                //     fieldContainer.className = 'invalid';
                                // });
                                error.details.invalidFieldKeys.forEach(element => {
                                    console.log(element);
                                    if (element == 'number') {
                                        this.noFieldsCardNumber = 1;
                                    } if (element == 'cvv') {
                                        this.noFieldsCvv = 1;
                                    } if (element == 'expirationDate') {
                                        this.noFieldsDate = 1;
                                    }
                                });
                                break;
                            case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
                                // occurs when:
                                //   * the client token used for client authorization was generated
                                //     with a customer ID and the fail on duplicate payment method
                                //     option is set to true
                                //   * the card being tokenized has previously been vaulted (with any customer)
                                // See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.fail_on_duplicate_payment_method
                                console.error('This payment method already exists in your vault.');
                                this.brainTreeError = 'This payment method already exists in your vault.'
                                break;
                            case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
                                // occurs when:
                                //   * the client token used for client authorization was generated
                                //     with a customer ID and the verify card option is set to true
                                //     and you have credit card verification turned on in the Braintree
                                //     control panel
                                //   * the cvv does not pass verfication (https://developers.braintreepayments.com/reference/general/testing/#avs-and-cvv/cid-responses)
                                // See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.verify_card
                                console.error('CVV did not pass verification');
                                break;
                            case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
                                // occurs for any other tokenization error on the server
                                console.error('Tokenization failed server side. Is the card valid?');
                                this.brainTreeError = 'Tokenization failed server side. Is the card valid?'
                                break;
                            case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
                                // occurs when the Braintree gateway cannot be contacted
                                console.error('Network error occurred when tokenizing.');
                                this.brainTreeError = 'Network error occurred when tokenizing.'
                                break;
                            default:
                                console.error('Something bad happened!');
                                this.brainTreeError = 'Something bad happened!  '
                        }
                    });
                }
                
            }
        }
        else {
            this.finalSubmitForm(value);
        }

    }
    public savedCardLoding: boolean = false;
    finalSubmitForm(type = null) {
        // if (this.donationForm.controls['Amount'].value == null && this.otheramoutValidation == false && this.maxOtherAmount == false) {
        //     this.donationForm.controls['Amount'].setValue(this.donationForm.controls['OtherAmount'].value)
        //     this.donationForm.controls['CausePaymentOptionValueId'].setValue(null);
        // }
        if (type == null) {
            this.savedCardLoding = false;
            this.Step2Fields = [
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountHolder'],
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['RoutingNumber'],
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountNumber'],
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountType'],
            ]
        } else if (type == "savedCard") {
            this.savedCardLoding = true;
            this.Step2Fields = [
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard']
            ]
        } else if (type == 'savedCreditcard') {
            this.savedCardLoding = true;
            console.log("hhhhh", this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'])
            this.Step2Fields = [
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard']
            ]
        }

        console.log(this.donationForm, "TEST", this.IsSavedAchPresent);
        console.log(this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard']);

        const invalid = []
        const controls = this.Step2Fields;
        let i: any;
        for (i in controls) {
            controls[i].markAsDirty();
            if (controls[i].invalid) {
                invalid.push(i);
            }
        }

        if (invalid[0] == undefined) {
            this.isLoading = true;
            console.log("validdddd", this.Step2Fields);
            // this.apiloading = true;
            let params = {}
            params["url"] =
                "frontend-site/process/" + this.selectedMemberId + "/" + this.selectedCauseId;
            params["params"] = this.RequestPostParams({
                type: this.selectedPaymentMethod,
            });
            this._crud.postData(params).subscribe(data => {
                this.isLoading = false;
                this.buttonStatus = false;
                this.formResult = data;
                if (this.formResult['status'] == "success") {

                    this.successPopup(this.formResult);
                    this.donationForm.reset();
                    this.isCauseSelected = false

                    // $('.focus-label.is-focused').removeClass('is-focused');
                    this.selectedPaymentMethod = "";
                } else {
                    this.buttonStatus = false;
                    this.successPopup(this.formResult);

                }
            })
        } else {
            // this.focusCall();
            this.buttonStatus = false;
            console.log("invalid")
        }
    }
    successPopup(data) {
        if(data['status'] == 'success'){
            this.response = {title: 'Thank You',message: 'Your donation has been processed successfully'}
        }
        else{
            // this.response = {title: data['status'],message: data['msg']}
            this.response = {title: 'Error',message: data['msg']}
        }   
        //   this.modalService.show(SuccessPopupComponent, {initialState})
        const modalRef = this.modalService.open(SuccessPopupComponent, {
            size: "md",
        });
        modalRef.componentInstance.user = this.response;
        modalRef.result.then(
            result => {
                //   console.log("result-----modal",result)
                if (result.result && data['status'] == "success") {
                    // /clearing all forms.
                    this.router.navigateByUrl("/memberDashboard/mydonation/" + this.routeParam.params['memberId'])
                    this.donationForm.reset();
                    this.selectedPaymentMethod = "";
                    this.isCauseSelected = false;
                    this.causeId = null;
                    this.formInit();
                    this.ngOnInit();


                }
                //  else{
                //      this.selectedPaymentMethod = "";
                //      this.donationForm.controls['PaymentProcessParams'].controls['PaymentMethodId'].setValue(0);
                //  }
            });
    }
    RequestPostParams(paymentFields = null, nonce = null) {
        this.postDataJsonPrepare = {};
        this.postDataJsonPrepare['PaymentProcessParams'] = {};
        this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'] = {};
        this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['BillingAddress'] = {};

        // console.log(this.donationForm.controls.BillingAddress.controls.AddressId.value);
        this.postDataJsonPrepare['MemberId'] = this.donationForm.controls.Member.value;
        this.postDataJsonPrepare['CausePaymentOptionId'] = this.donationForm.controls.CausePaymentOptionId.value;
        this.postDataJsonPrepare['CausePaymentOptionValueId'] = this.donationForm.controls.CausePaymentOptionValueId.value;

        // if ( this.otheramoutValidation == false && this.maxOtherAmount == false) {
        // }else{
        //     this.postDataJsonPrepare['CausePaymentOptionValueId'] =  this.donationForm.controls.CausePaymentOptionValueId.value;

        // }
        this.postDataJsonPrepare['Frequency'] = this.donationForm.controls.Frequency.value;
        this.postDataJsonPrepare['Amount'] = this.donationForm.controls.Amount.value;
        this.postDataJsonPrepare['CurrencyISOCode'] = this.donationForm.controls.CurrencyISOCode.value;
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["AddressId"] = this.donationForm.controls.BillingAddress.controls.AddressId.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["Address1"] = this.donationForm.controls.BillingAddress.controls.Address1.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["Address2"] = this.donationForm.controls.BillingAddress.controls.Address2.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["City"] = this.donationForm.controls.BillingAddress.controls.City.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["StateId"] = this.donationForm.controls.BillingAddress.controls.StateId.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["CountryId"] = this.countryId
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["Zip"] = this.donationForm.controls.BillingAddress.controls.Zip.value
        if (this.selectedFrequencyName == "Recurring (Monthly)") {
            this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["PayNow"] = this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].value

        }
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingDate"] = this.donationForm.controls.Date.value

        if (paymentFields != null) {
            // console.log(this.selectedFrequencyName,paymentFields);
            this.postDataJsonPrepare["PaymentProcessParams"]["SaveCardForFuture"] =
                "0";
            if (paymentFields['type'] == "manual_payment") {
                this.postDataJsonPrepare["PaymentProcessParams"]["ProceedWithoutPaymentProcess"] = "1";
                // this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["PayNow"] = "0"
            } else {
                this.postDataJsonPrepare["PaymentProcessParams"][
                    "ProceedWithoutPaymentProcess"
                ] = "0";
            }

            this.postDataJsonPrepare["PaymentProcessParams"][
                "PaymentMethodId"
            ] = this.InitialMethodId;
            if (paymentFields.type == "ach") {
                // if (this.selectedSavedCard == false) {
                if (this.savedCardLoding == false) {

                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                        "AccountHolder"
                    ] = this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountHolder.value;
                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                        "AccountNumber"
                    ] = this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountNumber.value;
                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                        "RoutingNumber"
                    ] = this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.RoutingNumber.value;
                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                        "AccountType"
                    ] = this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountType.value;

                    if (this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.SaveCardForFuture.value) {
                        this.postDataJsonPrepare["PaymentProcessParams"][
                            "SaveCardForFuture"
                        ] = "1";
                    } else {
                        this.postDataJsonPrepare["PaymentProcessParams"][
                            "SaveCardForFuture"
                        ] = "0";
                    }
                }
                else {
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "PaymentGatewayTokenId"
                    ] = this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
                }
            } else if (paymentFields.type == "credit_or_debt") {
                // if (this.selectedSavedCard == false) {
                if (this.savedCardLoding == false) {

                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                        "Nonce"
                    ] = this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].value

                    if (this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.SaveCardForFuture.value) {
                        this.postDataJsonPrepare["PaymentProcessParams"][
                            "SaveCardForFuture"
                        ] = "1";
                    } else {
                        this.postDataJsonPrepare["PaymentProcessParams"][
                            "SaveCardForFuture"
                        ] = "0";
                    }
                } else {

                    if(nonce == null){
                        this.postDataJsonPrepare["PaymentProcessParams"][
                            "PaymentGatewayTokenId"
                        ] = this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;    
                    }else{
                        this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['Nonce'] = nonce;  
                    }
                   
                }
            }

        }


        return this.postDataJsonPrepare;
    }
    nextStep() {
        this.Step2Fields = []
        if (this.TabActiveIndex < 3) {
            if (this.TabActiveIndex == 0) {

                if (this.donationForm.controls['Amount'].value == null && this.otheramoutValidation == false && this.maxOtherAmount == false && this.decimalVal == false && this.decimalError == false) {
                    this.donationForm.controls['Amount'].setValue(this.donationForm.controls['OtherAmount'].value)
                    this.donationForm.controls['CausePaymentOptionValueId'].setValue(null);
                }

                if (this.donationForm.controls['Amount'].value == null) {
                    if (this.otheramoutValidation == false && this.maxOtherAmount == false && this.decimalVal == false && this.decimalError == false) {
                        this.TabActiveIndex++;

                    } else {
                        document.getElementById("other_amount").classList.add("is-focused");
                        console.log("focus other amount");
                    }

                } else {
                    this.TabActiveIndex++;

                }


            } else if (this.TabActiveIndex == 1) {
                this.Step2Fields = [
                    this.donationForm.controls['BillingAddress'].controls['Address1'],
                    this.donationForm.controls['BillingAddress'].controls['Address2'],
                    this.donationForm.controls['BillingAddress'].controls['City'],
                    this.donationForm.controls['BillingAddress'].controls['StateId'],
                    this.donationForm.controls['BillingAddress'].controls['Zip'],
                ]
                const invalid = []
                const controls = this.Step2Fields;
                let i: any;
                for (i in controls) {
                    controls[i].markAsDirty();
                    if (controls[i].invalid) {
                        invalid.push(i);
                    }
                }

                if (invalid[0] == undefined && this.InitialMethodId != undefined) {
                    this.TabActiveIndex++;
                    console.log(this.InitialMethodId);
                    this.paymentMethodError = false;

                } else {
                    console.log("error case-------", invalid[0], this.InitialMethodId)
                    if (this.InitialMethodId != undefined) {
                        this.paymentMethodError = false;
                    } else {
                        this.paymentMethodError = true;
                    }

                }


            }
        }
    }
    prevStep() {
        if (this.TabActiveIndex < 3 && this.TabActiveIndex != 0) {
            this.TabActiveIndex--;
        }
    }
    findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
        return document.querySelector('.hosted-field--label[for="' + field.container.id + '"]');
    }

    createBraintreeUI(token) {
        braintree.client.create({
            authorization: token
        }).then((clientInstance) => {
            this.loaderCreditsaved = false
            this.loaderCreditNew = false
            braintree.hostedFields.create({
                client: clientInstance,
                styles: {
                    // Style all elements
                    // 'input': {
                    //   'font-size': '16px',
                    //   'color': '#3A3A3A'
                    // },
                    '.invalid': {
                        'color': '#D0041D'
                    },

                    // Styling element state
                    'input': {
                        'font-size': '16px!important',
                        "font-weight": '500!important',
                        'font-family': "'Poppins', sans-serif !important",
                        'color': '#0D0D0D',
                        ':placeholder': {
                            'font-weight': '500!important',
                            'color': '#9F9F9F!important',
                        }
                    },

                    // Media queries
                    // Note that these apply to the iframe, not the root window.
                    // '@media screen and (max-width: 700px)': {
                    //     'input': {
                    //         'font-size': '14px'
                    //     }
                    // }
                },

                // The hosted fields that we will be using
                // NOTE : cardholder's name field is not available in the field options
                // and a separate input field has to be used incase you need it
                fields: {
                    number: {
                        selector: '#card-number',
                        placeholder: 'Card Number'
                    },
                    cvv: {
                        selector: '#cvv',
                        placeholder: 'CVV'
                    },
                    expirationDate: {
                        selector: '#expiration-date',
                        // placeholder: 'Expiration Date (MM/YY)'
                        placeholder: 'MM/YY'
                    }
                }
            }).then((hostedFieldsInstance) => {
                console.log(hostedFieldsInstance);
                this.hostedFieldsInstance = hostedFieldsInstance;
                hostedFieldsInstance.on('focus', (event) => {
                    const field = event.fields[event.emittedBy];
                    const label = this.findLabel(field);
                    if (label['htmlFor'] == 'card-number') {
                        this.noFieldsCardNumber = 0;
                    }
                    if (label['htmlFor'] == 'expiration-date') {
                        this.noFieldsDate = 0;
                    }
                    if (label['htmlFor'] == 'cvv') {
                        this.noFieldsCvv = 0;
                    }

                    // label.classList.remove('filled'); // added and removed css classes
                    // can add custom code for custom validations here
                });
                hostedFieldsInstance.on('blur', (event) => {
                    const field = event.fields[event.emittedBy];
                    // const label = this.findLabel(field); // fetched label to apply custom validations
                    // can add custom code for custom validations here
                });

                hostedFieldsInstance.on('empty', (event) => {
                    const field = event.fields[event.emittedBy];
                    // can add custom code for custom validations here
                });

                hostedFieldsInstance.on('validityChange', (event) => {
                    const field = event.fields[event.emittedBy];
                    const label = this.findLabel(field);
                    if (field.isPotentiallyValid) { // applying custom css and validations
                        // label.classList.remove('invalid');
                    } else {
                        // label.classList.add('invalid');
                    }
                    // can add custom code for custom validations here
                });
            });
            braintree.threeDSecure.create({
                version: 2, // Will use 3DS2 whenever possible
                client: clientInstance
              }).then((threeDSecureInstance) => {
                  this.threeDSecure = threeDSecureInstance;
            });
        }).catch(error => {
            console.log('error', error);
            if (error.name == 'BraintreeError') {
                this.paymentGatewayErrorMsg = "Payment Gateway is not configured"
            }
        })
    }
    clearCardDetails() {
        if (this.hostedFieldsInstance) {
            this.hostedFieldsInstance.clear('number');
            this.hostedFieldsInstance.clear('cvv');
            this.hostedFieldsInstance.clear('expirationDate');
        }

    }
    roundOtherAmount() {
        if (this.donationForm.controls['OtherAmount'].value) {
            // console.log("othermaount value------",this.DonationForm.controls['OtherAmount'].value)
            if(!isNaN(this.donationForm.controls['OtherAmount'].value)){
                let othAmount = this.donationForm.controls['OtherAmount'].value;
                let fixedOthAmount = parseFloat(othAmount).toFixed(2);
                // console.log("fixed oth amount-----",fixedOthAmount)
                this.donationForm.controls['OtherAmount'].setValue(fixedOthAmount);
                this.donationAmount = this.donationForm.controls['OtherAmount'].value
            }else{
                this.donationForm.controls['OtherAmount'].setValue(null);
                // document.getElementById("otherAmount").classList.remove("is-focused");
            }
               
        }

    }
    isAddNewCard(value) {
        console.log(value);
        this.loaderCreditNew = true
        this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls['ChooseCard'].reset(); //setValue('')
        value == true ? this.addNewCard = true : this.addNewCard = false;
        if (this.selectedPaymentMethod == 'credit_or_debt' && value == true) {
            this.getClientToken(this.clientToken)
        }

    }
    getThreedsecureCountries(){
        let params = {}
        params["url"] = "frontend-site/3d-secure-countries";
        this._crud.getData(params).subscribe(data => {
            this.threedCountryList = data['data'];
            console.log('list', this.threedCountryList);
      
        }) 
      }
}
