<!-- <iframe
  [src]= 'urldummy'
  frameborder="0"
  webkitallowfullscreen
  mozallowfullscreen
  allowfullscreen
></iframe> -->

<div class="contact-alert" [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">
          &times;
      </span>
  </button>
    <div class="popup-inner">
        <div class="text-center pt-5 popup-title">
            <h2>Video</h2>
            <p>Together we can change the world</p>
        </div>
        <div class="contact-form">
            <div class="row">
                <iframe [src]='safeURL' frameborder="0" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>