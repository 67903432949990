import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FamilyMemberComponent } from '../family-member/family-member.component';
import { MainComponent } from '../main.component';
import { MainModule } from '../main.module';

const routes: Routes = [
  {
      'path': '',
      'component': MainComponent,
      'children': [
          {
              'path': '',
              'component': FamilyMemberComponent,
          },
      ],
  },
];

@NgModule({
  declarations: [FamilyMemberComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MainModule,
  ]
})
export class FamilyMemberModule { }
