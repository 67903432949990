<!-- <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="bx-white ">
        <table *ngFor="let event of dataList">
            <a style="cursor:pointer;" (click)="registerEvent(event.EventStartDate,event.EventTypeName,event.Title)">
            <tr>
                <figure>
                    <img src="{{event.logo_url}}" alt="Shalom ">
                </figure>
                <span>{{event.EventStartDate}}</span>
                <span>{{event.EventTypeName}}</span>
                <span>{{event.Title}}</span>
                <hr>
            </tr>
            </a> 
            
        </table>
        
    </div>
  </div> -->
<div class="contact-alert" [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <button type="button" class="close" (click)="close()" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
    <div class="popup-inner">
        <div class="text-center pt-5 popup-title">
            <h2>Add Registration</h2>
            <p>Together we can change the world</p>
        </div>
        <form>
            <div class="contact-form">
                <div class="row">
                    <div class="col-6 col-md-4 mb-3 pop-up-event-list" *ngFor="let event of dataList">
                        <a style="cursor:pointer;" (click)="registerEvent(event.EventStartDate,event.EventTypeName,event.Title)">
                            <figure>
                                <img src="{{event.logo_url}}" alt="Shalom ">
                            </figure>
                            <div class="event-desc-bx">
                                <span class="d-block event-name-txt">{{event.EventTypeName}}</span>
                                <!-- <span class="d-block event-date-txt">{{event.EventStartDate}}</span> -->
                                <span class="d-block event-title-txt">{{event.Title}}</span>
                                <span class="d-block event-date-txt">{{event.startMonth}}
                                    {{event.startDay}}{{event.startYear == event.endYear? '': ','}} {{event.startYear == event.endYear ? '' :  event.startYear}} -
                                    {{event.endMonth == event.startMonth ? '' : event.endMonth}} {{event.endDay}}{{','}} {{event.endYear}}</span>
                            </div>
                        </a>
                    </div>
                    <!-- <div class="col-md-12 mt-2 mb-3 d-flex justify-content-center">
                        <button type="submit" class="btn-border btn-modal mr-2">Cancel</button>
                        <button type="submit" class="btn-filled btn-modal">Save</button>
                    </div> -->

                </div>
            </div>
        </form>
    </div>
</div>