<section class="member-wrp">
    <div class="bg-body">
        <div class="login-wrp ">
            <div class="login-form">
                <figure>
                    <img src="./assets/member/images/page-logo.svg" alt="Shalom">
                </figure>
                <form [formGroup]="resetPasswordForm">
                    <div class="form-wrp">
                        <h3>Reset Your Password</h3>
                        <div class="form-inner">
                            <div class="form-group focus-label" id="">
                                <label class="form-label-of" for="">New Password</label>
                                <input class="form-control" id="" formControlName="newPassword" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="passwordFetch($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.newPassword">
                                    <span class="error-message"
                                        *ngIf="resetPasswordForm.get('newPassword').hasError(validation.type) && (resetPasswordForm.get('newPassword').dirty || resetPasswordForm.get('newPassword')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                            <div class="form-group focus-label" id="">
                                <label class="form-label-of" for="">Confirm Password</label>
                                <input class="form-control" id="" formControlName="confirmPassword" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="checkPassword($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.confirmPassword">
                                <span class="error-message"
                                    *ngIf="resetPasswordForm.get('confirmPassword').hasError(validation.type) && (resetPasswordForm.get('confirmPassword').dirty || resetPasswordForm.get('confirmPassword')?.touched) || resetPasswordForm.get('confirmPassword').hasError('passwordMismatch')">
                                    {{validation.message}}
                                </span>
                                </span>
                            </div>

                            <p class="error mb-3">{{tokenErrorMsg}}</p>
                            <div class="d-flex remember-me-wrp pb-3">

                                <button type="submit" class="btn-blue-login mr-3" (click)="Submit()">Request</button>
                                <button type="submit" class="btn-blue-login" routerLink="/member-login">Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>