<div class="mall-slide">
    <div class="slid-content">
        <div class="mall-show-slide fade" *ngFor="let item of Data;">
            <img src="{{item.imageUrl}}" style="width:100%">
            <h6>{{item.date}}</h6>
            <h5>{{item.name}}</h5>
            <button class="btn btn-primary btn-common">SUBSCRIBE</button>

        </div>
        <div class="mall-carousel-ind">
            <ul>
                <li *ngFor="let item of Imagedata; let i = index;" [class.mall-this]="startIndex - 1 === i"></li>
            </ul>
        </div>
    </div>
</div>