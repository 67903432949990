import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { DonationComponent } from './donation.component';
import { MainComponent } from '../main.component';
import { MainModule } from '../main.module';
import { FormGroup, FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxBraintreeModule } from 'ngx-braintree';
import { SavedcardlistModule } from '../savedcardlist/savedcardlist.module';

const routes: Routes = [
  {
      'path': '',
      'component': MainComponent,
      'children': [
          {
              'path': '',
              'component': DonationComponent,
          },
      ],
  },
];


@NgModule({
  declarations: [DonationComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes), 
    MainModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NgxBraintreeModule,
    SavedcardlistModule
  ],
})
export class DonationModule { }
