<section class="member-wrp">
    <div class="bg-body">
        <div class="login-wrp ">
            <div class="login-form">
                <form [formGroup]="forgotPasswordForm">
                    <div class="row form-wrp">
                        <div class="col-md-7 form-wrp-left">
                            <figure>
                                <img src="./assets/member/images/img-login-left-brown.svg" alt="Shalom">
                            </figure>
                        </div>
                        <div class="col-md-5 form-wrp-right">
                            <div class="login-head-title">
                                <h1>Hi, Welcome Back!</h1>
                                <p>Enter your email to reset the password</p>
                            </div>

                            <div class="form-inner">
                                <div class="form-group focus-label" id="userName" [ngClass]="userEmail ? 'is-focused':'' ">
                                    <label class="form-label-of" for="Email">Email</label>
                                    <input autocomplete="off" formControlName="Email" id="Email" type="email" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.Email">
                                        <span class="error-message"
                                            *ngIf="forgotPasswordForm.get('Email').hasError(validation.type) && (forgotPasswordForm.get('Email').dirty || forgotPasswordForm.get('Email')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div>
                                <span class="error-message mb-3">{{displayMsg}}</span>
                                <div class="captcha-holder mb-3">
                                    <re-captcha formControlName="captcha" (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled" required siteKey="6Lekt9saAAAAAP9hDIjQMcIT1MZTPLLcekqRPz4u"></re-captcha>
                                    <span class="m-form__help" *ngIf="notcaptcha">
                                    <span class="error-message" >Captcha is required</span>
                                    </span>
                                </div>
                                <button type="submit" class="btn-common mb-3" (click)="Submit()" [disabled]="buttonDisable"><span class="span-loader-padding">Request</span> <i *ngIf="isLoading" class="spinner-border"></i></button>
            
                                <button type="submit" class="btn-border" routerLink="/member-login">Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
        <ul class="nav-menu-btm">
            <li><a href="/privacy-policy">Privacy Notice</a></li>
            <li><a href="/help">Help</a></li>
            <li><a href="/terms-and-conditions">Conditions of Use</a></li>
        </ul>
    </div>
</section>