<div class="contact-alert" [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <!-- <div class="alert-top-bar">
        <div class="row align-item-center">
            <div class="col-7 col-md-7">
                <h5>Edit Contact Info</h5>
            </div>
            <div class="col-5 col-md-5 text-right">
                <figure class="mb-0">
                    <img src="./assets/member/images/dashboard-logo.svg " alt="Shalom ">
                </figure>
                <button type="button" class="close" (click)="activeModal.dismiss('Cross click')">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
        </div>
    </div> -->
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">
            &times;
        </span>
    </button>
    <div class="popup-inner">
        <div class="text-center pt-5 popup-title">
            <h2>Edit Contact Information</h2>
            <p>Together we can change the world</p>
        </div>
        <form [formGroup]="editContactForm">
            <div class="contact-form">
                <div class="row">
                    <!-- <div class="col-md-12 prof-add-member">
                        <div class="img-upload-bx">

                            <figure class="text-center" >
                                <a *ngIf="!notValidImage" class="close-button" (click)="removePhoto()">
                                    <i  class="la la-remove">x</i>
                                </a>
                                <img [src]="url " alt=" ">

                            </figure>
                            <label for="fileInput" style="display: block;"><a style="cursor:pointer;"
                                    class="add-photo-link mb-5"><img src="./assets/member/images/icon-camera.svg "
                                        alt="Shalom ">{{notValidImage ? 'Upload' : 'change'}}</a></label>
                            <input hidden id="fileInput" type='file' (change)="fileChange($event)">
                        </div>
                    </div> -->
                    <div class="col-md-6">
                        <div class="form-group focus-label" id="email">
                            <label class="form-label-of" for="">Email</label>
                            <input class="form-control" id="" formControlName="email" type="email"
                                (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"
                                readonly>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <ng-select [formControl]="editContactForm.controls.SalutationId" [items]="SalutationList"
                                placeholder="Title" bindValue="Id" bindLabel="Name"
                                [(ngModel)]="selectedSalutationId">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <!-- <ng-select [formControl]="createForm.controls.Member" [items]="memberList" placeholder="Member Name" bindValue="Id" bindLabel="Name" [(ngModel)]="selectedMemberId" (change)="selectedMember($event)">
                        </ng-select> -->
                            <div class="form-group focus-label" id="fname">
                                <label class="form-label-of" for="">First Name</label>
                                <input class="form-control" id="" formControlName="firstName" type="text"
                                    (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"
                                    (input)="firstNameFetch($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.firstName">
                                    <span class="error-message"
                                        *ngIf="editContactForm.get('firstName').hasError(validation.type) && (editContactForm.get('firstName').dirty || editContactForm.get('firstName')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label" id="lname">
                            <label class="form-label-of" for="">Last Name</label>
                            <input class="form-control" id="" formControlName="lastName" type="text"
                                (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"
                                (input)="lastNameFetch($event.target.value)">
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.lastName">
                                <span class="error-message"
                                    *ngIf="editContactForm.get('lastName').hasError(validation.type) && (editContactForm.get('lastName').dirty || editContactForm.get('lastName')?.touched)">
                                    {{validation.message}}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label" id="mob">
                            <label class="form-label-of" for="">Mobile Number</label>
                            <input class="form-control" id="" formControlName="primaryNumber" type="text"
                                (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.primaryNumber">
                                <span class="error-message"
                                    *ngIf="editContactForm.get('primaryNumber').hasError(validation.type) && (editContactForm.get('primaryNumber').dirty || editContactForm.get('primaryNumber')?.touched)">
                                    {{validation.message}}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label" id="secNum">
                            <label class="form-label-of" for="">Secondary Number</label>
                            <input class="form-control" id="" formControlName="secondaryNumber" type="text"
                                (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.secondaryNumber">
                                <span class="error-message"
                                    *ngIf="editContactForm.get('secondaryNumber').hasError(validation.type) && (editContactForm.get('secondaryNumber').dirty || editContactForm.get('secondaryNumber')?.touched)">
                                    {{validation.message}}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label">
                                <ng-select [formControl]="editContactForm.controls.Gender" [items]="genderList"
                                    placeholder="Gender" bindValue="Id" bindLabel="Name"
                                    [(ngModel)]="selectedGenderId">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label" id="dob">
                                <label class="form-label-of" for="">Date of Birth</label>
                                <!-- <input type="text" [(ngModel)]="selectedStartDate"
                                    (ngModelChange)="onDateChange($event)" class="form-control dob-input"
                                     (focus)="animateLabel($event.target)"
                                    (focusout)="animateLabelRemove($event.target)"
                                    [formControl]="editContactForm.controls.Dob" #dp="bsDatepicker"
                                    [bsConfig]="bsConfig" bsDatepicker [(bsValue)]="myDateValue" id="txtdate"> -->

                                    <input class="form-control dob-input" type="text"  (focus)="animateLabel($event.target)"
                                    (focusout)="animateLabelRemove($event.target)" [formControl]="editContactForm.controls.Dob"
          angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()" [(ngModel)]="model" (dateChanged)="changeDobEvent($event)" id="txtdate" readonly
           [options]="myDpOptions" 
          #dp="angular-mydatepicker"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label">
                                <!-- <label class="form-label-of" for="">Preferred Languages</label> -->
                                <!-- <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <ng-select [items]="languages" bindValue="Id" formControlName="language"
                                    class="textClass" bindLabel="Language" placeholder="Preferred Languages"
                                    [searchable]="false" [clearable]="false" (change)="selectLanguage($event)"
                                    [multiple]="true" notFoundText="no language found" [(ngModel)]="selectedLanguages">
                                </ng-select>
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.language">
                                    <span class="error-message"
                                        *ngIf="editContactForm.get('language').hasError(validation.type) && (editContactForm.get('language').dirty || editContactForm.get('language')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <div class="form-group focus-label">
                                <!-- <label class="form-label-of" for="">Preferred Communication Method</label>
                            <input class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <ng-select [items]="communicationMethods" bindValue="Id"
                                    formControlName="communicationMethod" class="textClass"
                                    bindLabel="CommunicationMethod" placeholder="Preferred Communication Method"
                                    [searchable]="false" [clearable]="false" (change)="selectedContactMode($event)"
                                    [multiple]="true" notFoundText="no conatct mode found"
                                    [(ngModel)]="selectedComModes"></ng-select>
                                <span class="m-form__help"
                                    *ngFor="let validation of ValidationMessage.communicationMethod">
                                    <span class="error-message"
                                        *ngIf="editContactForm.get('communicationMethod').hasError(validation.type) && (editContactForm.get('communicationMethod').dirty || editContactForm.get('communicationMethod')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="form-group upload-btn-wrapper">
                            <label class="form-label-of upload-input upload-label" for="upload" *ngIf="!photoName">Upload Photo</label>
                            <label class="form-label-of upload-input upload-file-path" for="upload" *ngIf="photoName">{{photoName}}</label>
                            <input type="file" id="upload" (change)="fileChange(fileInputt)" name="photo" formControlName="Photo" accept="image/*" class="form-control m-input" value="" #fileInputt>
                        </div>
                    </div> -->
                    <div class="col-md-6">
                        <div class="form-group upload-btn-wrapper">
                            <label id="label-up" class="form-label-of upload-input upload-label" for="upload"><span>Photo</span></label>
                            <span  class="upload-file-path">{{photoName}}</span>
                            <!-- <label class="form-label-of upload-input upload-file-path" for="upload" *ngIf="photoName">{{photoName}}</label> -->
                            <input type="file" id="upload" (change)="fileChange(fileInputt)" name="photo" formControlName="Photo" accept="image/*" class="form-control m-input" value="" #fileInputt>
                            <!-- <span class="m-form__help" *ngFor="let validation of ValidationMessage.photo">
                            <span class="error-message"
                                *ngIf="testimonyForm.get('photo').hasError(validation.type) && (testimonyForm.get('photo').dirty || testimonyForm.get('photo')?.touched)">
                                {{validation.message}}
                            </span>
                            </span> -->
                        </div>
                    </div>
                    <!-- <div class="col-md-12 prof-add-member">
                        <div class="img-upload-bx">

                            <figure class="text-center" >
                                <a *ngIf="!notValidImage" class="close-button" (click)="removePhoto()">
                                    <i  class="la la-remove">x</i>
                                </a>
                                <img [src]="url " alt=" ">

                            </figure>
                            <label for="fileInput" style="display: block;"><a style="cursor:pointer;"
                                    class="add-photo-link mb-5"><img src="./assets/member/images/icon-camera.svg "
                                        alt="Shalom ">{{notValidImage ? 'Upload' : 'change'}}</a></label>
                            <input hidden id="fileInput" type='file' (change)="fileChange($event)">
                        </div>
                    </div> -->
                </div>
                    <div class="row" style="margin-top: 5px;">
                        <div class="col-md-6">
                            <button type="submit" class="btn-border btn-modal" style="float: right;"
                                (click)="activeModal.dismiss('Cross click')">Cancel</button>
                        </div>
                        <div class="col-md-6">
                            <button type="submit" class="btn-filled btn-modal" (click)="save()" [disabled]="isSavebutton"><span class="span-loader-padding">Save</span>  <i *ngIf="isLoading"
                                class="spinner-border"></i></button>
                        </div>
                    </div>
                   
                    <!-- <div class="col-md-12 mt-2 mb-3 d-flex justify-content-center">
                        
                       
                    </div> -->

                
            </div>
        </form>
    </div>
</div>