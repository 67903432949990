<div class="d-flex inner-page-wrp"  [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <div class="inner-page-content">
        <!-- <div class="breadcrumb-wrp mb-1">
            <a routerLink="../../home/{{memberId}}">Dashboard</a>
        </div> -->
        <div class="top-strip d-flex btm-border type2 mb-3">
            <h5 class="mb-0">Exclusive videos for SPF Members</h5>
            <!-- <a href="javascript:void(0)">View all</a> -->
        </div>
        <div class="bx-white">
            <div class="row exclusive-video-list">
                <div class="col-6 col-md-4 exclusive-video-each" *ngFor="let videos of videoChunkArray[0]">
                    <div class="video-thumb-img">
                        <figure>
                            <img src="{{videos.VideoImageUrl}}" alt="Shalom ">
                        </figure>
                        <button type="button" class="btn-play-video" (click)="videoModal(videos.VideoUrl)"></button>
                    </div>
                    <h5>{{videos.videoDescription}}</h5>
                    <span>{{videos.VideoDuration}}, {{videos.PublishedDate}}</span>
                </div>
                <!-- <div class="col-6 col-md-4 ">
                <div class="video-thumb-img">
                    <figure>
                        <img src="./assets/member/images/img-ex-video1.png " alt="Shalom ">
                    </figure>
                    <button type="button" class="btn-play-video"></button>
                </div>
                <h5>Excepteur sint occaecat cupidatat non</h5>
                <span>12:00 AM - 04:30 PM, 24 Jan 2021</span>
            </div>
            <div class="col-6 col-md-4 ">
                <div class="video-thumb-img">
                    <figure>
                        <img src="./assets/member/images/img-ex-video2.png " alt="Shalom ">
                    </figure>
                    <button type="button" class="btn-play-video"></button>
                </div>
                <h5>Excepteur sint occaecat cupidatat non</h5>
                <span>12:00 AM - 04:30 PM, 24 Jan 2021</span>
            </div>
            <div class="col-6 col-md-4 ">
                <div class="video-thumb-img">
                    <figure>
                        <img src="./assets/member/images/img-ex-video3.png " alt="Shalom ">
                    </figure>
                    <button type="button" class="btn-play-video"></button>
                </div>
                <h5>Excepteur sint occaecat cupidatat non</h5>
                <span>12:00 AM - 04:30 PM, 24 Jan 2021</span>
            </div> -->
            </div>
            <div class="loadMore" *ngIf="this.videoChunkArray.length > 1">
                <div class="row exclusive-video-list" *ngIf="loadVideo">
                    <div class="col-6 col-md-4 exclusive-video-each" *ngFor="let videos of videoSplitArray">
                        <div class="video-thumb-img">
                            <figure>
                                <img src="{{videos.VideoImageUrl}}" alt="Shalom ">
                            </figure>
                            <button type="button" class="btn-play-video" (click)="videoModal(videos.VideoUrl)"></button>
                        </div>
                        <h5>{{videos.videoDescription}}</h5>
                        <span>{{videos.VideoDuration}}, {{videos.PublishedDate}}</span>
                    </div>
                </div>    
                <button *ngIf="this.count < this.videoChunkArray.length && this.count != this.videoChunkArray.length - 1" class="btn-load-more mt-4" type="button" (click)="loadMoreVideo()">Load More</button>
            </div>
        </div>
    </div>
    <div class="home-right pl-0 ">
        <div class="bx-white connect-bx">
            <h4 class="text-center mb-3 mt-3">Connect with Us</h4>
            <div class="connect-btn-wrp">
            <figure class="text-center mb-2" *ngIf="isSpfMember">
                <img src="./assets/member/images/img-connect-purple.svg " alt="Shalom ">
            </figure>
            <figure class="text-center mb-2" *ngIf="singleDonationExist == true && isSpfMember == false">
                <img src="./assets/member/images/img-connect-green.svg " alt="Shalom ">
            </figure>
            <figure class="text-center mb-2" *ngIf="singleDonationExist == false && isSpfMember == false">
                <img src="./assets/member/images/img-connect-brown.svg " alt="Shalom ">
            </figure>
            <ul class="button-list">
                <li class="d-flex">
                    <a (click)="modalOpen('prayer')">Prayer Request</a>
                </li>
                <li class="d-flex">
                    <a (click)="modalOpen('testimony')">Testimony</a>
                </li>
                <li class="d-flex">
                    <a (click)="modalOpen('contact')">Contact Us</a>
                </li>
            </ul>
        </div>
        </div>
        <!-- <div class="bx-white live-desc-bx">
            <button type="button" class="btn-live mb-2">Live Now</button>
            <div class="live-desc ">
                <h4>Excepteur sint occaecat cupidatat non</h4>
                <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
            </div>
        </div> -->
    </div>
</div>