import { Component, OnInit } from '@angular/core';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsPopupComponent } from '../contact-us-popup/contact-us-popup.component';
import { ActivatedRoute,Router} from '@angular/router';

@Component({
  selector: 'app-recentnewslist',
  templateUrl: './recentnewslist.component.html',
  styleUrls: ['./recentnewslist.component.scss']
})
export class RecentnewslistComponent implements OnInit {
  recentNews = [];
  counter  = 1;
  totalPages;
  memberId;
  routeParam;
  isSpfMember:boolean;
  singleDonationExist:boolean = false;
  isLoading:boolean = false;
  constructor(public _crud: CommonCrudService,public modalService: NgbModal,public activeRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.getRecentNews();
    this.routeParam = this.activeRoute.snapshot;
    this.memberId = this.routeParam.params['memberId'];
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
	  this.singleDonationExist = currentUser.singleDonationExist;
  }
  getRecentNews(page = 1){
    let params = {
      url : "https://shalomworld.org/wp-json/v1/blog?perpage=14&page="+page+"&category=all"
      // url: "https://qabeta.shalomworld.org/wp-json/v1/blog?perpage=14&page=1&category=all",params: { }
    }
    this._crud.getData(params,true).subscribe(res => {
      this.isLoading = false;
      // pushing next blog values
      var i,j;
      for (i = 0,j =  res['blogs'].length; i < j; i ++) {
        // console.log("blogs------------",res['blogs'][i]);
        res['blogs'][i]['blogDescription'] = res['blogs'][i]['blogDescription'].replace(/<[^>]*>/g,"");
        // console.log("blogs-removed-----------",res['blogs'][i]['blogDescription']);
        this.recentNews.push(res['blogs'][i]);
      }
      this.totalPages = res['pages'];
      // console.log("recent news----",this.recentNews)
    });
  }
  gotoUrl(websiteUrl){
    // console.log("web urll---",websiteUrl)
    window.open(websiteUrl);
  }
  getNews(value){
    if(value){
      this.counter = this.counter + 1;
    }else{
      this.counter = this.counter - 1;
    }
    this.getRecentNews(this.counter);

  }
  modalOpen(type) {

    // console.log("inside modl open")
    const modalRef = this.modalService.open(ContactUsPopupComponent, {
        size: "lg"
    });
    modalRef.componentInstance.memberId = this.memberId;
    modalRef.componentInstance.type = type;
  }
  loadMoreNews(){
    this.isLoading = true;
    this.counter = this.counter + 1;
    this.getRecentNews(this.counter);
  }

}
