import { Injectable ,NgZone} from '@angular/core';
import { Router } from "@angular/router";
import { CommonCrudService } from '../../../../_services/common-crud.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData;

  constructor(
    public router: Router,  
    public ngZone: NgZone , // NgZone service to remove outside scope warning,
    public _crud: CommonCrudService
  ) { 
     
    }
     // Returns true when user is looged in and email is verified

  // Sign out 
  SignOut() {
   
      localStorage.removeItem('currentUser');
      localStorage.removeItem('IsSpfMember');
      localStorage.removeItem('primaryCountryId');
      localStorage.removeItem('memberId');
      localStorage.removeItem('videoApiCounter');
      localStorage.removeItem('apiVideoData');
      localStorage.removeItem('eventListData');
      localStorage.removeItem('publicationData');
      localStorage.removeItem('publicationBookUrl')
      this.router.navigate(['/member-login']);
    
  }

}
