<div class="susbscription-content">
  <div class="bx-white table-min-ht">
    <div class="top-strip d-flex">
      <!-- <figure class="mb-0">
                <img src="./assets/member/images/icon-office.svg" alt="Shalom">
            </figure>
            <h5 class="ml-3 mb-0 width-1">My Subscription</h5> -->
      <div class="dropdown btn-right-holder width-1">
        <!-- <button type="button" class="btn-common btn-width-1" (click)="selectedSubscription(0)">New Subscription</button> -->
        <button
          type="button"
          class="btn-common btn-width-1"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          New Subscription
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" (click)="selectedSubscription(0)"
            >Single Magazine</a
          >
          <a class="dropdown-item" (click)="selectedSubscription(2)"
            >Bulk Order</a
          >
        </div>
      </div>
      <div class="d-flex pagination-wrp width-2">
        <div class="pagination-list">
          <mat-paginator
            [pageSizeOptions]="[10]"
            [hidePageSize]="true"
            [ngClass]="'pagination-holder'"
          ></mat-paginator>
        </div>
        <!-- <div class=" d-flex pagination-btn"> -->
        <!-- <button type="button" class="btn-common mr-3 btn-w1" (click)="selectedSubscription(3)">Gift</button> -->
        <!-- <button type="button" class="btn-common btn-w2" (click)="selectedSubscription(2)">Sponsor</button> -->
        <!-- </div> -->
      </div>
    </div>
    <!-- Datatable starts here -->
    <div class="table-responsive-wrp">
      <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="PrimaryCountryTime">
          <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.PrimaryCountryTime }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="PublicationName">
          <mat-header-cell *matHeaderCellDef> Publication </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div
              class="desc-content-sec"
              [ngClass]="{ causeClass: element.PublicationName.length > 20 }"
            >
              <h5 class="heading-restrict">{{ element.PublicationName }}</h5>
              <div class="hover-bx">
                <h5>{{ element.PublicationName }}</h5>
              </div>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="SubscriberName">
          <mat-header-cell *matHeaderCellDef> Subscriber </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div
              class="desc-content-sec"
              [ngClass]="{ causeClass: element.SubscriberName.length > 20 }"
            >
              <h5 class="heading-restrict">{{ element.SubscriberName }}</h5>
              <div class="hover-bx">
                <h5>{{ element.SubscriberName }}</h5>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="SubscriptionTypeText">
                    <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.SubscriptionTypeText}} </mat-cell>
                </ng-container> -->
        <ng-container matColumnDef="Duration">
          <mat-header-cell *matHeaderCellDef> Duration </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.Duration.replace("-", " ").replace("y", "Y") }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="PendingIssues">
          <mat-header-cell *matHeaderCellDef> Issues Left </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.PendingIssues }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="PaymentMethod">
          <mat-header-cell *matHeaderCellDef> Pmt. Method </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.LastFourDigit
            }}<img [src]="element.PaymentMethodImageUrl" alt="" />
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="SubscriptionPrice">
          <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="desc-content-sec "[ngClass]="{ causeClass: element.SubscriptionPrice.length>5 }">
              <h5 class="heading-restrict">  {{ element.SubscriptionPrice }} {{ element.CurrencyCode }}</h5>
              <div class="hover-bx">
                <h5>  {{ element.SubscriptionPrice }} {{ element.CurrencyCode }}</h5>
              </div>
            </div>
          </mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="SubscriptionPrice">
          <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.SubscriptionPrice }} {{ element.CurrencyCode }}
          </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="PendingAmount">
          <mat-header-cell *matHeaderCellDef> Due </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="desc-content-sec "[ngClass]="{ causeClass: element.PendingAmount.length > 5 }">
              <h5 class="heading-restrict">{{ element.PendingAmount }}</h5>
              <div class="hover-bx">
                <h5>{{ element.PendingAmount }}</h5>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="SubscriptionStatus">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.SubscriptionStatus == 'OnHold'">
              <span>On Hold</span>
            </ng-container>
            <ng-container *ngIf="element.SubscriptionStatus !== 'OnHold'">
              <span>{{ element.SubscriptionStatus }}</span>
            </ng-container>
            <!-- <div class="dropdown-wrp">
                            <a href="javascript:void(0)" data-toggle="dropdown" class="status-icon"><img src="./assets/member/images/icon-status.svg" alt="Shalom"></a>
                            <ul class="dropdown-menu">
                                <li><a href="#">Renew</a></li>
                                <li><a href="#">Address Change</a></li>
                                <li><a href="#">Cancel</a></li>
                                <li><a href="#">Make Payment</a></li>
                            </ul>
                        </div> -->
            <div
              class="dropdown-wrp"
              *ngIf="element.SubscriptionStatus == 'Active'"
            >
              <a
                href="#"
                class="status-icon"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="./assets/member/images/icon-status.svg"
                  alt="Shalom"
                />
              </a>
              <div class="dropdown-menu">
                <li *ngIf="element.SubscriptionType != 3">
                  <a
                    style="cursor: pointer"
                    (click)="
                      selectedSubscription(
                        1,
                        element.SubscriptionId,
                        element.MemberLastName
                      )
                    "
                    >Renew</a
                  >
                </li>
                <!-- <li><a href="#">Address Change</a></li> -->
                <li>
                  <a routerLink="cancel-subscription/{{ element.RecordId }}"
                    >Cancel</a
                  >
                </li>
                <!-- <li><a href="#">Make Payment</a></li>                     -->
                <li *ngIf="element.PendingAmount > 0">
                  <a routerLink="make-subpayment/{{ element.SubscriptionId }}"
                    >Make Payment</a
                  >
                </li>
              </div>
            </div>
            <div
              class="dropdown-wrp"
              *ngIf="
                element.SubscriptionStatus == 'Expired' &&
                element.SubscriptionType != 3
              "
            >
              <a
                href="#"
                class="status-icon"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="./assets/member/images/icon-status.svg"
                  alt="Shalom"
                />
              </a>
              <div class="dropdown-menu">
                <li>
                  <a
                    style="cursor: pointer"
                    (click)="
                      selectedSubscription(
                        1,
                        element.SubscriptionId,
                        element.MemberLastName
                      )
                    "
                    >Renew</a
                  >
                </li>
                <!-- <li><a href="#">Address Change</a></li> -->
                <!-- <li><a routerLink="cancel-subscription/{{element.RecordId}}">Cancel</a></li> -->
                <li *ngIf="element.PendingAmount > 0">
                  <a routerLink="make-subpayment/{{ element.SubscriptionId }}"
                    >Make Payment</a
                  >
                </li>
              </div>
            </div>
            <div
              class="dropdown-wrp"
              *ngIf="element.SubscriptionStatus == 'Adv.Renewal'"
            >
              <a
                href="#"
                class="status-icon"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="./assets/member/images/icon-status.svg"
                  alt="Shalom"
                />
              </a>
              <div class="dropdown-menu">
                <!-- <li><a *ngIf="element.SubscriptionType !=3" style="cursor:pointer;" (click)="selectedSubscription(1,element.SubscriptionId,element.MemberLastName)">Renew</a></li> -->
                <!-- <li><a href="#">Address Change</a></li> -->
                <li>
                  <a routerLink="cancel-subscription/{{ element.RecordId }}"
                    >Cancel</a
                  >
                </li>
                <!-- <li><a href="#">Make Payment</a></li>                     -->
                <li *ngIf="element.PendingAmount > 0">
                  <a routerLink="make-subpayment/{{ element.SubscriptionId }}"
                    >Make Payment</a
                  >
                </li>
              </div>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
  <!-- <div class="bx-white">
        <div class="row pagination-wrp p-3">
            <div class="pagination-list col-lg-5">
                <mat-paginator [pageSizeOptions]="[7]" [hidePageSize]="true" [ngClass]="'pagination-holder'"></mat-paginator>
            </div>
            <div class=" col-lg-7 d-flex pagination-btn">
                <button type="button" class="btn-common btn-width-1" (click)="selectedSubscription(0)">NEW SUBSCRIPTION</button>
                <button type="button" class="btn-common mx-2 btn-width-2" (click)="selectedSubscription(3)">GIFT</button>
                <button type="button" class="btn-common btn-width-2" (click)="selectedSubscription(2)">SPONSOR</button>
            </div>
        </div>
    </div> -->
</div>
