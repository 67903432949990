import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUsPopupComponent } from './contact-us-popup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormGroup, FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        NgSelectModule,
        ReactiveFormsModule,
        FormsModule

    ],
    declarations: [ContactUsPopupComponent]
})
export class ContactUsPopupModule { }
