<div class="privacy-term_wrp" [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <div class="head-bar">
        <div class="container">
            <div class="row">
                <!-- <div class="col-5 col-md-2">
                    <img src="./assets/member/images/dashboard-logo-purple.svg" alt="Shalom">
                </div> -->
                <div class="col-5 col-md-2" *ngIf="!isLogged">
                    <a routerLink="/member-login"><img src="./assets/member/images/dashboard-logo-brown.svg" alt="Shalom"></a>
                </div>
                <div class="col-5 col-md-2" *ngIf="isSpfMember && isLogged">
                    <a routerLink="/memberDashboard/home/{{this.memberId}}"><img src="./assets/member/images/dashboard-logo-purple.svg" alt="Shalom"></a>
                </div>
                <!-- ontime donation user -->
                <div class="col-5 col-md-2" *ngIf="singleDonationExist == true && isSpfMember == false && isLogged">
                    <a routerLink="/memberDashboard/home/{{this.memberId}}"><img src="./assets/member/images/dashboard-logo-green.svg" alt="Shalom"></a>
                </div>
                <!-- new user -->
                <div class="col-5 col-md-2" *ngIf="singleDonationExist == false && isSpfMember == false && isLogged">
                    <a routerLink="/memberDashboard/home/{{this.memberId}}"><img src="./assets/member/images/dashboard-logo-brown.svg" alt="Shalom"></a>
                </div>
                <div class="col-7 col-md-10">
                    <div class="d-flex justify-content-end align-items-center">
                        <div class="top-bar">
                            <div class="tab-wrp">
                                <!-- not logged case -->
                                <ul class="nav nav-tabs" role="tablist" *ngIf="!isLogged">
                                    <li class="nav-item ">
                                         <a class="nav-link" data-toggle="tab" routerLink="/member-login"
                                          role="tab" >Dashboard</a>
                                     </li>
                                     <li class="nav-item">
                                         <a class="nav-link" data-toggle="tab" routerLink="/member-login"  
                                          role="tab" >My Donations</a>
                                     </li>
                                     <li class="nav-item">
                                         <a class="nav-link" data-toggle="tab" routerLink="/member-login" 
                                          role="tab"  >My
                                             Subscriptions</a>
                                     </li>
                                     <li class="nav-item">
                                         <a class="nav-link" data-toggle="tab" routerLink="/member-login"
                                          role="tab">My Event
                                             Registrations</a>
                                     </li>
                                     <!-- <li class="nav-item">
                                         <a class="nav-link" data-toggle="tab" role="tab">Settings</a>
                                     </li> -->
                                 </ul>
                                 <!-- logged case -->
                                <ul class="nav nav-tabs" role="tablist" *ngIf="isLogged">
                                   <li class="nav-item ">
                                        <a class="nav-link" data-toggle="tab" routerLink="/memberDashboard/home/{{this.memberId}}"
                                         role="tab" >Dashboard</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="tab" routerLink="/memberDashboard/mydonation/{{this.memberId}}"  
                                         role="tab" >My Donations</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="tab" routerLink="/memberDashboard/mysubscription/{{this.memberId}}" 
                                         role="tab"  >My
                                            Subscriptions</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="tab" routerLink="/memberDashboard/myeventregistration/{{this.memberId}}"
                                         role="tab" >My Event
                                            Registrations</a>
                                    </li>
                                    <!-- <li class="nav-item">
                                        <a class="nav-link" data-toggle="tab" role="tab">Settings</a>
                                    </li> -->
                                </ul>
                                <!-- Tab panes -->
                                <div class="tab-content">
    
                                    <div class="tab-pane" id="donation" role="tabpanel">
                                        <p>My Donations</p>
                                    </div>
                                    <div class="tab-pane" id="subscription" role="tabpanel">
                                        <p>My subscription</p>
                                    </div>
                                    <div class="tab-pane" id="registration" role="tabpanel">
                                        <p>Event Registrations</p>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                        <div class="waffle_menu">
                            <figure>
                                <img src="./assets/member/images/prof-top-icon.svg" alt="waffle"></figure>
                                <ul>
                                    <li>
                                        <a href="https://www.shalommedia.org/" target="_blank">
                                            <figure>
                                                <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/Shalom-Media.webp" alt=""></figure>
                                                <span>Shalom Media</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://shalomtidings.org/" target="_blank">
                                                <figure>
                                                    <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/Tidings.webp" alt="">
                                                </figure>
                                                <span>Shalom Tidings</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.shalomworld.org/" target="_blank">
                                                <figure>
                                                    <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/logo-without-background.webp" alt="">
                                                </figure>
                                                <span>Shalom World</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://qabeta.shalomworld.org/swpals/" target="_blank">
                                                <figure>
                                                    <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/SW-Pals.webp" alt="">
                                                </figure>
                                                <span>SW Pals</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.shalomworld.org/swprayer">
                                                <figure>
                                                    <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/SW-Prayer-1.webp" alt="">
                                                </figure>
                                                <span>SW Prayer</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.shalomworld.org/news" target="_blank">
                                                <figure>
                                                    <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/SW-News.webp" alt="">
                                                </figure>
                                                <span>SW News</span>
                                            </a>
                                        </li>
                                    </ul>
                            </div>
                            <div class="log-prof"  *ngIf="isLogged">
                                <div class="d-flex dropdown-wrp">
                                    <a href="#" class="" data-toggle="dropdown" aria-expanded="false">
                                        <div class="log-prof-desc">
                                            <figure>
                                                <!-- <img src="./assets/member/images/img-prof2.png" alt="Shalom"> -->
                                                <img src="http://myshalomtest.webc.in/myshalom/storage/uploads/members/1614678593LA7_1639372532.0025.jpg" alt="Shalom">
                                            </figure>
                                        </div>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end"
                                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-87px, 33px, 0px);">
                                        <span>Hi,Austin</span>
                                        <button class="btn btn-primary">Sign out</button>
                                    </div>
        
                                </div>
                            </div>
                    </div>
                    <div class="mob-btn">
                        <div class="back menu--3"> <label> <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="50" cy="50" r="30"></circle>
                                    <path class="line--1" d="M0 40h62c18 0 18-20-17 5L31 55"></path>
                                    <path class="line--2" d="M0 50h80"></path>
                                    <path class="line--3" d="M0 60h62c18 0 18 20-17-5L31 45"></path>
                                </svg> </label>
                        </div>
                    </div>
                </div>
                
                <div class="overlay"></div>
            </div>
        </div>
    </div>
<div class="container">
    <div class="privacy_wrp">
        <h2>Terms and Conditions</h2>
        <p>Welcome to www.shalomworld.org !</p>
        <p>Thanks for visiting our web site. This website is provided by Shalom Media USA, Inc. (“Shalom Media USA, Inc.”), located at 211 E Wisconsin Rd, Edinburg, TX.78539. www.shalomworld.org is the official web site for Shalom Media USA, Inc. a not for profit organization.</p>
        <h5>Using our Web Site Services</h5>
        <p>www.shalomworld.org is so diverse in its content and services and so, sometimes additional terms or product requirements may apply. Additional terms will be available with the relevant Services, and those additional terms will become part of your agreement with us if you use those services. We have taken maximum effort to design our web sites to be useful and informative in content and design. By visiting this website, services, and contents, you agree to abide by the following Terms and Conditions. By using our sites you automatically agree to them. Naturally, if you don’t agree, please do not use our sites. We reserve the right to make any modifications that we deem necessary at any time. Please continue to check these terms to see what those changes may be. Your use of the Shalom World and Shalom Media USA Web sites means that you accept those changes.</p>
        <h5>You must follow any policies made available to you within the Services</h5>
        <p>By visiting the web site you agree not to interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct. Don’t misuse our Services.</p>
        <p>Using our Services does not give you ownership of any property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed in or along with our Services. In connection with your use of the Services, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications.</p>
        <p>Some of our Services are available on mobile devices through apps both android and mac platforms. Do not use such Services in a way that physically, mentally or socially hurts you in anyway. Also do not use such services, to distract you and prevent you from obeying traffic or safety laws of the state.</p>
        <h5>Restrictions</h5>
        <p>All materials contained in the Shalom Media USA and Shalom World web sites (“Online Material”) are the copyrighted property of Shalom Media USA and Shalom World. All trademarks, service marks, and trade names are proprietary to Shalom Media USA and Shalom World. No Online Materials including videos, music, from our web sites may be downloaded, copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way, except that you may download one copy of the materials on any single computer for your personal, non- commercial home use only, provided that you 1) retain all copyright, trademark and propriety notices, 2) you make no modifications to the materials, 3) you do not use the materials in a manner that suggests an association with any of our products, services, events or brands, and 4) you do not download quantities of materials to a database, server, or personal computer for reuse for commercial purposes. You may not, however, copy, reproduce, republish, upload, post, transmit or distribute our Online Material in any way or for any other purpose unless you get our written permission first. Neither may you add, delete, distort or misrepresent any content on the Shalom Media USA and Shalom World sites. Attempts to modify any online materials, or to defeat or circumvent our security features are prohibited.</p>
        <h5>Submitting online materials</h5>
        <p>All remarks, ideas, comments, graphics, suggestions, testimonies, or other information that you send to Shalom World and Shalom Media USA through our sites (other than information we promise to protect under our privacy policy) becomes and remain our property, even if this agreement is later terminated. Such submissions are not treated as confidential. If we use them, or one similar in nature, we are not obligated to compensate you or anyone else for them. We will have the exclusive ownership of all present and future rights to the submissions of any kind we receive through web sites. We may or may not use your name when using for promotions or any other use. We can use them for any purpose we deem appropriate to our Shalom World and Shalom Media USA mission, without compensating you or anyone else. If we use them, or one similar in nature, we are not obligated to compensate you or anyone else for them. We will have the exclusive ownership of all present and future rights to submissions of any kind. You acknowledge that you are responsible for any submission you make. This means that you (and not we) have full responsibility for the content, message, or presentation including its legality, reliability, appropriateness, originality, and copyright.</p>
        <h5>Limitations of Liability</h5>
        <p>Shalom Media USA, Inc. and www.shalomworld.org will not be liable for any damages or injury that accompany or result from your use of any of its sites. These include (but are not limited to) damages or injury caused by any:</p>
        <ul>
            <li>Use of or Inability to use the sites</li>
            <li>Use of or inability to use any site to which hyperlink from our sites</li>
            <li>Failure of our sites to perform in the manner you expected or desired</li>
            <li>Error or omission of our sites</li>
            <li>Interruption or availability of our sites</li>
            <li>Defect or delay in operation or transmission of our sites</li>
            <li>Computer virus or line failure</li>
        </ul>
        <p>We are also not liable for any damages caused by the use of our web site. We are not liable even if we have been negligent.</p>
        <h5>Links</h5>
        <p>We sometimes provide referrals to and links to other World Wide Web sites from our sites. Such a link should not be seen as an endorsement, approval or agreement with any information or resources offered at sites you can access through our sites. If in doubt, always check the Uniform Resource Locator (URL) address provided in your WWW browser to see if you are still in a Shalom World and Shalom Media USA-operated site or have moved to another site. Shalom World and Shalom Media USA is not responsible for the content or practices of third party sites that may be linked to our sites. When Shalom World and Shalom Media USA provides links or references to other Web sites, no inference or assumption should be made and no representation should be inferred that Shalom World and Shalom Media USA is connected with, operates or controls these Web sites. Any approved link must not represent in any way, either explicitly or by implication, that you have received the endorsement, sponsorship or support of any Shalom World and Shalom Media USA site or endorsement, sponsorship or support of the ministry of Shalom World and Shalom Media USA, including its respective employees, agents or directors.</p>
        <h5>Termination</h5>
        <p>This agreement is effective until terminated by either party. You may terminate this agreement at any time, by destroying all materials obtained from all Shalom World and Shalom Media USA Web sites, along with all related documentation and all copies and installations. Shalom World and Shalom Media USA may terminate this agreement at any time and without notice to you, if, in its sole judgment, you breach any term or condition of this agreement. Upon termination, you must destroy all materials. In addition, by providing material on our Web sites, we do not in any way promise that the materials will remain available to you. And Shalom World and Shalom Media USA is entitled to terminate all or any part of any of its web sites without notice to you.</p>
        <h5>Jurisdiction</h5>
        <p>Unless otherwise specified, the Online Materials on our sites are presented to provide information about the resources of Shalom World and Shalom Media USA. The ministry of Shalom World and Shalom Media USA was organized under the laws of Texas, in the United States of America. If you use our sites from locations outside of the United States, you are responsible for compliance with any applicable laws. These Terms of Use, and the agreement they create, shall be governed by and interpreted according to the laws of the State of Texas (without applying the state’s conflict-of-law principles.) If any provision of this agreement is unlawful, void or unenforceable, it will not affect the validity and enforceability of any remaining provisions. Shalom World and Shalom Media USA may modify these Terms of Use, and the agreement they create, at any time, simply by updating this posting and without notice to you. This is the ENTIRE agreement regarding all the matters that have been listed.</p>
    </div>
</div>
<footer>
    <div class="container">
        <div class="footer_wrp">
        
                <div class="footer_menu_link d-flex">
                    <ul class="ul-col ul-first-col">
                        <li><a href="https://www.shalommedia.org/mission/" class="active">Mission</a></li>
                        <li><a href="https://www.shalommedia.org/history/">History</a></li>
                        <li><a href=" https://www.shalommedia.org/endorsement/">Endorsement</a></li>
                        <li><a href="https://www.shalommedia.org/milestones/">Milestones</a></li>
                        <li><a href="https://www.shalomworld.org/joinspf">Peace Fellowship</a></li>
                        <!-- <li><a href="https://beta.shalomworld.org/about">About Us</a></li> -->
                    </ul>
                    <ul class="ul-col ul-sec-col">
                        <li>
                            <a href="https://www.shalomworld.org/shows">Shows</a>
                        </li>
                        <li><a href="https://www.shalomworld.org/news">News</a></li>
                        <li><a href="https://www.shalomworld.org/watchon/connectedtv">Connected TV</a></li>
                        <li><a href="https://www.shalomworld.org/swplus">SW+</a></li>
                        <li><a href=" https://www.shalomworld.org/watchlive">Watch Live</a></li>
                    </ul>
                    <ul class="ul-col ul-third-col">
                        <li><a href="https://shalomtidings.org/prayers/" target="_blank">Prayers</a></li>
                        <li><a href="https://www.shalommedia.org/events/" target="_blank">Events</a></li>
                        <li><a href="https://www.shalommedia.org/volunteer/">Volunteer</a></li>
                        <li><a href="https://www.shalomworld.org/career">Careers</a></li>
                        <li><a href="https://www.shalommedia.org/press_release/">Press Release</a></li>
                    </ul>
                    <ul class="ul-col ul-fourth-col">
                        <li><a href=" https://swprayer.org/" target="_blank">SW Prayer</a></li>
                        <li><a href="https://www.swpals.org/" target="_blank">SW Pals</a></li>
                        <li><a href="https://www.shalomworld.org/show/movies">SW Movies</a></li>
                        <li><a href="shalommedia.de">Shalom Media German</a></li>
                        <li><a href="shalommedia.es">Shalom Media Spanish</a></li>
                    </ul>
                    <div class="footer_applink_right">
                        <!-- <h5>Download</h5> -->
                        <h5 class="mb-3">Get our Apps</h5>
                        <ul class="d-flex  align-items-center">
                            <li>
                                <button class="btn btn-lg dropdown-toggle" type="button"  data-toggle="dropdown">
                                    <img src="./assets/member/images/icon-ios.svg" alt=""><span class="icon-android"></span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="https://www.shalomworld.org/watchon/apps/android" target="_blank">Shalom World</a>
                                    <a class="dropdown-item" href="https://itunes.apple.com/us/app/shalom-tidings/id1305953479?mt=8" target="_blank">Shalom Tidings</a>
                                
                                </div>
                                <!-- <a href="https://beta.shalomworld.org/watchon/apps"></a> -->
                                
                            </li>
                            <li>
                                <button class="btn btn-lg dropdown-toggle" type="button"  data-toggle="dropdown">
                                    <img src="./assets/member/images/icon-android.svg" alt=""><span class="icon-android"></span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="https://www.shalomworld.org/watchon/apps/ios" target="_blank">Shalom World</a>
                                    <a class="dropdown-item" href="https://play.google.com/store/apps/details?id=com.shalomworld.shalomtidings&amp;hl=en" target="_blank">Shalom Tidings</a>
                                </div>
                            </li> 
                            <li>
                                    <a class="" href="https://www.amazon.com/Shalom-Tidings/e/B094668STL/ref=dp_byline_cont_ebooks_1" target="_blank"><img src="./assets/member/images/icon-kindle.png" alt=""></a>
                            </li> 
                        </ul>
                    </div>
                </div>
        </div>
        <div class=" footer_btm mt-5">
        
            <div class="d-flex footer_btm_link">
                <div class="d-flex footer_btm_left">
                    <p>
                    Copyright Shalom Media 2022. All rights reserved.
                    </p>
                    <ul class="d-flex">
                        <li> <a href="/privacy-policy">Privacy Statement</a> </li>
                        <li><span>|</span></li>
                        <li>
                            <a href="/terms-and-conditions">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
                <!-- <div class="footer_btm_right">
                    <p>Designed by <a href="https://webandcrafts.com" target="_blank"> webandcrafts</a></p>
                </div>
            -->
            </div>
            <a (click)="scrollTop()" class="backtotop">
            <p>Back to Top<span class="icon-arrow"></span></p>
        </a>
        </div>
</div>

</footer>
</div>



