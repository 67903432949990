import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonCrudService } from '../../../../_services/common-crud.service';

@Component({
  selector: 'app-success-popup',
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss']
})

export class SuccessPopupComponent implements OnInit {
  @Input() public user;
  @Input() verificationLink : boolean = false;
  @Input() userEmail;
  isSpfMember:boolean = false;
  singleDonationExist:boolean = false;
  notificationMsg ;
  resendEmailResponse:boolean = false;
  isLoading:boolean = false;

  constructor(public activeModal: NgbActiveModal,public _crud:CommonCrudService,) { }
  ngOnInit(): void {
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.singleDonationExist = currentUser?.singleDonationExist;
    console.log("user.title-----",this.user.title)
    if(this.user.title == 'success'){
      this.user.title = 'Success';
    }
    // console.log(this.user);
  }
  close(){
    this.activeModal.close({ 'result': true });
  }
  sendVerifivationEmail(){
    this.isLoading = true;
    let params = {
      url: "frontend-site/member/resend-email-verification", 
      params: {Email : this.userEmail}
    }
    this._crud.postData(params).subscribe(res=>{ 
      this.isLoading = false;
      console.log("response---res",res)
      this.resendEmailResponse = true;
      this.notificationMsg = res['msg']
     })
  }
}
