import { Component, OnInit,Input } from '@angular/core';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import * as braintree from 'braintree-web';
import { map } from 'rxjs/operators';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import { ActivatedRoute ,Router} from '@angular/router';
import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-savedcardlist',
  templateUrl: './savedcardlist.component.html',
  styleUrls: ['./savedcardlist.component.scss']
})
export class SavedcardlistComponent implements OnInit {

  constructor(public _crud : CommonCrudService,public router:Router,public modalService: NgbModal,public activeRoute:ActivatedRoute) { }

  @Input() paymentMethodType;
  @Input() brainTreeToken;
  @Input() paymentSettingId;
  @Input() memberId;
  @Input() donationForm;
  @Input() selectedFrequencyName;
  @Input() countryId;
  @Input() InitialMethodId;
  @Input() NetAmonutToPay;
  @Input() selectedCauseId;
  @Input() maxOtherAmount;
  @Input() otheramoutValidation;
  @Input() type;
  @Input() subType;
  @Input() subscriptionForm;
  @Input() selectedGiftMemberId;
  @Input() renewalOptions;
  @Input() eventForm;
  @Input() dynamicForm;
  @Input() specialRequestForm;
  hostedFieldsInstance: braintree.HostedFields;
  brainTreeError: string;
  cardholdersName: string;
  noFieldsCardNumber: number;
  noFieldsDate: number;
  noFieldsCvv: number;
  postDataJsonPrepare;
  selectedSavedCard:boolean = true;
  formResult;
  response;
  routeParam

  ngOnInit(): void {
    console.log("inside saved card list---",this.type);
    this.routeParam = this.activeRoute.snapshot;
    console.log("inside saved card list---",this.routeParam.params['memberId']);
    if(this.paymentMethodType != 'ach'){
      this.createBraintreeUI();
    }
  }
  createBraintreeUI() {
    // console.log("braintree");
    braintree.client.create({
        authorization: this.brainTreeToken
    }).then((clientInstance) => {
        braintree.hostedFields.create({
            client: clientInstance,
            styles: {
                // Style all elements
                // 'input': {
                //   'font-size': '16px',
                //   'color': '#3A3A3A'
                // },
                '.invalid': {
                    'color': '#D0041D'
                },
  
                // Styling element state
                // 'input': {
                //     // 'font-family': '"Merriweather", serif !important',
                //     'padding': "0 10px 0 20px !important",
                //     'letter-spacing': "0.025em !important",
                //     "font-weight": "300 !important",
                //     "font-size": "14px !important",
                // },
                'input': {
                    'font-size': '16px!important',
                    "font-weight": '500!important',
                    'font-family': "'Poppins', sans-serif !important",
                    'color': '#0D0D0D',
                    ':placeholder': {
                        'font-weight': '500!important',
                        'color': '#9F9F9F!important',
                    }
                },
  
                // Media queries
                // Note that these apply to the iframe, not the root window.
                // '@media screen and (max-width: 700px)': {
                //     'input': {
                //         'font-size': '14px'
                //     }
                // }
            },
  
            // The hosted fields that we will be using
            // NOTE : cardholder's name field is not available in the field options
            // and a separate input field has to be used incase you need it
            fields: {
                number: {
                    selector: '#card-number',
                    placeholder: 'Card Number'
                },
                cvv: {
                    selector: '#cvv',
                    placeholder: 'CVV'
                },
                expirationDate: {
                    selector: '#expiration-date',
                    placeholder: 'Expiration Date (MM/YY)'
                },
                // postalCode: {
                //     selector: '#postal-code',
                //     placeholder: 'Pin Code'
                // }
            }
        }).then((hostedFieldsInstance) => {
            this.hostedFieldsInstance = hostedFieldsInstance;
            hostedFieldsInstance.on('focus', (event) => {
                const field = event.fields[event.emittedBy];
                const label = this.findLabel(field);
                if (label['htmlFor'] == 'card-number') {
                    this.noFieldsCardNumber = 0;
                }
                if (label['htmlFor'] == 'expiration-date') {
                    this.noFieldsDate = 0;
                }
                if (label['htmlFor'] == 'cvv') {
                    this.noFieldsCvv = 0;
                }
                console.log(this.noFieldsCardNumber);
                // label.classList.remove('filled'); // added and removed css classes
                // can add custom code for custom validations here
                // this.cdRef.detectChanges();
            });
            hostedFieldsInstance.on('blur', (event) => {
                const field = event.fields[event.emittedBy];
                // const label = this.findLabel(field); // fetched label to apply custom validations
                // can add custom code for custom validations here
            });
  
            hostedFieldsInstance.on('empty', (event) => {
                const field = event.fields[event.emittedBy];
                // can add custom code for custom validations here
            });
  
            hostedFieldsInstance.on('validityChange', (event) => {
                const field = event.fields[event.emittedBy];
                const label = this.findLabel(field);
                if (field.isPotentiallyValid) { // applying custom css and validations
                    // label.classList.remove('invalid');
                } else {
                    // label.classList.add('invalid');
                }
                // can add custom code for custom validations here
            });
        });
    }).catch(error => {
        console.log('error', error);
        if (error.name == 'BraintreeError') {
            // this.paymentGatewayErrorMsg = "Payment Gateway is not configured"
        }
    })
    // this.clearCardDetails();
  }
  findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
    return document.querySelector('.hosted-field--label[for="' + field.container.id + '"]');
  }
  getClientToken() {
    let params = {};
    params['url'] = 'frontend-site/member/client-token/' + this.paymentSettingId + '/' + this.memberId;
    params['params'] = {};
    return this._crud
        .getBrainTreeToken(params)
        .pipe(map((res: any) => {
            // console.log(res.data.ClientToken,"maped clint response")
            return res.data.ClientToken;
        }));
  }
  onPaymentStatus(response): void {
    //   console.log(response);
    }
  public isSavedCard: boolean = false;
  payButtonStatus(response): void {
    this.isSavedCard = response;
  }
  createPurchase(nonce: string, chargeAmount: number){

    if(this.type == 'donation'){
        if (this.donationForm.controls['Amount'].value == null && this.otheramoutValidation == false && this.maxOtherAmount == false) {
        this.donationForm.controls['Amount'].setValue(this.donationForm.controls['OtherAmount'].value)
        this.donationForm.controls['CausePaymentOptionValueId'].setValue(null);
        }
        let param = {};
        let params = this.RequestPostParams(
            { type: this.paymentMethodType },
            nonce
        );
    
        console.log("valid");
        param['url'] = "frontend-site/process/" + this.memberId + "/" + this.selectedCauseId;
        param['params'] = params;
        return this._crud
        .postData(param)
        .pipe(map((res: any) => {
            // console.log("responnse---------",res)
            this.formResult = res
            if (this.formResult['status'] == "success") {
                this.successPopup(this.formResult);
                this.donationForm.reset();
            } else {
                this.successPopup(this.formResult);
            
            }
            return res.data.nonce;
        })); 
    }else if(this.type == 'subscription'){
        let param = {};
        let params = this.RequestPostParams(
            { type: this.paymentMethodType },
            nonce
        );
        if (this.subType == "Renewal Subscription") {
            param['url'] = "frontend-site/member-subscription/renew-process";
          }else{
            param['url'] = 'frontend-site/member-subscription/process';
          }
          
          param['params'] = params;
          return this._crud
          .postData(param)
          .pipe(map((res: any) => {
            
              this.formResult = res
                if (this.formResult['status'] == "success") {
                    this.successPopup(this.formResult);
                   
                } else {
                    this.successPopup(this.formResult);
                   
                }
              return res.data.nonce;
          })); 
    }else if(this.type == 'event'){
        let param = {};
        let params = this.RequestPostParams(
            { type: this.paymentMethodType },
            nonce
        );
        param['url'] =  "frontend-site/member-event/register",
        param['params'] = params;
        return this._crud
        .postData(param)
        .pipe(map((res: any) => {
            // console.log("responnse---------",res)
            this.formResult = res
        if (this.formResult['status'] == "success") {
            this.successPopup(this.formResult);
            // this.eventForm.reset();
            // $('.focus-label.is-focused').removeClass('is-focused');
            // this.selectedPaymentMethod = "";
            // this.getCountryList(this.UserLocation.PrimaryCountryId);
        } else {
            this.successPopup(this.formResult);
        
        }
        return res.data.nonce;
        })); 
    }   
  }
//   createPurchaseSubscription(nonce: string, chargeAmount: number){
//         let param = {};
//         let params = this.RequestPostParams(
//             { type: this.paymentMethodType },
//             nonce
//         );
//         if (this.subType == "Renewal Subscription") {
//             param['url'] = "frontend-site/member-subscription/renew-process";
//           }else{
//             param['url'] = 'frontend-site/member-subscription/process';
//           }
          
//           param['params'] = params;
//           return this._crud
//           .postData(param)
//           .pipe(map((res: any) => {
            
//               this.formResult = res
//                 if (this.formResult['status'] == "success") {
//                     this.successPopup(this.formResult);
                   
//                 } else {
//                     this.successPopup(this.formResult);
                   
//                 }
//               return res.data.nonce;
//           })); 
//   }
  RequestPostParams(paymentFields = null, nonce = null) {
    if(this.type == 'donation'){
        this.postDataJsonPrepare = {};
        this.postDataJsonPrepare['PaymentProcessParams'] = {};
        this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'] = {};
        this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['BillingAddress'] = {};

        // console.log(this.donationForm.controls.BillingAddress.controls.AddressId.value);
        this.postDataJsonPrepare['MemberId'] =  this.donationForm.controls.Member.value;
        this.postDataJsonPrepare['CausePaymentOptionId'] =  this.donationForm.controls.CausePaymentOptionId.value;
        this.postDataJsonPrepare['CausePaymentOptionValueId'] =  this.donationForm.controls.CausePaymentOptionValueId.value;

        this.postDataJsonPrepare['Frequency'] =  this.donationForm.controls.Frequency.value;
        this.postDataJsonPrepare['Amount'] =  this.donationForm.controls.Amount.value;
        this.postDataJsonPrepare['CurrencyISOCode'] =  this.donationForm.controls.CurrencyISOCode.value;
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["AddressId"] = this.donationForm.controls.BillingAddress.controls.AddressId.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["Address1"] = this.donationForm.controls.BillingAddress.controls.Address1.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["Address2"] = this.donationForm.controls.BillingAddress.controls.Address2.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["City"] = this.donationForm.controls.BillingAddress.controls.City.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["StateId"] = this.donationForm.controls.BillingAddress.controls.StateId.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["CountryId"] = this.countryId
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["Zip"] = this.donationForm.controls.BillingAddress.controls.Zip.value
        if(this.selectedFrequencyName == "Recurring (Monthly)"){
            this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["PayNow"] = this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].value

        }
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingDate"] = this.donationForm.controls.Date.value

        if (paymentFields != null) {
            // console.log(this.selectedFrequencyName,paymentFields);
            this.postDataJsonPrepare["PaymentProcessParams"]["SaveCardForFuture"] =
                "0";
                if(paymentFields['type']==  "manual_payment"){
                    this.postDataJsonPrepare["PaymentProcessParams"]["ProceedWithoutPaymentProcess"] = "1";
                    // this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["PayNow"] = "0"
                }else{
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "ProceedWithoutPaymentProcess"
                    ] = "0";
                }
        
            this.postDataJsonPrepare["PaymentProcessParams"][
                "PaymentMethodId"
            ] = this.InitialMethodId;
            if (paymentFields.type == "ach") {
                if (this.selectedSavedCard == false) {
                }
                else {
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "PaymentGatewayTokenId"
                    ] = this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
                }
            } else if (paymentFields.type == "credit_or_debt") {
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "Nonce"
                ] = nonce;

                if (this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.SaveCardForFuture.value) {
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "SaveCardForFuture"
                    ] = "1";
                } else {
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "SaveCardForFuture"
                    ] = "0";
                }
            } 
    
        }
    }else if(this.type == 'subscription'){
        this.postDataJsonPrepare = {};
        this.postDataJsonPrepare['PaymentProcessParams'] = {};
        this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'] = {};
        this.postDataJsonPrepare['MemberId'] = this.memberId;
        this.postDataJsonPrepare['IsGiftTo'] = (this.subscriptionForm.controls.IsGiftTo.value) ? '1' : '0';
        this.postDataJsonPrepare['DeliveryAddress'] = {};
        this.postDataJsonPrepare['DeliveryAddress']['AddressId'] = this.subscriptionForm.controls['DeliveryAddress'].controls['AddressId'].value;
        this.postDataJsonPrepare['DeliveryAddress']['NewAddress'] = {};
        this.postDataJsonPrepare['DeliveryAddress']['NewAddress']['Address1'] = this.subscriptionForm.controls['DeliveryAddress'].controls['Address1'].value;
        this.postDataJsonPrepare['DeliveryAddress']['NewAddress']['Address2']= this.subscriptionForm.controls['DeliveryAddress'].controls['Address2'].value;
        this.postDataJsonPrepare['DeliveryAddress']['NewAddress']['City'] = this.subscriptionForm.controls['DeliveryAddress'].controls['City'].value;
        this.postDataJsonPrepare['DeliveryAddress']['NewAddress']['StateId'] = this.subscriptionForm.controls['DeliveryAddress'].controls['StateId'].value;
        this.postDataJsonPrepare['DeliveryAddress']['NewAddress']['Zip'] = this.subscriptionForm.controls['DeliveryAddress'].controls['Zip'].value;
        

        // renew subscription
        if(this.subType == 'Renewal Subscription'){
            if (this.renewalOptions.Type == 1) {
            this.postDataJsonPrepare['Subscription'] = {
                "RenewalSubscriptionId": this.subscriptionForm.controls['Subscription'].controls['RenewalSubscriptionId'].value,
                "PublicationComboId": this.subscriptionForm.controls['Subscription'].controls['PublicationComboId'].value,
                "PriceOptionId": this.subscriptionForm.controls['Subscription'].controls['PriceOptionId'].value,
                "Type": 1
            }
            } else if (this.renewalOptions.Type == 2) {
            //after testing replace with form values
                this.postDataJsonPrepare['Subscription'] = {
                    "RenewalSubscriptionId": this.subscriptionForm.controls['Subscription'].controls['RenewalSubscriptionId'].value,
                    "PublicationComboId":this.subscriptionForm.controls['Subscription'].controls['PublicationComboId'].value,
                    "ComboPriceId": this.subscriptionForm.controls['Subscription'].controls['ComboPriceId'].value,
                    "Format": this.subscriptionForm.controls['Subscription'].controls['Format'].value,
                    "Type": 2
                }
            }
        
        }
        else if(this.subType == 'Bulk Subscription'){
            this.postDataJsonPrepare["NoOfCopiesRequired"] = this.subscriptionForm.controls['NoOfCopiesRequired'].value;
            this.postDataJsonPrepare["SponsorshipFor"] = this.subscriptionForm.controls['SponsorshipFor'].value;
            this.postDataJsonPrepare['Subscription'] = {
            "PublicationComboId": this.subscriptionForm.controls['Subscription'].controls['PublicationComboId'].value,
            "ComboPriceId": this.subscriptionForm.controls['Subscription'].controls['ComboPriceId'].value,
            "Format": this.subscriptionForm.controls['Subscription'].controls['Format'].value,
            "Duration": this.subscriptionForm.controls['Subscription'].controls['Duration'].value,
            "DurationType": this.subscriptionForm.controls['Subscription'].controls['DurationType'].value
            };
            
        }else if(this.subType == 'Gift Subscription'){
            this.postDataJsonPrepare['GiftTo'] = {};
            //need discuss for gift memberid
            this.postDataJsonPrepare['GiftTo']['MemberId'] = this.selectedGiftMemberId;
            this.postDataJsonPrepare['GiftTo']['NewMemberDetails'] = {};
            //need country listing
            this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['CountryId']='';
            this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['Email']= this.subscriptionForm.controls['GiftTo'].controls['Email'].value;
            this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['FirstName']= this.subscriptionForm.controls['GiftTo'].controls['FirstName'].value;
            this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['Gender']='';
            this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['LastName']= this.subscriptionForm.controls['GiftTo'].controls['LastName'].value;
            this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['MobileNumber']= this.subscriptionForm.controls['GiftTo'].controls['PrimaryPhone'].value;
            this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['SalutationId']= this.subscriptionForm.controls['GiftTo'].controls['SalutationId'].value;
            this.postDataJsonPrepare['GiftTo']['NewMemberDetails']['SecondaryNumber']= this.subscriptionForm.controls['GiftTo'].controls['SecondaryPhone'].value;
            this.postDataJsonPrepare['Subscription'] = {
            "PublicationComboId": this.subscriptionForm.controls['Subscription'].controls['PublicationComboId'].value,
            "PriceOptionId": this.subscriptionForm.controls['Subscription'].controls['PriceOptionId'].value,
            "ComboPriceId": this.subscriptionForm.controls['Subscription'].controls['ComboPriceId'].value,
            "Format": this.subscriptionForm.controls['Subscription'].controls['Format'].value,
            };

        }
        else{
            //other subscriptions

        this.postDataJsonPrepare['Subscription'] = {
            "PublicationComboId": this.subscriptionForm.controls['Subscription'].controls['PublicationComboId'].value,
            "ComboPriceId": this.subscriptionForm.controls['Subscription'].controls['ComboPriceId'].value,
            "Format": this.subscriptionForm.controls['Subscription'].controls['Format'].value,
            "PriceOptionId": this.subscriptionForm.controls['Subscription'].controls['PriceOptionId'].value

        };
        }
        
        

        // common subscription param

        this.postDataJsonPrepare['PaymentProcessParams']['SubscriptionAmount'] = this.subscriptionForm.controls['PaymentProcessParams'].controls['SubscriptionAmount'].value;
        this.postDataJsonPrepare['PaymentProcessParams']['InstallmentAmount'] = "";
        this.postDataJsonPrepare['PaymentProcessParams']['CouponCode'] = "";
        this.postDataJsonPrepare['PaymentProcessParams']['PayAmount'] = this.subscriptionForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].value;
        this.postDataJsonPrepare['PaymentProcessParams']['InvoiceId'] = "";
        this.postDataJsonPrepare['PaymentProcessParams']['CurrencyISOCode'] = this.subscriptionForm.controls['PaymentProcessParams'].controls['CurrencyISOCode'].value;
        this.postDataJsonPrepare['PaymentProcessParams']['IsComplementary'] = '0';
        this.postDataJsonPrepare['PaymentProcessParams']['NetAmountToPay'] = this.subscriptionForm.controls['PaymentProcessParams'].controls['NetAmountToPay'].value;
        this.postDataJsonPrepare['PaymentProcessParams']['DiscountAmount'] = "";


        //paymentFields
        if (paymentFields != null) {
            // console.log("not null payment filelds")
            this.postDataJsonPrepare["PaymentProcessParams"]["SaveCardForFuture"] = "0";
            this.postDataJsonPrepare["PaymentProcessParams"]["ProceedWithoutPaymentProcess"] = "0";
            this.postDataJsonPrepare["PaymentProcessParams"]["PaymentMethodId"] = this.InitialMethodId;
            if(paymentFields['type']==  "manual_payment"){
            this.postDataJsonPrepare["PaymentProcessParams"]["ProceedWithoutPaymentProcess"] = "1";
            }else{
            this.postDataJsonPrepare["PaymentProcessParams"]["ProceedWithoutPaymentProcess"] = "0";
            }
            if (paymentFields.type == "ach") {
                if (this.selectedSavedCard == false) {
                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                        "AccountHolder"
                    ] = this.subscriptionForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountHolder.value;
                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                        "AccountNumber"
                    ] = this.subscriptionForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountNumber.value;
                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                        "RoutingNumber"
                    ] = this.subscriptionForm.controls.PaymentProcessParams.controls.PaymentFields.controls.RoutingNumber.value;
                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                        "AccountType"
                    ] = this.subscriptionForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountType.value;
                    // this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    //     "BankName"
                    // ] = this.subscriptionForm.controls.PaymentProcessParams.controls.PaymentFields.controls.BankName.value;
                    if (this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['SaveCardForFuture'].value) {
                    // console.log("inside save card tick")
                        this.postDataJsonPrepare["PaymentProcessParams"][
                            "SaveCardForFuture"
                        ] = "1";
                    } else {
                        this.postDataJsonPrepare["PaymentProcessParams"][
                            "SaveCardForFuture"
                        ] = "0";
                    }
                }
                else {
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "PaymentGatewayTokenId"
                    ] = this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
                }
            } else if (paymentFields.type == "credit_or_debt") {
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "Nonce"
                ] = nonce;

                if (this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['SaveCardForFuture'].value) {
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "SaveCardForFuture"
                    ] = "1";
                } else {
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "SaveCardForFuture"
                    ] = "0";
                }
            } else if (paymentFields.type == "check") {
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "PaymentReceivedDate"
                ] = this.subscriptionForm.controls.paymentReceivedDate.value;
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "CheckDate"
                ] = this.subscriptionForm.controls.CheckDate.value;
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "CheckNumber"
                ] = this.subscriptionForm.controls.CheckNumber.value;
                // this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                //     "CheckImage"
                // ] = this.CheckImage;

                this.postDataJsonPrepare["PaymentProcessParams"][
                    "PayNowWithManualPaymentMethod"
                ] = this.subscriptionForm.get('manualPaymentMethod').value;

            } else if (paymentFields.type == "bank_transfer") {
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "ReceivedDate"
                ] = this.subscriptionForm.controls.BankReceivedDate.value;
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "ReferenceNumber"
                ] = this.subscriptionForm.controls.ReferenceNumber.value;
                this.postDataJsonPrepare["PaymentProcessParams"][
                    "PayNowWithManualPaymentMethod"
                ] = this.subscriptionForm.get('manualPaymentMethod').value;
            } else if (paymentFields.type == "cash") {
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "ReceivedDate"
                ] = this.subscriptionForm.controls.cashReceivedDate.value;
                this.postDataJsonPrepare["PaymentProcessParams"][
                    "PayNowWithManualPaymentMethod"
                ] = this.subscriptionForm.get('manualPaymentMethod').value;
            }
            }
    }else if(this.type == 'event'){
        this.postDataJsonPrepare = {};
        this.postDataJsonPrepare['MemberId'] = this.memberId;
        this.postDataJsonPrepare['Event'] = {};
        this.postDataJsonPrepare['Event']['EventId'] = this.eventForm.controls.Event.controls.EventId.value;
        this.postDataJsonPrepare['Event']['EventTypeId'] = this.eventForm.controls.Event.controls.EventTypeId.value;
        this.postDataJsonPrepare['Event']['IsAccommodationRequired'] = (this.eventForm.controls.Event.controls.IsAccommodationRequired.value) ? 1 : 0;
        this.postDataJsonPrepare['CustomFieldValues'] = [];
        if(this.dynamicForm.invalid){
            console.log("dynmic form id not valid")
            //   this.additionalFieldRequiredMsg = true;
            //   console.log("dynamic form is not valid inside reqat form",this.additionalFieldRequiredMsg)
        }
        for (let x in this.dynamicForm.value) {
            this.postDataJsonPrepare['CustomFieldValues'].push({
                "Id": x,
                "Value": this.dynamicForm.value[x]
            })
        }

        this.postDataJsonPrepare['ParticipantDetails'] = [];
        let index = 0;
        for (const formGroup of this.eventForm.controls.ParticipantDetails.controls.ParticipantDetail.controls) {

            this.postDataJsonPrepare['ParticipantDetails'].push({
                "Key": index,
                'IsSystemMember': '0',
                'MemberId': this.memberId,
                'ParticipantDetail': {
                    'StreamId': formGroup.get('StreamId').value,
                    'Fname': formGroup.get('Fname').value,
                    'Lname': formGroup.get('Lname').value,
                    'SalutationId': formGroup.get('SalutationId').value,
                    'Age': formGroup.get('Age').value,
                    'Gender': formGroup.get('Gender').value
                }
            });
            index++;
        }
        this.postDataJsonPrepare['SpecialRequest'] = [];

        for (let x in this.specialRequestForm.value) {
            this.postDataJsonPrepare['SpecialRequest'].push({
                "Id": x,
                "Value": (this.specialRequestForm.value[x]) ? "1" : "0"
            })
        }

        this.postDataJsonPrepare['PaymentProcessParams'] = {};
        this.postDataJsonPrepare['PaymentProcessParams']['ProceedWithoutPaymentProcess'] = this.eventForm.controls['PaymentProcessParams'].controls['ProceedWithoutPaymentProcess'].value;
        this.postDataJsonPrepare['PaymentProcessParams']['RegistrationAmount'] = this.eventForm.controls.PaymentProcessParams.controls.NetAmountToPay.value;
        this.postDataJsonPrepare['PaymentProcessParams']['InvoiceId'] = "";
        this.postDataJsonPrepare['PaymentProcessParams']['DiscountAmount'] = this.eventForm.controls.PaymentProcessParams.controls.DiscountAmount.value;
        this.postDataJsonPrepare['PaymentProcessParams']['CurrencyISOCode'] =  this.eventForm.controls.PaymentProcessParams.controls.CurrencyISOCode.value;
        this.postDataJsonPrepare['PaymentProcessParams']['NetAmountToPay'] = this.eventForm.controls.PaymentProcessParams.controls.NetAmountToPay.value;
        this.postDataJsonPrepare['PaymentProcessParams']['IsAccomadationRequired'] = (this.eventForm.controls.PaymentProcessParams.controls.IsAccomadationRequired.value) ? 1 : 0;

        //   if (this.eventForm.controls.PartialPayment.value) {
        //       this.postDataJsonPrepare['PaymentProcessParams']['PartialPayment'] = {};
        //       this.postDataJsonPrepare['PaymentProcessParams']['PartialPayment']['PayingAmount'] = this.eventForm.controls.PayingAmount.value;
        //   }
        this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'] = {};
        this.postDataJsonPrepare["PaymentProcessParams"][
            "PaymentMethodId"
        ] = this.InitialMethodId;
        if (paymentFields.type == "ach") {
            if (this.selectedSavedCard == false) {
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "AccountHolder"
                ] = this.eventForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountHolder.value;
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "AccountNumber"
                ] = this.eventForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountNumber.value;
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "RoutingNumber"
                ] = this.eventForm.controls.PaymentProcessParams.controls.PaymentFields.controls.RoutingNumber.value;
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "AccountType"
                ] = this.eventForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountType.value;
                // this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                //     "BankName"
                // ] = this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.BankName.value;
                if (this.eventForm.controls.PaymentProcessParams.controls.PaymentFields.controls.SaveCardForFuture.value) {
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "SaveCardForFuture"
                    ] = "1";
                } else {
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "SaveCardForFuture"
                    ] = "0";
                }
            }
            else {
                this.postDataJsonPrepare["PaymentProcessParams"][
                    "PaymentGatewayTokenId"
                ] = this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
            }
        } else if (paymentFields.type == "credit_or_debt") {
            this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                "Nonce"
            ] = nonce;

            if (this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['SaveCardForFuture'].value) {
                this.postDataJsonPrepare["PaymentProcessParams"][
                    "SaveCardForFuture"
                ] = "1";
            } else {
                this.postDataJsonPrepare["PaymentProcessParams"][
                    "SaveCardForFuture"
                ] = "0";
            }
        } 
    }
  

    return this.postDataJsonPrepare;
}
successPopup(data) {
  this.response = {title: data['status'],message: data['msg']}
   
   //   this.modalService.show(SuccessPopupComponent, {initialState})
   const modalRef = this.modalService.open(SuccessPopupComponent, {
       size: "md",
   });
   modalRef.componentInstance.user = this.response;
   modalRef.result.then(
       result => {
       //   console.log("result-----modal",result)
       if(this.type == "donation"){
            if (result.result && data['status']=="success") {
                this.router.navigateByUrl("/memberDashboard/mydonation/"+this.routeParam.params['memberId'])
            }else{
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentMethodId'].setValue(0);
            }
       }else if(this.type == 'subscription'){
            if (result.result && data['status']=="success") {
                this.router.navigateByUrl("/memberDashboard/mysubscription/"+this.routeParam.params['memberId'])
            }  
        }else if(this.type == 'event'){
            if (result.result && data['status']=="success") {
                this.router.navigateByUrl("/memberDashboard/myeventregistration/"+this.routeParam.params['memberId'])
            }  
       }
           
       });
}


}

