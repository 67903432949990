<div class="contact-alert" [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <!-- <div class="alert-top-bar">
        <div class="row align-item-center">
            <div class="col-7 col-md-7">
                <h5>Edit Billing Address</h5>
            </div>
            <div class="col-5 col-md-5 text-right">
                <figure class="mb-0">
                    <img src="./assets/member/images/dashboard-logo.svg " alt="Shalom ">
                </figure>
                <button type="button" class="close"  (click)="close()">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
        </div>
    </div> -->
    <button type="button" class="close" (click)="close(true)">
        <span aria-hidden="true">
            &times;
        </span>
    </button>
    <div class="popup-inner">
        <div class="text-center pt-5 popup-title">
            <h2>List of Participants</h2>
            <!-- <p>Lorem Ipsum doler set</p> -->
        </div>
        <div class="contact-form">
            <ul class="participant-list-wrp">
                <li class="d-flex" *ngFor="let participant of participants;let  i = index">
                    <div class="participant-count"><span>{{i+1}}</span></div>
                    <div class="participant-name d-flex"><span>{{participant.SalutationName}} {{participant.Fname}} {{participant.Lname}}</span><i class="btn-close" (click)="removeParticipant(i)">x</i></div>
                </li>
                <!-- <li class="d-flex">
                    <div class="participant-count"><span>02</span></div>
                    <div class="participant-name d-flex"><span>Mrs. Neha Thomas abrham</span><i class="btn-close">x</i></div>
                </li>
                <li class="d-flex">
                    <div class="participant-count"><span>03</span></div>
                    <div class="participant-name d-flex"><span>Mr Abraham Thomas</span><i class="btn-close">x</i></div>
                </li>
                <li class="d-flex">
                    <div class="participant-count"><span>04</span></div>
                    <div class="participant-name d-flex"><span>Mrs. Neha Thomas abrham</span><i class="btn-close">x</i></div>
                </li>
                <li class="d-flex">
                    <div class="participant-count"><span>05</span></div>
                    <div class="participant-name d-flex"><span>Mrs. Neha Thomas abrham</span><i class="btn-close">x</i></div>
                </li>
                <li class="d-flex">
                    <div class="participant-count"><span>06</span></div>
                    <div class="participant-name d-flex"><span>Mr Abraham Thomas</span><i class="btn-close">x</i></div>
                </li>
                <li class="d-flex">
                    <div class="participant-count"><span>07</span></div>
                    <div class="participant-name d-flex"><span>Mr Abraham Thomas</span><i class="btn-close">x</i></div>
                </li>
                <li class="d-flex">
                    <div class="participant-count"><span>08</span></div>
                    <div class="participant-name d-flex"><span>Mrs. Neha Thomas abrham</span><i class="btn-close">x</i></div>
                </li> -->
            </ul>
            <div class=" mt-3 mb-3 d-flex justify-content-center">
                <button type="submit" class="btn-filled btn-modal" (click)="openAddParticipant()">Add </button>
            </div>
        </div>
    </div>
</div>