import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonCrudService } from 'src/app/_services/common-crud.service';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';

@Component({
  selector: 'app-edit-shipping-address',
  templateUrl: './edit-shipping-address.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css','./edit-shipping-address.component.scss']
})
export class EditShippingAddressComponent implements OnInit {
  addressCreateForm: any;
  userLocation: any;
  countryId: any;
  StatesList: any;
  state: any;
  @Input() public shippingForm: any;
  memberId: string;
  response: { title: any; message: any; };
  ValidationMessage: any = {
    AddressType: [{ type: "required", message: "Address Type is required" }],
    Address1: [  { type: 'required', message: 'Please enter your street address' },
    { type: 'maxlength', message: 'Street address can only have a maximum of 100 characters' },],
    Address2: [ { type: 'maxlength', message: 'Apt, Suite, Bldg can only have a maximum of 100 characters' },],
    City: [{ type: "required", message: "City is required" },{ type: 'pattern', message: 'Please enter your city without special characters' }, { type: 'maxlength', message: 'City can only have a maximum of 100 characters' },],
    CountryId: [{ type: "required", message: "Country is required" }],
    StateId: [{ type: "required", message: "State is required" }],
    Zip: [
      
    ]
};
  editAddress: boolean = false;
  isLoading : Boolean = false;
  isSpfMember:boolean;
  singleDonationExist:boolean = false;

  constructor(private formBuilder: FormBuilder,public _crud: CommonCrudService,public modalService: NgbModal,public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
        // console.log(this.shippingForm['formData'], "AHGKND");

    // this.getUserLocation();
    this.countryId = this.shippingForm['countryId']
    this.getAllStates(this.shippingForm['countryId']);
    this.formInit();
    this.memberId = localStorage.getItem('memberId');
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.singleDonationExist = currentUser.singleDonationExist;

  }
  animateLabel(event) {
    event.parentNode.classList.add('is-focused');
}

animateLabelRemove(e) {
    // console.log(e.value, "AHGKND");
    if (e.value == '') {
        e.parentNode.classList.remove('is-focused');
    }
}
formInit() {
  this.addressCreateForm = this.formBuilder.group({
      AddressType: [2, Validators.required],
      Address1: ["", [Validators.required, Validators.maxLength(100)]],
      Address2: ["",Validators.maxLength(100)],
      City: ["", [Validators.required, Validators.pattern("[a-zA-Z0-9&' ,.\-]*$"), Validators.maxLength(100)]],
      CountryId: [this.countryId, Validators.required],
      StateId: [this.state, Validators.required],
      Zip: [""]
  });
  this.zipValidation();
  if(this.shippingForm['formData'] != null){
    this.editAddress = true
    this.addressCreateForm.controls['Address1'].setValue(this.shippingForm['formData']['Address1'])
    if(this.shippingForm['formData']['Address2'] != null){
      this.addressCreateForm.controls['Address2'].setValue(this.shippingForm['formData']['Address2'])
    }
    this.addressCreateForm.controls['City'].setValue(this.shippingForm['formData']['City'])
    this.addressCreateForm.controls['CountryId'].setValue(this.shippingForm['formData']['CountryId'])
    this.state = this.shippingForm['formData']['StateId']
    if(this.shippingForm['formData']['Zip'] != null){
      this.addressCreateForm.controls['Zip'].setValue(this.shippingForm['formData']['Zip'])
    
      }    document.getElementById("address1").classList.add("is-focused");
    if(this.shippingForm['formData']['Address2'] != null){  document.getElementById("address2").classList.add("is-focused");
  }
  if(this.shippingForm['formData']['City'] != null){  
    document.getElementById("city").classList.add("is-focused");
  }
  if(this.shippingForm['formData']['Zip'] != null){  
    document.getElementById("zip").classList.add("is-focused");
  }
   


  }
  
}
getUserLocation() {
  let params = {
      url: 'frontend-site/user-location'
  }
  this._crud.getData(params).subscribe(data => {
      
      this.userLocation = data['data'];
      // console.log(this.userLocation);

      this.countryId = this.userLocation.CountryId
      // this.countryName = this.userLocation.CountryName
      this.getAllStates(this.countryId);
      // this.getAllCountries(this.userLocation.PrimaryCountryId);
      // this.getFooterText(this.userLocation.PrimaryCountryId);
  })
  
}
getAllStates(countryId) {
  let params = {}
  params["params"] = {
      CountryId: countryId
  }
  params["url"] = "frontend-site/fetch-states/" + countryId;
  this._crud.getData(params).subscribe(data => {
      // console.log('func3');
      // console.log('data3', data['data']);
      this.StatesList = data['data'];
  })

}
save(){
  // if (this.addressCreateForm.valid) {
    let Step2Fields = [
      this.addressCreateForm.controls['Address1'],
      this.addressCreateForm.controls['StateId'],
      this.addressCreateForm.controls['Zip'],
      this.addressCreateForm.controls['City'],
      // this.addressCreateForm.controls['SecondaryNumber'],
      // this.addressCreateForm.controls['Email']
    ]
    const invalid = []
    const controls = Step2Fields;
    let i: any;
    for (i in controls) {
        controls[i].markAsDirty();
        if (controls[i].invalid) {
            invalid.push(i);
        }
    }
    if(invalid[0] == undefined){
    this.isLoading = true;
  this.addressCreateForm.controls['CountryId'].setValue(this.countryId);
  // console.log("form data", this.addressCreateForm.value);
  if(this.shippingForm['formData'] == null){
    this._crud.addressMemberCreate(this.addressCreateForm.value, this.memberId).subscribe(data => {
      // console.log("data save",data);
      this.isLoading = false;
      // if(data['status']== "success"){
        this.successPopup(data);
  
      // }
  
    })
  }else{
    // console.log("inside save--else---")
    this._crud.addressMemberUpdate(this.addressCreateForm.value, this.shippingForm['formData']['MemberAddressId']).subscribe(data => {
      // console.log("data edit",data);
      this.isLoading = false;
      // if(data['status']== "success"){
        this.successPopup(data);
      // }
    })
  }
  
  
}
}
successPopup(data) {
  // console.log("inside succes popup")
  this.response = {title: data['status'],message: data['msg']}
   
   //   this.modalService.show(SuccessPopupComponent, {initialState})
   const modalRef = this.modalService.open(SuccessPopupComponent, {
       size: "md",
   });
   modalRef.componentInstance.user = this.response;
   modalRef.result.then(
    result => {
      // console.log("result-----modal",result)
        if (result.result && data['status']== "success") {
            // /clearing all forms.
            this.activeModal.close({ 'result': true });
        }
    });
}
close(){
  this.activeModal.close({ 'result': true });
}
zipValidation(){
  let countryCode =  localStorage.getItem('countryCode')

  if (countryCode == 'US') {
        console.log("zip validation--------us")
    this.addressCreateForm.get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{5,5}$/)]));
    this.addressCreateForm.get('Zip').updateValueAndValidity();
    this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code with five digits" }];
} else if (countryCode == 'CA') {//} else if (this.countryId == 'gQFUOwHXczkw') { canada
this.addressCreateForm.get('Zip').setValidators(Validators.compose([Validators.required,Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{6,7}$/)]));
this.addressCreateForm.get('Zip').updateValueAndValidity();
this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" },{ type: "pattern", message: "Please enter a valid postal code" }];
}else if (countryCode == 'AU') {//  australia
  this.addressCreateForm.get('Zip').setValidators(Validators.compose([Validators.required,Validators.pattern(/^(?!0+$)\d{4,4}$/)]));
  this.addressCreateForm.get('Zip').updateValueAndValidity();
this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" },{ type: "pattern", message: "Please enter a valid postal code" }];
}else if (countryCode== 'GB') {//uk
  this.addressCreateForm.get('Zip').setValidators(Validators.compose([Validators.required,Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{5,8}$/)]));
  this.addressCreateForm.get('Zip').updateValueAndValidity();
this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" },{ type: "pattern", message: "Please enter a valid postal code" }];
}else if (countryCode == 'IE') {//ireland
  this.addressCreateForm.get('Zip').setValidators(Validators.compose([Validators.required,Validators.pattern(/^(?!0+$)[0-9A-Za-z\s]{7,8}$/)]));
  this.addressCreateForm.get('Zip').updateValueAndValidity();
this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" },{ type: "pattern", message: "Please enter a valid postal code" }];
}else {
  this.addressCreateForm.get('Zip').setValidators(Validators.pattern(/^(?!0+$)[0-9A-Za-z\s\-]{3,10}$/));
  this.addressCreateForm.get('Zip').updateValueAndValidity();
  this.ValidationMessage.Zip = [{ type: "pattern", message: "Please enter a valid postal code" }];
}
}
billingZipValidation(value){
  let countryCode =  localStorage.getItem('countryCode')
  if (countryCode == 'CA') {
  var arr = value
  if(arr.length == 6 && !arr.match(" ") ){
console.log("true 1")
  }else if(arr.length == 7 && arr[3]== " " && arr.split(" ").length == 2){
      console.log("true 2")
  }else{
    this.addressCreateForm.controls['Zip'].setErrors({ 'pattern': true })
      console.log("false ")

  }
}else  if (countryCode== 'GB') {//uk
  var arr = value
  if(arr.length == 5  && !arr.match(" ") ){
console.log("true 1")
  }else if(arr.length > 5 && arr.length < 9 ){
      if(arr.match(" ")){
if((arr[2]== " " || arr[3]== " " || arr[4]== " ") && arr.split(" ").length == 2){
console.log("true 2", arr.split(" ").length)

}else{
  this.addressCreateForm.controls['Zip'].setErrors({ 'pattern': true })
console.log("false ") 
}
      }
  }else{
    this.addressCreateForm.controls['Zip'].setErrors({ 'pattern': true })
      console.log("false ")

  }
}else  if (countryCode == 'IE') {//ireland
  var arr = value
  if(arr.length == 7 && !arr.match(" ") ){
console.log("true 1")
  }else if(arr.length == 8 && arr[3]== " " && arr.split(" ").length == 2 ){
      console.log("true 2")
  }else{
    this.addressCreateForm.controls['Zip'].setErrors({ 'pattern': true })
      console.log("false ")

  }
}
}
}