import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsConditionComponent } from './terms-condition.component';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../main.component';
import { MainModule } from '../main.module';

const routes: Routes = [
  {
      'path': '',
      'component': MainComponent,
      'children': [
          {
              'path': '',
              'component': TermsConditionComponent,
          },
      ],
  },
];

@NgModule({
  declarations: [TermsConditionComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes), 
    MainModule
  ]
})
export class TermsConditionModule { }
