import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../../../_services/shared.service';
import { CommonCrudService } from '../../../../_services/common-crud.service';

@Component({
  selector: 'app-sider-bar-member',
  templateUrl: './sider-bar-member.component.html',
  styleUrls: ['./sider-bar-member.component.scss']
})
export class SiderBarMemberComponent implements OnInit {
  isSpfmember:any 
  memberProfile: any = [];
  ShalomOfficeAddress: any = [];
  PhotoUrl: any;
  FirstName: any;
  LastName: any;
  Salutation: any;

  constructor(public activeRoute: ActivatedRoute, public sharedService: SharedService, public _crud: CommonCrudService) { }
  routeParams;
  memberId;
  activeTab;

  ngOnInit(): void {
    this.isSpfmember = localStorage.getItem('IsSpfMember');
    this.routeParams = this.activeRoute.snapshot['_urlSegment'].segments[2].path;
    console.log("memberId-----",this.routeParams,this.isSpfmember );
    // this.memberId = this.routeParams;
    this.memberId = localStorage.getItem('memberId'); 
    this.sharedService.pathInfo.subscribe(path => {
      // console.log("path side bar",path);
      this.activeTab = path;
    });
    this.sharedService.currentPhoto.subscribe(photo =>{
      this.PhotoUrl = photo
    })
    this.sharedService.currentFname.subscribe(fname =>{
      this.FirstName = fname
    })
    this.sharedService.currentLname.subscribe(lname =>{
      this.LastName = lname
    })
    this.sharedService.currentTitle.subscribe(title =>{
      this.Salutation = title
    })
    let params = {
      url: "frontend-site/view-member", params: { MemberId: this.memberId }
    }
    this._crud.getData(params).subscribe(res => {
        this.memberProfile = res['data']['MemberProfile'];
        this.PhotoUrl = this.memberProfile['PhotoUrl']
        this.FirstName = this.memberProfile['FirstName']
        this.LastName = this.memberProfile['LastName']
        this.Salutation = this.memberProfile['Salutation']
        this.ShalomOfficeAddress = this.memberProfile['ShalomOfficeAddress']
        // console.log("side-bar data----", this.memberProfile);

    });
   
  }

}
