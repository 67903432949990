import { Component, OnInit } from '@angular/core';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { FormBuilder,Validators} from '@angular/forms';
import { Router ,ActivatedRoute} from '@angular/router';
import { SuccessPopupComponent } from '../../main/success-popup/success-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare let $: any;

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  constructor(public formBuilder: FormBuilder,public _crud:CommonCrudService,public router:Router,public _route:ActivatedRoute,
    public modalService: NgbModal) { 
    this.formInit();
  }
  forgotPasswordForm;
  displayMsg;
  isLoading : boolean = false;
  step2Fields;
  ValidationMessage: any = {
    Email: [
        { type: "required", message: "Please enter your email address" },
        { type: "pattern", message: "Please enter a valid email" }
    ]
  }
  ngOnInit(): void {
  }
  formInit(){
    this.forgotPasswordForm = this.formBuilder.group({
      Email:['',Validators.required]
  });
  }
  animateLabel(event) {
    event.parentNode.classList.add('is-focused');
  }

  animateLabelRemove(e) {
    // console.log(e.value, "AHGKND");
    if (e.value == '') {
        e.parentNode.classList.remove('is-focused');
    }
  }
  Submit(){
    this.step2Fields = [   
      this.forgotPasswordForm.controls['Email'],
    ]
  const invalid = [];
  const controls = this.step2Fields;
  let i: any;
  for (i in controls) {
      controls[i].markAsDirty();
      if (controls[i].invalid) {
          invalid.push(i);
      }
  }
  if (invalid[0] == undefined) {
    this.isLoading = true;
    let params = {
      url: "frontend-site/member/forgot-password", 
      params: {Email : this.forgotPasswordForm.controls.Email.value}
    }
    this._crud.postData(params).subscribe(res=>{
      this.isLoading = false;
      // console.log("response------",res);
      if(res['status'] == 'error'){
        this.displayMsg = res['msg'];
      }else{
        //call successpopup
        this.successPopup(res);

      }
      

    });
  }
  
  }
  successPopup(data) {
  let response = {title: data['status'],message: data['msg']}
     
     //   this.modalService.show(SuccessPopupComponent, {initialState})
     const modalRef = this.modalService.open(SuccessPopupComponent, {
         size: "sm",
     });
     modalRef.componentInstance.user = response;
     modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
          if (result.result && data['status']== "success") {
              // /clearing all forms.
              this.router.navigateByUrl('/member-login')
          }
      });
  }

}
