import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberRegisterComponent } from './member-register.component';
import { ReactiveFormsModule,FormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { RecaptchaModule } from 'ng-recaptcha';



@NgModule({
  declarations: [MemberRegisterComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    RecaptchaModule
  ]
})
export class MemberRegisterModule { }
