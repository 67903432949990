<!-- <a class="nav-link " routerLink="../"><i class="la la-arrow-left"></i>EventRegistration List</a> -->
<div class="donation-payment bx-white">
    <form [formGroup]="eventForm">
        <div class="row mob-no-margin">
            <div class="col-lg-12 text-center top-title-wrp">
                <h3>{{eventType}}</h3>
                <p>{{eventName}}<span>{{eventDate}}</span></p>
            </div>
            <div class="col-lg-12  mt-4" [hidden]="isAgeRequired">
                <div class="row align-items-center">
                    <div class="col-md-8 form-group">
                        <ng-select formControlName="CountryId" id="country" [(ngModel)]="countryId" [clearable]="false" placeholder="Country" required (change)="getAllPaymentMethods($event.CountryId, 1)" bindValue="CountryId" bindLabel="Name" [items]="CountryList" autocomplete="autocomplete_off_hack_xfr4!k"></ng-select>
                    </div>
                    <div class="col-md-4 top-checkbx form-group" formGroupName="Event" [hidden]="isAccomodationRequired">
                        <div class="checkbox-wrp d-flex align-items-center">
                            <input class="form-check-input" formControlName="IsAccommodationRequired" id="accomodation" value="" type="checkbox" (change)="changeAccomodation($event.target.checked)">
                            <label class="form-check-label" for="accomodation">Need Accommodation</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12  mt-4" [hidden]="!isAgeRequired">
                <div class="row align-items-center">
                    <div class="col-md-8 form-group">
                        <ng-select formControlName="CountryId" id="country" [(ngModel)]="countryId" [clearable]="false" placeholder="Country" required (change)="getAllPaymentMethods($event.CountryId, 1)" bindValue="CountryId" bindLabel="Name" [items]="CountryList" autocomplete="autocomplete_off_hack_xfr4!k"></ng-select>
                    </div>
                    <div class="col-md-4 top-checkbx form-group" formGroupName="Event" [hidden]="isAccomodationRequired">
                        <div class="checkbox-wrp d-flex align-items-center">
                            <input class="form-check-input" formControlName="IsAccommodationRequired" id="accomodation" value="" type="checkbox" (change)="changeAccomodation($event.target.checked)">
                            <label class="form-check-label" for="accomodation">Need Accommodation</label>
                        </div>
                    </div>
                </div>
            </div>
            <!-- commneting hardcoded html -->
            <div class="col-lg-12 participants" formGroupName="ParticipantDetails">
                <div class=" mt-4 mb-4">
                    <h4>Participant Details</h4>
                </div>
                <div class=" px-0 participant-row " formArrayName="ParticipantDetail">
                    <div class="w-100" [formGroupName]="i" *ngFor="let participant of eventForm.get('ParticipantDetails').get('ParticipantDetail')['controls']; let i = index">
                        <div class="participant-detail d-flex">

                            <div class="form-group width1 mr-3">
                                <ng-select formControlName="SalutationId" id="salutationid" [clearable]="false" placeholder="Title" bindValue="Id" bindLabel="Name" [items]="SalutationList" (change)="setTitle()"></ng-select>
                            </div>
                            <div class="form-group width2 mr-2 focus-label">
                                <label class="form-label-of" for="">First Name</label>
                                <input type="text" id="p_first_name" formControlName="Fname" autocomplete="off" (input)="firstNameFetch($event.target.value,i)" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="setFname(i)" required>
                                <!-- <span class="error-message danger alert" *ngIf="(participant.get('Fname')?.dirty || participant.get('Fname')?.touched) && participant.get('Fname')?.invalid">
                                        First name is required
                                    </span> -->
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Fname">
                                        <span class="error-message danger alert" *ngIf="participant.get('Fname')?.hasError(validation.type) && (participant.get('Fname')?.dirty || participant.get('Fname')?.touched) && participant.get('Fname')?.invalid">
                                            {{ validation.message }}
                                        </span>
                                </span>
                            </div>
                            <div class="form-group width2 ml-2 focus-label">
                                <label class="form-label-of" for="">Last Name</label>
                                <input formControlName="Lname" type="text" autocomplete="off" id="p_last_name" (input)="lastNameFetch($event.target.value,i)" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="setLname(i)" required>
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Lname">
                            <span class="error-message danger alert" *ngIf="participant.get('Lname')?.hasError(validation.type) && (participant.get('Lname')?.dirty || participant.get('Lname')?.touched) && participant.get('Lname')?.invalid">
                                {{ validation.message }}
                            </span>
                                </span>
                            </div>
                        </div>
                        <div class="   align-items-center  participant-detail d-flex">
                            <div class="form-group width1 mr-3">
                                <ng-select [items]="Genders" bindValue="Id" bindLabel="Name" placeholder="Gender" [searchable]="false" [clearable]="false" formControlName="Gender"></ng-select>
                            </div>
                            <div class="form-group width2 mr-3">
                                <ng-select formControlName="StreamId" [clearable]="false" [searchable]="false" required [items]="streamList" autocomplete="off" placeholder="Stream" bindValue="Id" bindLabel="Name" (change)="calculatePrice(i)"></ng-select>
                            </div>
                            <div class="form-group width3 focus-label" [hidden]="!isAgeRequired">
                                <label class="form-label-of" for="">Age</label>
                                <input formControlName="Age" type="text" id="age" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (change)="calculatePrice(i)">
                            </div>
                            <div class="form-group width4 d-flex align-items-center">
                                <!-- <span>$150</span> -->
                                <input type="hidden" formControlName="Cost" name="Cost" class="form-control m-input" placeholder="" value="" autocomplete="off">
                                <span>{{ participant.get('Cost').value == '' ? "0.00" : participant.get('Cost').value }} {{Currency}}</span>
                            </div>
                            <!-- <div class="form-group add-row-holder width5 ">
                                <span class="btn-add-row ml-auto" (click)="removeParticipantDetailsFiled(i)"><i class="icon-minus"><img src="./assets/event_media/img/icon-minus.png"></i></span>
                            </div> -->
                            <div class="form-group remove-holder width5 text-center">
                                <span (click)="removeParticipantDetailsFiled(i)">
                                <img src="./assets/member/images/icon-delete.svg"> 
                                <!-- Remove -->
                                </span>
                            </div>
                        </div>
                        <!-- <div class="form-group add-row-holder  btn-add width7 btm-border pb-5 mt-2">
                            <button class="btn-common btn-add" (click)="addParticipantDetailsFiled()" type="button">+Add Participant</button>
                        </div> -->
                    </div>
                    <!-- <div class="col-sm-12 form-group add-row-holder text-right">
                        <span class="addBtn" style="margin-right: 8px" (click)="addParticipantDetailsFiled()">Add Participants</span>
                    </div> -->
                    <span class="error-message" *ngIf="errorFound">{{ errorMessage}}</span>
                    <span class="error-message"> {{emptyParticipant}}</span>
                </div>
                <div class="form-group add-row-holder  btn-add width7 btm-border pb-5 mt-2 text-right">
                    <button class="btn-common btn-add" (click)="addParticipantDetailsFiled()" type="button">+ Add Participant</button>
                </div>
            </div>


            <div class="col-lg-12  mt-4 mb-4 spcl-req-wrp d-flex align-items-center" *ngIf="EventDetails?.SpecialRequest != ''">
                <h4>Special Request</h4>
                <div class="checkbox-wrp d-flex align-items-center ml-5 pr-5" *ngIf="EventDetails?.SpecialRequest != ''">
                    <div class="row px-0">
                        <div class="checkbox-wrp d-flex ml-5" *ngFor="let df of customFieldsCheckbox" class="col-md-4">
                            <app-custom-fields [form]="specialRequestForm" [custom]="df" (customChange)="specialRequestChange($event)">
                            </app-custom-fields>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-12 d-flex mb-3 align-items-center add-info-bx" *ngIf="customFields.length!=0">
                <h4 class="mr-2 h-width">Additional Information</h4>
                <div *ngFor="let df of customFields" class="add-info-field-each">
                    <app-custom-fields class="additionalFields" [form]="dynamicForm" [custom]="df">
                    </app-custom-fields>
                </div>
                <br>
                
            </div>
            <div class="col-lg-12 error-message" *ngIf="customFields.length!=0 && additionalFieldRequiredMsg">
                Please fill all the additional information
            </div>
            <div class="col-lg-12 title-area mt-3 mb-3 px-0" *ngIf="eventForm.get('PaymentProcessParams').get('NetAmountToPay').value != ''">
                <!-- <h2 class="d-flex content-right"><span class="amnt-title">Total Amount: $500</span></h2> -->
                <input type="hidden" name="TotalAmount" class="form-control m-input" placeholder="" value="" autocomplete="off">
                <h2 class="amnt-title">Total Amount: {{eventForm.get('PaymentProcessParams').get('NetAmountToPay').value}} {{Currency}}</h2>
            </div>
            <div class="col-lg-12 choose-radio-wrp my-4 " formGroupName="PaymentProcessParams" [hidden]="zeroAmount == 1">
                <div class="d-flex bx-grey align-items-center mob-mb">
                    <span class="radio-label  mob-mb">Payment Method</span>
                    <div class="form-check radio-btn-wrp" *ngFor="let payment of PaymentMethods; let i= index;">
                        <input type="radio" class="filled-in payment_method" id="{{payment.UniqueName}}" formControlName="PaymentMethodId" value="{{payment.PaymentMethodId}}" (change)="onPaymentMethodChange($event.target.id)" required [checked]="false">
                        <!-- <input class="form-check-input" type="radio" name="payment" id="credit" value="option1" checked> -->
                        <label class="form-check-label" for="{{payment.UniqueName}}">
                                {{payment.text}}
                            </label>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-12 d-flex add-card-wrp btm-border align-items-center pb-3 px-0">
                <h6>Paying with Card</h6>
                <div class="btn-add text-right">
                    <button type="button" class="btn-common">+Add Card</button>
                            </div>
                        </div>
                        <div class="col-lg-12 d-flex card-bx mt-4 mb-4">
                            <img src="./assets/member/images/icon-visa-card.png " alt=" " class="card-img-left">
                            <p><strong>XXXX 8394</strong><span>Visa</span></p>
                            <img src="./assets/member/images/icon-tick.png " alt=" " class="card-img-right">
                        </div>
                        <div class="col-lg-12 pl-0">
                            <button type="submit" class="btn-proceed">Proceed</button>
                        </div> -->



            <div id="payment" class="col-lg-12" [hidden]="selectedPaymentMethod == ''">
                <div class="row m-0 mob-no-margin payment-sec-wrp" formGroupName="PaymentProcessParams">
                    <div class="col-lg-12 title-area mt-3 mb-3 px-0" [hidden]="selectedPaymentMethod != 'ach'">
                        <h4>Payment Information</h4>
                        <div class="btn-addd text-right">
                            <button type="button" class="btn-common" *ngIf="IsSavedAchPresent" (click)="AddNewAchAccount()">+New Account</button>
                            <button type="button" class="btn-common" *ngIf="!IsSavedAchPresent && savedAchCardButtonShow" (click)="listsavedAchAccount()">Use Existing Account</button>
                            <hr>
                        </div>
                    </div>
                    <div class="col-lg-12 title-area mt-3  mb-3 px-0" [hidden]="selectedPaymentMethod != 'credit_or_debt'">
                        <h4>Payment Information</h4>
                        <div class="btn-addd text-right">
                            <button type="button" class="btn-common" *ngIf="showSavedCreditcardOption && savedCreditCardButtonShow" (click)="AddNewCreditCard()">+New Card</button>
                            <button type="button" class="btn-common" *ngIf="!showSavedCreditcardOption" (click)="listreditCard()">Use Existing Card</button>
                            <hr>
                        </div>
                    </div>
                    <!-- calling new component for saved cards gor credit -->
                    <div class="col-lg-10" *ngIf="selectedPaymentMethod == 'credit_or_debt' && !shownewCreditcardOption">
                        <app-savedcardlist [paymentMethodType]="selectedPaymentMethod" [brainTreeToken]="" [paymentSettingId]="paymentSettingId" 
                        [memberId]="selectedMemberId" [eventForm]="eventForm" [dynamicForm]="dynamicForm" [specialRequestForm]="specialRequestForm"
                        [InitialMethodId]="InitialMethodId" [NetAmonutToPay]="NetAmonutToPay" [type]="'event'" [brainTreeToken]="brainTreeToken"></app-savedcardlist>
                    </div>
                    <div [hidden]="selectedPaymentMethod != 'ach'" class="w-100 ach" formGroupName="PaymentFields">
                        <!-- showing saved card entires -->
                        <!-- style this entires -->
                        <!-- <ul class="nav nav-tabs  m-tabs-line m-tabs-line--success" role="tablist">
                            <li class="nav-item m-tabs__item">
                                <a class="nav-link m-tabs__link " [class.active]="IsSavedAchPresent" href="javascript:void(0);" role="tab" (click)="savedAccount()"><i class="la la-cloud-upload"></i> Saved accounts</a>
                            </li>
                            <li class="nav-item m-tabs__item">
                                <a class="nav-link m-tabs__link" [class.active]="!IsSavedAchPresent" href="javascript:void(0);" role="tab" (click)="AddNewCard()"><i class="la la-puzzle-piece"></i> Add new accounts</a>
                            </li>
                            <span class="col-sm-12 m-form__help">
                                <span class="error-message alert danger" *ngIf="( this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.dirty || this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.touched) && this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.invalid">
                                    Please select your account 
                                </span>
                            </span>
                        </ul> -->
                        <div class="row mob-no-margin mob-padding " *ngIf="IsSavedAchPresent">
                            <div class="col-lg-6 form-check radio-btn-wrp" *ngFor="let option of savedCards; let i=index">
                                <div class="bx-radio-btn-each">
                                    <input formControlName="ChooseCard" type="radio" class="form-check-input" value="{{option.PayMethodToken}}" id="{{option.PayMethodToken}}" (change)="onSelectedSavedcardChange($event.target.id)">
                                    <label for="{{option.PayMethodToken}}">
                                    <span class="d-block">
                                        {{option.AccountHolder}}
                                     </span>
                                    <span>
                                        {{option.MaskedAccountNumber}}
                                    </span>
                                </label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="btn-add text-right" >
                            <button type="button" class="btn-common" (click)="AddNewCard()">+Add Card</button>
                        </div> -->

                        <div class="col-lg-12 px-0 choose-radio-wrp" *ngIf="!IsSavedAchPresent || showNewCardOption">
                            <div class="row mob-no-margin ">
                                <div class="col-md-12 col-lg-12 d-flex mob-mb acnt-radio-type">
                                    <span class="radio-label">Account Type</span>
                                    <div class="form-check radio-btn-wrp" *ngFor="let accounttype of accounttypeList;let i = index;">
                                        <input type="radio" class="form-check-input" id="{{accounttype.value}}" formControlName="AccountType" value="{{accounttype.value}}">
                                        <label class="form-check-label" for="{{accounttype.value}}">
                                        {{accounttype.name}}
                                        </label>
                                    </div>

                                </div>
                                <span class="col-sm-12 m-form__help">
                                    <span class="error-message alert danger" *ngIf="( this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.dirty || this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.touched) && this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.invalid">
                                        Please select your account type
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-12 px-0 mt-3" *ngIf="!IsSavedAchPresent || showNewCardOption">
                            <div class="row">
                                <div class="col-md-4 col-lg-4 pr-0 mob-pr">
                                    <div class="form-group focus-label">
                                        <label class="form-label-of" for="ach_account_number">Account Number</label>
                                        <input formControlName="AccountNumber" type="text" name="ach_account_number" id="ach_account_number" class=" required" data-rule-number="true" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" />
                                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountNumber">
                                            <span class="error-message danger alert"  *ngIf="(eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.hasError(validation.type) && (eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.dirty || eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.touched)) && eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.invalid">
                                               {{ validation.message }}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-4">
                                    <div class="form-group focus-label">
                                        <label class="form-label-of" for="ach_routing_number">Routing Number</label>
                                        <input formControlName="RoutingNumber" type="text" class="required" autocomplete="off" name="ach_routing_number" id="ach_routing_number" data-rule-number="true" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.RoutingNumber">
                                            <span class="error-message danger alert"  *ngIf="(eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.hasError(validation.type) && (eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.dirty || eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.touched)) && eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.invalid">
                                               {{ validation.message }}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-4 pl-0 mob-pl">
                                    <div class="form-group focus-label">
                                        <label class="form-label-of" for="accName">Name on Account</label>
                                        <input formControlName="AccountHolder" type="text" autocomplete="off" name="ach_person_name" id="ach_person_name" class="required" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" maxlength="100">
                                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountHolder">
                                                <span class="error-message danger alert"  *ngIf="(eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.hasError(validation.type) && (eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.dirty || eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.touched)) && eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.invalid">
                                                   {{ validation.message }}
                                                </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="checkbox-wrp ml-3" *ngIf="isSavedCard">
                                    <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" id="save-id">
                                    <label for="save-id">Save Account for Future</label>
                                </div>
                            </div>
                        </div>
                        <!-- <label class="row col-form-label m-checkbox--inline" *ngIf="isSavedCard">
                            <label class="m-checkbox m-checkbox--solid m-checkbox--brand">
                              <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" >
                              Save Account for Future
                              <span></span>
                            </label>
                        </label> -->
                       
                    </div>
                    <div class="col-lg-12" *ngIf="shownewCreditcardOption && selectedPaymentMethod == 'credit_or_debt'" formGroupName="PaymentFields">
                        <ngx-braintree [getClientToken]="getClientToken.bind(this)" [showCardholderName]="false" [chargeAmount]="NetAmonutToPay" [createPurchase]="createPurchase.bind(this)" (paymentStatus)="onPaymentStatus($event)" (payButtonStatus)="payButtonStatus($event)"
                            *ngIf="brainTreeReInit">
                            <div class="ngxButtons">

                                <!-- <label class="row col-form-label m-checkbox--inline" *ngIf="isSavedCard">
                                  <label class="m-checkbox m-checkbox--solid m-checkbox--brand">
                                    <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" >
                                    Save Account for Future
                                    <span></span>
                                  </label>
                                </label> -->
                                <div class="checkbox-wrp mt-3" *ngIf="isSavedCard">
                                    <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" id="save-idd">
                                    <label for="save-idd">Save Account for Future</label>
                                </div>
                                <button class="btn-common btn-donate" ngxPay>Register</button>
                            </div>
                        </ngx-braintree>
                    </div>

                    <div [hidden]="selectedPaymentMethod != 'manual_payment'" class="p-0 ach col-xs-12">
                        <div class="row mob-no-margin mob-padding">

                            <div class="col-md-12 col-lg-12">
                                <span class="total-amnt-bold" >BankAccount Details : </span>
                                <br>
                                <span  style="white-space: pre-line;">{{text}}</span>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="selectedPaymentMethod != 'credit_or_debt'" class="submit-btn clearfix" style="display:block;width:100%;text-align:center;">
                        <button type="submit" (click)="tokenizeUserDetails()" class="btn-common btn-donate">Register  <i *ngIf="isLoading" class="spinner-border text-primary"></i></button>
                        <!-- <input type="button" value="REGISTER" (click)="tokenizeUserDetails()" class="btn-common btn-donate" /> -->
                    </div>
                    <!-- <div class="footer-txt">
                        <p class="text-center short-desc-btm mt-5">Shalom World is an initiative of Shalom Media {{footerText.CountryName}} Inc, a registered {{footerText.CountryRegistrationInfo}} non-profit organization. Contributions to Shalom Media USA are tax-deductible to the extent permitted
                            by law. Shalom Media’s tax identification number is {{footerText.CountryTaxInfo}}. </p>
                    </div> -->
                </div>
            </div>
        </div>

    </form>
</div>