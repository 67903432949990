<div class="contact-alert" [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <!-- <div class="alert-top-bar">
        <div class="row align-item-center">
            <div class="col-7 col-md-7">
                <h5>Edit Billing Address</h5>
            </div>
            <div class="col-5 col-md-5 text-right">
                <figure class="mb-0">
                    <img src="./assets/member/images/dashboard-logo.svg " alt="Shalom ">
                </figure>
                <button type="button" class="close"  (click)="close()">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
        </div>
    </div> -->
    <button type="button" class="close" (click)="close(true)">
        <span aria-hidden="true">
            &times;
        </span>
    </button>
    <div class="popup-inner">
        <div class="text-center pt-5 popup-title">
            <h2>Add Participant</h2>
            <!-- <p>Lorem Ipsum doler set</p> -->
        </div>
        <form [formGroup]="participantForm">
            <div class="contact-form" formArrayName="ParticipantDetail">
                <div class="row" [formGroupName]="i" *ngFor="let participant of participantForm.get('ParticipantDetail')['controls']; let i = index">
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <ng-select formControlName="StreamId" [clearable]="false" [searchable]="false" required [items]="streamList" autocomplete="off" placeholder="Stream" bindValue="Id" bindLabel="Name" (change)="straemSelected($event.Id)"></ng-select>
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.StreamId">
                                <span class="error-message danger alert" *ngIf="participant.get('StreamId')?.hasError(validation.type) && (participant.get('StreamId')?.dirty || participant.get('StreamId')?.touched) && participant.get('StreamId')?.invalid">
                                    {{ validation.message }}
                                </span>
                                    </span>
                                    <!-- <span class="m-form__help error-message danger alert" *ngIf="iserrorMsgforStream">
                                        {{errorMsgforStream}}
                                </span> -->
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <ng-select formControlName="SalutationId" id="salutationid" [clearable]="false" placeholder="Title" bindValue="Id" bindLabel="Name" [items]="SalutationList" (change)="setTitle()"></ng-select>
                        </div>
                    </div>
                   
                    <div class="col-md-6">
                        <div class="form-group focus-label" id="">
                            <label class="form-label-of" for="">First Name</label>
                            <input class="form-control" id="" type="text" formControlName="Fname" (input)="firstNameFetch($event.target.value,i)" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.Fname">
                                <span class="error-message danger alert" *ngIf="participant.get('Fname')?.hasError(validation.type) && (participant.get('Fname')?.dirty || participant.get('Fname')?.touched) && participant.get('Fname')?.invalid">
                                    {{ validation.message }}
                                </span>
                        </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label" id="">
                            <label class="form-label-of" for="">Last Name</label>
                            <input class="form-control" id="" type="text" formControlName="Lname"  (input)="lastNameFetch($event.target.value,i)" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.Lname">
                                <span class="error-message danger alert" *ngIf="participant.get('Lname')?.hasError(validation.type) && (participant.get('Lname')?.dirty || participant.get('Lname')?.touched) && participant.get('Lname')?.invalid">
                                    {{ validation.message }}
                                </span>
                                    </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <ng-select [items]="Genders" bindValue="Id" bindLabel="Name" placeholder="Gender" [searchable]="false" [clearable]="false" formControlName="Gender"></ng-select>
                        </div>
                    </div>
                    <div class="col-md-6" [hidden]="!isAgeRequired">
                        <div class="form-group focus-label">
                            <label class="form-label-of" for="">Age</label>
                            <input class="form-control" formControlName="Age" type="text" id="age" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" onPaste="return false" (keypress)="keyPressNumbers($event)" (change)="ageForStream($event.target.value)">
                            <span class="m-form__help error-message danger alert" *ngIf="minAgeValidation || maxAgeValidation">
                                Age should be in between {{minAge}} and {{maxAge}}
                        </span>
                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.Age">
                            <span class="error-message danger alert" *ngIf="participant.get('Age')?.hasError(validation.type) && (participant.get('Age')?.dirty || participant.get('Age')?.touched) && participant.get('Age')?.invalid">
                                {{ validation.message }}
                            </span>
                                </span>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2 mb-3 d-flex justify-content-center">
                        <button type="submit" class="btn-border btn-modal mr-3" (click)="close(true)">Cancel</button>
                        <button type="submit" class="btn-filled btn-modal" (click)="addParticipant()">+Add </button>
                    </div>

                </div>
            </div>
        </form>
    </div>
</div>