<div class="payment-wrp">
    <form [formGroup]="eventForm">
    <div class="bx-white"[hidden]="TabActiveIndex == 1 || TabActiveIndex == 2 || TabActiveIndex == 3">
        <div class="row">
            <div class="payment-left-img col-md-6 text-center">
                <figure>
                    <img src="./assets/member/images/img-eventreg-left.svg" alt="">
                </figure>
                <h3>Come, celebrate and live a life in Christ!</h3>
                <p>Have a friend who would enjoy this event? Invite them too!</p>
            </div>
            <div class="payment-right-form col-md-6">
                <div class="payment-first-section">
                    <div class="evnt-reg-first-sec" [hidden]="isAgeRequired">
                        <h4>{{eventType}}</h4>
                        <span class="mb-3">{{eventName}} | {{startMonth}}
                            {{startDay}}{{startYear == endYear? '': ','}} {{startYear == endYear ? '' : startYear}}-
                            {{endMonth == startMonth ? '' : endMonth}} {{endDay}}{{','}} {{endYear}}</span>
                            <!-- {{eventDate}} -->
                    
                        <div class="form-group focus-label">
                            <ng-select formControlName="CountryId" id="country" [(ngModel)]="countryId" [clearable]="false" placeholder="Country" required (change)="getAllPaymentMethods($event.CountryId, 1)" bindValue="CountryId" bindLabel="Name" [items]="CountryList" autocomplete="autocomplete_off_hack_xfr4!k"></ng-select>
                        </div>
                        <div class="mob-mb  single-radio form-group mb-3" *ngIf="!isAccomodationRequired">
                            <div class="form-check  pl-0" formGroupName="Event" *ngIf="EventDetails.IsAccomodationRequired == 1">
                                <input class="form-check-input"  formControlName="IsAccommodationRequired" type="checkbox" value="" id="acc1" (change)="changeAccomodation($event.target.checked)" disabled>
                                <label class="form-check-label" for="acc1">  Accommodation Included </label>
                            </div>
                            <div class="form-check  pl-0" formGroupName="Event" *ngIf="EventDetails.IsAccomodationRequired == 2">
                                <input class="form-check-input"  formControlName="IsAccommodationRequired" type="checkbox" value="" id="acc1" (change)="changeAccomodation($event.target.checked)">
                                <label class="form-check-label" for="acc1">Need Accommodation </label>
                            </div>
                        </div>
                    </div>
                    <div class="evnt-reg-first-sec" [hidden]="!isAgeRequired">
                        <h4>{{eventType}}</h4>
                        <!-- {{eventDate}} -->
                        <span class="mb-3">{{eventName}} | {{startMonth}}
                            {{startDay}}{{startYear == endYear? '': ','}} {{startYear == endYear ? '' : startYear}}-
                            {{endMonth == startMonth ? '' : endMonth}} {{endDay}}{{','}} {{endYear}} </span>
                       
                        <div class="form-group focus-label">
                            <ng-select formControlName="CountryId" id="country" [(ngModel)]="countryId" [clearable]="false" placeholder="Country" required (change)="getAllPaymentMethods($event.CountryId, 1)" bindValue="CountryId" bindLabel="Name" [items]="CountryList" autocomplete="autocomplete_off_hack_xfr4!k"></ng-select>
                        </div>
                        <div class="mob-mb  single-radio form-group mb-3" *ngIf="!isAccomodationRequired">
                            <div class="form-check  pl-0" formGroupName="Event" *ngIf="EventDetails.IsAccomodationRequired == 1">
                                <input class="form-check-input" formControlName="IsAccommodationRequired" value="" type="checkbox" id="acc2" (change)="changeAccomodation($event.target.checked)" disabled>
                                <label class="form-check-label" for="acc2"> Accommodation Included</label>
                            </div>
                            <div class="form-check  pl-0" formGroupName="Event" *ngIf="EventDetails.IsAccomodationRequired == 2">
                                <input class="form-check-input" formControlName="IsAccommodationRequired" value="" type="checkbox" id="acc2" (change)="changeAccomodation($event.target.checked)">
                                <label class="form-check-label" for="acc2">Need Accommodation</label>
                            </div>
                        </div>
                    </div>
                    <div class="add-view-participantbx mb-5">
                        <div class="head-area d-flex mb-3">
                            <h4>Participant Details</h4>
                            <button type="button" class="btn-border" (click)="addParticipant()" [disabled]="addPartButton">+ Add participant</button>
                        </div>
                        <span class="error-message danger alert" *ngIf="isParticipantsEmpty">Please add atleast one participant</span>
                        <div class="dotted-bx d-flex"  *ngIf="totalParticipants > 0">
                            <span>{{totalParticipants}} participant(s)</span>
                            <a href="javascript:void(0)" (click)="fecthParticipantlist()">View Details</a>
                        </div>
                        <span class="error-message danger alert" *ngIf="errorFound">{{errorMessage}}</span>
                    </div>
                    <div class="total-val d-flex mb-4">
                        <strong>Total Amount:</strong>
                        <!-- <span>$500 USD</span> -->
                        <span *ngIf="!eventForm.get('PaymentProcessParams').get('NetAmountToPay').value">0 {{Currency}}</span>
                        <span *ngIf="eventForm.get('PaymentProcessParams').get('NetAmountToPay').value">{{eventForm.get('PaymentProcessParams').get('NetAmountToPay').value}} {{Currency}}</span>
                    </div>
                    <button type="submit" class="btn-common mt-2" (click)="nextStep()">Continue</button>
                </div>
            </div>
        </div>
    </div>
    <div class="bx-white" *ngIf="TabActiveIndex == 1">
        <div class="row">
            <div class="payment-left-img col-md-6 text-center">
                <figure>
                    <img src="./assets/member/images/img-subscription-left.svg" alt="">
                </figure>
                <h3>Come, celebrate and live a life in Christ!</h3>
                <p>Have a friend who would enjoy this event? Invite them too!</p>
            </div>
            <div class="payment-right-form col-md-6">
                <div class="payment-second-section">
                    <div class="thank-msg-area spcl-req-wrp mb-3" *ngIf="EventDetails?.SpecialRequest != ''">
                        <h3>Special Request</h3>
                        <div class="" *ngFor="let df of customFieldsCheckbox">
                            <app-custom-fields [form]="specialRequestForm" [custom]="df" (customChange)="specialRequestChange($event)">
                            </app-custom-fields>
                        </div>
                    </div>
                    <div class="thank-msg-area row add-info-wrp" *ngIf="customFields.length!=0">
                        <h3>Additional Information</h3>
                        <div class="add-info-field-wrp">
                            <div class="add-info-each-field" *ngFor="let df of customFields">
                                <app-custom-fields class="additionalFields" [form]="dynamicForm" [custom]="df" (customChange)="customFieldChange($event)" (input)= "findCustomInvalidControls()" [selectedStartDate]="selectedCustomDate">
                                </app-custom-fields>
                            </div>
                        </div>
                    </div>
                    <span class="" *ngIf="additionalFieldRequiredMsg">
                        <span class="error-message">{{additionInfoMsg}}</span>
                    </span>
                    <div class="btn-wrp d-flex">
                        <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                        <button type="submit" class="btn-common mt-2 ml-3" (click)="nextStep()">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bx-white" [hidden]="TabActiveIndex == 1 || TabActiveIndex == 0 || TabActiveIndex == 3">
        <div class="row">
            <div class="payment-left-img col-md-6 text-center">
                <figure>
                    <img src="./assets/member/images/img-subscription-left.svg" alt="">
                </figure>
                <h3>Come, celebrate and live a life in Christ!</h3>
                <p>Have a friend who would enjoy this event? Invite them too!</p>
            </div>
            <div class="payment-right-form col-md-6">
                <div class="payment-second-section">
                    <div class="thank-msg-area text-center">
                        <h3>You are almost there...</h3>
                        <div class="donated-amnt-bx">
                            <!-- <span>$500</span> -->
                            <span *ngIf="eventForm.get('PaymentProcessParams').get('NetAmountToPay').value == 0">Free</span>
                            <span *ngIf="eventForm.get('PaymentProcessParams').get('NetAmountToPay').value > 0">{{eventForm.get('PaymentProcessParams').get('NetAmountToPay').value}} {{Currency}}</span>
                            <h6>Registration Amount</h6>
                        </div>
                    </div>
                    <!-- Billing address -->
                    <div class="delivery-addres mb-3" *ngIf="!hideBillingAddress && eventForm.get('PaymentProcessParams').get('NetAmountToPay').value > 0">
                        <h4>Billing Address</h4>
                        <div class="form-group focus-label" id="Add1">
                            <label for="add-msg" class="form-label-of">Street Address</label>
                            <textarea class="form-control" id="add-msg" [formControl]="eventForm.controls.BillingAddress.controls.Address1" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"></textarea>
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.Address1">
                                <span class="error-message danger alert"
                                    *ngIf="(eventForm.controls['BillingAddress'].get('Address1')?.hasError(validation.type) && (eventForm.controls['BillingAddress'].get('Address1')?.dirty || eventForm.controls['BillingAddress'].get('Address1')?.touched)) && eventForm.controls['BillingAddress'].get('Address1')?.invalid">
                                    {{ validation.message }}
                                </span>
                            </span>
                        </div>
                        <div class="two-col-wrp d-flex">
                            <div class="form-group focus-label mr-1" id="Add2">
                                <label for="add-code" class="form-label-of">Apt, Suite, Bldg</label>
                                <input class="form-control" id="add-code" [formControl]="eventForm.controls.BillingAddress.controls.Address2" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Address2">
                                    <span class="error-message danger alert"
                                        *ngIf="(eventForm.controls['BillingAddress'].get('Address2')?.hasError(validation.type) && (eventForm.controls['BillingAddress'].get('Address2')?.dirty || eventForm.controls['BillingAddress'].get('Address2')?.touched)) && eventForm.controls['BillingAddress'].get('Address2')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                            <div class="form-group focus-label ml-1" id="City">
                                <label for="city" class="form-label-of">City</label>
                                <input class="form-control" id="city" [formControl]="eventForm.controls.BillingAddress.controls.City" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.City">
                                    <span class="error-message danger alert"
                                        *ngIf="(eventForm.controls['BillingAddress'].get('City')?.hasError(validation.type) && (eventForm.controls['BillingAddress'].get('City')?.dirty || eventForm.controls['BillingAddress'].get('City')?.touched)) && eventForm.controls['BillingAddress'].get('City')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="two-col-wrp d-flex">
                            <div class="form-group focus-label mr-1">
                                <ng-select [formControl]="eventForm.controls.BillingAddress.controls.StateId" [items]="StatesList" id="BillingAddress-StateId" placeholder="State" bindValue="StateId" bindLabel="StateName" [(ngModel)]="state" [clearable]="false">
                                </ng-select>
                                <span class="error-message alert danger" *ngIf="(eventForm.controls['BillingAddress'].get('StateId')?.dirty || eventForm.controls['BillingAddress'].get('StateId')?.touched) && eventForm.controls['BillingAddress'].get('StateId')?.invalid">
                                    Please select your state
                                </span>
                            </div>
                            <div class="form-group focus-label ml-1" id="Code">
                                <label for="postal-code" class="form-label-of">Postal code</label>
                                <input class="form-control" id="postal-code" [formControl]="eventForm.controls.BillingAddress.controls.Zip" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="billingZipValidation($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Zip">
                                    <span class="error-message danger alert"
                                        *ngIf="(eventForm.controls['BillingAddress'].get('Zip')?.hasError(validation.type) && (eventForm.controls['BillingAddress'].get('Zip')?.dirty || eventForm.controls['BillingAddress'].get('Zip')?.touched)) && eventForm.controls['BillingAddress'].get('Zip')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    
                    </div>

<!-- **Billing address -->
                    <div class="payment-method-list" formGroupName="PaymentProcessParams" [hidden]="zeroAmount == 1">
                        <h4>Choose payment method:</h4>
                        <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing provident.</p> -->
                        <ul class="payment-radio-choose" >
                            <li class="d-flex active paymntList" *ngFor="let payment of PaymentMethods; let i= index;">
                                <!-- <input type="radio" id="test1" name="radio-group" checked>
                                <label for="test1">Credit/Debit</label> -->
                                <input type="radio" class="form-check-input" id="{{payment.UniqueName}}" formControlName="PaymentMethodId" value="{{payment.PaymentMethodId}}" (change)="onPaymentMethodChange($event.target.id)" required [checked]="false">
                                <label for="{{payment.UniqueName}}">
                                    <img *ngIf="isSpfMember" src="../../assets/images/icon-{{payment.UniqueName}}-purple.svg" alt="" style="width:50px; height: 20px;">
                                    <img  *ngIf="singleDonationExist == true && isSpfMember == false" src="../../assets/images/icon-{{payment.UniqueName}}-green.svg" alt="" style="width:50px; height: 20px;">
                                    <img *ngIf="singleDonationExist == false && isSpfMember == false" src="../../assets/images/icon-{{payment.UniqueName}}-brown.svg" alt="" style="width:50px; height: 20px;">
                                    {{payment.text}}</label>
                            </li>
                            <!-- <li class="d-flex">
                                <input type="radio" id="test2" name="radio-group">
                                <label for="test2">Bank Transfer</label>
                            </li>
                            <li class="d-flex">
                                <input type="radio" id="test3" name="radio-group">
                                <label for="test3">UPI Payment</label>
                            </li> -->
                        </ul>
                        <span class="error-message alert danger" *ngIf="paymentMethodError">
                            Please choose Payment Method
                        </span>
                    </div>
                    <div class="btn-wrp d-flex">
                        <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                        <button type="submit" *ngIf="zeroAmount == 0" class="btn-common mt-2 ml-3" (click)="nextStep()">Continue</button>
                        <button type="submit" *ngIf="zeroAmount == 1" class="btn-common mt-2 ml-3" (click)="tokenizeUserDetails()"><span class="span-loader-padding">Register</span>  <i *ngIf="isLoading" class="spinner-border"></i></button>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bx-white" [hidden]="TabActiveIndex == 1 || TabActiveIndex == 2 || TabActiveIndex == 0">
        <div class="row">
            <div class="payment-left-img col-md-6 text-center">
                <figure>
                    <img src="./assets/member/images/img-subscription-left.svg" alt="">
                </figure>
                <h3>Come, celebrate and live a life in Christ!</h3>
                <p>Have a friend who would enjoy this event? Invite them too!</p>
            </div>
            <div class="payment-right-form col-md-6" formGroupName="PaymentProcessParams" >
                <div class="payment-third-section" formGroupName="PaymentFields">
                    <div class="row payment-method-field" *ngIf="selectedPaymentMethod == 'credit_or_debt'">
                        <div class="col-7 payment-method-head-left">
                            <h3>Payment Method</h3>
                            <span>Credit/ Debit card</span>
                        </div>
                        <div class="col-5 text-right payment-method-head-right">
                            <h3>Amount</h3>
                            <span>{{eventForm.get('PaymentProcessParams').get('NetAmountToPay').value}} {{Currency}}</span>
                        </div>
                    </div>
                    <i *ngIf="loaderCreditsaved" class="spinner-border common-loader"></i>

                    <div class="" *ngIf="selectedPaymentMethod == 'credit_or_debt' && shownewCreditcardOption">
                        <h6 class="text-center"  style="text-align: center;">Saved Cards </h6>
                        <span class="col-sm-12 m-form__help">
                            <span class="error-message alert danger" *ngIf="( this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.dirty || this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.touched) && this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.invalid">
                                Please select your account
                            </span>
                        </span>
                        <ul class="payment-radio-choose">
                                <li class="d-flex active" *ngFor="let option of savedCreditCards; let i=index">
                                    <input type="radio"  name="ChooseCard" checked="" formControlName="ChooseCard" value="{{option.PaymentGatewayToken}}" id="{{option.PaymentGatewayToken}}" (change)="onSelectedSavedcardChange($event.target.id)">
                                    <label for="{{option.PaymentGatewayToken}}" class="d-flex" style="padding-left: 10px;"> 
                                        <img src="{{option.CreditCardImageUrl}}" alt="" style="width: 50px;height: 20px;">
                                        <span>xxx {{option.Last4}}</span>{{option.CardHolderName}}
                                    </label>
                                </li>
                           
                        </ul>
                        <div class="mob-mb  single-radio form-group mb-3 mt-3" *ngIf="selectedPaymentMethod == 'credit_or_debt' && shownewCreditcardOption">
                            <div class="form-check  pl-0">
                                <input class="form-check-input" [formControl]="eventForm.controls.AddNewCard"  type="checkbox" value="" id="acc3" (change)="isAddNewCard($event.target.checked)">
                                <label class="form-check-label" for="acc3"> Add New Card </label>
                            </div>
                        </div>
                        <i *ngIf="loaderCreditNew" class="spinner-border common-loader"></i>

                        <div class="btn-wrp d-flex" *ngIf="!addNewCard">
                            <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                            <button type="submit" class="btn-common mt-2 ml-3" (click)="tokenizeUserDetails('savedCreditcard')"><span class="span-loader-padding">Make Payment</span> <i *ngIf="isLoading && savedCardLoding" class="spinner-border"></i></button>
                        </div>
                    </div>
                   
                    <div class="bx-add-card" *ngIf="selectedPaymentMethod == 'credit_or_debt' && addNewCard">
                        <h6 class="text-center">Add a new card</h6>
                        <div class="p-0 credit_or_debt clearfix col-xs-12">
                            <div class="row" style="margin-top: -15px;">
                                <div class="field-container col-md-12 col-lg-12">
                                    <!-- Begin hosted fields section -->
                                    <label class="hosted-field--label" for="card-number">
                                    </label>
                                    <div class="hosted-field" id="card-number"></div>
                                    <span class="m-form__help" *ngIf="noFieldsCardNumber == 1">
                                        <span class="error-message danger alert">
                                            Please enter a valid card number
                                        </span>
                                    </span>
                                    <!-- End hosted fields section -->
                                </div>
                                <!-- <div class="textfield--float-label">
                            <input type="text" class="account_holder" placeholder="Account Holder">
                        </div> -->
                        </div>
                        <div class="row" style="margin-top: -10px;">
                                <div class="field-container col-md-6 col-lg-6" style="padding-right: 5px;"> 
                                    <!-- Begin hosted fields section -->
                                    <label class="hosted-field--label" for="expiration-date">
                                        <!-- Expiration Date -->
                                    </label>
                                    <div class="hosted-field" id="expiration-date"></div>
                                    <span class="m-form__help" *ngIf="noFieldsDate == 1">
                                        <span class="error-message danger alert">
                                            Please enter a valid card expiration date
                                        </span>
                                    </span>
                                    <!-- <div id="expiration-date" class="hosted-field"></div> -->
                                    <!-- End hosted fields section -->
                                </div>
                                <div class="field-container col-md-6 col-lg-6" style="padding-left: 5px;">
                                    <!-- Begin hosted fields section -->
                                    <label class="hosted-field--label" for="cvv">
                                        <!-- CVV -->
                                    </label>
                                    <!-- End hosted fields section -->
                                    <div class="hosted-field" id="cvv"></div>
                                    <span class="m-form__help" *ngIf="noFieldsCvv == 1">
                                        <span class="error-message danger alert">
                                            Please enter a valid card security code (CVV)
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div style="color:#ff5a5a;  padding: 0px;" *ngIf="brainTreeError != ''" class="alert danger">
                                <p>{{brainTreeError}}</p>
                            </div>
                            <div style="color:#ff5a5a;  padding: 0px;" *ngIf="paymentGatewayErrorMsg !=null" class="alert danger">
                                <p>{{paymentGatewayErrorMsg}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="btn-wrp d-flex" *ngIf="selectedPaymentMethod == 'credit_or_debt' && addNewCard">
                        <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                        <button type="submit" class="btn-common mt-2 ml-3"  (click)="tokenizeUserDetails()"><span class="span-loader-padding">Make Payment</span>  <i *ngIf="isLoading" class="spinner-border"></i></button>
                    </div>
                     <!-- ACH -->
                     <div class="row payment-method-field" *ngIf="selectedPaymentMethod == 'ach'">
                        <div class="col-7 payment-method-head-left">
                            <h3>Payment Method</h3>
                            <span>ACH</span>
                        </div>
                        <div class="col-5 text-right payment-method-head-right">
                            <h3>Amount</h3>
                            <!-- <span>{{totalPrice}} {{Currency}}</span> -->
                            <span>{{eventForm.get('PaymentProcessParams').get('NetAmountToPay').value}} {{Currency}}</span>
                        </div>
                    </div>
                     <!-- ach saved card list -->
                     <i *ngIf="loaderAchSaved" class="spinner-border common-loader"></i>

                     <div class="saved-card-wrp mt-2"  style="text-align: center;"  *ngIf="selectedPaymentMethod == 'ach' && IsSavedAchPresent">
                        <h6>Saved Accounts</h6>
                        <span class="col-sm-12 m-form__help">
                            <span class="error-message alert danger" *ngIf="( this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.dirty || this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.touched) && this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.invalid">
                                Please select your account
                            </span>
                        </span>
                        <ul class="payment-radio-choose">
                            <li class="d-flex active" *ngFor="let option of savedCards; let i=index">
                                <input type="radio"  name="ChooseCard" checked="" formControlName="ChooseCard" value="{{option.PayMethodToken}}" id="{{option.PayMethodToken}}" (change)="onSelectedSavedcardChange($event.target.id)">
                                <label for="{{option.PayMethodToken}}" class="d-flex"> 
                                    <img src="./assets/member/images/ach.png" alt="" style="width:50px; height: 20px;">
                                    <span>{{option.MaskedAccountNumber}}</span>{{option.AccountHolder}}
                                </label>
                            </li>
                            <!-- <li class="d-flex active">
                                <input type="radio" id="card2" name="radio-group">
                                <label for="card2" class="d-flex"> 
                                    <img src="./assets/member/images/icon-card.svg" alt="">
                                    <span>xxx 1234</span>Credit/Debit
                                </label>
                            </li>
                            <li class="d-flex active">
                                <input type="radio" id="card3" name="radio-group">
                                <label for="card3" class="d-flex"> 
                                    <img src="./assets/member/images/icon-card.svg" alt="">
                                    <span>xxx 1234</span>Credit/Debit
                                </label>
                            </li>
                            <li class="d-flex active">
                                <input type="radio" id="card4" name="radio-group" checked="">
                                <label for="card4" class="d-flex"> 
                                    <img src="./assets/member/images/icon-card.svg" alt="">
                                    <span>xxx 1234</span>Credit/Debit
                                </label>
                            </li>
                            <li class="d-flex active">
                                <input type="radio" id="card5" name="radio-group">
                                <label for="card5" class="d-flex"> 
                                    <img src="./assets/member/images/icon-card.svg" alt="">
                                    <span>xxx 1234</span>Credit/Debit
                                </label>
                            </li>
                            <li class="d-flex active">
                                <input type="radio" id="card6" name="radio-group">
                                <label for="card6" class="d-flex"> 
                                    <img src="./assets/member/images/icon-card.svg" alt="">
                                    <span>xxx 1234</span>Credit/Debit
                                </label>
                            </li> -->
                        </ul>
                        <div class="mob-mb  single-radio form-group mb-3 mt-3" *ngIf="selectedPaymentMethod == 'ach' && IsSavedAchPresent">
                            <div class="form-check  pl-0">
                                <input class="form-check-input" [formControl]="eventForm.controls.AddNewCard"  type="checkbox" value="" id="acc4" (change)="isAddNewCard($event.target.checked)">
                                <label class="form-check-label" for="acc4"> Add New Card </label>
                            </div>
                        </div>
                        <div class="btn-wrp d-flex" *ngIf="!addNewCard">
                            <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                            <button type="submit" class="btn-common mt-2 ml-3" (click)="tokenizeUserDetails('savedcard')"><span class="span-loader-padding">Make Payment</span> <i *ngIf="isLoading && savedCardLoding" class="spinner-border"></i></button>
                        </div>
                    </div>
                     <!-- ach new card -->
                     <div class="bx-add-card mt-4" *ngIf="selectedPaymentMethod == 'ach' && addNewCard">
                        <h6 class="text-center">Add a new account</h6>
                        <div class="radio-choose-type mt-3 mb-2 d-flex align-item-center">
                            <h5 class="mb-0 mr-3 mt-1">Account Type</h5>
                            <ul class="d-flex">
                                <li  *ngFor="let accounttype of accounttypeList;let i = index;">
                                    <input type="radio" class="form-check-input" id="{{accounttype.name}}" formControlName="AccountType" value="{{accounttype.value}}">
                                    <label class="form-check-label" for="{{accounttype.name}}">
                                    {{accounttype.name}}
                                    </label>
                                </li>
                                <!-- <div class="form-check radio-btn-wrp" *ngFor="let accounttype of accounttypeList;let i = index;">
                                    <input type="radio" class="form-check-input" id="{{accounttype.value}}" formControlName="AccountType" value="{{accounttype.value}}">
                                    <label class="form-check-label" for="{{accounttype.value}}">
                                    {{accounttype.name}}
                                    </label>
                                </div> -->
                                <!-- <li>
                                    <input type="radio" id="type2" name="radio-group">
                                    <label for="type2">Savings</label>
                                </li> -->
                            </ul>
                            <span class="col-sm-12 m-form__help">
                                <span class="error-message alert danger" *ngIf="( this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.dirty || this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.touched) && this.eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.invalid">
                                    Please select your account type
                                </span>
                            </span>
                        </div>
                        <div class="two-col-row d-flex">
                            <div class="form-group focus-label mr-1" id="account_number">
                                <label for="ac-num" class="form-label-of" >Account Number</label>
                                <input id="ac-num" class="form-control" formControlName="AccountNumber" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" data-rule-number="true">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountNumber">
                                    <span class="error-message danger alert"  *ngIf="(eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.hasError(validation.type) && (eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.dirty || eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.touched)) && eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                            <div class=" form-group focus-label ml-1" id="ach_routing_number">
                                <label for="route-num" class="form-label-of" >Routing Number</label>
                                <input id="route-num" class="form-control" formControlName="RoutingNumber" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" data-rule-number="true">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.RoutingNumber">
                                    <span class="error-message danger alert"  *ngIf="(eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.hasError(validation.type) && (eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.dirty || eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.touched)) && eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                            <div class="form-group focus-label mr-1">
                                <label for="acnt-name" class="form-label-of">Name on Account</label>
                                <input id="acnt-name"  formControlName="AccountHolder" class="form-control" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" maxlength="100">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountHolder">
                                    <span class="error-message danger alert"  *ngIf="(eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.hasError(validation.type) && (eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.dirty || eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.touched)) && eventForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.invalid">
                                        {{ validation.message }}
                                    </span>
                            </span>
                            </div>
                            <!-- <div class="form-group checkbx-handle ml-1">
                                <input type="checkbox" id="html" formControlName="SaveCardForFuture">
                                <label for="html">Save Account for Future</label>
                            </div> -->
                        </div>
                    </div>
                    <div class="btn-wrp d-flex" *ngIf="selectedPaymentMethod == 'ach' && addNewCard">
                        <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                        <button type="submit" class="btn-common mt-2 ml-3"  (click)="tokenizeUserDetails()">Make Payment <i *ngIf="isLoading" class="spinner-border"></i></button>
                    </div>
                     <!-- manual payment -->
                     <div class="row payment-method-field" *ngIf="selectedPaymentMethod == 'manual_payment'">
                        <div class="col-7 payment-method-head-left">
                            <h3>Payment Method</h3>
                            <span>{{selectedPaymentname}}</span>
                        </div>
                        <div class="col-5 text-right payment-method-head-right">
                            <h3>Amount</h3>
                            <!-- <span>{{totalPrice}} {{Currency}}</span> -->
                            <span>{{eventForm.get('PaymentProcessParams').get('NetAmountToPay').value}} {{Currency}}</span>

                        </div>
                    </div>
                    <div class="bx-add-card" *ngIf="selectedPaymentMethod == 'manual_payment'">
                        <div class="col-md-12 col-lg-12">
                            <span class="total-amnt-bold" >BankAccount Details : </span>
                            <br>
                            <span  style="white-space: pre-line;">{{text}}</span>
                        </div>
                    </div>

                    <div class="btn-wrp d-flex" *ngIf="selectedPaymentMethod == 'manual_payment'">
                        <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                        <button type="submit" class="btn-common mt-2 ml-3"  (click)="tokenizeUserDetails()"><span class="span-loader-padding">Make Payment</span>  <i *ngIf="isLoading" class="spinner-border"></i></button>
                    </div>


            </div>
        </div>
    </div>
   </div> 


    <!-- design html code for payment section -->
    <!-- <div class="bx-white">
        <div class="row">
            <div class="payment-left-img col-md-6 text-center">
                <figure>
                    <img src="./assets/member/images/img-subscription-left.svg" alt="">
                </figure>
                <h3>Come, celebrate and live a life in Christ!</h3>
                <p>Have a friend who would enjoy this event? Invite them too!</p>
            </div>
            <div class="payment-right-form col-md-6">
                <div class="payment-third-section">
                    <div class="row payment-method-field">
                        <div class="col-7 payment-method-head-left">
                            <h3>Payment method</h3>
                            <span>ACH</span>
                        </div>
                        <div class="col-5 text-right payment-method-head-right">
                            <h3>Amount</h3>
                            <span>$500</span>
                        </div>
                    </div>
                    <div class="saved-card-wrp mt-2">
                        <h6>Saved Cards</h6>
                        <ul class="payment-radio-choose">
                            <li class="d-flex active">
                                <input type="radio" id="card1" name="radio-group" checked="">
                                <label for="card1" class="d-flex"> 
                                    <img src="./assets/member/images/icon-card.svg" alt="">
                                    <span>xxx 1234</span>Credit/Debit
                                </label>
                            </li>
                            <li class="d-flex active">
                                <input type="radio" id="card2" name="radio-group">
                                <label for="card2" class="d-flex"> 
                                    <img src="./assets/member/images/icon-card.svg" alt="">
                                    <span>xxx 1234</span>Credit/Debit
                                </label>
                            </li>
                            <li class="d-flex active">
                                <input type="radio" id="card3" name="radio-group">
                                <label for="card3" class="d-flex"> 
                                    <img src="./assets/member/images/icon-card.svg" alt="">
                                    <span>xxx 1234</span>Credit/Debit
                                </label>
                            </li>
                            <li class="d-flex active">
                                <input type="radio" id="card4" name="radio-group" checked="">
                                <label for="card4" class="d-flex"> 
                                    <img src="./assets/member/images/icon-card.svg" alt="">
                                    <span>xxx 1234</span>Credit/Debit
                                </label>
                            </li>
                            <li class="d-flex active">
                                <input type="radio" id="card5" name="radio-group">
                                <label for="card5" class="d-flex"> 
                                    <img src="./assets/member/images/icon-card.svg" alt="">
                                    <span>xxx 1234</span>Credit/Debit
                                </label>
                            </li>
                            <li class="d-flex active">
                                <input type="radio" id="card6" name="radio-group">
                                <label for="card6" class="d-flex"> 
                                    <img src="./assets/member/images/icon-card.svg" alt="">
                                    <span>xxx 1234</span>Credit/Debit
                                </label>
                            </li>
                        </ul>
                        <div class="btn-wrp d-flex">
                            <button type="submit" class="btn-border mt-2 mr-3" (click)="prevStep()">Back</button>
                            <button type="submit" class="btn-common mt-2 ml-3">Make Payment</button>
                        </div>
                    </div>
                    <div class="bx-add-card mt-4">
                        <h6 class="text-center">Add a new card</h6>
                        <div class="radio-choose-type mt-3 mb-2 d-flex align-item-center">
                            <h5 class="mb-0 mr-3 mt-1">Account Type</h5>
                            <ul class="d-flex">
                                <li>
                                    <input type="radio" id="type1" name="radio-group" checked>
                                    <label for="type1">Checkings</label>
                                </li>
                                <li>
                                    <input type="radio" id="type2" name="radio-group">
                                    <label for="type2">Savings</label>
                                </li>
                            </ul>
                        </div>

                        <div class="two-col-row d-flex">
                            <div class="form-group focus-label mr-1">
                                <label for="ac-num" class="form-label-of">Account Number</label>
                                <input id="ac-num" class="form-control" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                            <div class=" form-group focus-label ml-1">
                                <label for="route-num" class="form-label-of">Routing Number</label>
                                <input id="route-num" class="form-control" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                            <div class="form-group focus-label mr-1">
                                <label for="acnt-name" class="form-label-of">Name on Account</label>
                                <input id="acnt-name" class="form-control" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            </div>
                            <div class="form-group checkbx-handle ml-1">
                                <input type="checkbox" id="html">
                                <label for="html">Save Account for Future</label>
                            </div>
                        </div>
                        <button type="submit" class="btn-common">Make Payment</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bx-white">
        <div class="row">
            <div class="payment-left-img col-md-6 text-center">
                <figure>
                    <img src="./assets/member/images/img-subscription-left.svg" alt="">
                </figure>
                <h3>Come, celebrate and live a life in Christ!</h3>
                <p>Have a friend who would enjoy this event? Invite them too!</p>
            </div>
            <div class="payment-right-form col-md-6">
                <div class="payment-third-section">
                    <div class="row payment-method-field">
                        <div class="col-7 payment-method-head-left">
                            <h3>Payment method</h3>
                            <span>UPI</span>
                        </div>
                        <div class="col-5 text-right payment-method-head-right">
                            <h3>Amount</h3>
                            <span>$500</span>
                        </div>
                    </div>
                    <div class="bx-add-card">
                        <h6 class="text-center">Add a new card</h6>
                    </div>
                    <div class="acnt-detail-desc mb-3">
                        <p>
                            <label>Bank Account Details:</label>
                            <span>Lorem</span>
                        </p>
                        <p>
                            <label>Account Name:</label>
                            <span>Lorem</span>
                        </p>
                        <p>
                            <label>Account Number:</label>
                            <span>Lorem</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
</div> -->
