import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditShippingAddressComponent } from './edit-shipping-address.component';

import {  ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [EditShippingAddressComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule, FormsModule ,
    NgSelectModule
  ]
})
export class EditShippingAddressModule { }
