<div class="d-flex inner-page-wrp"  [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <div class="inner-page-content">
        <div class="top-strip d-flex btm-border type2 mb-3 family-top-strip">
            <h5 class="mb-0 width-1">Recent News</h5>
            <!-- <div class="d-flex f-top-strip-btn">
                <button type="submit" class="btn-add-member btn-border" *ngIf=" counter > 1" (click)="getNews(false)">Prev</button>
                <button type="submit" class="btn-add-member btn-border" *ngIf="counter < totalPages" (click)="getNews(true)">Next</button>
            </div> -->
        </div>
        <!-- <div class="bx-white"> -->
            <div class="row recent-news-list">
                <div class=" col-md-12 recent-news-each" *ngFor="let news of recentNews">
                    <div class="recent-news-inner d-flex">
                        <div class="recent-news-desc ">
                            <p class="recent-view-data"><span class="color-yellow">{{news.category[0].category}}</span>&nbsp;.&nbsp;{{news.modifieddate | date: 'dd MMM yyyy'}}&nbsp;.&nbsp;{{news.views}} Views</p>
                            <h5 (click)="gotoUrl('https://www.shalomworld.org/news/'+news.blogUrl)">{{news.blogTitle}}</h5>
                            <p class="recent-news-blog">{{news.blogDescription}}</p>
                        </div>
                        <figure class="text-center mb-0">
                            <img src="{{news.rightThumb}}" alt="Shalom ">
                        </figure>
                    </div>
                </div>
            </div>
            <button type="button" *ngIf="counter < totalPages" class="btn-load-more mt-4" (click)="loadMoreNews()"><span class="span-loader-padding">Load More</span> <i *ngIf="isLoading" class="spinner-border"></i></button>
        <!-- </div> -->
    </div>
    <div class="home-right pl-0 ">
        <div class="bx-white connect-bx">
            <h4 class="text-center mb-3 mt-3">Connect with Us</h4>
            <div class="connect-btn-wrp">
                <figure class="text-center mb-2" *ngIf="isSpfMember">
                    <img src="./assets/member/images/img-connect-purple.svg " alt="Shalom ">
                </figure>
                <figure class="text-center mb-2" *ngIf="singleDonationExist == true && isSpfMember == false">
                    <img src="./assets/member/images/img-connect-green.svg " alt="Shalom ">
                </figure>
                <figure class="text-center mb-2" *ngIf="singleDonationExist == false && isSpfMember == false">
                    <img src="./assets/member/images/img-connect-brown.svg " alt="Shalom ">
                </figure>
                <ul class="button-list">
                    <li class="d-flex">
                        <a (click)="modalOpen('prayer')">Prayer Request</a>
                    </li>
                    <li class="d-flex">
                        <a (click)="modalOpen('testimony')">Testimony</a>
                    </li>
                    <li class="d-flex">
                        <a (click)="modalOpen('contact')">Contact Us</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <div class="bx-white live-desc-bx">
            <button type="button" class="btn-live mb-2">Live Now</button>
            <div class="live-desc ">
                <h4>Excepteur sint occaecat cupidatat non</h4>
                <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
            </div>
        </div> -->
    </div>
</div>