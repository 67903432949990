import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router} from '@angular/router';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { SharedService } from '../../../../_services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsPopupComponent } from '../contact-us-popup/contact-us-popup.component';

@Component({
  selector: 'app-donationcauselist',
  templateUrl: './donationcauselist.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css','./donationcauselist.component.scss']
})
export class DonationcauselistComponent implements OnInit {
  memberId: any;
  routeParam: any;
  countryId;
  listOfCauses;
  isSpfMember:boolean;
  singleDonationExist:boolean = false;
  constructor(public activeRoute:ActivatedRoute,public _crud: CommonCrudService,public router : Router,public sharedService: SharedService,public modalService: NgbModal) { }

  ngOnInit(): void {
    this.routeParam = this.activeRoute.snapshot;
    this.memberId = this.routeParam.params['memberId'];
    this.countryId = localStorage.getItem('countryId');
    this.sharedService.fetchPathData('dashboard','causelist');
    this.causeList();
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
	  this.singleDonationExist = currentUser.singleDonationExist;
  }
  causeList() {
    let params = {
        url: "frontend-site/cause/list", params: { CountryId: this.countryId }
    }
    this._crud.getData(params).subscribe(res => {

        // console.log("cause list----", res);
        this.listOfCauses = res['data'];
    });
  }
  donate(id:any = null, name:any = null){
    // /console.log("this.routeParam = this.activeRoute.snapshot;", id)
    this.router.navigate([`memberDashboard/mydonation/`+ this.memberId +`/donation` ] , { queryParams: { causeId: id , causeName: name} });

  }
  modalOpen(type) {

    // console.log("inside modl open")
    const modalRef = this.modalService.open(ContactUsPopupComponent, {
        size: "lg"
    });
    modalRef.componentInstance.memberId = this.memberId;
    modalRef.componentInstance.type = type;
  }

}
