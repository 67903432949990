import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-foot-bar',
  templateUrl: './foot-bar.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css','./foot-bar.component.scss']
})
export class FootBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  scrollTop() {
    window.scroll(0, 0);
  }

}
