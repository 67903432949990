import { Component, OnInit } from '@angular/core';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { FormBuilder,Validators} from '@angular/forms';
import { Router ,ActivatedRoute} from '@angular/router';
import { SuccessPopupComponent } from '../../main/success-popup/success-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaErrorParameters } from "ng-recaptcha";
declare let $: any;

@Component({
  selector: 'app-member-forgotpassword',
  templateUrl: './member-forgotpassword.component.html',
  styleUrls: ['./member-forgotpassword.component.scss']
})
export class MemberForgotpasswordComponent implements OnInit {

  forgotPasswordForm;
  displayMsg;
  isLoading : boolean = false;
  step2Fields;
  notcaptcha: boolean;
  captcharesponse: string = '';
  isloading:boolean = false;
  buttonDisable:boolean = false;
  userEmail= '';
  ValidationMessage: any = {
    Email: [
        { type: "required", message: "Please enter your email address" },
        { type: "pattern", message: "Please enter a valid email" }
    ],
    captcha:[{type: "required", message: "captcha is required" }]
  }
  constructor(
    public formBuilder: FormBuilder,public _crud:CommonCrudService,
    public router:Router,public _route:ActivatedRoute,
    public modalService: NgbModal) 
    {
      this.formInit();
    }

  ngOnInit(): void {
    this.userEmail = this._route.snapshot.queryParams["email"];
    console.log("route param-----",this.userEmail)
    if(this.userEmail){
      this.forgotPasswordForm.controls['Email'].setValue(this.userEmail);
      console.log("route param-----",this.userEmail,document.getElementById('userName').classList)
    }
  }
  formInit(){
    this.forgotPasswordForm = this.formBuilder.group({
      Email:['',Validators.required],
      captcha:[null,Validators.required]
  });
  }
  animateLabel(event) {
    event.parentNode.classList.add('is-focused');
  }

  animateLabelRemove(e) {
    // console.log(e.value, "AHGKND");
    if (e.value == '') {
        e.parentNode.classList.remove('is-focused');
    }
  }
  Submit(){
    this.step2Fields = [   
      this.forgotPasswordForm.controls['Email'],
    ]
  const invalid = [];
  const controls = this.step2Fields;
  let i: any;
  for (i in controls) {
      controls[i].markAsDirty();
      if (controls[i].invalid) {
          invalid.push(i);
      }
  }
  if(this.captcharesponse == ''){
    this.notcaptcha = true
    // console.log("error msg")
  }else{
    if (invalid[0] == undefined) {
      this.buttonDisable = true;
      this.isLoading = true;
      this.notcaptcha = false;
      let params = {
        url: "frontend-site/member/forgot-password", 
        params: {Email : this.forgotPasswordForm.controls.Email.value}
      }
      this._crud.postData(params).subscribe(res=>{
        this.isLoading = false;
        // console.log("response------",res);
        if(res['status'] == 'error'){
          // else{
            if(res['msg'].includes('account is not active')){
              // this.errorShow = false;
              console.log("contain----",res['msg'])
              res['msg']='Your myShalom account has not been verified. A verification link was sent to your email. Please click the link to activate your myShalom account.';
              this.successPopup(res);
            }else{
              // this.errorShow = true;
              // this.displayMsg = 'Your email or password was incorrect.';
                 this.displayMsg = res['msg'];

            }  
          // }
          this.buttonDisable = false;
          this.isLoading = false;
          // this.displayMsg = res['msg'];
        }else{
          //call successpopup
          this.successPopup(res);

        }
        

      });
    }
  }
  
  }
  successPopup(data) {
    let response = {title: data['status'],message: data['msg']}
    const modalRef = this.modalService.open(SuccessPopupComponent, {size: "lg"});
    modalRef.componentInstance.user = response;
    modalRef.componentInstance.verificationLink = true; 
    modalRef.componentInstance.userEmail =  this.forgotPasswordForm.controls.Email.value;
       modalRef.result.then(
        result => {
          // console.log("result-----modal",result)
            if (result.result && data['status']== "success") {
                // /clearing all forms.
                this.router.navigateByUrl('/member-login')
            }else {
              this.buttonDisable = false;
              this.isloading = false;
            }
        });
  }
  public resolved(captchaResponse: string): void {
    this.captcharesponse = captchaResponse == null ? '':"yes"
    this.notcaptcha =  captchaResponse == null ? true: false
    // console.log("captcha value--resolved--",this.notcaptcha)
    if(!this.captcharesponse){
        this.isloading = false;
    }
    // console.log(`Resolved captcha with response: `,captchaResponse);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    // console.log("captcha value----",this.notcaptcha)
    this.notcaptcha = true
    this.isloading = false;
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

}
