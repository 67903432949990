<div class="sidebar-wrp">
    <div class="bx-white bx-one"  *ngIf="memberProfile">
        <figure class="text-center mt-5">
            <!-- <img src="./assets/member/images/img-prof.png" alt="Shalom"> -->
            <img src="{{memberProfile.PhotoUrl}}" alt="Shalom">
        </figure>
        <!-- <h6 class="text-center  mb-4">John Mccarthy</h6> -->
        <h6 class="text-center  mb-4">{{memberProfile.Salutation}} {{memberProfile.FirstName }} {{memberProfile.LastName}}</h6>
        <div class="border-bx d-flex mb-4">
            <div class="sidebar-btn-holder">
                <button type="button" class="btn-common mr-3" routerLink="mydonation/{{this.memberId}}/donation">DONATE NOW</button>
            </div>
            <div class="donation-desc-right">
                <span>Donation in {{memberProfile.CurrentYear}}</span>
                <!-- <h6>$ 80.657</h6> -->
                <h6>{{memberProfile.CurrencySymbol}} {{memberProfile.DonationAmount}}</h6>

            </div>
        </div>
        <ul class="list-each">
            <li class="d-flex btm-border">
                <img src="./assets/member/images/icon-location.svg" alt="Shalom">
                <!-- <address class="mb-0">24124 Roberts Forks East Lynn, GA 50647-5821</address> -->
                <address class="mb-0">{{memberProfile.MemberAddress}} </address>
            </li>
            <li class="d-flex  btm-border">
                <img src="./assets/member/images/icon-email.svg" alt="Shalom">
                <!-- <a href="mailto:johnmccarthy@gmail.com">johnmccarthy@gmail.com</a> -->
                <a href="mailto:johnmccarthy@gmail.com">{{memberProfile.Email}}</a>
            </li>
            <li class="d-flex  btm-border">
                <img src="./assets/member/images/icon-mobile.svg" alt="Shalom">
                <!-- <a href="tel:+61 673789833">+61 673789833</a> -->
                <a href="tel:+61 673789833">{{memberProfile.MobileNumber}}</a>
            </li>
            <li class="d-flex">
                <img src="./assets/member/images/icon-call.svg" alt="Shalom">
                <a href="tel:484-018-8373">484-018-8373</a>
            </li>
        </ul>
    </div>
    <div class="bx-white  bx-sec">
        <div class="top-strip d-flex">
            <figure class="mb-0 ">
                <img src="./assets/member/images/icon-office.svg" alt="Shalom">
            </figure>
            <h5 class="ml-3 mb-0 width-1">Office Details</h5>
            <figure class="mb-0 width-2 text-right">
                <img src="./assets/member/images/icon-dots.png" alt="Shalom">
            </figure>
        </div>
        <ul class="list-each">
            <li class="d-flex btm-border">
                <img src="./assets/member/images/icon-location.svg" alt="Shalom">
                <address>Shalom Media USA Inc. 211 E Wisconsin Rd Edinburg, TX 78539</address>
                <!-- <address>{{memberProfile.ShalomOfficeAddress}}</address> -->

            </li>
            <li class="d-flex btm-border">
                <img src="./assets/member/images/icon-email.svg" alt="Shalom">
                <a href="mailto:info@shalommedia.org">info@shalommedia.org</a>
            </li>
            <li class="d-flex">
                <img src="./assets/member/images/icon-call.svg" alt="Shalom">
                <a href="tel:(215) 366-3031">(215) 366-3031</a>
            </li>
        </ul>
    </div>
    <div class="bx-white bx-sec" *ngIf="keySection">
        <div class="top-strip d-flex">
            <img src="./assets/member/images/icon-video.svg" alt="Shalom">
            <h5 class="ml-3 mb-0">Key People Videos</h5>

        </div>
        <ul class="video-list">
            <li *ngFor="let videos of listOfVideos">
                <a class="btn-play-video " data-target="#demo-video " data-toggle="modal " (click)="videoModal(videos.VideoUrl)"><img src="{{videos.VideoImageUrl}}" alt=" "> <button _ngcontent-c4="" class="live-btn" type="button"></button></a>
                <p>{{videos.videoDescription}}</p>
            </li>
        </ul>
        <!-- <ul class="video-list">
            <li>
                <a class="btn-play-video" data-target="#demo-video" data-toggle="modal">
                    <img src="./assets/member/images/people1.png" alt="">
                </a>
                <h5 class="mt-2 mb-1">Carolyn Benjamin</h5>
                <h6>Managing Director</h6>
            </li>
            <li>
                <a class="btn-play-video" data-target="#demo-video" data-toggle="modal"><img src="./assets/member/images/people2.png" alt=""></a>
                <h5 class="mt-2 mb-1">David Anderson</h5>
                <h6>Visualiser</h6>
            </li>
            <li>
                <a class="btn-play-video" data-target="#demo-video" data-toggle="modal"><img src="./assets/member/images/people3.png" alt=""></a>
                <h5 class="mt-2 mb-1">Jonathan Hemmings</h5>
                <h6>Managing Director</h6>
            </li>
        </ul> -->
    </div>
</div>
<!-- <div id="demo-video" class="modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg">

        
<div class="modal-content">

    <button type="button" class="close" data-dismiss="modal">&times;</button>

    <div class="modal-body py-2 px-2 py-md-3 px-md-3">
        <iframe src="https://player.vimeo.com/video/474655934" style="border:none;" title="Iframe Example" width="100%" height="650" id="iframe_id"></iframe>
    </div>
</div>

</div>
</div> -->