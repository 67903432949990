import { Component, OnInit ,AfterViewInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from '../../../../_services/shared.service';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { ActivatedRoute ,Router} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
declare let $: any;

@Component({
  selector: 'app-my-subscription',
  templateUrl: './my-subscription.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css','./my-subscription.component.scss']
})
export class MySubscriptionComponent implements OnInit , AfterViewInit{

  displayedColumns: string[] = ['PrimaryCountryTime', 'PublicationName', 'SubscriberName', 'Duration', 'PendingIssues', 'PaymentMethod', 'SubscriptionPrice', 'PendingAmount', 'SubscriptionStatus'];
  dataSource = new MatTableDataSource();
  subscriptionData;
  routeParam;
  memberId;
  subType;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  response: { location: string; message: string; };
  ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
  }
  constructor(public sharedService: SharedService, public _crud: CommonCrudService,public activeRoute:ActivatedRoute,
    public router:Router,public modalService: NgbModal) { }

  ngOnInit(): void {
    this.routeParam = this.activeRoute.snapshot.params;
    console.log("params-----",this.routeParam);
    this.memberId = this.routeParam.memberId;
    this.sharedService.fetchPathData('dashboard','mysubscription');
    this.sharedService.changeSidebarView(true);
    let params = {
        url: "frontend-site/subscription/list", params: { MemberId: this.memberId }
    }
    this._crud.getData(params).subscribe(res => {
        this.subscriptionData = res['data'];
        // console.log("subscription data----",this.subscriptionData);
        // const ELEMENT_DATA: PeriodicElement[] = this.subscriptionData
        this.dataSource = new MatTableDataSource(this.subscriptionData);
        this.dataSource.paginator = this.paginator;
    });
  }
  selectedSubscription(subType,id=null,name=null){
    // console.log("renew sub-----",id,name)
    this.subType = subType;
    if(id){
      this.router.navigate(["/memberDashboard/mysubscription/"+this.memberId+"/subscription"], { queryParams: {subType: this.subType,renewId:id,renewName:name}});
    }else{
      this.router.navigate(["/memberDashboard/mysubscription/"+this.memberId+"/subscription"], { queryParams: {subType: this.subType}});
    }
    
  }
  cancelSub(){
    // routerLink="cancel-subscription"
    // console.log("renew sub-----")
    this.response = { location:"subscription-cancel",message: "Are you sure you want to cancel this subscription"}

    const modalRef = this.modalService.open(AlertPopupComponent, {
        size: "sm"
    });
    modalRef.componentInstance.data = this.response;

}
    
  
}
