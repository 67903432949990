import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css', './alert-popup.component.scss']
})
export class AlertPopupComponent implements OnInit {
  @Input() public data;
  routeParams: any;
  memberId: string;
  isSpfMember:boolean;
  singleDonationExist:boolean = false;
  constructor(public activeModal: NgbActiveModal, public router:Router,public activeRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.memberId = localStorage.getItem('memberId');
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.singleDonationExist = currentUser.singleDonationExist;
    // console.log("data",this.data)
  }
  close(){
    this.activeModal.close({ 'result': true ,'data':false});
  }
  confirm(){
    // if(this.data.location == "subscription-cancel"){
    //   this.router.navigate(["/memberDashboard/mysubscription/"+this.memberId+"/cancel-subscription"]);

    // }
    this.activeModal.close({ 'result': true ,'data':true});

  }
}
