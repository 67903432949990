import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
// import { UserIdleModule } from 'angular-user-idle';

const routes: Routes = [];


@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    //Library are waiting for a user's inactive for a 10 sec.
    // If inactive are detected then onTimerStart() for 5 sec
    //when time is up, do login again
    // UserIdleModule.forRoot({idle: 10, timeout: 5})
  ]
})
export class MainModule { }
