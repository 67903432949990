<div class="d-flex inner-page-wrp">
    <div class="inner-page-content">
        <!-- <div class="breadcrumb-wrp mb-1">
            <a routerLink="../../home/{{memberId}}">Dashboard</a>
        </div> -->
        <div class="top-strip d-flex btm-border type2 mb-3">
            <h5 class="mb-0">Donation Causes</h5>
            <!-- <a href="javascript:void(0)">View all</a> -->
        </div>
        <div class="bx-white">
            <!-- <div class="row donation-list pb-4">
                <div class="col-6 col-md-4 donation-each" *ngFor="let cause of listOfCauses">
                    <figure class="text-center mb-0">
                        <img src="{{cause.ImageUrl}} " alt="Shalom ">
                    </figure>
                    <div class="donation-desc ">
                        <div class="desc-content-sec" [ngClass]="{'causeClass':cause.CauseDescription.length > 145}">
                            <h5 class="heading-restrict">{{ cause.CauseName}}</h5>
                            <p class="para-restrict">{{ cause.CauseDescription}}</p>
                            <div class="hover-bx">
                                <h5>{{ cause.CauseName}}</h5>
                                <p>{{ cause.CauseDescription}}</p>
                            </div>
                        </div>

                        <button type="submit" class="btn-border" (click)="donate(cause.CauseId, cause.CauseName)">Donate</button>
                    </div>
                </div>
            </div> -->
            <!-- code from dashboard -->
            <ul class="donation-cause-list">
                <li class="d-flex" *ngFor="let cause of listOfCauses">
                    <figure>
                        <img src="{{cause.ImageUrl}}" alt="Shalom">
                    </figure>
                    <div class="donation-desc">
                        <div class="desc-content-sec" [ngClass]="{'causeClass':cause.CauseDescription.length > 180 || cause.CauseName.length > 60}">
                            <h5 class="heading-restrict">{{ cause.CauseName}}</h5>
                            <p class="para-restrict">{{ cause.CauseDescription}}</p>
                            <div class="hover-bx">
                                <h5>{{ cause.CauseName}}</h5>
                                <p class="mb-0">{{ cause.CauseDescription}}</p>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn-border" (click)="donate(cause.CauseId, cause.CauseName)">Donate</button>
                </li>
            </ul>
        </div>
    </div>
    <div class="home-right pl-0 ">
        <div class="bx-white connect-bx">
            <h4 class="text-center mb-3 mt-3">Connect with Us</h4>
            <div class="connect-btn-wrp">
                <figure class="text-center mb-2" *ngIf="isSpfMember">
                    <img src="./assets/member/images/img-connect-purple.svg " alt="Shalom ">
                </figure>
                <figure class="text-center mb-2" *ngIf="singleDonationExist == true && isSpfMember == false">
                    <img src="./assets/member/images/img-connect-green.svg " alt="Shalom ">
                </figure>
                <figure class="text-center mb-2" *ngIf="singleDonationExist == false && isSpfMember == false">
                    <img src="./assets/member/images/img-connect-brown.svg " alt="Shalom ">
                </figure>
                <ul class="button-list">
                    <li class="d-flex">
                        <a (click)="modalOpen('prayer')">Prayer Request</a>
                    </li>
                    <li class="d-flex">
                        <a (click)="modalOpen('testimony')">Testimony</a>
                    </li>
                    <li class="d-flex">
                        <a (click)="modalOpen('contact')">Contact Us</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <div class="bx-white live-desc-bx">
            <button type="button" class="btn-live mb-2">Live Now</button>
            <div class="live-desc ">
                <h4>Excepteur sint occaecat cupidatat non</h4>
                <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
            </div>
        </div> -->
    </div>
</div>