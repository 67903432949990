import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { AuthService } from '../../auth/services/auth.service';

@Component({
  selector: 'app-head-bar',
  templateUrl: './head-bar.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css','./head-bar.component.scss']
})
export class HeadBarComponent implements OnInit {
  memberId;
  profileData;

  constructor(public activeRoute:ActivatedRoute,public _crud: CommonCrudService,public _auth:AuthService) { }

  ngOnInit(): void {
    this.memberId = this.activeRoute.snapshot['_urlSegment'].segments[2].path;
    console.log("params--headbar---",this.memberId);
    let params = {
      url: "frontend-site/view-member", params: { MemberId: this.memberId }
  }
  this._crud.getData(params).subscribe(res => {
      this.profileData = res['data']['MemberProfile'];
  });
    
  }
  logOut(){
    this._auth.SignOut();
  }
}
