import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../../_services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css','./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  activeTab;
  tab;
  memberId;
  constructor(public sharedService:SharedService,public activeRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.sharedService.pathInfo.subscribe(path => {
      console.log("path top bar",path);
      this.activeTab = path;
    });
    this.sharedService.tabInfo.subscribe(path =>{
        console.log("tab top bar",path);
        this.tab = path;
    });
    this.memberId = localStorage.getItem('memberId'); 
  }

}
