<!-- <a class="nav-link " routerLink="../"><i class="la la-arrow-left"></i>Subscription List</a> -->
<div class="donation-payment bx-white">
    <form [formGroup]="subscriptionForm">
        <div class="row mob-no-margin">
            <div class="col-lg-12">
                <div class="row">
                    <div class="form-group col-md-6">
                        <ng-select [formControl]="subscriptionForm.controls.Member" [items]="memberList" placeholder="Member Name" bindValue="Id" bindLabel="Name" [(ngModel)]="selectedMemberId" (change)="onMemberChange($event)">
                        </ng-select>
                    </div>
                    <div class="form-group col-md-6 pl-0 ng-select-desc-bx">
                        <ng-select [items]="Subscriptions" formControlName="SubscriptionType" bindValue="Id" bindLabel="Name" id="Subscriptiontype" placeholder="Subscription" [searchable]="false" [clearable]="false" (change)="changeSubType($event)" [(ngModel)]="subscription">
                        </ng-select>
                        <span class="error-message alert danger" *ngIf="(subscriptionForm.get('SubscriptionType')?.dirty || subscriptionForm.get('SubscriptionType')?.touched) && subscriptionForm.get('SubscriptionType')?.invalid">
                            Please select subscription
                        </span>
                    </div>
                </div>

            </div>
            <div class="col-lg-12 d-flex mob-dblock" *ngIf="subType=='Renewal Subscription'">
                <!-- <div class="row"> -->
                <!-- <div class="col-md-4 col-lg-4"> -->
                <div class="form-group width1 mr-3 focus-label" formGroupName="Subscription" hidden="true">
                    <label class="form-label-of" for="ren-id">Renewal ID</label>
                    <input type="text" formControlName="RenewalSubscriptionId" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                    <span class="m-form__help">
                            <span class="error-message danger alert"  *ngIf="errorRenewal">
                              We are unable to locate your subscription for renewal.Please check the subscription details provided
                            </span>
                    </span>
                </div>
                <!-- </div> -->
                <!-- <div class="col-md-4 col-lg-4"> -->
                <div class="form-group width1 mr-2 focus-label" formGroupName="Subscription" hidden="true">
                    <label class="form-label-of" for="last-name">Last Name</label>
                    <input formControlName="LName" type="text" id="last-name" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                </div>
                <!-- </div> -->
                <!-- <div class="col-md-2 col-lg-2"></div> -->
                <!-- <div class="col-md-2 col-lg-2"> -->
                <div class="form-group width2 ml-2" hidden="true">
                    <button type="button" class="btn-common" (click)="getRenewalOptions()">Renew</button>
                </div>
                <!-- </div> -->
                <!-- </div> -->
            </div>
            <div class="col-lg-12">
                <div class="form-group focus-label" *ngIf="subType=='Bulk Subscription'">
                    <label class="form-label-of" for="Sponsorship">Sponsorship for</label>
                    <input formControlName="SponsorshipFor" type="text" id="Sponsorship" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.SponsorshipFor">
                        <span class="error-message danger alert" *ngIf="(subscriptionForm.get('SponsorshipFor')?.hasError(validation.type) && (subscriptionForm.get('SponsorshipFor')?.dirty || subscriptionForm.get('SponsorshipFor')?.touched)) && subscriptionForm.get('SponsorshipFor')?.invalid">
                            {{ validation.message }}
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="row w-100 align-items-center m-0">
                    <div class="form-group  col-md-3 px-0">
                        <h4>Publications</h4>
                    </div>
                    <div class="form-group col-md-9 px-0" *ngIf="! flag">
                        <ng-select [items]="Publications" bindValue="Id" formControlName="Publication" class="textClass" bindLabel="Name" placeholder="Publications" [(ngModel)]="publicationIdFromHome" (change)="selectPublication($event)" [multiple]="multiselect"  [searchable]="false" [clearable]="false" notFoundText="There are no publications available for subscription in your country"></ng-select>
                    </div>
                    <div class="form-group col-md-9 px-0" *ngIf="subType !='Renewal Subscription' && flag">
                        <ng-select [items]="Publications" bindValue="Id" id="Publications_enabled" formControlName="Publication" class="textClass" bindLabel="Name" placeholder="Publications" [searchable]="false" [clearable]="false" (change)="selectPublication($event)" [multiple]="multiselect" notFoundText="There are no publications available for subscription in your country"></ng-select>
                        <span class="col-sm-12 m-form__help">
                            <span class="error-message alert danger" *ngIf="validPub">
                                Please select publication 
                            </span>
                        </span>
                    </div>
                    <div class="form-group col-md-9 px-0" *ngIf="subType =='Renewal Subscription'">
                        <ng-select [items]="renewItems" bindValue="Id" formControlName="Publication" class="textClass" bindLabel="Name" placeholder="Publications" [(ngModel)]="renewalSubscription"></ng-select>
                    </div>

                </div>

            </div>
            <div class="col-lg-12 d-flex align-items-center mob-dblock">
                <div class="form-group width4 mr-3">
                    <ng-select placeholder="Subscribe for" [items]="allDurations" bindValue="Duration" formControlName="SubscribeFor" id="Subscribefor" bindLabel="DisplayText" (change)="changeDuration($event)" [(ngModel)]="subscribefor" [searchable]="false" [clearable]="false">
                    </ng-select>
                    <span class="error-message alert danger" [hidden]="errorShow" *ngIf="(subscriptionForm.get('SubscribeFor')?.dirty || subscriptionForm.get('SubscribeFor')?.touched) && subscriptionForm.get('SubscribeFor')?.invalid">
                        Please select subscription period
                    </span>
                    <span class="error-message alert danger" *ngIf="errorShow">{{errorMsg}}</span>
                </div>
                <div class="form-group width5 mr-3 focus-label" *ngIf="subType=='Bulk Subscription'">
                    <label class="form-label-of" for="copy">Number of copies</label>
                    <input type="text" id="noOfCopiesRequired" formControlName="NoOfCopiesRequired" name="NoOfCopiesRequired" required value="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="amountCalculate()">
                    <!-- <span class="m-form__help" *ngFor="let validation of ValidationMessage.NoOfCopiesRequired"> -->
                    <span class="error-message danger alert" *ngIf="copiesValidation">
                                   Number of copies should be in between 2 and 1 lakh
                                </span>
                </div>
                <span class="tot-amnt">Total: {{totalPrice}} {{Currency}}</span>
            </div>
            <div class="col-lg-12  btm-border pb-3 para-desc" *ngIf="subType=='Bulk Subscription'">
                <p>If you would like to order bulk copies for a different duration or multiple magazines, please contact our office.</p>
            </div>
            <!-- gift recipient details-- -->
            <div *ngIf="subType == 'Gift Subscription'" formGroupName="GiftTo" class="col-lg-12">
                <div class="col-lg-12 title-area mt-5  mb-4 px-0">
                    <h4>Gift Recipient Details</h4>
                </div>
                <div class="col-lg-12 px-0" *ngIf="this.giftMemberForm == true">
                    <div class="row">
                        <div class="form-group col-md-8">
                            <ng-select class="custom" [formControl]="subscriptionForm.controls.GiftTo.controls.GiftMember" [items]="memberList" placeholder="Select Member Name" bindValue="Id" bindLabel="Name" [clearable]="true" [(ngModel)]="selectedGiftMemberId" (change)="onGiftMemberChange($event)" >
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 px-0" *ngIf="this.newGiftMember == true">
                    <div class="row">
                        <div class="col-md-2 col-lg-2 form-group pr-0 mob-pr">
                            <!-- <div class="select-main_selectric custom-slct on-top-title"> -->
                            <ng-select formControlName="SalutationId" [items]="SalutationList" placeholder="Title" bindValue="SalutationId" bindLabel="SalutationName" [clearable]="false" (change)="membersearch()">
                            </ng-select>
                            <!-- <span class="error-message danger alert" *ngIf="(DonationForm.controls['SubscriberInfo'].controls['GiftTo'].get('SalutationId')?.dirty || DonationForm.controls['SubscriberInfo'].controls['GiftTo'].get('SalutationId')?.touched) && DonationForm.controls['SubscriberInfo'].controls['GiftTo'].get('SalutationId')?.invalid">
                                Salutation is required
                            </span> -->
                            <!-- </div> -->
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group focus-label w3">
                                <label class="form-label-of" for="firstName">First Name</label>
                                <input formControlName="FirstName" type="text" id="GiftTo-FirstName" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (change)="membersearch()" (input)="firstNameFetch($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.FirstName">
                                    <span class="error-message danger alert" *ngIf="(subscriptionForm.controls['GiftTo'].get('FirstName')?.hasError(validation.type) && (subscriptionForm.controls['GiftTo'].get('FirstName')?.dirty || subscriptionForm.controls['GiftTo'].get('FirstName')?.touched)) && subscriptionForm.controls['GiftTo'].get('FirstName')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4 pl-0 mob-pl">
                            <div class="form-group focus-label w3">
                                <label class="form-label-of" for="lastName">Last Name</label>
                                <input formControlName="LastName" type="text" id="GiftTo-LastName" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (change)="membersearch()" (input)="lastNameFetch($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.LastName">
                                    <span class="error-message danger alert" *ngIf="(subscriptionForm.controls['GiftTo'].get('LastName')?.hasError(validation.type) && (subscriptionForm.controls['GiftTo'].get('LastName')?.dirty || subscriptionForm.controls['GiftTo'].get('LastName')?.touched)) && subscriptionForm.controls['GiftTo'].get('LastName')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 px-0" *ngIf="this.newGiftMember == true">
                    <div class="row">
                        <div class="col-md-4 col-lg-4 pr-0 mob-pr">
                            <div class="form-group focus-label w3">
                                <label class="form-label-of" for="Email">Email</label>
                                <input formControlName="Email" type="email" id="GiftTo-Email" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" maxlength="150" (change)="membersearch()">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Email">
                                    <span class="error-message danger alert" *ngIf="(subscriptionForm.controls['GiftTo'].get('Email')?.hasError(validation.type) && (subscriptionForm.controls['GiftTo'].get('Email')?.dirty || subscriptionForm.controls['GiftTo'].get('Email')?.touched)) && subscriptionForm.controls['GiftTo'].get('Email')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                            <div class="form-group focus-label w3">
                                <label class="form-label-of" for="mob">Mobile Phone</label>
                                <input formControlName="PrimaryPhone" type="tel" id="GiftTo-PrimaryPhone" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (change)="membersearch()">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.PrimaryPhone">
                                    <span class="error-message danger alert" *ngIf="(subscriptionForm.controls['GiftTo'].get('PrimaryPhone')?.hasError(validation.type) && (subscriptionForm.controls['GiftTo'].get('PrimaryPhone')?.dirty || subscriptionForm.controls['GiftTo'].get('PrimaryPhone')?.touched)) && subscriptionForm.controls['GiftTo'].get('PrimaryPhone')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4 pl-0 mob-pl">
                            <div class="form-group focus-label w3">
                                <label class="form-label-of" for="add-ph">Additional Phone (Optional)</label>
                                <input formControlName="SecondaryPhone" type="tel" id="add-ph" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (change)="membersearch()">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.SecondaryPhone">
                                    <span class="error-message danger alert" *ngIf="(subscriptionForm.controls['GiftTo'].get('SecondaryPhone')?.hasError(validation.type) && (subscriptionForm.controls['GiftTo'].get('SecondaryPhone')?.dirty || subscriptionForm.controls['GiftTo'].get('SecondaryPhone')?.touched)) && subscriptionForm.controls['GiftTo'].get('SecondaryPhone')?.invalid">
                                        {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- address section -->
            <div class="col-lg-12 title-area mt-4  mb-4">
                <h4>Delivery Address</h4>
            </div>
            <div formGroupName="DeliveryAddress" class="w-100">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-md-8 col-lg-8">
                            <div class="form-group focus-label w3" id="Add1">
                                <label class="form-label-of" for="adress">Street Address</label>
                                <input type="text" id="BillingAddress-Address1" formControlName="Address1" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Address1"> 
                                    <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['DeliveryAddress'].get('Address1')?.hasError(validation.type) && (subscriptionForm.controls['DeliveryAddress'].get('Address1')?.dirty || subscriptionForm.controls['DeliveryAddress'].get('Address1')?.touched)) && subscriptionForm.controls['DeliveryAddress'].get('Address1')?.invalid">
                                       {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4 pl-0 mob-pl">
                            <div class="form-group focus-label w3" id="Add2">
                                <label class="form-label-of" for="opt-address">Apt, Suite, Bldg
                                (Optional)</label>
                                <input type="text" id="opt-address" formControlName="Address2" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Address2"> 
                                    <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['DeliveryAddress'].get('Address2')?.hasError(validation.type) && (subscriptionForm.controls['DeliveryAddress'].get('Address2')?.dirty || subscriptionForm.controls['DeliveryAddress'].get('Address2')?.touched)) && subscriptionForm.controls['DeliveryAddress'].get('Address2')?.invalid">
                                       {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-md-4 col-lg-4 pr-0 mob-pr">
                            <div class="form-group focus-label w3" id="City">
                                <label class="form-label-of" for="city">City</label>
                                <input type="text" id="BillingAddress-City" formControlName="City" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.City"> 
                                    <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['DeliveryAddress'].get('City')?.hasError(validation.type) && (subscriptionForm.controls['DeliveryAddress'].get('City')?.dirty || subscriptionForm.controls['DeliveryAddress'].get('City')?.touched)) && subscriptionForm.controls['DeliveryAddress'].get('City')?.invalid">
                                       {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4 form-group" id="State">
                            <!-- <div class="select-main_selectric custom-slct on-top-title"> -->
                            <ng-select formControlName="StateId" [items]="StatesList" id="BillingAddress-StateId" placeholder="State" bindValue="StateId" bindLabel="StateName" [(ngModel)]="state" [clearable]="false">
                            </ng-select>
                            <span class="error-message alert danger" *ngIf="(subscriptionForm.controls['DeliveryAddress'].get('StateId')?.dirty || subscriptionForm.controls['DeliveryAddress'].get('StateId')?.touched) && subscriptionForm.controls['DeliveryAddress'].get('StateId')?.invalid">
                                Please select your state
                            </span>
                            <!-- </div> -->
                        </div>
                        <div class="col-md-4 col-lg-4 pl-0 mob-pl">
                            <div class="form-group focus-label w3" id="Code">
                                <label class="form-label-of" for="post-code">Postal Code</label>
                                <input type="text" id="BillingAddress-Zip" formControlName="Zip" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Zip"> 
                                    <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['DeliveryAddress'].get('Zip')?.hasError(validation.type) && (subscriptionForm.controls['DeliveryAddress'].get('Zip')?.dirty || subscriptionForm.controls['DeliveryAddress'].get('Zip')?.touched)) && subscriptionForm.controls['DeliveryAddress'].get('Zip')?.invalid">
                                       {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 choose-radio-wrp my-4" formGroupName="PaymentProcessParams" *ngIf="!hidePaymentMethod">
                <div class="d-flex  bx-grey align-items-center   mob-mb">
                    <span class="radio-label mob-mb">Payment Method</span>
                    <div class="form-check radio-btn-wrp" *ngFor="let payment of PaymentMethods; let i= index;">
                        <input type="radio" class="form-check-input" id="{{payment.UniqueName}}" formControlName="PaymentMethodId" value="{{payment.PaymentMethodId}}" (change)="onPaymentMethodChange($event.target.id)" required [checked]="false">
                        <label class="form-check-label" for="{{payment.UniqueName}}">
                        {{payment.text}}
                    </label>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-12 d-flex add-card-wrp btm-border align-items-center pb-3 px-0">
                <h6>Paying with Card</h6>
                <div class="btn-add text-right">
                    <button type="button" class="btn-common">+Add Card</button>
                </div>
            </div>
            <div class="col-lg-12 d-flex card-bx mt-4 mb-4">
                <img src="./assets/member/images/icon-visa-card.png " alt=" " class="card-img-left">
                <p><strong>XXXX 8394</strong><span>Visa</span></p>
                <img src="./assets/member/images/icon-tick.png " alt=" " class="card-img-right">
            </div>
            <div class="col-lg-12 pl-0">
                <button type="submit" class="btn-proceed">Proceed</button>
            </div> -->



            <div id="payment" class="col-lg-12" [hidden]="selectedPaymentMethod == ''">
                <div class="row m-0 mob-no-margin payment-sec-wrp" formGroupName="PaymentProcessParams">
                    <div class="col-lg-12 title-area mt-3 mb-3 px-0" [hidden]="selectedPaymentMethod != 'ach'">
                        <h4>Payment Information</h4>
                        <div class="btn-add text-right">
                            <button type="button" class="btn-common" *ngIf="IsSavedAchPresent" (click)="AddNewAchAccount()">+New Account</button>
                            <button type="button" class="btn-common" *ngIf="!IsSavedAchPresent && savedAchCardButtonShow" (click)="listsavedAchAccount()">Use Existing Account</button>
                            <hr>
                        </div>
                    </div>
                    <div class="col-lg-12 title-area mt-3 mb-3   px-0" [hidden]="selectedPaymentMethod != 'credit_or_debt'">
                        <h4>Payment Information</h4>
                        <div class="btn-add text-right">
                            <button type="button" class="btn-common" *ngIf="showSavedCreditcardOption && savedCreditCardButtonShow" (click)="AddNewCreditCard()">+New Card</button>
                            <button type="button" class="btn-common" *ngIf="!showSavedCreditcardOption" (click)="listreditCard()">Use Existing Card</button>
                        </div>    
                        <hr>
                    </div>
                    <!-- calling new component for saved cards gor credit -->
                    <div class="col-lg-10" *ngIf="selectedPaymentMethod == 'credit_or_debt' && !shownewCreditcardOption" >
                        <app-savedcardlist [type]="'subscription'" [paymentMethodType]="selectedPaymentMethod" [subType]="subType" [subscriptionForm]="subscriptionForm"
                        [memberId]="selectedMemberId" [selectedGiftMemberId]="selectedGiftMemberId" [renewalOptions]="renewalOptions" [NetAmonutToPay]="NetAmonutToPay"
                        [brainTreeToken]="brainTreeToken" [paymentSettingId]="paymentSettingId" [InitialMethodId]="InitialMethodId" [brainTreeToken]="brainTreeToken"></app-savedcardlist>
                    </div>
                    <div [hidden]="selectedPaymentMethod != 'ach'" class="w-100 ach" formGroupName="PaymentFields">
                        <!-- showing saved card entires -->
                        <!-- style this entires -->
                        <!-- <ul class="nav nav-tabs  m-tabs-line m-tabs-line--success" role="tablist">
                            <li class="nav-item m-tabs__item">
                                <a class="nav-link m-tabs__link " [class.active]="IsSavedAchPresent" href="javascript:void(0);" role="tab" (click)="savedAccount()"><i class="la la-cloud-upload"></i> Saved accounts</a>
                            </li>
                            <li class="nav-item m-tabs__item">
                                <a class="nav-link m-tabs__link" [class.active]="!IsSavedAchPresent" href="javascript:void(0);" role="tab" (click)="AddNewCard()"><i class="la la-puzzle-piece"></i> Add new accounts</a>
                            </li> <span class="col-sm-12 m-form__help">
                                <span class="error-message alert danger" *ngIf="( this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.dirty || this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.touched) && this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('ChooseCard')?.invalid">
                                    Please select your account 
                                </span>
                            </span>
                        </ul> -->
                        <div class="row mob-no-margin" *ngIf="IsSavedAchPresent">
                            <div class="col-lg-6 form-check radio-btn-wrp mob-padding" *ngFor="let option of savedCards; let i=index">
                                <div class="bx-radio-btn-each">
                                    <input formControlName="ChooseCard" type="radio" class="form-check-input" value="{{option.PayMethodToken}}" id="{{option.PayMethodToken}}" (change)="onSelectedSavedcardChange($event.target.id)">
                                    <label for="{{option.PayMethodToken}}">
                                    <span class="d-block">
                                        {{option.AccountHolder}}
                                     </span>
                                    <span>
                                        {{option.MaskedAccountNumber}}
                                    </span>
                                </label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="btn-add text-right my-3">
                            <button type="button" class="btn-common" (click)="AddNewCard()">+Add Card</button>
                        </div> -->
                        <div class="col-lg-12 px-0 choose-radio-wrp" *ngIf="!IsSavedAchPresent || showNewCardOption">
                            <div class="row">
                                <div class="col-md-12 col-lg-12 d-flex mob-mb acnt-radio-type">
                                    <span class="radio-label">Account Type</span>
                                    <div class="form-check radio-btn-wrp" *ngFor="let accounttype of accounttypeList;let i = index;">
                                        <input type="radio" class="form-check-input" id="{{accounttype.value}}" formControlName="AccountType" value="{{accounttype.value}}">
                                        <label class="form-check-label" for="{{accounttype.value}}">
                                        {{accounttype.name}}
                                        </label>
                                    </div>

                                </div>
                                <span class="col-sm-12 m-form__help">
                                    <span class="error-message alert danger" *ngIf="( this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.dirty || this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.touched) && this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.invalid">
                                        Please select your account type
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-12 px-0 mt-3" *ngIf="!IsSavedAchPresent || showNewCardOption">
                            <div class="row">
                                <div class="col-md-4 col-lg-4 pr-0 mob-pr">
                                    <div class="form-group focus-label" id="account_number">
                                        <label class="form-label-of" for="ach_account_number">Account Number</label>
                                        <input formControlName="AccountNumber" type="text" name="ach_account_number" id="ach_account_number" class=" required" data-rule-number="true" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" />
                                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountNumber">
                                            <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.hasError(validation.type) && (subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.dirty || subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.touched)) && subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.invalid">
                                               {{ validation.message }}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-4">
                                    <div class="form-group focus-label" id="ach_routing_number">
                                        <label class="form-label-of" for="ach_routing_number">Routing Number</label>
                                        <input formControlName="RoutingNumber" type="text" class="required" autocomplete="off" name="ach_routing_number" id="ach_routing_number" data-rule-number="true" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.RoutingNumber">
                                            <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.hasError(validation.type) && (subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.dirty || subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.touched)) && subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.invalid">
                                               {{ validation.message }}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-4 pl-0 mob-pl">
                                    <div class="form-group focus-label" id="ach_person_name">
                                        <label class="form-label-of" for="accName">Name on Account</label>
                                        <input formControlName="AccountHolder" type="text" autocomplete="off" name="ach_person_name" id="ach_person_name" class="required" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" maxlength="100">
                                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountHolder">
                                                <span class="error-message danger alert"  *ngIf="(subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.hasError(validation.type) && (subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.dirty || subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.touched)) && subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.invalid">
                                                   {{ validation.message }}
                                                </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <!-- <label class="row col-form-label m-checkbox--inline" *ngIf="isSavedCard">
                                    <label class="m-checkbox m-checkbox--solid m-checkbox--brand">
                                      <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" >
                                      Save Account for Future
                                      <span></span>

                                    </label>
                                </label> -->
                                <!-- not working this code,please fix the issue -->
                                <div class="checkbox-wrp ml-3" *ngIf="isSavedCard">
                                    <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" id="save-id">
                                    <label for="save-id">Save Account for Future</label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="shownewCreditcardOption && selectedPaymentMethod == 'credit_or_debt'">
                        <ngx-braintree [getClientToken]="getClientToken.bind(this)" [showCardholderName]="false" [chargeAmount]="NetAmonutToPay" [createPurchase]="createPurchase.bind(this)" (paymentStatus)="onPaymentStatus($event)" (payButtonStatus)="payButtonStatus($event)"
                            *ngIf="brainTreeReInit">
                            <div class="ngxButtons">

                                <!-- <label class="row col-form-label m-checkbox--inline" formGroupName="PaymentFields">
                                  <label class="m-checkbox m-checkbox--solid m-checkbox--brand" *ngIf="isSavedCard">
                                    <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" >
                                    Save Account for Future
                                    <span></span>
                                  </label>
                                </label> -->
                                <div class="checkbox-wrp mt-3" *ngIf="isSavedCard" formGroupName="PaymentFields">
                                    <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" id="save-idd">
                                    <label for="save-idd">Save Account for Future</label>
                                </div>
                                <button class="btn-common btn-donate" ngxPay>Subscribe</button>
                            </div>
                        </ngx-braintree>
                    </div>

                    <div [hidden]="selectedPaymentMethod != 'manual_payment'" class="p-0 ach col-xs-12">
                        <div class="row mob-no-margin mob-padding">

                            <div class="col-md-12 col-lg-12">
                                <span class="total-amnt-bold" >BankAccount Details : </span>
                                <br>
                                <span  style="white-space: pre-line;">{{text}}</span>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="selectedPaymentMethod != 'credit_or_debt' || hidePaymentMethod" class="submit-btn clearfix" style="display:block;width:100%;text-align:center;">
                        <button type="submit" (click)="tokenizeUserDetails()" class="btn-common btn-donate">Subscribe  <i *ngIf="isLoading" class="spinner-border text-primary"></i></button>
                        <!-- <input type="button" value="SUBMIT" (click)="tokenizeUserDetails()" class="btn-common btn-donate" /> -->
                    </div>
                    <!-- <div class="footer-txt">
                        <p class="text-center short-desc-btm mt-5">Shalom World is an initiative of Shalom Media {{footerText.CountryName}} Inc, a registered {{footerText.CountryRegistrationInfo}} non-profit organization. Contributions to Shalom Media USA are tax-deductible to the extent permitted
                            by law. Shalom Media’s tax identification number is {{footerText.CountryTaxInfo}}. </p>
                    </div> -->
                </div>
            </div>
        </div>
    </form>
</div>