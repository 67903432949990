import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateReccuringDonationComponent } from './update-reccuring-donation.component';
import { MainComponent } from '../main.component';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxBraintreeModule } from 'ngx-braintree';
import { NgSelectModule } from '@ng-select/ng-select';

const routes: Routes = [
  {
      'path': '',
      'component': MainComponent,
      'children': [
          {
              'path': '',
              'component': UpdateReccuringDonationComponent,
          },
      ],
  },
];
@NgModule({
  declarations: [UpdateReccuringDonationComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes), 
    ReactiveFormsModule,
    FormsModule,
    NgxBraintreeModule,
    NgSelectModule

  ]
})
export class UpdateReccuringDonationModule { }
