<!-- <div  class="address" [formGroup]="group" class="row"> -->
<!-- Form inputs will go here -->
<!-- <div class="col-xl-6" *ngFor="let field  of FieldsData; let i = index;">
        <div class="form-group" >
          <label>{{field.FieldLabelText}}</label>
          <input type="text" [formControlName]="'cust_'+field.Id"    class="form-control"  placeholder="{{field.FieldLabelText}}" value="">
        </div>
      </div> -->
<!-- <div class="row" formArrayName="customFieldsArray">
    <div class="col-xl-6" *ngFor="let option of FormArray.controls; let i = index;">
      <div class="form-group" [formGroupName]="i"> name="{{'cust_'+field.Id}}" [formControl]="group.get('cust_'+field.Id)"
        <label>Address</label>[formControl]="group.get('cust_'+field.Id)"
        <input type="text" formControlName="city" />
      </div>
    </div>
  </div> -->
<!-- </div> -->
<div [formGroup]="form" class="col-xl-12 custom_div" *ngIf="!paymentPage">
  <div [ngSwitch]="custom.controlType" class="form-group custom_div_inner"  [ngClass]="datePic ? 'is-focused':'' ">
    <label class="custom_label" [attr.for]="custom.key"  *ngIf="custom.type != 'checkbox'">{{custom.label}}</label>
    <input *ngSwitchCase="'textbox'" [formControlName]="custom.key" [id]="custom.key" [type]="custom.type"
      class="form-control custom-input" (change)="changeEvent($event.target.value,custom)" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
    <textarea *ngSwitchCase="'textarea'" [formControlName]="custom.key" [id]="custom.key"
      class="form-control custom-textarea" (change)="changeEvent($event.target.value,custom)" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"></textarea>
    <!-- <input *ngSwitchCase="'textboxdatepicker'" [formControlName]="custom.key" [id]="custom.key" [type]="custom.type"
      class="form-control custom-datepicker" (change)="changeEvent($event.target.value,custom)"> -->
      <!-- <input *ngSwitchCase="'textboxdatepicker'" [formControlName]="custom.key" [id]="custom.key" [type]="custom.type"
      class="form-control custom-datepicker" #dp="bsDatepicker" [bsConfig]="bsConfig"
      bsDatepicker [(bsValue)]="myDateValue" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" [(ngModel)]="selectedStartDate" class="form-control custom-datepicker"
      (ngModelChange)="changeEvent($event,custom)"> -->
      <!-- new datepiker code for memberDashbrd -->
      <div class="input-box-container" *ngSwitchCase="'textboxdatepicker'">
        <input class="input-box"   [formControlName]="custom.key" 
          angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()" (dateChanged)="changeEvent($event,custom)" id="{{custom.key}}" readonly
           [options]="myDpOptions" 
          #dp="angular-mydatepicker"/>
          <!-- placeholder="{{custom.label}}"  (focusout)="animateLabelRemoveDatePiker($event)" (focus)="animatelabelDatePiker($event)" -->
      </div>
    <label *ngSwitchCase="'checkbox'" class="col-form-label m-checkbox--inline">
      <label class="m-checkbox m-checkbox--solid m-checkbox--brand chckbx_wrp">
        <input [formControlName]="custom.key" [id]="custom.key" type="checkbox" (change)="changeEvent(form.get(custom.key+'').value,custom)">
        <label class="form-check-label" [for]="custom.key"> {{custom.label}}</label>
        <span><div *ngIf="form.get(custom.key+'').value" style="margin-left: 45px;    width: 110px;"><b>{{(custom.data?.CalculationType==1)?'+':'-'}} {{custom.data?.Cost}}</b></div> </span>
      </label>
    </label>
  </div>

  <!-- <span class="m-form__help errorMessage" *ngIf="!valid">{{custom.label}} is required</span> -->
</div>

<div [formGroup]="form" class="col-xl-12 custom_div" *ngIf="paymentPage">
  <div [ngSwitch]="custom.controlType" class="form-group custom_div_inner">
    <label class="custom_label"  [attr.for]="custom.key" *ngIf="custom.type != 'checkbox'">{{custom.label}}</label>
    <label *ngSwitchCase="'checkbox'" class="col-form-label m-checkbox--inline">
      <label class="m-checkbox m-checkbox--solid m-checkbox--brand chckbx_wrp">
        <input [formControlName]="custom.key" [id]="custom.key" type="checkbox" (change)="changeEvent(form.get(custom.key+'').value,custom)">
        <label [attr.for]="custom.key">{{custom.label}}</label>
        <span></span>
        <div *ngIf="form.get(custom.key+'').value" style="position: absolute; display: inline-block;"><b>{{(custom.data?.CalculationType==1)?'+':'-'}} {{custom.data?.Cost}}</b></div> 
      </label>
    </label>
    <input *ngSwitchCase="'textbox'" [formControlName]="custom.key" [id]="custom.key" [type]="custom.type"
      class="form-control custom-input" (change)="changeEvent($event.target.value,custom)" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
    <textarea *ngSwitchCase="'textarea'" [formControlName]="custom.key" [id]="custom.key"
      class="form-control custom-textarea" (change)="changeEvent($event.target.value,custom)" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"></textarea>
    <input *ngSwitchCase="'textboxdatepicker'" [formControlName]="custom.key" [id]="custom.key" [type]="custom.type"
      class="form-control custom-datepicker" (change)="changeEvent($event.target.value,custom)" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
     
  </div>

  <span class="m-form__help errorMessage" *ngIf="!isValid">{{custom.label}} is required</span>
</div> 