import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddMemberComponent } from '../add-member/add-member.component';
import { SharedService } from '../../../../_services/shared.service';
import { ContactUsPopupComponent } from '../contact-us-popup/contact-us-popup.component';
import { EditContactInfoComponent } from '../edit-contact-info/edit-contact-info.component';

@Component({
  selector: 'app-family-member',
  templateUrl: './family-member.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css', './family-member.component.scss']
})
export class FamilyMemberComponent implements OnInit {

  routeParam;
  memberId;
  listOfFamilyMem;
  familyMemberCount;
  formData;
  defaultCauseId;
  defaultCauseName;
  isSpfMember:boolean;
  singleDonationExist:boolean = false;
  constructor(public _crud: CommonCrudService, public modalService: NgbModal, public activeRoute: ActivatedRoute, private router: Router, public sharedService: SharedService) { }

  ngOnInit(): void {
    this.routeParam = this.activeRoute.snapshot;
    this.memberId = this.routeParam.params['memberId'];
    this.sharedService.fetchPathData('dashboard', 'familylist');
    this.defaultCauseId = localStorage.getItem('dCauseId');
    this.defaultCauseName = localStorage.getItem('dCauseName');
    this.familyMembers();
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
	  this.singleDonationExist = currentUser.singleDonationExist;
  }
  familyMembers() {

    let params = {
      url: "frontend-site/list-family-members-with-primary-member", params: { PrimaryMemberId: this.memberId }
    }
    this._crud.getData(params).subscribe(res => {

      // console.log("family member list----", res);         

      this.listOfFamilyMem = res['data'];
      this.familyMemberCount = this.listOfFamilyMem.length;
      this.listOfFamilyMem.forEach(element => {
        if (element.SpfId == null) {
          element.addnew = 1;
        } else {
          element.addnew = 0;

        }
      });
      this.listOfFamilyMem.forEach(element => {
        if (element.IsPrimaryMember == 1) {
          this.sharedService.memberInfo(element['PhotoUrl']);
          this.sharedService.memberFname(element['FirstName'])
          this.sharedService.memberLname(element['LastName'])
          this.sharedService.memberTitle(element['SalutationName'])


        }
      });
    });

  }
  addMember(form = null) {
    this.formData = form;
    const modalRef = this.modalService.open(AddMemberComponent, {
      size: "lg"
    });
    modalRef.componentInstance.formData = this.formData;
    modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
        if (result.result) {
          // /clearing all forms.
          this.familyMembers();
        }
      });
  }
  donateFRomFamily(memberId: any = null, spf: boolean = false) {
    if (!spf) {
      this.router.navigate([`memberDashboard/mydonation/` + this.memberId + `/donation`], { queryParams: { memberId: memberId } });
    } else {
      this.router.navigate([`memberDashboard/mydonation/` + this.memberId + `/donation`], { queryParams: { memberId: memberId, causeId: this.defaultCauseId, causeName: this.defaultCauseName } });
    }

  }
  subscription(subType, memberId = null) {
    this.router.navigate(["/memberDashboard/mysubscription/" + this.memberId + "/subscription"], { queryParams: { subType: subType, memberId: memberId } });


  }
  modalOpen(type) {

    // console.log("inside modl open")
    const modalRef = this.modalService.open(ContactUsPopupComponent, {
      size: "lg"
    });
    modalRef.componentInstance.memberId = this.memberId;
    modalRef.componentInstance.type = type;
  }
  editContactInfo() {
    const modalRef = this.modalService.open(EditContactInfoComponent, {
      size: "lg"
    });
    modalRef.componentInstance.memberId = this.memberId;
    modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
        if (result.result) {
          // /calling all api in settings apage
          this.ngOnInit();
        }
      });
  }

}
