import { Component, OnInit } from '@angular/core';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { FormBuilder,Validators} from '@angular/forms';
import { Router ,ActivatedRoute} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { RecaptchaErrorParameters } from "ng-recaptcha";
import { SuccessPopupComponent } from '../../main/success-popup/success-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare let $: any;

@Component({
  selector: 'app-member-login',
  templateUrl: './member-login.component.html',
  styleUrls: ['./member-login.component.scss']
})
export class MemberLoginComponent implements OnInit {

  loginForm;
  memberId;
  returnUrl = null;
  errorShow : boolean = false;
  errorMsg;
  isloading:boolean = false;
  notcaptcha: boolean;
  captcharesponse: string = '';
  step2Fields;
  show: boolean = true
  password = "password"
  ValidationMessage: any = {
    Email: [
        { type: "required", message: "Please enter your email address" },
        { type: "pattern", message: "Please enter a valid email" }
    ],
    Password: [
        {type: "required", message: "Please enter your password" },
        {type: "pattern",message: "Numbers,space and special characters not allowed"},
        {type: "minlength", message: "Please enter valid password " }
    
    ],
    captcha:[{type: "required", message: "captcha is required" }]
  }
  cookieValue: string;

  constructor(
    public formBuilder: FormBuilder,
    public _crud:CommonCrudService,
    public router:Router,public _route:ActivatedRoute,
    private cookieService: CookieService,
    public modalService : NgbModal
    ) 
    { 
      this.formInit();
    }

  ngOnInit(): void {
  }
  animateLabel(event) {
    event.parentNode.classList.add('is-focused');
  }

  animateLabelRemove(e) {
    // console.log(e.value, "AHGKND");
    if (e.value == '') {
        e.parentNode.classList.remove('is-focused');
    }
  }
  formInit(){
    this.loginForm = this.formBuilder.group({
      Email:['',Validators.required],
      Password:['',Validators.required],
      RemeberMe:[false],
      captcha:[null,Validators.required]
    });
    if(this.cookieService.get( 'rememeber') != undefined){
      if(this.cookieService.get( 'rememeber') == 'yes'){
        this.loginForm.controls['Email'].setValue(this.cookieService.get( 'userName'));
        this.loginForm.controls['Password'].setValue(this.cookieService.get( 'password'));
      }
    }
    
  }
  loginSubmit(){
       
    console.log("inside login ")
    if(this.loginForm.controls['RemeberMe'].value){
        this.cookieService.set( 'rememeber','yes' );
        this.cookieService.set( 'userName',this.loginForm.controls['Email'].value );
        this.cookieService.set( 'password', this.loginForm.controls['Password'].value);
    }else{
        this.cookieService.set( 'rememeber','No' );
        this.cookieService.set( 'userName',"" );
        this.cookieService.set( 'password', "");
    }
    this.step2Fields = [
  
        this.loginForm.controls['Email'],
        this.loginForm.controls['Password'],
      ]
    const invalid = [];
    const controls = this.step2Fields;
    let i: any;
    for (i in controls) {
        controls[i].markAsDirty();
        if (controls[i].invalid) {
            invalid.push(i);
        }
    }
    if(this.captcharesponse == ''){
        this.notcaptcha = true
        // console.log("error msg")
    }else{
        //validation pointing
        if (invalid[0] == undefined) {

          this.isloading = true;
          this.notcaptcha = false
          // console.log("api")
          let params = {
              url: "frontend-site/member/login", 
              params: {Email : this.loginForm.controls.Email.value, Password : this.loginForm.controls.Password.value}
          }
          this._crud.postData(params).subscribe(res=>{  
              this.isloading = false;
              // console.log("response meber register------->",res);
              if(res['status'] == 'success'){
                  // console.log("inside status syuseee",res['data']);
                  //save data in local storege
                  let user = res['data'];
                  this.memberId = res['data']['Details']['MemberId'];
                  if(user && user.access_token){
                      // console.log("saving data inside local storage");
                      localStorage.setItem('currentUser',JSON.stringify(user));
                      localStorage.setItem('memberId',this.memberId);
                      localStorage.setItem('primaryCountryId',res['data']['Details']['PrimaryCountryId']);
                      localStorage.setItem('countryId',res['data']['Details']['MemberCountryId'])
                      localStorage.setItem('IsSpfMember',res['data']['IsSpfMember']);
                      localStorage.setItem('countryCode',res['data']['Details']['CountryCode']);


                  }
                  this.returnUrl = this._route.snapshot.queryParams["returnUrl"];
                  if(this.returnUrl){
                      this.router.navigate([this.returnUrl]);
                  }else{
                      this.router.navigateByUrl("/memberDashboard/home/" + this.memberId);
                  }
              

              }else{
                if(res['msg'].includes('account is not active')){
                  this.errorShow = false;
                  console.log("contain----",res['msg'])
                  res['msg']='Your myShalom account has not been verified. A verification link was sent to your email. Please click the link to activate your myShalom account.';
                  this.successPopup(res);
                }else{
                  this.errorShow = true;
                  this.errorMsg = 'Your email or password was incorrect.';
                }  
              }
          });
        }    
    }
  }
  public resolved(captchaResponse: string): void {
    this.captcharesponse = captchaResponse == null ? '':"yes"
    this.notcaptcha =  captchaResponse == null ? true: false
    // console.log("captcha value--resolved--",this.notcaptcha)
    if(!this.captcharesponse){
        this.isloading = false;
    }
    // console.log(`Resolved captcha with response: `,captchaResponse);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    // console.log("captcha value----",this.notcaptcha)
    this.notcaptcha = true
    this.isloading = false;
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }
  successPopup(data) {
    let response = {title: data['status'],message: data['msg']}
    const modalRef = this.modalService.open(SuccessPopupComponent, {size: "lg"});
    modalRef.componentInstance.user = response;
    modalRef.componentInstance.verificationLink = true; 
    modalRef.componentInstance.userEmail =  this.loginForm.controls.Email.value;
    modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
          if (result.result && data['status']=='success') {
            modalRef.close({ 'result': true });
          }
      });
  }
  passwordType(){
    if (this.password === 'password') {
      this.password = 'text';
      this.show = false;
    } else {
      this.password = 'password';
      this.show = true;
    }  }
    forgotPassword(){
      this.router.navigate([`/member-forgotpassword/`], { queryParams: { email: this.loginForm.controls['Email'].value} });      
  }
}
