
import { HttpHeaders,HttpParams } from '@angular/common/http';
import { environment} from "../environments/environment";

export class Helpers {

    // public ApiBaseUrl: string = "http://myshalomtest.webc.in/myshalom/public/api/";
    public ApiBaseUrl : string = environment.apiURL;

    public jwt(content_type = null) {
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if (currentUser) {
            if (currentUser.OptedPrimaryCountry == null) {
                currentUser.OptedPrimaryCountry = "";
            }
        }
        if (currentUser && currentUser.access_token) {
            let headers = new HttpHeaders({
                Authorization: "Bearer " + currentUser.access_token
            });
            headers.append("pc-id", currentUser.OptedPrimaryCountry);
            if (content_type != null && content_type == "application/json") {
                headers.append("Content-Type", "application/json");
            }

            return ({ headers: headers ,params : new HttpParams});
        }
        else {
            let headers = new HttpHeaders({
                'Content-Type': "application/json"
            });
            return ({ headers: headers ,params : new HttpParams});
        }


        // let headers = new HttpHeaders();
        // if (content_type != null && content_type == "application/json") {
        //     headers.append("Content-Type", "application/json");
        // }
        // return ({ headers: headers ,params : new HttpParams});

    }
    static datepickerInit(element) {
        element.datepicker({
            todayHighlight: true,
            orientation: "bottom left",
            format: "M-dd-yyyy",
            templates: {
                leftArrow: '<i class="la la-angle-left"></i>',
                rightArrow: '<i class="la la-angle-right"></i>'
            },
            autoclose: true
        });
    }

}