import { Component, OnInit } from '@angular/core';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { FormBuilder,Validators} from '@angular/forms';
import { Router ,ActivatedRoute} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { RecaptchaErrorParameters } from "ng-recaptcha";
declare let $: any;


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginForm;
    memberId;
    returnUrl = null;
    errorShow : boolean = false;
    errorMsg;
    isloading:boolean = false;
    notcaptcha: boolean;
    captcharesponse: string = '';
    step2Fields;
    ValidationMessage: any = {
        Email: [
            { type: "required", message: "Please enter your email address" },
            { type: "pattern", message: "Please enter a valid email" }
        ],
        Password: [
            {type: "required", message: "Please enter your password" },
            {type: "pattern",message: "Numbers,space and special characters not allowed"},
            {type: "minlength", message: "Please enter valid password " }
        
        ],
        captcha:[{type: "required", message: "captcha is required" }]
    }
    cookieValue: string;
    constructor(public formBuilder: FormBuilder,public _crud:CommonCrudService,public router:Router,public _route:ActivatedRoute,private cookieService: CookieService) { 
        this.formInit();
    }
    ngOnInit() {

        $('.radiotabs > a').on('click', function(e) {
            // ALERT("hiii");
            $(this).parent().find('input').prop('checked', false);
            $(this).find('input').prop('checked', true);
        });

        /*----Placeholder Up---*/
        $(".member-wrp input").on('focus', function() {
            $(this).parent(".focus-label").addClass("is-focused");
        });
        $(".member-wrp input").focusout(function() {
            if ($(this).val() == "") {
                $(this).parents(".focus-label").removeClass("is-focused");
            }
        });
    }
    animateLabel(event) {
        event.parentNode.classList.add('is-focused');
    }

    animateLabelRemove(e) {
        // console.log(e.value, "AHGKND");
        if (e.value == '') {
            e.parentNode.classList.remove('is-focused');
        }
    }
    formInit(){
        this.loginForm = this.formBuilder.group({
            Email:['',Validators.required],
            Password:['',Validators.required],
            RemeberMe:[false],
            captcha:[null,Validators.required]
        });
        if(this.cookieService.get( 'rememeber') != undefined){
            if(this.cookieService.get( 'rememeber') == 'yes'){
                this.loginForm.controls['Email'].setValue(this.cookieService.get( 'userName'));
                this.loginForm.controls['Password'].setValue(this.cookieService.get( 'password'));
// document.getElementById("userName").classList.add("is-focused");
// document.getElementById("passWord").classList.add("is-focused");


            }
        }
        
    }
    loginSubmit(){
       
        // console.log("inside login ")
        if(this.loginForm.controls['RemeberMe'].value){
            this.cookieService.set( 'rememeber','yes' );
            this.cookieService.set( 'userName',this.loginForm.controls['Email'].value );
            this.cookieService.set( 'password', this.loginForm.controls['Password'].value);
        }else{
            this.cookieService.set( 'rememeber','No' );
            this.cookieService.set( 'userName',"" );
            this.cookieService.set( 'password', "");
        }
        this.step2Fields = [
      
            this.loginForm.controls['Email'],
            this.loginForm.controls['Password'],
          ]
        const invalid = [];
        const controls = this.step2Fields;
        let i: any;
        for (i in controls) {
            controls[i].markAsDirty();
            if (controls[i].invalid) {
                invalid.push(i);
            }
        }
        if(this.captcharesponse == ''){
            this.notcaptcha = true
            // console.log("error msg")
        }else{
            //validation pointing
            if (invalid[0] == undefined) {

            this.isloading = true;
            this.notcaptcha = false
            // console.log("api")
            this.isloading = true;
            let params = {
                url: "frontend-site/member/login", 
                params: {Email : this.loginForm.controls.Email.value, Password : this.loginForm.controls.Password.value}
            }
            this._crud.postData(params).subscribe(res=>{
                this.isloading = false;
                // console.log("response meber register------->",res);
                if(res['status'] == 'success'){
                    // console.log("inside status syuseee",res['data']);
                    //save data in local storege
                    let user = res['data'];
                    this.memberId = res['data']['Details']['MemberId'];
                    if(user && user.access_token){
                        // console.log("saving data inside local storage");
                        localStorage.setItem('currentUser',JSON.stringify(user));
                        localStorage.setItem('memberId',this.memberId);
                        localStorage.setItem('primaryCountryId',res['data']['Details']['PrimaryCountryId']);
                        localStorage.setItem('countryId',res['data']['Details']['MemberCountryId'])
                        localStorage.setItem('IsSpfMember',res['data']['IsSpfMember']);


                    }
                    this.returnUrl = this._route.snapshot.queryParams["returnUrl"];
                    if(this.returnUrl){
                        this.router.navigate([this.returnUrl]);
                    }else{
                        this.router.navigateByUrl("/memberDashboard/home/" + this.memberId);
                    }
                

                }else{
                    this.errorShow = true;
                    this.errorMsg = res['msg'];
                }
            });
        }    
    }
    }
    public resolved(captchaResponse: string): void {
        this.captcharesponse = captchaResponse == null ? '':"yes"
        this.notcaptcha =  captchaResponse == null ? true: false
        // console.log("captcha value--resolved--",this.notcaptcha)
        if(!this.captcharesponse){
            this.isloading = false;
        }
        // console.log(`Resolved captcha with response: `,captchaResponse);
      }
    
      public onError(errorDetails: RecaptchaErrorParameters): void {
        // console.log("captcha value----",this.notcaptcha)
        this.notcaptcha = true
        this.isloading = false;
        console.log(`reCAPTCHA error encountered; details:`, errorDetails);
      }

}
