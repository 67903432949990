import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class SharedService {

    view: any = '';
    path: any = '';
    tab: any = '';
    photo: any = '';
    fname: any = '';
    lname: any = '';
    title: any = '';

    private hideSidebar = new BehaviorSubject(this.view);
    private pathDetail = new BehaviorSubject(this.path);
    private tabDetail = new BehaviorSubject(this.tab);
    private messageSource = new BehaviorSubject('default message');
    private photoUrl = new BehaviorSubject(this.photo);
    private firstName = new BehaviorSubject(this.fname);
    private lastName = new BehaviorSubject(this.lname);
    private salutation = new BehaviorSubject(this.title);

    sideBarData = this.hideSidebar.asObservable();
    pathInfo = this.pathDetail.asObservable();
    tabInfo = this.tabDetail.asObservable();
    currentMessage = this.messageSource.asObservable();
    currentPhoto = this.photoUrl.asObservable();
    currentFname = this.firstName.asObservable();
    currentLname = this.lastName.asObservable();
    currentTitle = this.salutation.asObservable();



    constructor(private router: Router) { }

    changeSidebarView(status) {
        this.hideSidebar.next(status);
    }

    fetchPathData(info = null, pathData) {
        this.pathDetail.next(pathData);
        this.tabDetail.next(info);
    }
    // share data
    changeMessage(message: string) {
        this.messageSource.next(message)
    }
    memberInfo(memberData) {
        this.photoUrl.next(memberData);
    }
    memberFname(memberData) {
        this.firstName.next(memberData);
    }
    memberLname(memberData) {
        this.lastName.next(memberData);
    }
    memberTitle(memberData) {
        this.salutation.next(memberData);
    }
}
