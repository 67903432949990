<section class="member-wrp">
    <div class="bg-body">
        <div class="login-wrp ">
            <div class="login-form">
                <form [formGroup]="registerForm">
                    <div class="form-wrp">
                        <div class="login-head-title">
                            <h1>Create your Account</h1>
                            <p>Please enter the details to create your account. </p>
                        </div>
                        <div class="row form-inner">
                            <div class="col-md-6 form-group focus-label">
                                <label class="form-label-of" for="f-name">First Name</label>
                                <!-- <input id="f-name" type="text" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <input autocomplete="off" formControlName="Fname" id="f-name" type="text" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="firstNameFetch($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Fname">
                                    <span class="error-message"
                                        *ngIf="registerForm.get('Fname').hasError(validation.type) && (registerForm.get('Fname').dirty || registerForm.get('Fname')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                            <div class="col-md-6 form-group focus-label">
                                <label class="form-label-of" for="l-name">Last Name</label>
                                <!-- <input id="l-name" type="text" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <input autocomplete="off" formControlName="Lname" id="l-name" type="text" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="lastNameFetch($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Lname">
                                    <span class="error-message"
                                        *ngIf="registerForm.get('Lname').hasError(validation.type) && (registerForm.get('Lname').dirty || registerForm.get('Lname')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                            <div class="col-md-6 form-group focus-label up-label">
                                <!-- <select class="form-control">
                                       <option>Country</option>
                                   </select> -->
                                <ng-select formControlName="CountryId" id="country" [(ngModel)]="countryId" [clearable]="false" placeholder="Country" required (change)="onCountryChange($event.Id)" bindValue="Id" bindLabel="Name" [items]="CountryList" autocomplete="autocomplete_off_hack_xfr4!k"></ng-select>
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.CountryId">
                                       <span class="error-message"
                                           *ngIf="registerForm.get('CountryId').hasError(validation.type) && (registerForm.get('CountryId').dirty || registerForm.get('CountryId')?.touched)">
                                           {{validation.message}}
                                       </span>
                                </span>
                            </div>
                            <div class="col-md-6 form-group focus-label">
                                <label class="form-label-of" for="mob">Mobile Number</label>
                                <!-- <input id="mob" type="tel" class=" " (focus)="animateLabel($event.target) " (focusout)="animateLabelRemove($event.target) "> -->
                                <input autocomplete="off" formControlName="Mobile" id="mob" type="tel" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Mobile">
                                    <span class="error-message"
                                        *ngIf="registerForm.get('Mobile').hasError(validation.type) && (registerForm.get('Mobile').dirty || registerForm.get('Mobile')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                            <div class="col-md-6 form-group focus-label ">
                                <label class="form-label-of " for="email ">Email</label>
                                <!-- <input id="email" type="email" class=" " (focus)="animateLabel($event.target) " (focusout)="animateLabelRemove($event.target) "> -->
                                <input autocomplete="off" formControlName="Email" id="email" type="email" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="fetchEmail($event.target.value)" maxlength="150">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Email">
                                    <span class="error-message"
                                        *ngIf="registerForm.get('Email').hasError(validation.type) && (registerForm.get('Email').dirty || registerForm.get('Email')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                            <div class="col-md-6 form-group focus-label ">
                                <label class="form-label-of " for="cemail ">Confirm Email</label>
                                <!-- <input id="cemail" type="email" class=" " (focus)="animateLabel($event.target) " (focusout)="animateLabelRemove($event.target) "> -->
                                <input autocomplete="off" formControlName="ConfirmEmail" id="cemail" name="confemail" type="email" class="drop" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="fetchConfirmEmail($event.target.value)" maxlength="150">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.ConfirmEmail">
                                    <span class="error-message"
                                        *ngIf="registerForm.get('ConfirmEmail').hasError(validation.type) && (registerForm.get('ConfirmEmail').dirty || registerForm.get('ConfirmEmail')?.touched) || registerForm.get('ConfirmEmail').hasError('passwordMismatch')">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                            <div class="col-md-6 form-group focus-label dropdown">
                                <label class="form-label-of" for="pswrd">Password</label>
                                <!-- <input id="pswrd" type="password" class="pswrd-input" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <input autocomplete="off" class="form-control dropdown-toggle" formControlName="Password" id="pswrd" type="password" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"
                                    (input)="passwordFetch($event.target.value)">
                                <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
                                    <ul>
                                        <li [ngClass]="{'pwd_class': totalChar}"><span>&#10003;</span> 8 or more characters</li>
                                        <li [ngClass]="{'pwd_class': atleaatAlpha}"><span> &#10003;</span> Upper & Lowercase letters</li>
                                        <li [ngClass]="{'pwd_class': atleastDigit}"> <span>&#10003;</span> Atleast one number</li>
                                    </ul>
                                </div>
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Password">
                                    <span class="error-message"
                                        *ngIf="registerForm.get('Password').hasError(validation.type) && (registerForm.get('Password').dirty || registerForm.get('Password')?.touched)">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                            <div class="col-md-6 form-group focus-label">
                                <label class="form-label-of" for="cpswrd">Confirm Password</label>
                                <!-- <input id="cpswrd" type="password" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"> -->
                                <input autocomplete="off" formControlName="ConfirmPassword" id="cpswrd" type="password" class="" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="checkPassword($event.target.value)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.ConfirmPassword">
                                    <span class="error-message"
                                        *ngIf="registerForm.get('ConfirmPassword').hasError(validation.type) && (registerForm.get('ConfirmPassword').dirty || registerForm.get('ConfirmPassword')?.touched) || registerForm.get('ConfirmPassword').hasError('passwordMismatch')">
                                        {{validation.message}}
                                    </span>
                                </span>
                            </div>
                            <div class="col-md-12 my-4 captcha-para-wrp">
                                <div class="row">
                                    <div class="col-md-4 cpatcha-holder">
                                        <div class="captcha-code">
                                            <re-captcha (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled" siteKey="6Lekt9saAAAAAP9hDIjQMcIT1MZTPLLcekqRPz4u"></re-captcha>
                                            <span class="m-form__help" *ngIf="captcha">
                                            <span class="error-message">
                                                Captcha is required
                                            </span></span>
                                        </div>
                                    </div>
                                    <div class="col-md-4 btn-holder">
                                        <button type="submit " (click)="submit()" class="btn-common">Create Now <i *ngIf="isloading" class="spinner-border"></i></button>
                                    </div>
                                    <div class="col-md-4 text-right back-link">
                                        <span>Already have an account?<a routerLink="/member-login">Login</a></span>
                                    </div>
                                   
                                </div>
                                <div class="col-md-12">
                                    <div class="row" *ngIf="errorShow">
                                        <span class="error-message">
                                            {{errorMsg}}
                                        </span>  
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-md-12 desc-text">
                                <p class="mb-0">Once you’ve registered, you will receive a confirmation email with an activation link. Please click on the link to activate your account.</p>
                            </div>
                            <!-- <div class="col-md-12">
                                <button type="submit " (click)="submit()" class="btn-common">Create Now <i *ngIf="isloading" class="spinner-border"></i></button>
                            </div>
                            <div class="col-md-12 text-right back-link">
                                <span>Already have an account?<a routerLink="/member-login">Login</a></span>
                            </div> -->
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <ul class="nav-menu-btm ">
            <li><a href="/privacy-policy">Privacy Notice</a></li>
            <li><a href="/help">Help</a></li>
            <li><a href="/terms-and-conditions">Conditions of Use</a></li>
        </ul>
    </div>
</section>