<div class="contact-alert" [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <!-- <div class="alert-top-bar">
        <div class="row align-item-center">
            <div class="col-7 col-md-7">
                <h5>Change Password</h5>
            </div>
            <div class="col-5 col-md-5 text-right">
                <figure class="mb-0">
                    <img src="./assets/member/images/dashboard-logo.svg " alt="Shalom ">
                </figure>
                <button type="button" class="close" (click)="activeModal.dismiss('Cross click')">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
        </div>
    </div> -->
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">
            &times;
        </span>
    </button>
    <div class="popup-inner">
        <div class="text-center pt-5 popup-title">
            <h2>Change Password</h2>
            <p>Together we can change the world</p>
        </div>
        <form [formGroup]="changePasswordForm">
            <div class="contact-form">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <label class="form-label-of" for="">Current Password</label>
                            <input class="form-control" id="" name="cpwd" formControlName="currentPassword" type="password" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.currentPassword">
                            <span class="error-message"
                                *ngIf="changePasswordForm.get('currentPassword').hasError(validation.type) && (changePasswordForm.get('currentPassword').dirty || changePasswordForm.get('currentPassword')?.touched)">
                                {{validation.message}}
                            </span>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label dropdown">
                            <label class="form-label-of" for="">New Password</label>
                            <input class="form-control dropdown-toggle" id="" formControlName="newPassword" type="password" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="passwordFetch($event.target.value)">
                            <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
                                <ul>
                                    <li [ngClass]="{'pwd_class': totalChar}"><span>&#10003;</span> 8 or more characters</li>
                                    <li [ngClass]="{'pwd_class': atleaatAlpha}"><span> &#10003;</span> Upper & Lowercase letters</li>
                                    <li [ngClass]="{'pwd_class': atleastDigit}"> <span>&#10003;</span> Atleast one number</li>
                                </ul>
                            </div>
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.newPassword">
                            <span class="error-message"
                                *ngIf="changePasswordForm.get('newPassword').hasError(validation.type) && (changePasswordForm.get('newPassword').dirty || changePasswordForm.get('newPassword')?.touched)">
                                {{validation.message}}
                            </span>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <label class="form-label-of" for="">Confirm Password</label>
                            <input class="form-control" id="" formControlName="confirmPassword" type="password" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="checkPassword($event.target.value)">
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.confirmPassword">
                            <span class="error-message"
                                *ngIf="changePasswordForm.get('confirmPassword').hasError(validation.type) && (changePasswordForm.get('confirmPassword').dirty || changePasswordForm.get('confirmPassword')?.touched) || changePasswordForm.get('confirmPassword').hasError('passwordMismatch')">
                                {{validation.message}}
                            </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top: 5px;">
                    <div class="col-md-6">
                        <button type="submit" class="btn-border btn-modal" style="float: right;" (click)="activeModal.dismiss('Cross click')">Cancel</button>
                    </div>
                    <div class="col-md-6">
                        <button type="submit" class="btn-filled btn-modal" (click)="save()"><span class="span-loader-padding">Save</span> <i *ngIf="isLoading" class="spinner-border"></i></button>
                    </div>
                </div>
                    <!-- <div class="col-md-12 mt-2 mb-3 d-flex justify-content-center">
                        
                        
                    </div> -->

                <!-- </div> -->
            </div>
        </form>
    </div>
</div>