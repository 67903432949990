<section class="member-wrp">
    <div class="bg-body">
        <div class="login-wrp ">
            <div class="login-form">
                <form >
                    <div class="row form-wrp">
                        <div class="col-md-7 form-wrp-left">
                            <figure>
                                <img src="./assets/member/images/img-login-left-brown.svg" alt="Shalom">
                            </figure>
                        </div>
                        <div class="col-md-5 form-wrp-right">
                            <div class="login-head-title">
                                <h1>Hi, Welcome</h1>
                                <p>Email Verification</p>
                            </div>

                            <div class="form-inner">
                                <!-- <div class="form-group focus-label mb-1" id="passWord">
                                    <label class="form-label-of" for="Password">New Password</label>
                                    <input autocomplete="off" formControlName="newPassword" id="Password" type="password" class="pswrd-input" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.newPassword">
                                        <span class="error-message"
                                            *ngIf="resetPasswordForm.get('newPassword').hasError(validation.type) && (resetPasswordForm.get('newPassword').dirty || resetPasswordForm.get('newPassword')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div>
                                <div class="form-group focus-label mb-1" id="cpassWord">
                                    <label class="form-label-of" for="cPassword">Confirm Password</label>
                                    <input autocomplete="off" formControlName="confirmPassword" id="cPassword" type="text" class="pswrd-input" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                    <span class="m-form__help" *ngFor="let validation of ValidationMessage.confirmPassword">
                                        <span class="error-message"
                                            *ngIf="resetPasswordForm.get('confirmPassword').hasError(validation.type) && (resetPasswordForm.get('confirmPassword').dirty || resetPasswordForm.get('confirmPassword')?.touched)">
                                            {{validation.message}}
                                        </span>
                                    </span>
                                </div> -->
                                <span class="error">Your email has been successfuly verified. Please click the button below to login to your myShalom account.</span>
                                
                                <!-- <button type="submit" class="btn-common mb-3" (click)="Submit()">Request</button> -->
            
                                <button type="submit" class="btn-common mb-3"(click)="success()">OK</button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
        <ul class="nav-menu-btm">
            <li><a href="/privacy-policy">Privacy Notice</a></li>
            <li><a href="/help">Help</a></li>
            <li><a href="/terms-and-conditions">Conditions of Use</a></li>
        </ul>
    </div>
</section>