<div class="d-flex inner-page-wrp">
    <div class="inner-page-content">
        <!-- <div class="breadcrumb-wrp mb-1">
            <a routerLink="../../home/{{memberId}}">Dashboard</a>
        </div> -->
        <div class="top-strip d-flex btm-border type2 mb-3">
            <h5 class="mb-0 width-1">Events</h5>
            <!-- <a href="javascript:void(0)">View all</a> -->
        </div>
        <div class="bx-white">
            <div class="row events-list pb-4">
                <div class="col-md-4 events-each" *ngFor="let event of listOfEvents">
                    <figure class="text-center mb-0">
                        <img src="{{event.logo_url}}" alt="Shalom ">
                    </figure>
                    <div class="events-desc ">
                        <div class="desc-content-sec" [ngClass]="{'causeClass':event.EventTypeName.length > 23 }">
                            <h5 class="heading-restrict">{{event.EventTypeName}}</h5>
                            <h6 class="d-block location-txt heading-restrict">&nbsp;{{event.Title}}</h6>
                            <div class="hover-bx">
                                <h5>{{event.EventTypeName}}</h5>
                                <h6 class="d-block location-txt">{{event.Title}}</h6>
                            </div>
                        </div>
                        <!-- <p>{{event.EventStartDate}}</p> -->
                        <p>{{event.startMonth}}
                            {{event.startDay}}{{event.startYear == event.endYear? '': ','}} {{event.startYear == event.endYear ? '' : event.startYear}} -
                            {{event.endMonth == event.startMonth ? '' : event.endMonth}} {{event.endDay}}{{','}} {{event.endYear}}</p>
                        <div class="event-btn-wrp d-flex mt-2">
                            <button type="submit" class="btn btn-common reg mr-3" (click)="registerEvent(event.EventStartDate,event.EventTypeName,event.Title)"  [disabled]="event.IsEventClosed == 1 || event.IsMaxEventRegisterExceed == 1">Register</button>
                            <button *ngIf="event.AttachmentDetails != null" type="submit" class="btn btn-border" (click)="downloadFlyer(event.AttachmentDetails?event.AttachmentDetails.FileName:null)">Flyer</button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4 events-each">
                <figure class="text-center mb-0">
                    <img src="./assets/member/images/img-dashbrd-event2.png " alt="Shalom ">
                </figure>
                <div class="events-desc ">
                    <h6 class="d-block location-txt">Canada</h6>
                    <h5>Shalom Retreat</h5>
                    <p>Mar 19 - 21, 2021</p>
                    <div class="event-btn-wrp d-flex mt-2">
                        <button type="submit" class="btn btn-common mr-3">Register</button>
                        <button type="submit" class="btn btn-border">Flayer</button>
                    </div>
                </div>
            </div>
            <div class="col-md-4 events-each">
                <figure class="text-center mb-0">
                    <img src="./assets/member/images/img-dashbrd-event3.png " alt="Shalom ">
                </figure>
                <div class="events-desc ">
                    <h6 class="d-block location-txt">Canada</h6>
                    <h5>Shalom Retreat</h5>
                    <p>Mar 19 - 21, 2021</p>
                    <div class="event-btn-wrp d-flex mt-2">
                        <button type="submit" class="btn btn-common mr-3">Register</button>
                        <button type="submit" class="btn btn-border">Flayer</button>
                    </div>
                </div>
            </div> -->
            </div>
        </div>
    </div>
    <div class="home-right pl-0 ">
        <div class="bx-white connect-bx">
            <h4 class="text-center mb-3 mt-3">Connect with Us</h4>
            <div class="connect-btn-wrp">
                <figure class="text-center mb-2" *ngIf="isSpfMember">
                    <img src="./assets/member/images/img-connect-purple.svg " alt="Shalom ">
                </figure>
                <figure class="text-center mb-2" *ngIf="singleDonationExist == true && isSpfMember == false">
                    <img src="./assets/member/images/img-connect-green.svg " alt="Shalom ">
                </figure>
                <figure class="text-center mb-2" *ngIf="singleDonationExist == false && isSpfMember == false">
                    <img src="./assets/member/images/img-connect-brown.svg " alt="Shalom ">
                </figure>
                <ul class="button-list">
                    <li class="d-flex">
                        <a (click)="modalOpen('prayer')">Prayer Request</a>
                    </li>
                    <li class="d-flex">
                        <a (click)="modalOpen('testimony')">Testimony</a>
                    </li>
                    <li class="d-flex">
                        <a (click)="modalOpen('contact')">Contact Us</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <div class="bx-white live-desc-bx">
        <button type="button" class="btn-live mb-2">Live Now</button>
        <div class="live-desc ">
            <h4>Excepteur sint occaecat cupidatat non</h4>
            <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
        </div>
    </div> -->
    </div>
</div>