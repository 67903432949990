import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-attachmentdetails',
  templateUrl: './attachmentdetails.component.html',
  styleUrls: ['./attachmentdetails.component.scss']
})
export class AttachmentdetailsComponent implements OnInit {
  @Input() dataOfAttachment ;

  constructor(private activeModal:NgbActiveModal) { }

  ngOnInit(): void {
    console.log(this.dataOfAttachment)
  }
  downloadFlyer(link) {
    console.log("attch download",link);
    if (link != null) {
      var win = window.open(link,'_blank');
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        // alert('Please allow popups for this website');
      }
    }

  }
  close(){
    this.activeModal.close({ 'result': true ,'data':false});
  }
}
