import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventParticipantdetailComponent } from '../event-participantdetail/event-participantdetail.component';

@Component({
  selector: 'app-event-participantlist',
  templateUrl: './event-participantlist.component.html',
  styleUrls: ['./event-participantlist.component.scss']
})
export class EventParticipantlistComponent implements OnInit {
  isSpfMember;
  singleDonationExist:boolean = false;
  participants = [];
  @Input() eventForm: FormGroup;
  @Input() eventId;
  @Input() IsAccommodationRequired;
  @Input() streamList ;
  @Input() isAgeRequired;
  @Input() SalutationList;
  @Input() Genders;
  @Input() memberId;
  constructor(public activeModal: NgbActiveModal, public modalService: NgbModal) { }

  ngOnInit(): void {
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.singleDonationExist = currentUser.singleDonationExist;
   this.latestParticipants();
  }
  removeParticipant(i){
    console.log(i,JSON.parse(localStorage.getItem('participant')))
    let participants = JSON.parse(localStorage.getItem('participant'))
    let updatedParticipants = participants.splice(i,1);
    console.log(participants)
    localStorage.setItem('participant', JSON.stringify(participants))
    this.latestParticipants();
  }
  latestParticipants(){
    this.participants = []
    let participant = JSON.parse(localStorage.getItem('participant'))
    if(participant && participant.length > 0){
      participant.forEach((element,index) => {
        this.participants[index] = element[0].ParticipantDetail
        let salutation = this.SalutationList.filter(x => { return x.Id == element[0].ParticipantDetail['SalutationId'] })
        if(salutation[0]){
          element[0].ParticipantDetail['SalutationName'] = salutation[0]['Name']

        }
      
       });
    }
   
  }
  openAddParticipant(){
    // this.activeModal.dismiss()
    const modalRef = this.modalService.open(EventParticipantdetailComponent, {
      size: "lg"
  });
  modalRef.componentInstance.eventId = this.eventForm.controls.Event.value.EventId;
        modalRef.componentInstance.IsAccommodationRequired = this.eventForm.controls.Event.value.IsAccommodationRequired;
        modalRef.componentInstance.eventForm = this.eventForm;
        modalRef.componentInstance.streamList = this.streamList;
        modalRef.componentInstance.isAgeRequired = this.isAgeRequired;
        modalRef.componentInstance.SalutationList = this.SalutationList;
        modalRef.componentInstance.Genders = this.Genders;
        modalRef.componentInstance.memberId = this.memberId;
        modalRef.result.then(
          result => {
                console.log("result-----modal",result)

              if (result.result== true) {
                this.latestParticipants();

              }
          });
  }
  close(value){
    this.activeModal.close({ 'result': value });
  }
}
