import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { ContactUsPopupComponent } from "./main/contact-us-popup/contact-us-popup.component";
import { AlertPopupComponent } from "./main/alert-popup/alert-popup.component";
import { SharedService } from '../../_services/shared.service';

@Component({
  selector: 'app-dashboard-theme',
  templateUrl: './dashboard-theme.component.html',
  styleUrls: ['../../../assets/member/css/style.css', './dashboard-theme.component.scss']
})
export class DashboardThemeComponent implements OnInit {
  response: { message: string; };
  currentMsgFromChild1ToChild2: any;
  message: any;
  hideSideBar:boolean = false;
  isSpfMember:boolean;
  singleDonationExist:boolean = false;
  constructor(public modalService: NgbModal,public sharedservice:SharedService) { }
  // @ViewChild(HomeMemberComponent) child;
  ngOnInit(): void {
    this.sharedservice.sideBarData.subscribe(data=>{
      console.log("side hidebard daat---",data);
      if(data){
        this.hideSideBar = data;
      }else{
        this.hideSideBar = false;
      }
    });
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.singleDonationExist = currentUser.singleDonationExist;
    console.log("single----donation----",this.isSpfMember,this.singleDonationExist)
  }

  modalOpen() {

    const modalRef = this.modalService.open(ContactUsPopupComponent, {
        size: "lg"
    });
}
AlertModal() {
  this.response = {message: "test data"}

    const modalRef = this.modalService.open(AlertPopupComponent, {
        size: "sm"
    });
    modalRef.componentInstance.data = this.response;

}
}
