import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllvideolistComponent } from './allvideolist.component';
import { MainComponent } from '../main.component';
import { RouterModule, Routes } from '@angular/router';
import { MainModule } from '../main.module';

const routes: Routes = [
  {
      'path': '',
      'component': MainComponent,
      'children': [
          {
              'path': '',
              'component': AllvideolistComponent,
          },
      ],
  },
];


@NgModule({
  declarations: [AllvideolistComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MainModule,
  ]
})
export class AllvideolistModule { }
