import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../../_services/shared.service';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { VideoPopupComponent } from '../video-popup/video-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { AddMemberComponent } from '../add-member/add-member.component';
import { ContactUsPopupComponent } from '../contact-us-popup/contact-us-popup.component';
// import { PrayerRequestComponent } from '../prayer-request/prayer-request.component';
// import { TestimonyComponent } from '../testimony/testimony.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css', './home.component.scss']
})
export class HomeComponent implements OnInit {
  listOfCauses: any;
  listOfEvents: any;
  listOfVideos: any;
  listOfFamilyMem: any;
  recentDonation: any;
  listOprogram: any;
  routeParam;
  memberId;
  public response
    languageList: any;
    formData: any;
    countryId: string;

  constructor(public sharedService: SharedService, public _crud: CommonCrudService, public modalService: NgbModal,public activeRoute:ActivatedRoute,        private router: Router
    ) { }

  ngOnInit(): void {
    this.sharedService.fetchPathData('dashboard', 'home');
    this.routeParam = this.activeRoute.snapshot;
    console.log("params-----",this.routeParam);
    this.memberId = this.routeParam.params['memberId'];
    this.countryId = localStorage.getItem('countryId')
    this.causeList();
    this.eventList();
    this.videoList();
    this.familyMembers();
    this.recentDonations();
    this.programList();
  }
  causeList() {
    let params = {
        url: "frontend-site/cause/list", params: { CountryId: this.countryId }
    }
    this._crud.getData(params).subscribe(res => {

        console.log("cause list----", res);
        this.listOfCauses = res['data'];
    });
}
eventList() {
    let params = {
        url: "frontend-site/event/list", params: { MemberId: this.memberId }
    }
    this._crud.getData(params).subscribe(res => {

        console.log("event list----", res);
        this.listOfEvents = res['data'];

    });
}
videoList() {
    let params = {
        url: "frontend-site/video-profile", params: { MemberId: this.memberId }
    }
    this._crud.getData(params).subscribe(res => {

        console.log("video list----", res);
        this.listOfVideos = res['data']['SidebarRight'];

    });
    let param = {
        url: "frontend-site/language-list"
    }
    this._crud.getData(param).subscribe(res => {

        console.log("language list----", res);
       this.languageList = res['data'];

    });
}
familyMembers() {

    let params = {
        url: "frontend-site/fetch-family-member", params: { PrimaryMemberId: this.memberId }
    }
    this._crud.getData(params).subscribe(res => {

        console.log("family member list----", res);
        this.listOfFamilyMem = res['data'];
        this.listOfFamilyMem.forEach(element => {
            if (element.SpfId == null) {
                element.addnew = 1;
            } else {
                element.addnew = 0;

            }
        });
    });
}
recentDonations() {

    let params = {
        url: "frontend-site/recent-donation/list", params: { MemberId: this.memberId }
    }
    this._crud.getData(params).subscribe(res => {

        console.log("recent donation list----", res);
        this.recentDonation = res['data'];
    });
}
videoModal(url) {
    const modalRef = this.modalService.open(VideoPopupComponent, {
        size: "lg"
    });
    this.response = {url: url}

    modalRef.componentInstance.user = this.response;

}
programList(){
  
    let params = {
        url: "frontend-site/program-list"
    }
    this._crud.getData(params).subscribe(res => {

        console.log("program list----", res);
        this.listOprogram = res['data'];
    });  
}
addMember(form = null){
    console.log(form);
    this.formData = form;
    const modalRef = this.modalService.open(AddMemberComponent, {
        size: "lg"
    }); 
    modalRef.componentInstance.formData = this.formData;
    modalRef.result.then(
        result => {
          console.log("result-----modal",result)
            if (result.result) {
                // /clearing all forms.
                this.familyMembers();
            }
        });
}
donate(id:any = null, name:any = null){
    console.log("this.routeParam = this.activeRoute.snapshot;", id)
    this.router.navigate([`memberDashboard/mydonation/`+ this.memberId +`/donation` ] , { queryParams: { causeId: id , causeName: name} });

}
donateFRomFamily( memberId:any = null){
    this.router.navigate([`memberDashboard/mydonation/`+ this.memberId +`/donation` ] , { queryParams: {memberId: memberId} });

}
subscription(subType,memberId=null){
      this.router.navigate(["/memberDashboard/mysubscription/"+this.memberId+"/subscription"], { queryParams: {subType: subType,memberId:memberId}});
    
    
}
registerEvent(eventDate,eventType,eventName){
    this.router.navigate(["/memberDashboard/myeventregistration/"+this.memberId+"/eventregistration"], { queryParams: {eventDate: eventDate,eventType:eventType,eventName:eventName}});
}
modalOpen() {

    const modalRef = this.modalService.open(ContactUsPopupComponent, {
        size: "lg"
    });
    modalRef.componentInstance.memberId = this.memberId;
}
// modalOpenPrayer(){
//     const modalRef = this.modalService.open(PrayerRequestComponent, {
//         size: "lg"
//     });
//     modalRef.componentInstance.memberId = this.memberId;
// }
// modalOpenTestimony(){
//     const modalRef = this.modalService.open(TestimonyComponent, {
//         size: "lg"
//     });
//     modalRef.componentInstance.memberId = this.memberId;
// }
}
