<div class="susbscription-content">
    <div class="bx-white table-min-ht pd-btm">
        <div class="top-strip d-flex">
            <!-- <figure class="mb-0">
                <img src="./assets/member/images/icon-office.svg" alt="Shalom">
            </figure>
            <h5 class="ml-3 mb-0 width-1">My Donations</h5> -->
            <div class=" d-flex pagination-btn">
                <button type="button" class="btn-common btn-width-3" (click)="makeDonation()" >Make a Donation</button>
            </div>
            <div class="d-flex pagination-wrp width-2">
                <div class="btn-right-holder width-1 ">
                    <div class="year-select mr-2">
                        <!-- <select class="form-control mr-2" (change)="onChange($event.target.value)"  [(ngModel)]="selectedYear">
                            <option value="">All</option>
                            <option *ngFor="let cause of yearList; index i">{{cause}}</option>
                        </select> -->
                        <button type="button" class="btn btn-width-1"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedYear == '' ? 'All':selectedYear}}</button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item"  (click)="onChange('')">All</a>
                            <a *ngFor="let year of yearList; index i" class="dropdown-item" (click)="onChange(year)">{{year}}</a>
                        </div>
                    </div>
                    <button id="print" type="button" class="btn-print" (click)="export()">Print</button>
                </div>
                <div class="pagination-list">
                    <mat-paginator [pageSizeOptions]="[10]" [hidePageSize]="true" [ngClass]="'pagination-holder'"></mat-paginator>
                    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
                </div>
            </div>
        </div>
        <!-- datatable starts here------ -->
        <div class="table-responsive-wrp ">
            <mat-table #table [dataSource]="dataSource">
                <ng-container matColumnDef="PrimaryCountryTime">
                    <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.PrimaryCountryTime}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="CauseName">
                    <mat-header-cell *matHeaderCellDef> Donation Cause </mat-header-cell>
                    <mat-cell *matCellDef="let element"> 
                        <div class="desc-content-sec" [ngClass]="{'causeClass':element.CauseName.length > 28}">
                            <h5 class="heading-restrict"> {{element.CauseName}}  </h5>
                            <div class="hover-bx">
                                <h5> {{element.CauseName}} </h5>
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="MemberName">
                    <mat-header-cell *matHeaderCellDef> Member </mat-header-cell>
                    <mat-cell *matCellDef="let element"> 
                        <div class="desc-content-sec" [ngClass]="{'causeClass':element.MemberName.length > 20}">
                            <h5 class="heading-restrict"> {{element.MemberName}} </h5>
                            <div class="hover-bx">
                                <h5> {{element.MemberName}} </h5>
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Amount">
                    <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                    <mat-cell *matCellDef="let element"> <span *ngIf="element.CreditType == 2" style="color: red;">{{'('}}{{element.Amount}} {{element.CurrencyCode}}{{')'}}</span>
                        <span *ngIf="element.CreditType != 2">{{element.Amount}} {{element.CurrencyCode}}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="PaymentMethod">
                    <mat-header-cell *matHeaderCellDef> Payment Method </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.LastFourDigit}} <img [src]="element.PaymentMethodUrl" alt="Shalom"> </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="CreditTypeText">
                    <mat-header-cell *matHeaderCellDef> Card Type </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <img [src]="element.PaymentMethodUrl" alt="Shalom">
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Last4">
                    <mat-header-cell *matHeaderCellDef> Last4 </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.LastFourDigit}} </mat-cell>
                </ng-container> -->
                <ng-container matColumnDef="Expiry">
                    <mat-header-cell *matHeaderCellDef> Expiry </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.ExpiryDate}} </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </div>
    </div>
    <!-- <div class="bx-white">
        <div class="row pagination-wrp p-3">
            <div class="pagination-list col-md-7">
                <mat-paginator [pageSizeOptions]="[7]" [hidePageSize]="true" [ngClass]="'pagination-holder'"></mat-paginator>
             
            </div>
            <div class=" col-md-5 d-flex pagination-btn">
                <button type="button" class="btn-common btn-width-3" routerLink="donation">MAKE DONATION</button>
            </div>
        </div>
    </div> -->
</div>