import { Injectable } from '@angular/core';
import { HttpClient ,HttpParams} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry} from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Helpers } from '../helpers';
// import { DatePipe } from '@angular/common';


@Injectable()

export class CommonCrudService {
  splittedData;
  constructor(private http: HttpClient,private helper: Helpers) { }

  getData(data,url=null) {
    
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let options = this.helper.jwt();
    if (data.params) {
       options.params = data.params;
    }
    if(!url){
      return this.http
      .get(this.helper.ApiBaseUrl + data.url, options)
      .pipe(catchError(e => {
          let data = JSON.parse(e._body);
          if (e.status == 403) {
          }else {
             
          }
          return Observable.throw(e);
      }));
    }else{
      return this.http
      .get(data.url, options)
      .pipe(catchError(e => {
          let data = JSON.parse(e._body);
          if (e.status == 403) {
          }else {
             
          }
          return Observable.throw(e);
      }));
    }
  
  }
  postData(data) {
      
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let options = this.helper.jwt();
    return this.http
        .post(this.helper.ApiBaseUrl + data.url, data.params,options)
        .pipe(catchError(e => {
            let data = JSON.parse(e._body);
            if (e.status == 403) {
            }else {
              
            }
            return Observable.throw(e);
        }));
    }
    spaceSplit(data) {
      return this.splittedData = data.split(" ");
    }
    
    // getBrainTreeToken(CountryPaymentSettingId, memberID) {

    //   return this.http.get(`${this.helper.ApiBaseUrl}payment-method/client-token/${CountryPaymentSettingId}/${memberID}`, this.helper.jwt());
    // }
    getBrainTreeToken(data) {
    
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      let options = this.helper.jwt();
      if (data.params) {
         options.params = data.params;
      }
  
      return this.http
          .get(this.helper.ApiBaseUrl + data.url, options)
          .pipe(catchError(e => {
              let data = JSON.parse(e._body);
              if (e.status == 403) {
              }else {
                 
              }
              return Observable.throw(e);
          }));
    }
    verify() {
      return this.http
          .get(this.helper.ApiBaseUrl + "frontend-site/validate-token", this.helper.jwt())
          .pipe(map((response: any) => response));
  }
  familyMemberCreate(param, memberId,paramComm) {
    let body = new FormData();
    body.append('SalutationId', (param.SalutationId == null? '': param.SalutationId ))
    body.append('RelationshipId', param.RelationshipId)
    body.append('FirstName', param.FirstName)
    body.append('LastName', param.LastName)
    body.append('MobileNumber', param.MobileNumber)
    body.append('SecondaryNumber', param.SecondaryNumber)
    body.append('Gender', (param.Gender == null ? "": param.Gender))
    body.append('Dob', (param.Dob == undefined?'':param.Dob.singleDate.formatted))
    body.append('Photo', param.Photo)
    body.append('Email', param.Email);
    body.append('PreferredLanguageOfCommunication', paramComm['PreferredLanguageOfCommunication'])
    body.append('PreferredModeOfContact',  paramComm['PreferredModeOfContact'])
    return this.http.post(this.helper.ApiBaseUrl + `frontend-site/family-member/${memberId}/add`, body, this.helper.jwt())
    .pipe(map((response: any) => response));
  }
  familyMemberUpdate(param, memberId, familymemberId,paramComm) {

    let body = new FormData();
    body.append('SalutationId', (param.SalutationId == null? '': param.SalutationId ))
    body.append('RelationshipId', param.RelationshipId)
    body.append('FirstName', param.FirstName)
    body.append('LastName', param.LastName)
    body.append('MobileNumber', param.MobileNumber)
    body.append('SecondaryNumber', param.SecondaryNumber)
    body.append('Gender', (param.Gender == null ? "": param.Gender))
    body.append('Dob', (param.Dob == undefined?'':param.Dob.singleDate.formatted))
    body.append('Photo', param.Photo)
    body.append('IsPhotoRemovedFlag', param.IsPhotoRemovedFlag)
    body.append('Email', param.Email);
    body.append('PreferredLanguageOfCommunication', paramComm['PreferredLanguageOfCommunication'])
    body.append('PreferredModeOfContact',  paramComm['PreferredModeOfContact'])
// family-member/{primary_member_id}/edit/{member_id}
    return this.http.post(this.helper.ApiBaseUrl + `frontend-site/family-member/${memberId}/update/${familymemberId}
    `, body, this.helper.jwt())
  }
  public postRequest(image,param) {
    //based on param['type ] chnge form data
    const formData = new FormData();
    formData.append('firstname',param.data.FirstName);
    formData.append('lastname',param.data.LastName);
    formData.append('phone',param.data.MobileNumber);
    formData.append('email',param.data.Email);
    formData.append('country',param.data.CountryName);
    formData.append('category',param['type'] );
    formData.append('address',param.data.address);

    if(param['type'] == 'Prayer'){ 
      // formData.append('image', image);
      formData.append('message', param.prayerRequest);
      formData.append('disclaimer', param.publishPermission);
    }else{
      formData.append('file', image);
      formData.append('testimonial', param.testimony);
      formData.append('testimonypermission', param.publishPermission);
    }
    return this.http.post(param.url, formData,this.helper.jwt())
    .pipe(map((response: any) => response));
}
addressMemberUpdate(param, member_id) {

  let body = new FormData();
  body.append('AddressType', param.AddressType)
  body.append('Address1', param.Address1)
  body.append('Address2', param.Address2)
  body.append('City', param.City)
  body.append('CountryId', param.CountryId)
  body.append('StateId', param.StateId)
  body.append('Zip', param.Zip)
  return this.http.post(this.helper.ApiBaseUrl + `frontend-site/member/${member_id}/update-address`, body, this.helper.jwt())
  .pipe(map((response: any) => response));
}
addressMemberCreate(param, memberId) {

  let body = new FormData();
  body.append('AddressType', param.AddressType)
  body.append('Address1', param.Address1)
  body.append('Address2', param.Address2)
  body.append('City', param.City)
  body.append('CountryId', param.CountryId)
  body.append('StateId', param.StateId)
  body.append('Zip', param.Zip)

  return this.http.post(this.helper.ApiBaseUrl + `frontend-site/member/${memberId}/add-address`, body, this.helper.jwt())
  .pipe(map((response: any) => response));
}
getCountries(value = "") {
  return this.getData({
      url: "frontend-site/country/list",
  });
}
postContactInfo(param) {
  console.log("do at crud-----",param['params'].Dob)
  let body = new FormData();
  body.append('Gender', (param['params'].Gender == null? '': param['params'].Gender))
    body.append('Dob', (param['params'].Dob == undefined ? '': param['params'].Dob.singleDate.formatted))
  body.append('SalutationId', (param['params'].SalutationId == null? '':param['params'].SalutationId))
  body.append('FirstName', param['params'].firstName)
  body.append('LastName', param['params'].lastName)
  body.append('MobileNumber', param['params'].primaryNumber)
  body.append('SecondaryNumber',  param['params'].secondaryNumber)
  body.append('PreferredLanguageOfCommunication', param['PreferredLanguageOfCommunication'])
  body.append('PreferredModeOfContact',  param['PreferredModeOfContact'])
  body.append('Photo', param['params'].Photo)
  body.append('IsPhotoRemovedFlag', param['params'].IsPhotoRemovedFlag)
  body.append('Email', param['params'].email);
// family-member/{primary_member_id}/edit/{member_id}
  return this.http.post(this.helper.ApiBaseUrl + param['url']
  , body, this.helper.jwt())
}
}