<div class="contact-alert">
    <div class="alert-top-bar">
        <div class="row align-item-center">
            <div class="col-7 col-md-7">
                <h5>Add Address</h5>
            </div>
            <div class="col-5 col-md-5 text-right">
                <figure class="mb-0">
                    <img src="./assets/member/images/dashboard-logo.svg " alt="Shalom ">
                </figure>
                <button type="button" class="close">
                    <span aria-hidden="true">
                        &times;
                    </span>
                </button>
            </div>
        </div>
    </div>
    <form>
        <div class="contact-form">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group focus-label">
                        <label class="form-label-of" for="">Street Address</label>
                        <input [formControl]="addressCreateForm.controls.Address1" class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.Address1">
                            <span class="error-message"
                                *ngIf="addressCreateForm.get('Address1').hasError(validation.type) && (addressCreateForm.get('Address1').dirty || addressCreateForm.get('Address1')?.touched)">
                                {{validation.message}}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group focus-label">
                        <label class="form-label-of" for="">Apt, Suite, Bldg (Optional)</label>
                        <input [formControl]="addressCreateForm.controls.Address2"  class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.Address2">
                            <span class="error-message"
                                *ngIf="addressCreateForm.get('Address2').hasError(validation.type) && (addressCreateForm.get('Address2').dirty || addressCreateForm.get('Address2')?.touched)">
                                {{validation.message}}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group focus-label">
                        <label class="form-label-of" for="">City</label>
                        <input [formControl]="addressCreateForm.controls.City"  class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.City">
                            <span class="error-message"
                                *ngIf="addressCreateForm.get('City').hasError(validation.type) && (addressCreateForm.get('City').dirty || addressCreateForm.get('City')?.touched)">
                                {{validation.message}}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group focus-label">
                        <ng-select [formControl]="addressCreateForm.controls.StateId" [items]="StatesList" id="BillingAddress-StateId" placeholder="State" bindValue="StateId" bindLabel="StateName" [(ngModel)]="state" [clearable]="false">
                        </ng-select>
                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.StateId">
                            <span class="error-message"
                                *ngIf="addressCreateForm.get('StateId').hasError(validation.type) && (addressCreateForm.get('StateId').dirty || addressCreateForm.get('StateId')?.touched)">
                                {{validation.message}}
                            </span>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group focus-label">
                        <label class="form-label-of" for="">Postal Code</label>
                        <input [formControl]="addressCreateForm.controls.Zip"  class="form-control" id="" type="text" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.Zip">
                            <span class="error-message"
                                *ngIf="addressCreateForm.get('Zip').hasError(validation.type) && (addressCreateForm.get('Zip').dirty || addressCreateForm.get('Zip')?.touched)">
                                {{validation.message}}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 5px;">
                <div class="col-md-6" >
                    <button type="submit" class="btn-border btn-modal" style="float:right;">Cancel</button>
                </div>
                <div class="col-md-6">
                    <button type="submit" class="btn-filled btn-modal" (click)="save()">Save</button>
                </div>
            </div>
                <!-- <div class="col-md-12 mt-2 mb-3 d-flex">
                    
                   
                </div> -->

            
        </div>
    </form>
</div>