<!-- <a class="nav-link " routerLink="../.."><i class="la la-arrow-left"></i>Subscription List</a> -->
<div class="bx-white cancel-wrp">
    <div class="bx-top-strip">
        <h2>Cancel Subscription</h2>
    </div>
    <div class="cancel-inner">
        <div class="row ">
            <div class="col-lg-6">
                <ul>
                    <li>
                        <span>Subscriber Name</span>
                        <strong>{{subscriptionData.SubscriberName}}</strong>
                    </li>
                    <li>
                        <span>Publication Name</span>
                        <strong>{{subscriptionData.PublicationName}}</strong>
                    </li>
                    <li>
                        <span>Subscription Date</span>
                        <strong>{{subscriptionData.SubscriptionDate | date: 'dd MMM yyy'}}</strong>
                    </li>
                    <li>
                        <span>Current Status</span>
                        <strong>{{subscriptionData.Status}}</strong>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6">
                <ul>
                    <li>
                        <span>Number of Issues</span>
                        <strong>{{subscriptionData.TotalIssues}}</strong>
                    </li>
                    <li>
                        <span>Number of Issues Sent</span>
                        <strong>{{subscriptionData.TotalIssueSent}} </strong>
                    </li>
                    <li>
                        <span>Amount Received</span>
                        <strong>{{subscriptionData.ReceivedAmount}} {{subscriptionData.CurrencyCode}}</strong>
                    </li>
                    <li>
                        <span>Amount to be Refunded</span>
                        <strong>{{subscriptionData.AdvanceAmount}} {{subscriptionData.CurrencyCode}}</strong>
                    </li>
                </ul>
            </div>
        </div>
        <form [formGroup] = "cancelSubscriptionForm">
        <div class="d-flex bx-grey align-items-center mob-mb" *ngIf="showRefundOption">
            <!-- <span class="radio-label mob-mb">Payment Method</span> -->
            <div class="form-check radio-btn-wrp">
                <input type="radio" formControlName="RefundType" class="form-check-input" id="Donate" name="fund-type-radio" value="2" (click)="changeRefundType()">
                <label class="form-check-label" for="Donate">  Donate the Refundable Amount </label></div>
            <div class="form-check radio-btn-wrp pl-0" *ngIf="this.subscriptionData?.IsSingleElectronicTransaction != 0">
                <input type="radio" formControlName="RefundType" class="form-check-input" id="Refund" name="fund-type-radio" value="1" (click)="changeRefundType()">
                <label class="form-check-label " for="Refund" > Process Refund </label>
            </div>
            <div class="form-check radio-btn-wrp pl-0">
                <input type="radio" formControlName="RefundType" class="form-check-input" id="Later" name="fund-type-radio" value="0" (click)="changeRefundType()">
                <label class="form-check-label " for="Later" > Refund Later </label>
            </div>
        </div>
        <span class="col-sm-12 m-form__help">
            <span class="error-message" *ngIf="!selectRefundType">
                Please select your refund type
            </span>
        </span>
        <div class="">
            <button type="button" class="btn-back-blue mr-3" routerLink="../..">Back</button>
            <button type="button" class="btn-common" (click)="cancelSubscription()"><span class="span-loader-padding">Cancel Subscription</span>  <i *ngIf="isLoading" class="spinner-border"></i></button>
        </div>
        </form>
    </div>
</div>
<!-- Doubts
2.advance amount,recieved amount,refunded amount
issue
bill pay payment status not paid
 -->