<section class="dashboard-top-wrp">
    <div class="container">
        <div class="head-bar row">
            <div class="col-3 col-md-3">
                <img src="./assets/member/images/dashboard-logo.svg" alt="Shalom">
            </div>
            <div class="col-9 col-md-9">
                <div class="d-flex justify-content-end">
                    <ul class="logo-list d-flex">
                        <li><img src="./assets/member/images/top-logo1.png" alt="Shalom"></li>
                        <li><img src="./assets/member/images/top-logo2.png" alt="Shalom"></li>
                        <li><img src="./assets/member/images/top-logo3.png" alt="Shalom"></li>
                        <li><img src="./assets/member/images/top-logo4.png" alt="Shalom"></li>
                        <li><img src="./assets/member/images/top-logo5.png" alt="Shalom"></li>
                        <li><img src="./assets/member/images/top-logo6.png" alt="Shalom"></li>
                    </ul>
                    <!-- old code -->
                    <!-- <div class="log-prof">
                        <div class="d-flex">
                            <div class="log-prof-desc">
                                <span>Hi, {{profileData.FirstName}}</span>
                                <h6 class="mb-0">My <strong>shalom</strong></h6>
                            </div>
                            <figure>
                                <img src="./assets/member/images/img-prof2.png" alt="Shalom">
                            </figure>
                        </div>
                    </div> -->
                    <!-- new code -->
                    <div class="log-prof">
                        <div class="d-flex dropdown-wrp">
                            <a href="#" class="" data-toggle="dropdown" aria-expanded="false">
                                <div class="log-prof-desc d-flex">
                                    <div class="log-prof-name">
                                        <span>Hi, {{profileData.FirstName}}</span>
                                        <h6 class="mb-0">My <strong>shalom</strong></h6>
                                    </div>
                                    <figure>
                                        <img src="./assets/member/images/img-prof2.png" alt="Shalom">
                                        <!--   <img [src]="profileData.PhotoUrl" alt="Shalom">  need to style image-->
                                    </figure>
                                </div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-87px, 33px, 0px);">
                                <span>Hi, {{profileData.FirstName}} {{profileData.LastName}}</span>
                                <button class="btn btn-promary" (click)="logOut()">LogOut</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>