<section class="top-bar">
    <div class="container">
        <div class="tab-wrp" *ngIf="tab != 'payment'">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" routerLink="home/{{this.memberId}}" role="tab" [ngClass]="{ 'active':activeTab==='home'}">Dashboard</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" routerLink="mydonation/{{this.memberId}}" role="tab" [ngClass]="{ 'active':activeTab==='mydonation'}">My Donations</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" routerLink="mysubscription/{{this.memberId}}" role="tab" [ngClass]="{ 'active':activeTab==='mysubscription'}">My subscription</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" routerLink="myeventregistration/{{this.memberId}}" role="tab" [ngClass]="{ 'active':activeTab==='myeventregistration'}">Event Registrations</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" routerLink="settings/{{this.memberId}}" role="tab" [ngClass]="{ 'active':activeTab==='settings'}">Settings</a>
                </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content">

                <div class="tab-pane" id="donation" role="tabpanel">
                    <p>My Donations</p>
                </div>
                <div class="tab-pane" id="subscription" role="tabpanel">
                    <p>My subscription</p>
                </div>
                <div class="tab-pane" id="registration" role="tabpanel">
                    <p>Event Registrations</p>
                </div>
                <div class="tab-pane" id="settings" role="tabpanel">
                    <p>Settings</p>
                </div>
            </div>
        </div>
        <div class="tab-wrp top-bar-type2" *ngIf="tab == 'payment' && activeTab == 'donation'">

            <a class="nav-link " routerLink="mydonation/{{this.memberId}}"><i class="la la-arrow-left"></i>Donation</a>
        </div>
        <div class="tab-wrp top-bar-type2" *ngIf="tab == 'payment' && activeTab == 'subscription'">

            <a class="nav-link" routerLink="mysubscription/{{this.memberId}}"><i class="la la-arrow-left"></i>Subscription</a>
        </div>
        <div class="tab-wrp top-bar-type2" *ngIf="tab == 'payment' && activeTab == 'event'">

            <a class="nav-link" routerLink="myeventregistration/{{this.memberId}}"><i class="la la-arrow-left"></i>Event Registration</a>
        </div>
        <div class="tab-wrp top-bar-type2" *ngIf="tab == 'cancel' && activeTab == 'cancel-subscription'">

            <a class="nav-link" routerLink="mysubscription/{{this.memberId}}"><i class="la la-arrow-left"></i>Cancel Subscription</a>
        </div>
        <div class="tab-wrp top-bar-type2" *ngIf="tab == 'cancel' && activeTab == 'cancel-event'">

            <a class="nav-link" routerLink="myeventregistration/{{this.memberId}}"><i class="la la-arrow-left"></i>Cancel Event Registration</a>
        </div>
        <div class="tab-wrp top-bar-type2" *ngIf="tab == 'payment' && activeTab == 'recurringdonation'">

            <a class="nav-link" routerLink="settings/{{this.memberId}}"><i class="la la-arrow-left"></i>Update Recurring Donation</a>
        </div>
    </div>
</section>