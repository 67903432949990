import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineAll } from 'rxjs/operators';
import { CommonCrudService } from 'src/app/_services/common-crud.service';
import { ContactUsPopupComponent } from '../contact-us-popup/contact-us-popup.component';
import { VideoPopupComponent } from '../video-popup/video-popup.component';

@Component({
  selector: 'app-allvideolist',
  templateUrl: './allvideolist.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css','./allvideolist.component.scss']
})
export class AllvideolistComponent implements OnInit {
  routeParam: any;
  memberId: any;
  isSpfmember: string;
  listOfVideos: any;
  response: { url: any; };
  isSpfMember:boolean;
  singleDonationExist:boolean = false;
  videoChunkArray = [];
  videoChunkLength = 1;
  count = 0;
  loadVideo:boolean = false;
  videoSplitArray = [];
  constructor(public activeRoute:ActivatedRoute,public _crud: CommonCrudService, public modalService: NgbModal) { }

  ngOnInit(): void {
    this.isSpfmember = localStorage.getItem('IsSpfMember');

    this.routeParam = this.activeRoute.snapshot;
    this.memberId = this.routeParam.params['memberId']; 
    this.videoList();
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
	  this.singleDonationExist = currentUser.singleDonationExist;
   }
   videoList() {
    let params = {
        url: "frontend-site/video-profile", params: { MemberId: this.memberId, IsSpfMember: (this.isSpfmember == 'true'? 1:0)}
    }
    this._crud.getData(params).subscribe(res => {
  
        // console.log("video list----", res);
        this.listOfVideos = res['data'];
        if(this.listOfVideos.length > 6){
          // array chunking
          var i,j, temporary, chunk = 6;
          for (i = 0,j = this.listOfVideos.length; i < j; i += chunk) {
              temporary = this.listOfVideos.slice(i, i + chunk);
              this.videoChunkArray.push(temporary)
              // do whatever
          }
          // console.log("temp array----",this.videoChunkArray);
        }else{
          this.videoChunkArray.push(this.listOfVideos)
        }
        this.videoChunkLength = this.videoChunkArray.length;
        // console.log("chunk array length-----,",this.videoChunkLength)
  
    });
    
  }
  videoModal(url) {
    const modalRef = this.modalService.open(VideoPopupComponent, {
        size: "lg"
    });
    this.response = {url: url}
  
    modalRef.componentInstance.user = this.response;
  
  }
  modalOpen(type) {

    const modalRef = this.modalService.open(ContactUsPopupComponent, {
        size: "lg"
    });
    modalRef.componentInstance.memberId = this.memberId;
    modalRef.componentInstance.type = type;
  }
  loadMoreVideo(){
    // increse count
    this.loadVideo = true;
    this.count = this.count +1 ;
    console.log("count incrmnt-----",this.count,this.videoChunkArray[this.count]);
    
    var i,j;
    for (i = 0,j = this.videoChunkArray[this.count].length; i < j; i ++) {
      this.videoSplitArray.push(this.videoChunkArray[this.count][i]);
        // do whatever
    }
    console.log("vdo splt attay----",this.videoSplitArray)
  }
}
