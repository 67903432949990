import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../../../_services/shared.service';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoPopupComponent } from '../video-popup/video-popup.component';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['../../../../../assets/member/css/style.css', './side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
    routeParam: any;
    keySection: boolean = true;
    memberProfile: any;
    routeParams;
    memberId;
    listOfVideos: any;
    public response

    constructor(public activeRoute: ActivatedRoute, public sharedService: SharedService, public _crud: CommonCrudService,public modalService: NgbModal) { }

    ngOnInit() {
        this.routeParam = this.activeRoute.snapshot['_urlSegment'].segments[1].path;
        this.routeParams = this.activeRoute.snapshot['_urlSegment'].segments[2].path;
        console.log("paramsssss-----",this.routeParams);
        this.memberId = this.routeParams;
        this.sharedService.pathInfo.subscribe(msg => {
            // console.log("msg path----->",msg)
            this.routeParam = msg;
            if (msg && msg != 'home') {
                // console.log("not equal home")
                this.keySection = false;
            } else if (msg && msg == 'home') {
                this.keySection = true;
            }
        });

        let params = {
            url: "frontend-site/view-member", params: { MemberId: this.memberId }
        }
        this._crud.getData(params).subscribe(res => {
            this.memberProfile = res['data']['MemberProfile'];
            if (this.memberProfile.CurrencyCode == "USD") {
                this.memberProfile.CurrencySymbol = "$";
            }
            // console.log("side-bar data----", res);

        });
this.videoList();
    }
    videoList() {
        let params = {
            // url: "frontend-site/video-profile", params: { MemberId: '1602482417EPH' }
            url: "frontend-site/video-profile", params: { MemberId: this.memberId }

        }
        this._crud.getData(params).subscribe(res => {
    
            // console.log("video list----", res);
            this.listOfVideos = res['data']['SidebarLeft'];
    
        });
    }
    videoModal(url) {
        const modalRef = this.modalService.open(VideoPopupComponent, {
            size: "lg"
        });
        this.response = {url: url}
    
        modalRef.componentInstance.user = this.response;
    
    }
}
