import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css','./slide.component.scss']
})
export class SlideComponent implements OnInit {
  startIndex = 0;
  Imagedata;
  Data;

  constructor() { }

  ngOnInit(): void {
    this.Data = [
      { 'imageUrl': "../../../../../assets/member/images/img-publication.png", "date": "Oct 21-22", "name": "Shalom Times1" },
      { 'imageUrl': "../../../../../assets/member/images/img-publication.png", "date": "Oct 22-23", "name": "Shalom Times2" },
      { 'imageUrl': "../../../../../assets/member/images/img-publication.png", "date": "Oct 23-24", "name": "Shalom Times3" }
  ];
  this.Imagedata = ["../../../../../assets/member/images/img-publication.png", "../../../../../assets/member/images/img-publication.png", "../../../../../assets/member/images/img-publication.png"];

  this.Repeat();
  }
  Repeat() {
    setTimeout(() => {
        this.__FunctionSlide();
        this.Repeat();
    }, 3000);
  }
  __FunctionSlide() {
    const slides = Array.from(document.getElementsByClassName('mall-show-slide'));
    // console.log("slides----->", slides)
    if (slides === []) {
        this.Repeat();
    }
    for (const x of slides) {
        const y = x as HTMLElement;
        y.style.display = 'none';
    }
    if (this.startIndex > slides.length - 1) {
        this.startIndex = 0;
        const slide = slides[this.startIndex] as HTMLElement;
        // console.log("slide------", slide)
        slide.style.display = 'block';
        this.startIndex++;
    } else {

        const slide = slides[this.startIndex] as HTMLElement;
        slide.style.display = 'block';
        this.startIndex++;
    }
  }

}
