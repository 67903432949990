import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SavedAccountsComponent } from './saved-accounts.component';
import { NgxBraintreeModule } from 'ngx-braintree';


@NgModule({
  declarations: [SavedAccountsComponent],
  imports: [
    CommonModule,
    NgxBraintreeModule
  ]
})
export class SavedAccountsModule { }
