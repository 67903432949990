import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonCrudService } from 'src/app/_services/common-crud.service';
import { SharedService } from 'src/app/_services/shared.service';
import { FormBuilder,Validators,FormArray } from '@angular/forms';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';

@Component({
  selector: 'app-cancel-event-registration',
  templateUrl: './cancel-event-registration.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css','./cancel-event-registration.component.scss']
})
export class CancelEventRegistrationComponent implements OnInit {
  routeParam: any;
  eventData: any;
  eventId: any;
  postDataJsonPrepare: {};
  arrayPar:{};
  cancelSubscriptionForm: any;
  selectRefundType :boolean = true;
  showRefundOption : boolean = true;
  netPaidAmount: number = 0;
  cancelReg = 0;
  cancelParticipentId: any;
  ParticipantDetails: any;
  selectAllPart: number = 2;
  response: { title: any; message: any; };
  memberId: string;
  responseCancel: { location: string; message: string; };
  isLoading:boolean = false;
  errorForSelectPar: string;
  eventStartDate: any;
  startDay: any;
  startMonth: any;
  startYear: any;
  eventEndDate: any;
  endDay: any;
  endMonth: any;
  endYear: any;

  constructor(public sharedService:SharedService,public activeRoute:ActivatedRoute,public _crud : CommonCrudService, public router:Router,
    public modalService: NgbModal,private cdRef: ChangeDetectorRef,public formBuilder : FormBuilder) {
    this.formInit();
   }

  ngOnInit(): void {
    this.sharedService.fetchPathData('cancel','cancel-event');
    this.routeParam = this.activeRoute.snapshot.params;
    console.log("params-----",this.routeParam);
    this.eventId = this.routeParam.regId;
    this.memberId = this.routeParam.memberId; 
    // this.sharedService.fetchPathData('cancel','cancel-subscription');
    let params = {
      url: "frontend-site/member-event/get-cancel-relevant-data/"+ this.eventId,
  }
  this._crud.getData(params).subscribe(res => {
      this.eventData = res['data'];
      this.eventStartDate = this.eventData.EventStartAt.split("-")
                this.startDay = this.eventStartDate[1];
                this.startMonth = this.eventStartDate[0];
                this.startYear = this.eventStartDate[2];
                this.eventEndDate = this.eventData.EventCloseAt.split("-")
                this.endDay = this.eventEndDate[1];
                this.endMonth = this.eventEndDate[0];
                this.endYear = this.eventEndDate[2];
      // console.log("this.eventData",this.eventData);
      // this.cancelParticipentId = this.activeRoute.snapshot.queryParamMap.get("id");
      let ParticipantDetails = <FormArray>this.cancelSubscriptionForm.get('ParticipantDetails');
      for (let option of this.eventData.ParticipantDetails) {
          let identifier = false
          ParticipantDetails.push(this.formBuilder.group({
              Name: option.Name,
              registration_fee: option.registration_fee,
              status: option.status,
              id: option.id,
              identifier: identifier,
          }))
        }
      if(this.eventData.RefundedAmount == 0){
        this.showRefundOption = false;
        // this.cancelSubscriptionForm.controls.RefundType.setValue(0);
        this.netPaidAmount = this.eventData.NetPaidAmount;
      }
     });
     this.formInit();
  }
  formInit(){
    this.cancelSubscriptionForm = this.formBuilder.group({
      RefundType: ["2", Validators.compose([Validators.required])],
      ParticipantDetails: this.formBuilder.array([]),

    });
  }
  RequestPostParams() {
    this.postDataJsonPrepare = {};
    this.postDataJsonPrepare['PaymentMethodId'] = ""
    this.postDataJsonPrepare['RefundType'] = parseInt(this.cancelSubscriptionForm.get('RefundType').value);
  //proces refund 
  if (this.cancelSubscriptionForm.get('RefundType').value == 1) {
    this.postDataJsonPrepare['RefundProcessType'] = (this.eventData?.IsSingleElectronicTransaction!=0)? 1: 2;
}
//process refund with manual method
// if(this.cancelSubscriptionForm.get('RefundType').value == 1 && this.eventData?.IsSingleElectronicTransaction == 0){
//   this.postDataJsonPrepare['RefundType'] = 0;
//   this.postDataJsonPrepare['RefundProcessType'] = "";
//   // this.cancelSubscriptionForm.controls.RefundType.setValue(0);
// }
    // this.postDataJsonPrepare['TypeOfCancellation'] = this.cancelReg == 0 ? 1 : 2;
    this.postDataJsonPrepare['TypeOfCancellation'] = this.selectAllPart;

    this.postDataJsonPrepare['CancelledParticipantData'] = [];
  //   this.postDataJsonPrepare['CancelledParticipantData'].push({
  //     Id: this.ParticipantDetails
  // })
    for (let option of this.cancelSubscriptionForm.value.ParticipantDetails) {
        if (option.identifier) {
            this.postDataJsonPrepare['CancelledParticipantData'].push({
                Id: option.id
            })
        }
    }

    this.postDataJsonPrepare['RefundAmount'] =this.netPaidAmount
    this.postDataJsonPrepare['CancelledAmount'] = this.netPaidAmount

    if (this.cancelSubscriptionForm.get('RefundType').value == 2) {
        this.postDataJsonPrepare['RefundAmount'] = this.netPaidAmount
    }

    if (this.cancelSubscriptionForm.get('RefundType').value == 1) {
        // this.postDataJsonPrepare['RefundProcessType'] = 1;
        this.postDataJsonPrepare['PaymentProcessParams'] = {};
        this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'] = {};
    }
    return this.postDataJsonPrepare;
}

cancel(){this.arrayPar = {}
this.arrayPar['CancelledParticipantData'] = []
 

  // console.log(this.RequestPostParams());
  for (let option of this.cancelSubscriptionForm.value.ParticipantDetails) {
    if (option.identifier) {
        this.arrayPar['CancelledParticipantData'].push({
            Id: option.id
        })
    }
}
  if((this.selectAllPart == 1 || this.arrayPar['CancelledParticipantData'].length > 0) &&  this.cancelSubscriptionForm.controls['RefundType'].value != null){
console.log("api",this.cancelSubscriptionForm.controls['RefundType'].value);
this.errorForSelectPar = ""

 this.isLoading = true;
  this.responseCancel = { location:"Event-cancel",message: "Are you sure you want to cancel?"}
      const modalRef = this.modalService.open(AlertPopupComponent, {size: "md",
      backdrop: 'static', // disable modal from closing on click outside
      keyboard: false, // disable modal closing by keyboard esc
  }
      );
      modalRef.componentInstance.data = this.responseCancel;
      modalRef.result.then(
        result => {
          // console.log("result-----modal",result)
            if (result.data) {
                //calling cancel api
                let params = {};
                params['url'] = 'frontend-site/member-event/cancel/'+ this.eventId
                params['params'] = this.RequestPostParams();
                this._crud.postData(params).subscribe(
                  
                      data => {
                        this.isLoading=false;
                        // console.log(data);
                        this.successPopup(data);
              
                      });
            }else{
              this.isLoading=false;
            }
        });
  }else{
    // console.log("error message")
    if(this.selectAllPart == 1 || this.arrayPar['CancelledParticipantData'].length > 0){
      this.errorForSelectPar = ""

    }else{
      this.errorForSelectPar = "Select atleast one participant"

    }
if(this.cancelSubscriptionForm.controls['RefundType'].value == null){
this.selectRefundType = false
}else{
  this.selectRefundType = true

}
  }
 
}
changeRefundType(){
  this.selectRefundType = true;
}
selectPart(data,amt,id){
  this.errorForSelectPar = ""
// console.log("event:",data,amt,this.cancelSubscriptionForm.value.ParticipantDetails);
let amount = 0;
for (let option of this.cancelSubscriptionForm.value.ParticipantDetails) {
  // for (let option of this.eventData.ParticipantDetails) {

    if (option.identifier) {
        // console.log("inside true identifier")
        if (parseFloat(this.eventData.NetPaidAmount) >= (amount + parseFloat(option.registration_fee))) {
            amount = amount + parseFloat(option.registration_fee);
        }
    }
}
this.netPaidAmount = amount;
}
selectAll(data){
  this.errorForSelectPar = ""
  // console.log("data from sletal----", this.cancelSubscriptionForm.value.ParticipantDetails);
  let ParticipantDetails = <FormArray>this.cancelSubscriptionForm.get('ParticipantDetails');
  if(data.checked == true){
    this.selectAllPart = 1;
   
  
    for (let option of this.cancelSubscriptionForm.value.ParticipantDetails) {
      // console.log("option------",option)
      ParticipantDetails.removeAt(option.Id);
      ParticipantDetails.push(this.formBuilder.group({
        Name: option.Name,
        registration_fee: option.registration_fee,
        status: option.status,
        id: option.id,
        identifier: option.status == 1 ? true : false,
    }))

  }

  }
  if(data.checked == false){
    this.selectAllPart = 2;
    for (let option of this.cancelSubscriptionForm.value.ParticipantDetails) {
      ParticipantDetails.removeAt(option.Id);
      ParticipantDetails.push(this.formBuilder.group({
        Name: option.Name,
        registration_fee: option.registration_fee,
        status: option.status,
        id: option.id,
        identifier: false,
    }))
    }
  }
}
successPopup(data) {
  this.isLoading=false;
  this.response = {title: data['status'],message: data['msg']}
   
   //   this.modalService.show(SuccessPopupComponent, {initialState})
   const modalRef = this.modalService.open(SuccessPopupComponent, {
       size: "md",
   });
   modalRef.componentInstance.user = this.response;
   modalRef.result.then(
    result => {
      // console.log("result-----modal",result)
        if (result.result) {
          if(data['status'] == 'success'){
            // console.log("result-----modal",result)
            this.router.navigateByUrl('/memberDashboard/myeventregistration/'+this.memberId)

          }
            // /clearing all forms.
            // this.activeModal.close({ 'result': true });
        }
    });
}
}
