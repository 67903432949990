<div class="d-flex inner-page-wrp">
    <div class="inner-page-content">
        <!-- <div class="breadcrumb-wrp mb-1">
            <a routerLink="../../home/{{memberId}}">Dashboard</a>
        </div> -->
        <div class="top-strip d-flex btm-border type2 mb-3 family-top-strip">
            <h5 class="mb-0 width-1">Family Members<span>{{familyMemberCount}} Members</span></h5>
            <div class="d-flex f-top-strip-btn">
                <button type="submit" class="btn-add-member btn-border" (click)="addMember()">+ Add a member</button>
            </div>
        </div>
        <div class="row family-member-list ">
            <div class="col-6 col-md-4 family-list-each text-center" *ngFor="let member of listOfFamilyMem">
                <div class="family-list-inner">
                    <figure class="text-center ">
                        <img src="{{ member.PhotoUrl}}" alt="Shalom ">
                    </figure>
                    <div class="list-desc">
                        <div class="desc-content-sec" [ngClass]="{'causeClass':member.MemberName.length > 21}">
                            <h5 class="heading-restrict">{{ member.MemberName}}</h5>
                            <div class="hover-bx">
                                <h5>{{ member.MemberName}}</h5>
                            </div>
                        </div>
                        <!-- <h6>Primary</h6> -->
                        <h6>{{member.Relationship?member.Relationship:"Primary"}}</h6>
                        <span *ngIf="member.SpfId != null">SPF {{member.SpfId}}</span>
                        <span class="txt-underline" *ngIf="member.SpfId == null" (click)="donateFRomFamily(member.MemberId,true)">Join SPF</span>
                        <!-- <img src="./assets/member/images/icon-edit.svg" alt="Shalom" title="edit profile" style="cursor:pointer;" (click)="member.Relationship?addMember(member):editContactInfo()"> -->
                        <a href="javascript:void(0)" class="blue-link d-block mb-2" (click)="member.Relationship?addMember(member):editContactInfo()">Edit Profile</a>
                        <div class="d-flex btn-family justify-content-center">
                            <button type="submit" class="btn-border mr-1" (click)="donateFRomFamily(member.MemberId,true)">Donate</button>
                            <!-- <button type="submit" class="btn-border ml-1" (click)="subscription(0,member.FamilyMemberId)">Subscribe</button> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-3 family-list-each text-center">
            <div class="family-list-inner">
                <figure class="text-center">
                    <img src="./assets/member/images/img-family-member2.png " alt="Shalom ">
                </figure>
                <div class="list-desc">
                    <h5>Hema John</h5>
                    <h6>Relationship</h6>
                    <span class="txt-underline">Join SPF</span>
                    <a href="javascript:void(0)" class="blue-link d-block mb-2">View Profile</a>
                    <button type="submit" class="btn-border">Donate</button>
                </div>
            </div>
        </div>
        <div class="col-md-3 family-list-each text-center">
            <div class="family-list-inner">
                <figure class="text-center">
                    <img src="./assets/member/images/img-family-member3.png " alt="Shalom ">
                </figure>
                <div class="list-desc">
                    <h5>Neha John</h5>
                    <h6>Relationship</h6>
                    <span class="txt-underline">Join SPF</span>
                    <a href="javascript:void(0)" class="blue-link d-block mb-2">View Profile</a>
                    <button type="submit" class="btn-border">Donate</button>
                </div>
            </div>
        </div>
        <div class="col-md-3 family-list-each text-center">
            <div class="family-list-inner">
                <figure class="text-center">
                    <img src="./assets/member/images/img-family-member3.png " alt="Shalom ">
                </figure>
                <div class="list-desc">
                    <h5>Neha John</h5>
                    <h6>Relationship</h6>
                    <span class="txt-underline">Join SPF</span>
                    <a href="javascript:void(0)" class="blue-link d-block mb-2">View Profile</a>
                    <button type="submit" class="btn-border">Donate</button>
                </div>
            </div>
        </div>
        <div class="col-md-3 family-list-each text-center">
            <div class="family-list-inner">
                <figure class="text-center ">
                    <img src="./assets/member/images/img-family-member1.png " alt="Shalom ">
                </figure>
                <div class="list-desc">
                    <h5>Mr. John Mccarthy</h5>
                    <h6>Primary</h6>
                    <span>SPFID4567</span>
                    <a href="javascript:void(0)" class="blue-link d-block mb-2">View Profile</a>
                    <button type="submit" class="btn-border">Donate</button>
                </div>
            </div>
        </div>
        <div class="col-md-3 family-list-each text-center">
            <div class="family-list-inner">
                <figure class="text-center">
                    <img src="./assets/member/images/img-family-member2.png " alt="Shalom ">
                </figure>
                <div class="list-desc">
                    <h5>Hema John</h5>
                    <h6>Relationship</h6>
                    <span class="txt-underline">Join SPF</span>
                    <a href="javascript:void(0)" class="blue-link d-block mb-2">View Profile</a>
                    <button type="submit" class="btn-border">Donate</button>
                </div>
            </div>
        </div>
        <div class="col-md-3 family-list-each text-center">
            <div class="family-list-inner">
                <figure class="text-center">
                    <img src="./assets/member/images/img-family-member3.png " alt="Shalom ">
                </figure>
                <div class="list-desc">
                    <h5>Neha John</h5>
                    <h6>Relationship</h6>
                    <span class="txt-underline">Join SPF</span>
                    <a href="javascript:void(0)" class="blue-link d-block mb-2">View Profile</a>
                    <button type="submit" class="btn-border">Donate</button>
                </div>
            </div>
        </div>
        <div class="col-md-3 family-list-each text-center">
            <div class="family-list-inner">
                <figure class="text-center">
                    <img src="./assets/member/images/img-family-member3.png " alt="Shalom ">
                </figure>
                <div class="list-desc">
                    <h5>Neha John</h5>
                    <h6>Relationship</h6>
                    <span class="txt-underline">Join SPF</span>
                    <a href="javascript:void(0)" class="blue-link d-block mb-2">View Profile</a>
                    <button type="submit" class="btn-border">Donate</button>
                </div>
            </div>
        </div>
        <div class="col-md-3 family-list-each text-center">
            <div class="family-list-inner">
                <figure class="text-center ">
                    <img src="./assets/member/images/img-family-member1.png " alt="Shalom ">
                </figure>
                <div class="list-desc">
                    <h5>Mr. John Mccarthy</h5>
                    <h6>Primary</h6>
                    <span>SPFID4567</span>
                    <a href="javascript:void(0)" class="blue-link d-block mb-2">View Profile</a>
                    <button type="submit" class="btn-border">Donate</button>
                </div>
            </div>
        </div>
        <div class="col-md-3 family-list-each text-center">
            <div class="family-list-inner">
                <figure class="text-center">
                    <img src="./assets/member/images/img-family-member2.png " alt="Shalom ">
                </figure>
                <div class="list-desc">
                    <h5>Hema John</h5>
                    <h6>Relationship</h6>
                    <span class="txt-underline">Join SPF</span>
                    <a href="javascript:void(0)" class="blue-link d-block mb-2">View Profile</a>
                    <button type="submit" class="btn-border">Donate</button>
                </div>
            </div>
        </div>
        <div class="col-md-3 family-list-each text-center">
            <div class="family-list-inner">
                <figure class="text-center">
                    <img src="./assets/member/images/img-family-member3.png " alt="Shalom ">
                </figure>
                <div class="list-desc">
                    <h5>Neha John</h5>
                    <h6>Relationship</h6>
                    <span class="txt-underline">Join SPF</span>
                    <a href="javascript:void(0)" class="blue-link d-block mb-2">View Profile</a>
                    <button type="submit" class="btn-border">Donate</button>
                </div>
            </div>
        </div> -->
            <!-- <div class="col-md-3 family-list-each text-center">
            <div class="family-list-inner">
                <figure class="text-center">
                    <img src="./assets/member/images/img-family-member3.png " alt="Shalom ">
                </figure>
                <div class="list-desc">
                    <h5>Neha John</h5>
                    <h6>Relationship</h6>
                    <span class="txt-underline">Join SPF</span>
                    <a href="javascript:void(0)" class="blue-link d-block mb-2">View Profile</a>
                    <button type="submit" class="btn-border">Donate</button>
                </div>
            </div>
        </div> -->
        </div>
    </div>
    <div class="home-right pl-0 ">
        <div class="bx-white connect-bx">
            <h4 class="text-center mb-3 mt-3">Connect with Us</h4>
            <div class="connect-btn-wrp">
                <figure class="text-center mb-2" *ngIf="isSpfMember">
                    <img src="./assets/member/images/img-connect-purple.svg " alt="Shalom ">
                </figure>
                <figure class="text-center mb-2" *ngIf="singleDonationExist == true && isSpfMember == false">
                    <img src="./assets/member/images/img-connect-green.svg " alt="Shalom ">
                </figure>
                <figure class="text-center mb-2" *ngIf="singleDonationExist == false && isSpfMember == false">
                    <img src="./assets/member/images/img-connect-brown.svg " alt="Shalom ">
                </figure>
                <ul class="button-list">
                    <li class="d-flex">
                        <a (click)="modalOpen('prayer')">Prayer Request</a>
                    </li>
                    <li class="d-flex">
                        <a (click)="modalOpen('testimony')">Testimony</a>
                    </li>
                    <li class="d-flex">
                        <a (click)="modalOpen('contact')">Contact Us</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- <div class="bx-white live-desc-bx">
        <button type="button" class="btn-live mb-2">Live Now</button>
        <div class="live-desc ">
            <h4>Excepteur sint occaecat cupidatat non</h4>
            <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
        </div>
    </div> -->
    </div>
</div>