<!-- <a class="nav-link " routerLink="../../"><i class="la la-arrow-left"></i>My Account</a> -->
<div class="bx-white cancel-wrp first-bx">
    <div class="bx-top-strip">
        <h2>Donation Details</h2>
    </div>
    <div class="cancel-inner">
        <div class="row ">
            <div class="col-lg-6">
                <ul>
                    <li>
                        <span>Member Name</span>
                        <div class="desc-content-sec"  [ngClass]="{'causeClass':userName.length > 25}">
                            <span class="heading-restrict">{{recurringDonationData.FirstName}} {{recurringDonationData.LastName}}</span>
                            <div class="hover-bx">
                                <span>{{recurringDonationData.FirstName}} {{recurringDonationData.LastName}}</span>
                            </div>
                        </div>
                        <!-- <strong>{{recurringDonationData.FirstName}} {{recurringDonationData.LastName}}</strong> -->
                    </li>
                    <li>
                        <span>Donation Cause</span>
                        <strong>{{recurringDonationData.CauseName}}</strong>
                    </li>
                    <li>
                        <span>Amount</span>
                        <strong>{{recurringDonationData.Amount}} {{recurringDonationData.CurrencyCode}}</strong>
                    </li>
                    <li>
                        <span>Recurring Frequency</span>
                        <strong>{{recurringDonationData.RecurringFrequency}}</strong>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6">
                <ul>
                    <li>
                        <span>SPF ID</span>
                        <strong>{{recurringDonationData.SpfId}}</strong>
                    </li>
                    <li>
                        <span>Start Date</span>
                        <strong>{{recurringDonationData.createdAt | date: 'dd MMM yyy'}}</strong>
                    </li>
                    <li>
                        <span>Payment Method</span>
                        <strong>{{recurringDonationData.PaymentMethodName}}</strong>
                    </li>
                    <li>
                        <span>Recurring Date</span>
                        <strong>{{recurringDonationData.recurringDate}}</strong>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</div>
<div _ngcontent-awk-c227="" class="bx-white cancel-wrp update-donation-wrp">
    <form [formGroup]="createForm">
        <div class="cancel-inner">
            <h5>Update Recurring Donation</h5>
            <div class="d-flex recurring-field-detail">

                <div class="form-group focus-label w1" id="amount">
                    <label for="" class="form-label-of">Amount</label>
                    <input type="text" id="" name="Amount" formControlName="Amount" readonly
                        (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)"
                        (input)="onAmountChange($event.target.value)">
                </div>


                <!-- <div class="form-group focus-label w1" id="savedCard" >
                <label *ngIf="isNotManual" for="" class="form-label-of">AccountHolderName</label>
                <input *ngIf="isNotManual" type="text" id="" formControlName="savedCard" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
            </div> -->
                <!-- showing selcted saved card -->
                <div class="form-group  focus-label w1 is-focused" *ngIf="isNotManual && recurringDonationData.PaymentMethodIdentifier != 'ach'">
                    <label for="{{selectedSavedCard.PayMethodToken}}" class="form-label-of">Card Detail</label>
                    <input formControlName="savedCard" type="text" class="form-check-input m-0" value="{{selectedSavedCard.AccountHolder}} {{selectedSavedCard.MaskedAccountNumber}}"
                        id="{{selectedSavedCard.PayMethodToken}}" disabled="true" name="savedCard">
                </div>
                <div class="form-group  focus-label w1 is-focused" *ngIf="isNotManual && recurringDonationData.PaymentMethodIdentifier == 'ach'">
                    <label for="{{selectedSavedCard.PayMethodToken}}" class="form-label-of">Account Detail</label>
                    <input formControlName="savedCard" type="text" class="form-check-input m-0" value="{{selectedSavedCard.AccountHolder}} {{selectedSavedCard.MaskedAccountNumber}}"
                        id="{{selectedSavedCard.PayMethodToken}}" disabled="true" name="savedCard">
                </div>

                <div class="icon-acnt text-center" *ngIf="isNotManual">
                    <a (click)="listCards(recurringDonationData.PaymentMethodIdentifier)"> <img
                            src="./assets/member/images/icon-card-grey.svg" alt="">
                        <span>Saved</span></a>
                </div>


                <div class="icon-acnt text-center" *ngIf="isNotManual">
                    <a (click)="addNew()"><img src="./assets/member/images/icon-card-grey.svg" alt="">
                        <span>Add New</span></a>
                </div>

            </div>

            <div class="acnt-detail-wrp" 
                *ngIf="recurringDonationData.PaymentMethodIdentifier == 'ach' && addNewAcc">
                <!-- <h5 class="mt-4">Current Account Details</h5> -->
                <h5 class="mt-4">Payment Information</h5>

                <!-- <div class="row">
                    <div class="col-md-6 pr-0">
                        <div class="form-group focus-label">
                            <label for="" class="form-label-of">Account Holder Name</label>
                            <input type="text" id="" formControlName="AccountHolder" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountHolder">
                                <span class="error-message danger alert"  *ngIf="(createForm.get('AccountHolder')?.hasError(validation.type) && (createForm.get('AccountHolder')?.dirty || createForm.get('AccountHolder')?.touched)) && createForm.get('AccountHolder')?.invalid">
                                   {{ validation.message }}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                           
                            <ng-select formControlName="AccountType" [items]="AccountTypeList" id="AccountType" placeholder="Select Account Type" bindValue="value" bindLabel="type" [(ngModel)]="accountType" [clearable]="false">
                            </ng-select>
                           
                            <span class="col-sm-12 m-form__help">
                            <span class="error-message alert danger" *ngIf="( this.createForm.get('AccountType')?.dirty || this.createForm.get('AccountType')?.touched) && this.createForm.get('AccountType')?.invalid">
                                Please select your account type
                            </span>
                            </span>
                        </div>

                    </div>
                    <div class="col-md-6 pr-0">
                        <div class="form-group focus-label">
                            <label for="" class="form-label-of">Routing Number</label>
                            <input type="text" id="" data-rule-number="true" formControlName="RoutingNumber" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.RoutingNumber">
                                <span class="error-message danger alert"  *ngIf="(createForm.get('RoutingNumber')?.hasError(validation.type) && (createForm.get('RoutingNumber')?.dirty || createForm.get('RoutingNumber')?.touched)) && createForm.get('RoutingNumber')?.invalid">
                                   {{ validation.message }}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group focus-label">
                            <label for="" class="form-label-of">Account Number</label>
                            <input type="text" id="" data-rule-number="true" formControlName="AccountNumber" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                            <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountNumber">
                                <span class="error-message danger alert"  *ngIf="(createForm.get('AccountNumber')?.hasError(validation.type) && (createForm.get('AccountNumber')?.dirty || createForm.get('AccountNumber')?.touched)) && createForm.get('AccountNumber')?.invalid">
                                   {{ validation.message }}
                                </span>
                            </span>
                        </div>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-12 d-flex mob-mb acnt-radio-type">
                        <span class="radio-label">Account Type</span>
                        <div class="form-check radio-btn-wrp"
                            *ngFor="let accounttype of AccountTypeList;let i = index;">
                            <input type="radio" class="form-check-input" id="{{accounttype.value}}"
                                formControlName="AccountType" value="{{accounttype.value}}">
                            <label class="form-check-label" for="{{accounttype.value}}">
                                {{accounttype.name}}
                            </label>

                        </div>
                    </div>
                    <span class="col-sm-12 m-form__help">
                        <span class="error-message alert danger"
                            *ngIf="( this.createForm.get('AccountType')?.dirty || this.createForm.get('AccountType')?.touched) && this.createForm.get('AccountType')?.invalid">
                            Please select your account type
                        </span>
                    </span>
                    <div class="col-lg-12 mt-3">
                        <div class="row">
                            <div class="col-md-4 col-lg-4 pl-0 mob-pl">
                                <div class="form-group focus-label">
                                    <label for="" class="form-label-of">Account Number</label>
                                    <input type="text" id="" data-rule-number="true" formControlName="AccountNumber"
                                        (focus)="animateLabel($event.target)"
                                        (focusout)="animateLabelRemove($event.target)">
                                    <span class="m-form__help"
                                        *ngFor="let validation of ValidationMessage.AccountNumber">
                                        <span class="error-message danger alert"
                                            *ngIf="(createForm.get('AccountNumber')?.hasError(validation.type) && (createForm.get('AccountNumber')?.dirty || createForm.get('AccountNumber')?.touched)) && createForm.get('AccountNumber')?.invalid">
                                            {{ validation.message }}
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div class="col-md-4 col-lg-4">
                                <div class="form-group focus-label">
                                    <label for="" class="form-label-of">Routing Number</label>
                                    <input type="text" id="" data-rule-number="true" formControlName="RoutingNumber"
                                        (focus)="animateLabel($event.target)"
                                        (focusout)="animateLabelRemove($event.target)">
                                    <span class="m-form__help"
                                        *ngFor="let validation of ValidationMessage.RoutingNumber">
                                        <span class="error-message danger alert"
                                            *ngIf="(createForm.get('RoutingNumber')?.hasError(validation.type) && (createForm.get('RoutingNumber')?.dirty || createForm.get('RoutingNumber')?.touched)) && createForm.get('RoutingNumber')?.invalid">
                                            {{ validation.message }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4 pr-0 mob-pr">
                                <div class="form-group focus-label">
                                    <label for="" class="form-label-of">Account Holder Name</label>
                                    <input type="text" id="" formControlName="AccountHolder"
                                        (focus)="animateLabel($event.target)"
                                        (focusout)="animateLabelRemove($event.target)">
                                    <span class="m-form__help"
                                        *ngFor="let validation of ValidationMessage.AccountHolder">
                                        <span class="error-message danger alert"
                                            *ngIf="(createForm.get('AccountHolder')?.hasError(validation.type) && (createForm.get('AccountHolder')?.dirty || createForm.get('AccountHolder')?.touched)) && createForm.get('AccountHolder')?.invalid">
                                            {{ validation.message }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="checkbox-wrp ml-3" *ngIf="isSavedCard">
                        <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" id="save-id">
                        <label for="save-id">Save Account for Future</label>
                    </div>
                </div> -->
            </div>
            <div class="acnt-detail-wrp"
                *ngIf="recurringDonationData.PaymentMethodIdentifier == 'credit_or_debt' && electronicPaymnt">
                <i *ngIf="loaderCreditNew" class="spinner-border common-loader"></i>
                <div class="p-0 credit_or_debt clearfix col-xs-12">
                    <!-- hosted fields -->
                   
                    <div class="row">
                        <div class="field-container col-md-4 col-lg-4">
                            <!-- Begin hosted fields section -->
                            <label class="hosted-field--label" for="card-number">
        </label>
                            <div class="hosted-field" id="card-number"></div>
                            <span class="m-form__help" *ngIf="noFieldsCardNumber == 1">
            <span class="error-message danger alert">
               Please enter a valid card number
            </span>
                            </span>
                            <!-- End hosted fields section -->
                        </div>
                        <div class="field-container col-md-4 col-lg-4">
                            <!-- Begin hosted fields section -->
                            <label class="hosted-field--label" for="expiration-date">
        <!-- Expiration Date -->
        </label>
                            <div class="hosted-field" id="expiration-date"></div>
                            <span class="m-form__help" *ngIf="noFieldsDate == 1">
            <span class="error-message danger alert">
               Please enter a valid  card expiration date
            </span>
                            </span>
                            <!-- <div id="expiration-date" class="hosted-field"></div> -->
                            <!-- End hosted fields section -->
                        </div>
                        <div class="field-container col-md-4 col-lg-4">
                            <!-- Begin hosted fields section -->
                            <label class="hosted-field--label" for="cvv">
            <!-- CVV -->
        </label>
                            <!-- End hosted fields section -->
                            <div class="hosted-field" id="cvv"></div>
                            <span class="m-form__help" *ngIf="noFieldsCvv == 1">
            <span class="error-message danger alert">
               Please enter a valid card security code (CVV)
            </span>
                            </span>
                        </div>
                    </div>
                    <!-- <div class="textfield--float-label"> -->
                    <!-- Begin hosted fields section -->
                    <!-- <label id="postal-code" class="hosted-field--label" for="postal-code"> -->
                    <!-- Postal Code -->
                    <!-- </label> -->
                    <!-- End hosted fields section -->
                    <!-- </div> -->
                    <div style="color:#ff5a5a;" *ngIf="brainTreeError != ''" class="alert danger">
                        <p>{{brainTreeError}}</p>
                    </div>
                    <div style="color:#ff5a5a;" *ngIf="paymentGatewayErrorMsg !=null" class="alert danger">
                        <p>{{paymentGatewayErrorMsg}}</p>
                    </div>
                    <!-- <button class="pay-button">Pay</button> -->
                </div>
                <!-- <ngx-braintree [getClientToken]="getClientToken.bind(this)" [showCardholderName]="false"
                    [chargeAmount]="NetAmonutToPay" [createPurchase]="createPurchase.bind(this)">
                    <div class="ngxButtons"> -->
                        <!-- <label class="row col-form-label m-checkbox--inline" *ngIf="isSavedCard">
                  <label class="m-checkbox m-checkbox--solid m-checkbox--brand">
                    <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" >
                    Save Account for Future
                    <span></span>
                  </label>
                </label> -->
                        <!-- <div class="checkbox-wrp mt-3" *ngIf="isSavedCard">
                            <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" id="save-id">
                            <label for="save-id">Save Account for Future</label>
                        </div> -->
                        <!-- <button ngxPay class="btn-common">Update</button>
                    </div>
                </ngx-braintree> -->
            </div>
            <!-- *ngIf="recurringDonationData.PaymentMethodIdentifier != 'credit_or_debt'" -->
            <button  type="button" *ngIf="recurringDonationData.PaymentMethodIdentifier != 'credit_or_debt'"
                class="btn-common my-3" (click)="PaymentProceedFinal()"><span class="span-loader-padding">Update</span>  <i *ngIf="isLoading"
                    class="spinner-border"></i></button>
                    <button  type="button" *ngIf="recurringDonationData.PaymentMethodIdentifier == 'credit_or_debt'"
                class="btn-common my-3" (click)="PaymentProceedFinalCreditCard()"><span class="span-loader-padding">Update</span>  <i *ngIf="isLoading"
                    class="spinner-border"></i></button>
            <div class="txt-btm" style="text-align: center">Please contact Shalom office to make any addtional changes</div>
        </div>
    </form>
</div>