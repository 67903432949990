import { Component } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private userIdle: UserIdleService,public router:Router) { }
  title = 'myshalom-memberDashboard';
  ngOnInit(): void {
    console.log("idle----")
    this.userIdle.resetTimer();
    let idleCheck = localStorage.getItem('idleTimeCheck');
    if(idleCheck == "1" || !idleCheck){
      if(!idleCheck){
        localStorage.setItem('idleTimeCheck',"1");
      }
      console.log("idle libry can check for user inactivtiy");
       //Start watching for user inactivity.
      this.userIdle.startWatching();
      
      // Start watching when user idle is starting.
      this.userIdle.onTimerStart().subscribe(count => console.log("count------",count));
      
      // Start watch when time is up.
      this.userIdle.onTimeout().subscribe(() => {
        console.log("idle user idle for 10 mints---do login");
        localStorage.removeItem('currentUser');
        localStorage.removeItem('IsSpfMember');
        localStorage.removeItem('primaryCountryId');
        localStorage.removeItem('memberId');
        localStorage.removeItem('videoApiCounter');
        localStorage.removeItem('apiVideoData');
        localStorage.removeItem('eventListData');
        localStorage.removeItem('publicationData');
        localStorage.removeItem('publicationBookUrl');

        // window.alert('do login agin');
        this.router.navigate(['/member-login']);
      });
    }else{
      console.log("idle libry cant check for user inacty on vido play");
    }
   
  }
}
