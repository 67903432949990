import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonCrudService } from 'src/app/_services/common-crud.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  tokenForVerification: any;

  constructor(public _route:ActivatedRoute,public _crud:CommonCrudService,public route:Router) { }

  ngOnInit(): void {
    this.tokenForVerification = this._route.snapshot.params['token'] || '';
    console.log( this._route.snapshot.params['token'])
    let params = {
      url: "member/email-verify/" + this.tokenForVerification, 
     
    }
    this._crud.getData(params).subscribe(res=>{
     
      if(res['status'] == 'error'){
      }else{
        //call successpopup
console.log(res)
      }
      

    });
  }
  success(){
    this.route.navigateByUrl('/member-login')

  }

}
