<head>
    <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" rel="stylesheet">
</head>
<div class="attch-wrp">
    <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
        <span aria-hidden="true">
            &times;
        </span>
    </button>
    <h3 class="m-form__heading-title">
        Attachments
        <!-- <i data-toggle="m-tooltip" data-width="auto" class="m-form__heading-help-icon flaticon-info" title="Some help text goes here"></i> -->
    </h3>
    <div class="custom-form-group-headings row">
        <div class="col-xl-12">
            <div class="m-widget4">
                <div class="m-widget4__item" *ngFor="let ach of dataOfAttachment">
                    <div class="m-widget4__img m-widget4__img--logo">
                        <i class="fa fa-download"></i>
                    </div>
                    <div class="m-widget4__info">
                    <span class="m-widget4__title">
                        {{ach.AttachmentText}}
                    </span><br>
                    </div>
                    <span class="m-widget4__ext">
                    <a style="cursor: pointer;" class="m-widget4__number m--font-brand" (click)="downloadFlyer(ach.FileName)"
                    download>Download</a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div *ngFor="let ach of dataOfAttachment"> 
    <button type="button" class="btn btn-sm btn-success" (click)="downloadFlyer(ach.FileName)"><i
        class="la la-cloud-upload"></i> download</button>
</div> -->
