<div class="privacy-term_wrp" [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
        <div class="container">
            <div class="head-bar row">
                <!-- <div class="col-5 col-md-2">
                    <img src="./assets/member/images/dashboard-logo-purple.svg" alt="Shalom">
                </div> -->
                <div class="col-5 col-md-2" *ngIf="!isLogged">
                    <a routerLink="/member-login"><img src="./assets/member/images/dashboard-logo-brown.svg" alt="Shalom"></a>
                </div>
                <div class="col-5 col-md-2" *ngIf="isSpfMember && isLogged">
                    <a routerLink="/memberDashboard/home/{{this.memberId}}"><img src="./assets/member/images/dashboard-logo-purple.svg" alt="Shalom"></a>
                </div>
                <!-- ontime donation user -->
                <div class="col-5 col-md-2" *ngIf="singleDonationExist == true && isSpfMember == false && isLogged">
                    <a routerLink="/memberDashboard/home/{{this.memberId}}"><img src="./assets/member/images/dashboard-logo-green.svg" alt="Shalom"></a>
                </div>
                <!-- new user -->
                <div class="col-5 col-md-2" *ngIf="singleDonationExist == false && isSpfMember == false && isLogged">
                    <a routerLink="/memberDashboard/home/{{this.memberId}}"><img src="./assets/member/images/dashboard-logo-brown.svg" alt="Shalom"></a>
                </div>
                <div class="col-7 col-md-10">
                    <div class="d-flex justify-content-end align-items-center">
                        <div class="top-bar">
                            <div class="tab-wrp">
                               <!-- not logged case -->
                               <ul class="nav nav-tabs" role="tablist" *ngIf="!isLogged">
                                <li class="nav-item ">
                                     <a class="nav-link" data-toggle="tab" routerLink="/member-login"
                                      role="tab" >Dashboard</a>
                                 </li>
                                 <li class="nav-item">
                                     <a class="nav-link" data-toggle="tab" routerLink="/member-login"  
                                      role="tab" >My Donations</a>
                                 </li>
                                 <li class="nav-item">
                                     <a class="nav-link" data-toggle="tab" routerLink="/member-login" 
                                      role="tab"  >My
                                         Subscriptions</a>
                                 </li>
                                 <li class="nav-item">
                                     <a class="nav-link" data-toggle="tab" routerLink="/member-login"
                                      role="tab">My Event
                                         Registrations</a>
                                 </li>
                                 <!-- <li class="nav-item">
                                     <a class="nav-link" data-toggle="tab" role="tab">Settings</a>
                                 </li> -->
                             </ul>
                             <!-- logged case -->
                            <ul class="nav nav-tabs" role="tablist" *ngIf="isLogged">
                               <li class="nav-item ">
                                    <a class="nav-link" data-toggle="tab" routerLink="/memberDashboard/home/{{this.memberId}}"
                                     role="tab" >Dashboard</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" routerLink="/memberDashboard/mydonation/{{this.memberId}}"  
                                     role="tab" >My Donations</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" routerLink="/memberDashboard/mysubscription/{{this.memberId}}" 
                                     role="tab"  >My
                                        Subscriptions</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" routerLink="/memberDashboard/myeventregistration/{{this.memberId}}"
                                     role="tab" >My Event
                                        Registrations</a>
                                </li>
                                <!-- <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" role="tab">Settings</a>
                                </li> -->
                            </ul>
                                <!-- Tab panes -->
                                <div class="tab-content">
    
                                    <div class="tab-pane" id="donation" role="tabpanel">
                                        <p>My Donations</p>
                                    </div>
                                    <div class="tab-pane" id="subscription" role="tabpanel">
                                        <p>My subscription</p>
                                    </div>
                                    <div class="tab-pane" id="registration" role="tabpanel">
                                        <p>Event Registrations</p>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                        <div class="waffle_menu">
                            <figure>
                                <img src="./assets/member/images/prof-top-icon.svg" alt="waffle"></figure>
                                <ul>
                                    <li>
                                        <a href="https://www.shalommedia.org/" target="_blank">
                                            <figure>
                                                <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/Shalom-Media.webp" alt=""></figure>
                                                <span>Shalom Media</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://shalomtidings.org/" target="_blank">
                                                <figure>
                                                    <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/Tidings.webp" alt="">
                                                </figure>
                                                <span>Shalom Tidings</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.shalomworld.org/" target="_blank">
                                                <figure>
                                                    <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/logo-without-background.webp" alt="">
                                                </figure>
                                                <span>Shalom World</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://qabeta.shalomworld.org/swpals/" target="_blank">
                                                <figure>
                                                    <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/SW-Pals.webp" alt="">
                                                </figure>
                                                <span>SW Pals</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.shalomworld.org/swprayer">
                                                <figure>
                                                    <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/SW-Prayer-1.webp" alt="">
                                                </figure>
                                                <span>SW Prayer</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.shalomworld.org/news" target="_blank">
                                                <figure>
                                                    <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/SW-News.webp" alt="">
                                                </figure>
                                                <span>SW News</span>
                                            </a>
                                        </li>
                                    </ul>
                            </div>
                            <div class="log-prof" *ngIf="isLogged">
                                <div class="d-flex dropdown-wrp">
                                    <a href="#" class="" data-toggle="dropdown" aria-expanded="false">
                                        <div class="log-prof-desc">
                                            <figure>
                                                <!-- <img src="./assets/member/images/img-prof2.png" alt="Shalom"> -->
                                                <!-- <img src="http://myshalomtest.webc.in/myshalom/storage/uploads/members/1614678593LA7_1639372532.0025.jpg" alt="Shalom"> -->
                                                <img src="{{PhotoUrl}}" alt="Shalom">
                                            </figure>
                                        </div>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end" 
                                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-87px, 33px, 0px);">
                                        <span>Hi, {{FirstName}}</span>
                                        <button class="btn btn-primary" (click)="logOut()">Sign out</button>
                                    </div>
        
                                </div>
                            </div>
                    </div>
                    <div class="mob-btn">
                        <div class="back menu--3"> <label> <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="50" cy="50" r="30"></circle>
                                    <path class="line--1" d="M0 40h62c18 0 18-20-17 5L31 55"></path>
                                    <path class="line--2" d="M0 50h80"></path>
                                    <path class="line--3" d="M0 60h62c18 0 18 20-17-5L31 45"></path>
                                </svg> </label>
                        </div>
                    </div>
                </div>
                
                <div class="overlay"></div>
            </div>
        </div>
<div class="container">
    <div class="privacy_wrp" *ngIf="primaryCountry == 'BprLdASASGl0'">
        <h2>TERMS AND CONDITIONS</h2>
        <p>Welcome to www.my-shalom.org!</p>
        <p>Thank you for visiting our website. This website is provided by Shalom Media USA Inc. (“Shalom Media USA”), located at 211 E Wisconsin Rd, Edinburg, TX 78539. www.my-shalom.org is the official website for Shalom Media USA, a not for profit organization.</p>
        <h5>Using Our Website Services</h5>
        <p>www.my-shalom.org is so diverse in its content and services and so, sometimes additional terms or product requirements may apply. Additional terms will be available with the relevant Services, and those additional terms will become part of your agreement with us if you use those services. We have taken maximum effort to design our websites to be useful and informative in content and design. By visiting this website, services, and contents, you agree to abide by the following Terms and Conditions. By using our sites, you automatically agree to them. Naturally, if you don’t agree, please do not use our sites. We reserve the right to make any modifications that we deem necessary at any time. Please continue to check these terms to see what those changes may be. Your use of the myShalom and Shalom Media USA websites means that you accept those changes.</p>
        <h5>You Must Follow Any Policies Made Available to You Within the Services</h5>
        <p>By visiting the website, you agree not to interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct. Don’t misuse our Services.</p>
        <p>Using our Services does not give you ownership of any property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed in or along with our Services. In connection with your use of the Services, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications.</p>
        <p>Some of our Services are available on mobile devices through apps in both android and mac platforms. Do not use such Services in a way that physically, mentally or socially hurts you in any way. Also, do not use such services to distract and to prevent you from obeying traffic or safety laws of the state.</p>
        <h5>Restrictions</h5>
        <p>All materials contained in the Shalom Media USA and myShalom websites (“Online Material”) are the copyrighted property of Shalom Media USA and myShalom. All trademarks, service marks, and trade names are proprietary to Shalom Media USA and myShalom. No Online Materials including videos, music, from our websites may be downloaded, copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way, except that you may download one copy of the materials on any single computer for your personal, non-commercial home use only, provided that you 1) retain all copyright, trademark and propriety notices, 2) you make no modifications to the materials, 3) you do not use the materials in a manner that suggests an association with any of our products, services, events or brands, and 4) you do not download quantities of materials to a database, server, or personal computer for reuse for commercial purposes. You may not, however, copy, reproduce, republish, upload, post, transmit or distribute our Online Material in any way or for any other purpose unless you get our written permission first. Neither may you add, delete, distort or misrepresent any content on the Shalom Media USA and myShalom sites. Attempts to modify any online materials, or to defeat or circumvent our security features are prohibited.</p>
        <h5>Submitting Online Materials</h5>
        <p>All remarks, ideas, comments, graphics, suggestions, testimonies, or other information that you send to myShalom and Shalom Media USA through our sites (other than information we promise to protect under our privacy policy) becomes and remain our property, even if this agreement is later terminated. Such submissions are not treated as confidential. If we use them, or one similar in nature, we are not obligated to compensate you or anyone else for them. We will have the exclusive ownership of all present and future rights to the submissions of any kind we receive through websites. We may or may not use your name when using for promotions or any other use. We can use them for any purpose we deem appropriate to our myShalom and Shalom Media USA mission, without compensating you or anyone else. If we use them, or one similar in nature, we are not obligated to compensate you or anyone else for them. We will have the exclusive ownership of all present and future rights to submissions of any kind. You acknowledge that you are responsible for any submission you make. This means that you (and not we) have full responsibility for the content, message, or presentation including its legality, reliability, appropriateness, originality, and copyright.</p>
        <h5>Limitations of Liability</h5>
        <p>Shalom Media USA and www.my-shalom.org will not be liable for any damages or injury that accompany or result from your use of any of its sites. These include (but are not limited to) damages or injury caused by any:</p>
        <ul style="list-style: disc; margin-left: 40px;">
            <li>Use of or inability to use the sites</li>
            <li>Use of or inability to use any site to which hyperlink from our sites</li>
            <li>Failure of our sites to perform in the manner you expected or desired</li>
            <li>Error or omission of our sites</li>
            <li>Interruption or availability of our sites</li>
            <li>Defect or delay in operation or transmission of our sites</li>
            <li>Computer virus or line failure</li>
        </ul>
        <p>We are also not liable for any damages caused by the use of our website. We are not liable even if we have been negligent.</p>
        <h5>Links</h5>
        <p>We sometimes provide referrals to and links to other World Wide Web sites from our sites. Such a link should not be seen as an endorsement, approval or agreement with any information or resources offered at sites you can access through our sites. If in doubt, always check the Uniform Resource Locator (URL) address provided in your WWW browser to see if you are still in a myShalom and Shalom Media USA-operated site or have moved to another site. myShalom and Shalom Media USA is not responsible for the content or practices of third party sites that may be linked to our sites. When myShalom and Shalom Media USA provides links or references to other websites, no inference or assumption should be made and no representation should be inferred that myShalom and Shalom Media USA is connected with, operates or controls these websites. Any approved link must not represent in any way, either explicitly or by implication, that you have received the endorsement, sponsorship or support of any myShalom and Shalom Media USA site or endorsement, sponsorship or support of the ministry of myShalom and Shalom Media USA, including its respective employees, agents or directors.</p>
        <h5>Termination</h5>
        <p>This agreement is effective until terminated by either party. You may terminate this agreement at any time, by destroying all materials obtained from all myShalom and Shalom Media USA websites, along with all related documentation and all copies and installations. myShalom and Shalom Media USA may terminate this agreement at any time and without notice to you, if, in its sole judgment, you breach any term or condition of this agreement. Upon termination, you must destroy all materials. In addition, by providing material on our websites, we do not in any way promise that the materials will remain available to you. And myShalom and Shalom Media USA is entitled to terminate all or any part of any of its websites without notice to you.</p>
        <h5>Jurisdiction</h5>
        <p>Unless otherwise specified, the Online Materials on our sites are presented to provide information about the resources of myShalom and Shalom Media USA. The ministry of myShalom and Shalom Media USA was organized under the laws of Texas, in the United States of America. If you use our sites from locations outside of the United States, you are responsible for compliance with any applicable laws. These Terms of Use, and the agreement they create, shall be governed by and interpreted according to the laws of the State of Texas (without applying the state's conflict-of-law principles.) If any provision of this agreement is unlawful, void or unenforceable, it will not affect the validity and enforceability of any remaining provisions. myShalom and Shalom Media USA may modify these Terms of Use, and the agreement they create, at any time, simply by updating this posting and without notice to you. This is the ENTIRE agreement regarding all the matters that have been listed.</p>
    </div>
    <div class="privacy_wrp" *ngIf="primaryCountry == 'FbxDk7Am3ODq'">
        <h2>TERMS AND CONDITIONS</h2>
        <p>Welcome to www.my-shalom.org!</p>
        <p>Thank you for visiting our website. This website is provided by Shalom Media UK Ltd. (“Shalom Media UK”), located at 845 Barnsley Road, Sheffield, S5 0QF. www.my-shalom.org is the official website for Shalom Media UK, a not for profit organization.</p>
        <h5>Using Our Website Services</h5>
        <p>www.my-shalom.org is so diverse in its content and services and so, sometimes additional terms or product requirements may apply. Additional terms will be available with the relevant Services, and those additional terms will become part of your agreement with us if you use those services. We have taken maximum effort to design our websites to be useful and informative in content and design. By visiting this website, services, and contents, you agree to abide by the following Terms and Conditions. By using our sites, you automatically agree to them. Naturally, if you don’t agree, please do not use our sites. We reserve the right to make any modifications that we deem necessary at any time. Please continue to check these terms to see what those changes may be. Your use of the myShalom and Shalom Media UK websites means that you accept those changes.</p>
        <h5>You Must Follow Any Policies Made Available to You Within the Services</h5>
        <p>By visiting the website, you agree not to interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct. Don’t misuse our Services.</p>
        <p>Using our Services does not give you ownership of any property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed in or along with our Services. In connection with your use of the Services, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications.</p>
        <p>Some of our Services are available on mobile devices through apps in both android and mac platforms. Do not use such Services in a way that physically, mentally or socially hurts you in any way. Also, do not use such services to distract and to prevent you from obeying traffic or safety laws of the state.</p>
        <h5>Restrictions</h5>
        <p>All materials contained in the Shalom Media UK and myShalom websites (“Online Material”) are the copyrighted property of Shalom Media UK and myShalom. All trademarks, service marks, and trade names are proprietary to Shalom Media UK and myShalom. No Online Materials including videos, music, from our websites may be downloaded, copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way, except that you may download one copy of the materials on any single computer for your personal, non-commercial home use only, provided that you 1) retain all copyright, trademark and propriety notices, 2) you make no modifications to the materials, 3) you do not use the materials in a manner that suggests an association with any of our products, services, events or brands, and 4) you do not download quantities of materials to a database, server, or personal computer for reuse for commercial purposes. You may not, however, copy, reproduce, republish, upload, post, transmit or distribute our Online Material in any way or for any other purpose unless you get our written permission first. Neither may you add, delete, distort or misrepresent any content on the Shalom Media UK and myShalom sites. Attempts to modify any online materials, or to defeat or circumvent our security features are prohibited.</p>
        <h5>Submitting Online Materials</h5>
        <p>All remarks, ideas, comments, graphics, suggestions, testimonies, or other information that you send to myShalom and Shalom Media UK through our sites (other than information we promise to protect under our privacy policy) becomes and remain our property, even if this agreement is later terminated. Such submissions are not treated as confidential. If we use them, or one similar in nature, we are not obligated to compensate you or anyone else for them. We will have the exclusive ownership of all present and future rights to the submissions of any kind we receive through websites. We may or may not use your name when using for promotions or any other use. We can use them for any purpose we deem appropriate to our myShalom and Shalom Media UK mission, without compensating you or anyone else. If we use them, or one similar in nature, we are not obligated to compensate you or anyone else for them. We will have the exclusive ownership of all present and future rights to submissions of any kind. You acknowledge that you are responsible for any submission you make. This means that you (and not we) have full responsibility for the content, message, or presentation including its legality, reliability, appropriateness, originality, and copyright.</p>
        <h5>Limitations of Liability</h5>
        <p>Shalom Media UK and www.my-shalom.org will not be liable for any damages or injury that accompany or result from your use of any of its sites. These include (but are not limited to) damages or injury caused by any:</p>
        <ul style="list-style: disc; margin-left: 40px;">
            <li>Use of or Inability to use the sites</li>
            <li>Use of or inability to use any site to which hyperlink from our sites</li>
            <li>Failure of our sites to perform in the manner you expected or desired</li>
            <li>Error or omission of our sites</li>
            <li>Interruption or availability of our sites</li>
            <li>Defect or delay in operation or transmission of our sites</li>
            <li>Computer virus or line failure</li>
        </ul>
        <p>We are also not liable for any damages caused by the use of our website. We are not liable even if we have been negligent.</p>
        <h5>Links</h5>
        <p>We sometimes provide referrals to and links to other World Wide Web sites from our sites. Such a link should not be seen as an endorsement, approval or agreement with any information or resources offered at sites you can access through our sites. If in doubt, always check the Uniform Resource Locator (URL) address provided in your WWW browser to see if you are still in a myShalom and Shalom Media UK-operated site or have moved to another site. myShalom and Shalom Media UK is not responsible for the content or practices of third party sites that may be linked to our sites. When myShalom and Shalom Media UK provides links or references to other websites, no inference or assumption should be made and no representation should be inferred that myShalom and Shalom Media UK is connected with, operates or controls these websites. Any approved link must not represent in any way, either explicitly or by implication, that you have received the endorsement, sponsorship or support of any myShalom and Shalom Media UK site or endorsement, sponsorship or support of the ministry of myShalom and Shalom Media UK, including its respective employees, agents or directors.</p>
        <h5>Termination</h5>
        <p>This agreement is effective until terminated by either party. You may terminate this agreement at any time, by destroying all materials obtained from all myShalom and Shalom Media UK websites, along with all related documentation and all copies and installations. myShalom and Shalom Media UK may terminate this agreement at any time and without notice to you, if, in its sole judgment, you breach any term or condition of this agreement. Upon termination, you must destroy all materials. In addition, by providing material on our websites, we do not in any way promise that the materials will remain available to you. And myShalom and Shalom Media UK is entitled to terminate all or any part of any of its websites without notice to you.</p>
        <h5>Jurisdiction</h5>
        <p>Unless otherwise specified, the Online Materials on our sites are presented to provide information about the resources of myShalom and Shalom Media UK. The ministry of myShalom and Shalom Media UK was organized under the federal laws of the United Kingdom. If you use our sites from locations outside of the United Kingdom, you are responsible for compliance with any applicable laws. These Terms of Use, and the agreement they create, shall be governed by and interpreted according to the laws of the United Kindom Terms and Conditions (without applying the state's conflict-of-law principles.) If any provision of this agreement is unlawful, void or unenforceable, it will not affect the validity and enforceability of any remaining provisions. myShalom and Shalom Media UK may modify these Terms of Use, and the agreement they create, at any time, simply by updating this posting and without notice to you. This is the ENTIRE agreement regarding all the matters that have been listed.</p>
    </div>
        <div class="privacy_wrp" *ngIf="primaryCountry == 'IU999wydfCbl'">
            <h2>TERMS AND CONDITIONS</h2>
            <p>Welcome to www.my-shalom.org!</p>
            <p>Thank you for visiting our website. This website is provided by Shalom Media Europe CLG (“Shalom Media Europe”), located at Post Box #92, Portlaoise, Co. Laois, R32 YX08, Republic of Ireland. www.my-shalom.org is the official website for Shalom Media Europe, a not for profit organization.</p>
            <h5>Using Our Website Services</h5>
            <p>www.my-shalom.org is so diverse in its content and services and so, sometimes additional terms or product requirements may apply. Additional terms will be available with the relevant Services, and those additional terms will become part of your agreement with us if you use those services. We have taken maximum effort to design our websites to be useful and informative in content and design. By visiting this website, services, and contents, you agree to abide by the following Terms and Conditions. By using our sites, you automatically agree to them. Naturally, if you don’t agree, please do not use our sites. We reserve the right to make any modifications that we deem necessary at any time. Please continue to check these terms to see what those changes may be. Your use of the myShalom and Shalom Media Europe websites means that you accept those changes.</p>
            <h5>You Must Follow Any Policies Made Available to You Within the Services</h5>
            <p>By visiting the website, you agree not to interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct. Don’t misuse our Services.</p>
            <p>Using our Services does not give you ownership of any property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed in or along with our Services. In connection with your use of the Services, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications.</p>
            <p>Some of our Services are available on mobile devices through apps in both android and mac platforms. Do not use such Services in a way that physically, mentally or socially hurts you in any way. Also, do not use such services to distract and to prevent you from obeying traffic or safety laws of the state.</p>
            <h5>Restrictions</h5>
            <p>All materials contained in the Shalom Media Europe and myShalom websites (“Online Material”) are the copyrighted property of Shalom Media Europe and myShalom. All trademarks, service marks, and trade names are proprietary to Shalom Media Europe and myShalom. No Online Materials including videos, music, from our websites may be downloaded, copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way, except that you may download one copy of the materials on any single computer for your personal, non-commercial home use only, provided that you 1) retain all copyright, trademark and propriety notices, 2) you make no modifications to the materials, 3) you do not use the materials in a manner that suggests an association with any of our products, services, events or brands, and 4) you do not download quantities of materials to a database, server, or personal computer for reuse for commercial purposes. You may not, however, copy, reproduce, republish, upload, post, transmit or distribute our Online Material in any way or for any other purpose unless you get our written permission first. Neither may you add, delete, distort or misrepresent any content on the Shalom Media Europe and myShalom sites. Attempts to modify any online materials, or to defeat or circumvent our security features are prohibited.</p>
            <h5>Submitting Online Materials</h5>
            <p>All remarks, ideas, comments, graphics, suggestions, testimonies, or other information that you send to myShalom and Shalom Media Europe through our sites (other than information we promise to protect under our privacy policy) becomes and remain our property, even if this agreement is later terminated. Such submissions are not treated as confidential. If we use them, or one similar in nature, we are not obligated to compensate you or anyone else for them. We will have the exclusive ownership of all present and future rights to the submissions of any kind we receive through websites. We may or may not use your name when using for promotions or any other use. We can use them for any purpose we deem appropriate to our myShalom and Shalom Media Europe mission, without compensating you or anyone else. If we use them, or one similar in nature, we are not obligated to compensate you or anyone else for them. We will have the exclusive ownership of all present and future rights to submissions of any kind. You acknowledge that you are responsible for any submission you make. This means that you (and not we) have full responsibility for the content, message, or presentation including its legality, reliability, appropriateness, originality, and copyright.</p>
            <h5>Limitations of Liability</h5>
            <p>Shalom Media Europe and www.my-shalom.org will not be liable for any damages or injury that accompany or result from your use of any of its sites. These include (but are not limited to) damages or injury caused by any:</p>
            <ul style="list-style: disc; margin-left: 40px;">
                <li>Use of or Inability to use the sites</li>
                <li>Use of or inability to use any site to which hyperlink from our sites</li>
                <li>Failure of our sites to perform in the manner you expected or desired</li>
                <li>Error or omission of our sites</li>
                <li>Interruption or availability of our sites</li>
                <li>Defect or delay in operation or transmission of our sites</li>
                <li>Computer virus or line failure</li>
            </ul>
            <p>We are also not liable for any damages caused by the use of our website. We are not liable even if we have been negligent.</p>
            <h5>Links</h5>
            <p>We sometimes provide referrals to and links to other World Wide Web sites from our sites. Such a link should not be seen as an endorsement, approval or agreement with any information or resources offered at sites you can access through our sites. If in doubt, always check the Uniform Resource Locator (URL) address provided in your WWW browser to see if you are still in a myShalom and Shalom Media Europe-operated site or have moved to another site. myShalom and Shalom Media Europe is not responsible for the content or practices of third party sites that may be linked to our sites. When myShalom and Shalom Media Europe provides links or references to other websites, no inference or assumption should be made and no representation should be inferred that myShalom and Shalom Media Europe is connected with, operates or controls these websites. Any approved link must not represent in any way, either explicitly or by implication, that you have received the endorsement, sponsorship or support of any myShalom and Shalom Media Europe site or endorsement, sponsorship or support of the ministry of myShalom and Shalom Media Europe, including its respective employees, agents or directors.</p>
            <h5>Termination</h5>
            <p>This agreement is effective until terminated by either party. You may terminate this agreement at any time, by destroying all materials obtained from all myShalom and Shalom Media Europe websites, along with all related documentation and all copies and installations. myShalom and Shalom Media Europe may terminate this agreement at any time and without notice to you, if, in its sole judgment, you breach any term or condition of this agreement. Upon termination, you must destroy all materials. In addition, by providing material on our websites, we do not in any way promise that the materials will remain available to you. And myShalom and Shalom Media Europe is entitled to terminate all or any part of any of its websites without notice to you.</p>
            <h5>Jurisdiction</h5>
            <p>Unless otherwise specified, the Online Materials on our sites are presented to provide information about the resources of myShalom and Shalom Media Europe. The ministry of myShalom and Shalom Media Europe was organized under the laws of the Republic of Ireland. If you use our sites from locations outside of the Republic of Ireland, you are responsible for compliance with any applicable laws. These Terms of Use, and the agreement they create, shall be governed by and interpreted according to the laws of the Republic of Ireland (without applying the state's conflict-of-law principles.) If any provision of this agreement is unlawful, void or unenforceable, it will not affect the validity and enforceability of any remaining provisions. myShalom and Shalom Media Europe may modify these Terms of Use, and the agreement they create, at any time, simply by updating this posting and without notice to you. This is the ENTIRE agreement regarding all the matters that have been listed.</p>
        </div>
        <div class="privacy_wrp" *ngIf="primaryCountry == 'gQFUOwHXczkw'">
            <h2>TERMS AND CONDITIONS</h2>
            <p>Welcome to www.my-shalom.org!</p>
            <p>Thank you for visiting our website. This website is provided by Shalom Media Canada (“Shalom Media Canada”), located at 1929 New Brighton Drive SE, Calgary, AB T2Z 4X1. www.my-shalom.org is the official website for Shalom Media Canada, a not for profit organization.</p>
            <h5>Using Our Website Services</h5>
            <p>www.my-shalom.org is so diverse in its content and services and so, sometimes additional terms or product requirements may apply. Additional terms will be available with the relevant Services, and those additional terms will become part of your agreement with us if you use those services. We have taken maximum effort to design our websites to be useful and informative in content and design. By visiting this website, services, and contents, you agree to abide by the following Terms and Conditions. By using our sites, you automatically agree to them. Naturally, if you don’t agree, please do not use our sites. We reserve the right to make any modifications that we deem necessary at any time. Please continue to check these terms to see what those changes may be. Your use of the myShalom and Shalom Media Canada websites means that you accept those changes.</p>
            <h5>You Must Follow Any Policies Made Available to You Within the Services</h5>
            <p>By visiting the website, you agree not to interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct. Don’t misuse our Services.</p>
            <p>Using our Services does not give you ownership of any property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed in or along with our Services. In connection with your use of the Services, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications.</p>
            <p>Some of our Services are available on mobile devices through apps in both android and mac platforms. Do not use such Services in a way that physically, mentally or socially hurts you in any way. Also, do not use such services to distract and to prevent you from obeying traffic or safety laws of the state.</p>
            <h5>Restrictions</h5>
            <p>All materials contained in the Shalom Media Canada and myShalom websites (“Online Material”) are the copyrighted property of Shalom Media Canada and myShalom. All trademarks, service marks, and trade names are proprietary to Shalom Media Canada and myShalom. No Online Materials including videos, music, from our websites may be downloaded, copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way, except that you may download one copy of the materials on any single computer for your personal, non-commercial home use only, provided that you 1) retain all copyright, trademark and propriety notices, 2) you make no modifications to the materials, 3) you do not use the materials in a manner that suggests an association with any of our products, services, events or brands, and 4) you do not download quantities of materials to a database, server, or personal computer for reuse for commercial purposes. You may not, however, copy, reproduce, republish, upload, post, transmit or distribute our Online Material in any way or for any other purpose unless you get our written permission first. Neither may you add, delete, distort or misrepresent any content on the Shalom Media Canada and myShalom sites. Attempts to modify any online materials, or to defeat or circumvent our security features are prohibited.</p>
            <h5>Submitting Online Materials</h5>
            <p>All remarks, ideas, comments, graphics, suggestions, testimonies, or other information that you send to myShalom and Shalom Media Canada through our sites (other than information we promise to protect under our privacy policy) becomes and remain our property, even if this agreement is later terminated. Such submissions are not treated as confidential. If we use them, or one similar in nature, we are not obligated to compensate you or anyone else for them. We will have the exclusive ownership of all present and future rights to the submissions of any kind we receive through websites. We may or may not use your name when using for promotions or any other use. We can use them for any purpose we deem appropriate to our myShalom and Shalom Media Canada mission, without compensating you or anyone else. If we use them, or one similar in nature, we are not obligated to compensate you or anyone else for them. We will have the exclusive ownership of all present and future rights to submissions of any kind. You acknowledge that you are responsible for any submission you make. This means that you (and not we) have full responsibility for the content, message, or presentation including its legality, reliability, appropriateness, originality, and copyright.</p>
            <h5>Limitations of Liability</h5>
            <p>Shalom Media Canada and www.my-shalom.org will not be liable for any damages or injury that accompany or result from your use of any of its sites. These include (but are not limited to) damages or injury caused by any:</p>
            <ul style="list-style: disc; margin-left: 40px;">
                <li>Use of or Inability to use the sites</li>
                <li>Use of or inability to use any site to which hyperlink from our sites</li>
                <li>Failure of our sites to perform in the manner you expected or desired</li>
                <li>Error or omission of our sites</li>
                <li>Interruption or availability of our sites</li>
                <li>Defect or delay in operation or transmission of our sites</li>
                <li>Computer virus or line failure</li>
            </ul>
            <p>We are also not liable for any damages caused by the use of our website. We are not liable even if we have been negligent.</p>
            <h5>Links</h5>
            <p>We sometimes provide referrals to and links to other World Wide Web sites from our sites. Such a link should not be seen as an endorsement, approval or agreement with any information or resources offered at sites you can access through our sites. If in doubt, always check the Uniform Resource Locator (URL) address provided in your WWW browser to see if you are still in a myShalom and Shalom Media Canada-operated site or have moved to another site. myShalom and Shalom Media Canada is not responsible for the content or practices of third party sites that may be linked to our sites. When myShalom and Shalom Media Canada provides links or references to other websites, no inference or assumption should be made and no representation should be inferred that myShalom and Shalom Media Canada is connected with, operates or controls these websites. Any approved link must not represent in any way, either explicitly or by implication, that you have received the endorsement, sponsorship or support of any myShalom and Shalom Media Canada site or endorsement, sponsorship or support of the ministry of myShalom and Shalom Media Canada, including its respective employees, agents or directors.</p>
            <h5>Termination</h5>
            <p>This agreement is effective until terminated by either party. You may terminate this agreement at any time, by destroying all materials obtained from all myShalom and Shalom Media Canada websites, along with all related documentation and all copies and installations. myShalom and Shalom Media Canada may terminate this agreement at any time and without notice to you, if, in its sole judgment, you breach any term or condition of this agreement. Upon termination, you must destroy all materials. In addition, by providing material on our websites, we do not in any way promise that the materials will remain available to you. And myShalom and Shalom Media Canada is entitled to terminate all or any part of any of its websites without notice to you.</p>         
            <h5>Jurisdiction</h5>
            <p>Unless otherwise specified, the Online Materials on our sites are presented to provide information about the resources of myShalom and Shalom Media Canada. The ministry of myShalom and Shalom Media Canada was organized under the federal laws of Canada. If you use our sites from locations outside of Canada, you are responsible for compliance with any applicable laws. These Terms of Use, and the agreement they create, shall be governed by and interpreted according to the laws of Canada Terms and Conditions (without applying the state's conflict-of-law principles.) If any provision of this agreement is unlawful, void or unenforceable, it will not affect the validity and enforceability of any remaining provisions. myShalom and Shalom Media Canada may modify these Terms of Use, and the agreement they create, at any time, simply by updating this posting and without notice to you. This is the ENTIRE agreement regarding all the matters that have been listed.</p>
        </div>
        <div class="privacy_wrp" *ngIf="primaryCountry == 'Sxx5Tbo4GL1w'">
            <h2>TERMS AND CONDITIONS</h2>
            <p>Thanks for visiting our website. This website is provided by</p>
            <p>Shalom Media Australia Ltd.
                PO BOX 62, 3/22 Aminya Street
                Mansfield, QLD 4122
                Australia</p>
                <p>www.my-shalom.org is the official website for Shalom Media Australia Ltd., registered as an incorporated association under the Associations Incorporation Act 2009. Incorporation No: INC1400473.</p>
            <h5>Using Our Website Services</h5>
            <p>www.my-shalom.org is so diverse in its content and services and so, sometimes additional terms or product requirements may apply. Additional terms will be available with the relevant Services, and those additional terms will become part of your agreement with us if you use those services. We have taken the maximum effort to design our website to be useful and informative in content and design. By visiting this website, services, and contents, you agree to abide by the following Terms and Conditions. By using our website, you automatically agree to them. Naturally, if you do not agree, please do not use our sites. We reserve the right to make any modifications that we deem necessary at any time. Please continue to check these terms to see what those changes may be. Your use of the Shalom Media Australia Ltd. website means that you accept those changes.
                You must follow any policies made available to you within the Services.
                By visiting the website, you agree not to interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct. Do not misuse our Services.
                Using our Services does not give you ownership of any property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Do not remove, obscure, or alter any legal notices displayed in or along with our Services. In connection with your use of the Services, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications.
                Some of our Services are available on mobile devices through apps both android and mac platforms. Do not use such Services in a way that physically, mentally or socially hurts you in anyway. Also do not use such services, to distract you and prevent you from obeying traffic or safety laws of the state.</p>
            <!-- <h5>You Must Follow Any Policies Made Available to You Within the Services</h5>
            <p>By visiting the website, you agree not to interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct. Don’t misuse our Services.</p>
            <p>Using our Services does not give you ownership of any property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed in or along with our Services. In connection with your use of the Services, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications.</p>
            <p>Some of our Services are available on mobile devices through apps in both android and mac platforms. Do not use such Services in a way that physically, mentally or socially hurts you in any way. Also, do not use such services to distract and to prevent you from obeying traffic or safety laws of the state.</p> -->
            <h5>Restrictions</h5>
            <P>All materials contained in the Shalom Media Australia Ltd. website (“Online Material”) is the copyrighted property of Shalom Media Australia Ltd. All trademarks, service marks, and trade names are proprietary to Shalom Media Australia Ltd. No Online Materials including videos, music, from our website may be downloaded, copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way, except that you may download one copy of the materials on any single computer for your personal, non-commercial home use only, provided that you 1) retain all copyright, trademark and propriety notices, 2) you make no modifications to the materials, 3) you do not use the materials in a manner that suggests an association with any of our products, services, events or brands, and 4) you do not download quantities of materials to a database, server, or personal computer for reuse for commercial purposes. You may not, however, copy, reproduce, republish, upload, post, transmit or distribute our Online Material in any way or for any other purpose unless you get our written permission first. Neither may you add, delete, distort or misrepresent any content on the Shalom Media Australia Ltd. website. Attempts to modify any Online Materials, or to defeat or circumvent our security features are prohibited.</P>  
            <h5>Submitting Online Materials</h5>
            <p>All remarks, ideas, comments, graphics, suggestions, testimonies, or other information that you send to Shalom Media Australia Ltd. through our websites (other than information we promise to protect under our privacy policy) becomes and remains our property, even if this agreement is later terminated. Such submissions are not treated as confidential. If we use them, or one similar in nature, we are not obligated to compensate you or anyone else for them. We will have the exclusive ownership of all present and future rights to the submissions of any kind we receive through websites. We may or may not use your name when using for promotions or any other use. We can use them for any purpose we deem appropriate to our Shalom Media Australia Ltd. mission, without compensating you or anyone else. If we use them, or one similar in nature, we are not obligated to compensate you or anyone else for them. We will have the exclusive ownership of all present and future rights to submissions of any kind. You acknowledge that you are responsible for any submission you make. This means that you (and not we) have full responsibility for the content, message, or presentation including its legality, reliability, appropriateness, originality, and copyright.</p>
            <h5>Limitations of Liability</h5>
            <p>Shalom Media Australia Ltd. and www.my-shalom.org will not be liable for any damages or injury that accompany or result from your use of any of its sites. These include (but are not limited to) damages or injury caused by any:</p>
            <ul style="list-style: disc; margin-left: 40px;">
                <li>Use of or Inability to use the sites</li>
                <li>Use of or inability to use any site to which hyperlink from our sites</li>
                <li>Failure of our sites to perform in the manner you expected or desired</li>
                <li>Error or omission of our sites</li>
                <li>Interruption or availability of our sites</li>
                <li>Defect or delay in operation or transmission of our sites</li>
                <li>Computer virus or line failure</li>
            </ul>
            <p>We are also not liable for any damages caused by the use of our website. We are not liable even if we have been negligent.</p>
            <h5>Links</h5>
            <p>We sometimes provide referrals to and links to other World Wide Web sites from our website. Such a link should not be seen as an endorsement, approval or agreement with any information or resources offered at sites you can access through our website. If in doubt, always check the Uniform Resource Locator (URL) address provided in your WWW browser to see if you are still in a Shalom Media Australia Ltd. -operated site or have moved to another site. Shalom Media Australia Ltd. is not responsible for the content or practices of third party sites that may be linked to our website. When Shalom Media Australia Ltd. provides links or references to other Websites, no inference or assumption should be made and no representation should be inferred that Shalom Media Australia Ltd. is connected with, operates or controls these Websites. Any approved link must not represent in any way, either explicitly or by implication, that you have received the endorsement, sponsorship or support of any Shalom Media Australia Ltd. site or endorsement, sponsorship or support of the ministry of Shalom Media Australia Ltd., including its respective employees, agents or directors.</p>
            <h5>Termination</h5>
            <p>This agreement is effective until terminated by either party. You may terminate this agreement at any time by destroying all materials obtained from the Shalom Media Australia Ltd. website, along with all related documentation and all copies and installations. Shalom Media Australia Ltd. may terminate this agreement at any time and without notice to you, if, in its sole judgment, you breach any term or condition of this agreement. Upon termination, you must destroy all materials. In addition, by providing material on our website, we do not in any way promise that the materials will remain available to you. And Shalom Media Australia Ltd. is entitled to terminate all or any part of any of its website without notice to you.</p>
            <h5>Jurisdiction</h5>
            <p>Unless otherwise specified, the Online Materials on our sites are presented to provide information about the resources of Shalom Media Australia Ltd. The ministry of Shalom Media Australia Ltd. was organized under the laws of Victoria, in Australia. If you use our website from locations outside of Australia, you are responsible for compliance with any applicable laws. These Terms of Use, and the agreement they create, shall be governed by and interpreted according to the laws of the State of Victoria (without applying the state’s conflict-of-law principles.) If any provision of this agreement is unlawful, void or unenforceable, it will not affect the validity and enforceability of any remaining provisions. Shalom Media Australia Ltd. may modify these Terms of Use, and the agreement they create, at any time, simply by updating this posting and without notice to you. This is the ENTIRE agreement regarding all the matters that have been listed.</p>
        <h5>FINANCIAL TRANSACTION AND CREDIT CARD PAYMENT POLICY</h5>
        <p>Introduction</p>
        <p>Providing individuals the option of giving donations by credit card through the myShalom website is a major convenience for both donors as well as for Shalom Media Australia Limited (“SMA”). When properly managed, employees/volunteers can contribute to easier and more secure accounting of income. To achieve these benefits, a number of precautionary procedures should be put in place.</p>
        <h5>Purpose</h5>
        <p>The purpose of this policy is to ensure that donations are received as efficiently and quickly as possible through the acceptance of payments by credit card.</p>
    <h5>Policy</h5>
    <p>Card details provided to SMA will be kept confidential and only authorised staff or officers on behalf of the organisation will have access to those activities and the amount specified by the contributor. Credit card use will be monitored according to the procedures listed below. Any use of the credit card inconsistent with this policy and these procedures will be grounds for dismissal.</p>
   <p>Authorisation</p>
   <h5>FINANCIAL TRANSACTION CREDIT CARD PROCEDURES</h5>
   <h5>Responsibilities</h5>
   <p>It is the responsibility of the Chief Administrative Officer (CAO) to ensure that:</p>
   <ul>
       <li>staff and volunteers are aware of this policy;</li>
       <li>any breaches of this policy coming to the attention of management are dealt with appropriately
        It is the responsibility of all the responsible employees and volunteers to ensure that the usage of contributors’ credit cards conforms to this policy.</li>
   </ul>
<h5>Processes</h5>
<p>1. Contributor Card Usage
    The charging of each financial transaction card will be undertaken by specific persons who will remain accountable for charging those cards provided by contributors to SMA. Contributors will select the box on the declaration statement to this effect as “Agree” to charge.
    Only the authorised staff or volunteer may use the contributor’s credit card as given. If payments are not going through, then the authorised person is to contact the contributor and inform of the situation. Any change of card details is to be updated by the contributor for further processing.</p>
<p>2. Responsibilities and Usage of Credit Cards
    Shalom Media Australia Limited:</p>
    <ul>
        <li>* shall maintain the website to reflect the various amounts for donations as options and the frequency of charges as monthly and/or one-time;</li>
   <li>* shall provide on its website an option for contributors to cancel further deductions (if they had previously selected a monthly payment option);</li>
    <li>* shall, in all cases, obtain and retain sufficient supporting documentation to validate the payments or shall in lieu provide a statutory declaration;</li>
<li>* shall attach supporting documentation to the monthly statement from the bank;shall review the monthly statement for inaccuracies and report these to the CAO;</li>
<li>* shall sign the monthly statement to verify that transactions have been made for official purposes;</li>
<li>* shall notify the contributor immediately if: the credit card number details are wrong;any unauthorised transaction is detected or suspected</li>
<li>* shall notify the bank of any change in name or contact details;</li>
<li>* shall take adequate measures to ensure the security of credit card numbers</li>

</ul>
<p>3. Credit Card Refund Policy</p>
<ul>
   <li>* If any duplicate payments or unauthorised use of the credit card within SMA occurs, then the responsible person in SMA will report the matter to the CAO and the respective card holder, and then organise refund of the amount within two business days. The refund amount will be in full and transaction charges will be borne by SMA.</li>
   <li>* If credit cards are cancelled, then the responsible person at SMA will contact the contributor without much delay and update the details.</li>
   <li>* Responsible person is liable for any unauthorised transaction for payments outside SMA with the given card details, unless donor’s card is lost, stolen or subject to fraud on some part of a third party.</li>
   <li>* If an event registration is cancelled, the registrant will be eligible for a refund if the cancellation is prior to the stated cancellation cut-off date.</li>
   <li>* If a publication subscription is initially paid in advance and later part way through if cancelled by the Donor or if unable to provide by SMA, the subscriber will be eligible for a refund based on the issues remaining for the subscription.</li>
   <li>* SMA will only be able to honor any one-time donation refund requests if the request is made within 30 days of the actual donation.
Authorised person to charge the credit card and :</li>
<li>* must process only the amount specified by the contributor;</li>
<li>* must ensure that the contributor selects (required) the box to declare the amount to charge;</li>
<li>* must not use the credit card for any proscribed purchases of the company;</li>
<li>* must accept the payment notified through an acknowledgement letter or email to the donor appropriately;</li>
<li>* must ensure that the account maintains a minimum balance of $5000 at any point in time to meet the refund</li>
</ul>
<p>4. Credit Card Misconduct</p>
<p>Wherever a breach in this policy occurs, the CAO must assess the nature of the breach and institute an appropriate disciplinary process, including (without limitation of SMA’s right to summarily dismiss an employee or volunteer for serious misconduct):</p>
<ul>
    <li>* counselling and / or verbal warning (and note created and retained in employee’s personnel file);</li>
    <li>* a written warning
The CAO, after consultation with the Board of Directors, may determine whether to report a breach of the policy to the police for a criminal investigation.
At the next Finance Committee meeting, the CAO shall report:</li>
<li>* the investigation of the circumstances of the breach;</li>
    <li>* police reports and action (if any); and</li>
        <li>* disciplinary action taken (if any)</li>
</ul>
<p>Authorisation</p>
<p>Signature of CEO / Administration Manager / President of BOD</p>
<p>Name of CEO</p>
<p>Date</p>
</div>
</div>
<footer>
    <div class="container">
        <div class="footer_wrp">
        
                <div class="footer_menu_link d-flex">
                    <ul class="ul-col ul-first-col">
                        <li><a href="https://www.shalommedia.org/mission/" class="active">Mission</a></li>
                        <li><a href="https://www.shalommedia.org/history/">History</a></li>
                        <li><a href=" https://www.shalommedia.org/endorsement/">Endorsement</a></li>
                        <li><a href="https://www.shalommedia.org/milestones/">Milestones</a></li>
                        <li><a href="https://www.shalomworld.org/joinspf">Peace Fellowship</a></li>
                        <!-- <li><a href="https://beta.shalomworld.org/about">About Us</a></li> -->
                    </ul>
                    <ul class="ul-col ul-sec-col">
                        <li>
                            <a href="https://www.shalomworld.org/shows">Shows</a>
                        </li>
                        <li><a href="https://www.shalomworld.org/news">News</a></li>
                        <li><a href="https://www.shalomworld.org/watchon/connectedtv">Connected TV</a></li>
                        <li><a href="https://www.shalomworld.org/swplus">SW+</a></li>
                        <li><a href=" https://www.shalomworld.org/watchlive">Watch Live</a></li>
                    </ul>
                    <ul class="ul-col ul-third-col">
                        <li><a href="https://shalomtidings.org/prayers/" target="_blank">Prayers</a></li>
                        <li><a href="https://www.shalommedia.org/events/" target="_blank">Events</a></li>
                        <li><a href="https://www.shalommedia.org/volunteer/">Volunteer</a></li>
                        <li><a href="https://www.shalomworld.org/career">Careers</a></li>
                        <li><a href="https://www.shalommedia.org/press_release/">Press Release</a></li>
                    </ul>
                    <ul class="ul-col ul-fourth-col">
                        <li><a href=" https://swprayer.org/" target="_blank">SW Prayer</a></li>
                        <li><a href="https://www.swpals.org/" target="_blank">SW Pals</a></li>
                        <li><a href="https://www.shalomworld.org/show/movies">SW Movies</a></li>
                        <li><a href="shalommedia.de">Shalom Media German</a></li>
                        <li><a href="shalommedia.es">Shalom Media Spanish</a></li>
                    </ul>
                    <div class="footer_applink_right">
                        <!-- <h5>Download</h5> -->
                        <h5 class="mb-3">Get our Apps</h5>
                        <ul class="d-flex  align-items-center">
                            <li>
                                <button class="btn btn-lg dropdown-toggle" type="button"  data-toggle="dropdown">
                                    <img src="./assets/member/images/icon-ios.svg" alt=""><span class="icon-android"></span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="https://www.shalomworld.org/watchon/apps/android" target="_blank">Shalom World</a>
                                    <a class="dropdown-item" href="https://itunes.apple.com/us/app/shalom-tidings/id1305953479?mt=8" target="_blank">Shalom Tidings</a>
                                
                                </div>
                                <!-- <a href="https://beta.shalomworld.org/watchon/apps"></a> -->
                                
                            </li>
                            <li>
                                <button class="btn btn-lg dropdown-toggle" type="button"  data-toggle="dropdown">
                                    <img src="./assets/member/images/icon-android.svg" alt=""><span class="icon-android"></span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="https://www.shalomworld.org/watchon/apps/ios" target="_blank">Shalom World</a>
                                    <a class="dropdown-item" href="https://play.google.com/store/apps/details?id=com.shalomworld.shalomtidings&amp;hl=en" target="_blank">Shalom Tidings</a>
                                </div>
                            </li> 
                            <li>
                                    <a class="" href="https://www.amazon.com/Shalom-Tidings/e/B094668STL/ref=dp_byline_cont_ebooks_1" target="_blank"><img src="./assets/member/images/icon-kindle.png" alt=""></a>
                            </li> 
                        </ul>
                    </div>
                </div>
        </div>
        <div class=" footer_btm mt-5">
        
            <div class="d-flex footer_btm_link">
                <div class="d-flex footer_btm_left">
                    <p>
                    Copyright Shalom Media 2022. All rights reserved.
                    </p>
                    <ul class="d-flex">
                        <li> <a href="/privacy-policy">Privacy Statement</a> </li>
                        <li><span>|</span></li>
                        <li>
                            <a href="/terms-and-conditions">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
                <!-- <div class="footer_btm_right">
                    <p>Designed by <a href="https://webandcrafts.com" target="_blank"> webandcrafts</a></p>
                </div>
            -->
            </div>
            <a (click)="scrollTop()" class="backtotop">
            <p>Back to Top<span class="icon-arrow"></span></p>
        </a>
        </div>
</div>

</footer>
</div>



