import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { RecaptchaModule ,RecaptchaFormsModule} from 'ng-recaptcha';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    // FormGroup,
    RouterModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [ CookieService ],
})
export class LoginModule { }
