import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentdetailsComponent } from './attachmentdetails.component';



@NgModule({
  declarations: [AttachmentdetailsComponent],
  imports: [
    CommonModule
  ]
})
export class AttachmentdetailsModule { }
