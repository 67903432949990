import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DonationPaymentComponent } from './donation-payment.component';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../main.component';
import { MainModule } from '../main.module';
import { SavedcardlistModule } from '../savedcardlist/savedcardlist.module';
import { NgxBraintreeModule } from 'ngx-braintree';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
const routes: Routes = [
  {
    'path': '',
    'component': MainComponent,
    'children': [
      {
        'path': '',
        'component': DonationPaymentComponent,
      },
    ],
  },
];
@NgModule({
  declarations: [DonationPaymentComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MainModule,
    NgSelectModule,
    NgxBraintreeModule,
    SavedcardlistModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class DonationPaymentModule { }
