import { Component, OnInit, ViewEncapsulation, AfterViewInit, Input, Output, EventEmitter, OnDestroy,AfterViewChecked } from '@angular/core';
import { SharedService } from '../../../../_services/shared.service';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactUsPopupComponent } from '../contact-us-popup/contact-us-popup.component';
import { AddMemberComponent } from '../add-member/add-member.component';
import { VideoPopupComponent } from '../video-popup/video-popup.component';
import { Swiper } from 'swiper';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { DomSanitizer } from '@angular/platform-browser';

// import SwiperCore, { Navigation } from 'swiper';
import SwiperCore, { Pagination, Navigation, A11y } from 'swiper';
import { Subscription } from 'rxjs';
import { EditContactInfoComponent } from '../edit-contact-info/edit-contact-info.component';
import { isInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
declare let $: any;
@Component({
  selector: 'app-home-member',
  templateUrl: './home-member.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css', './home-member.component.scss'],
  // encapsulation: ViewEncapsulation.None

})
export class HomeMemberComponent implements OnInit, OnDestroy {
  routeParam;
  memberId;
  countryId;
  listOfCauses;
  listOprogram;
  listOfFamilyMem: any;
  formData: any;
  isLoading:boolean = false;
  familyMemberCount;
  listOfVideos: any = [];
  isSpfmember: string;
  response: { url: any; };
  eventTypes;
  totalEventCount;
  activeEventTypeList;
  eventTypeIndex = -1;
  imageObject: ({ image: string; thumbImage: string; title: string; } | { image: string; thumbImage: string; title?: undefined; })[];
  primaryCountryId: string;
  currencyList: any;
  currencyPaymentList: any;
  Currency: any;
  paymentOptionsList: any;
  paymentOption: any;
  selectedPaymentOption: any;
  SelectedPaymentOptionId: any;
  frequencyList: any;
  selectedFrequencyId: any;
  PaymentOptionValueId: any;
  frequencyOptions: any[];
  selectedFrequencyName: any;
  initialSelectedAmount: any;
  initialFrequency: any;
  donationForm: FormGroup;
  minAmount: any;
  otheramoutValidation: boolean;
  maxOtherAmount: boolean;
  bannerList;
  safeURL: any;
  iframeLoad: boolean = false;
  message: string;
  // subscriptions: Subscription;
  dataShared: Subscription;
  private fragment: string;//added for scrolling issue
  fragmentUrl;
  listOfEvents: any = [];
  publicationList: any = [];
  video: boolean = false;
  defaultCauseAmount: any;
  defaultCauseYear: any;
  CurrencyCodeDefaultCause: any;
  bookUrl: any;
  defaultCauseId;
  defaultCauseName;
  donationTotal: any;
  recentNews = [];
  recentComingUpNews = [];
  isSpfMember: boolean;
  singleDonationExist: boolean = false;
  totalDonatedAmount = 0;
  currentYear: any;
  comingProgramPrayer: any;
  comingProgramworld: any;
  holyMass = [];
  decimalVal: boolean = false;
  decimalError: boolean = false;
  videoSwiperSlider:boolean = true;
  
  // @Output() msgToSibling = new EventEmitter<any>();
  // currentMsgToSibling: any = "success"
  constructor(public sharedService: SharedService, public _crud: CommonCrudService, public modalService: NgbModal,
    public activeRoute: ActivatedRoute, private router: Router, public formBuilder: FormBuilder, private _sanitizer: DomSanitizer) { }

  ngOnInit() {

    this.activeRoute.fragment.subscribe(fragment => {
      this.fragment = fragment;//added for scrolling issue
    });
    this.routeParam = this.activeRoute.snapshot;
    this.fragmentUrl = this.activeRoute.snapshot.fragment;
    if (this.fragmentUrl == 'publications') {
      this.sharedService.fetchPathData('dashboard', 'publications');
    } else {
      this.sharedService.fetchPathData('dashboard', 'home');
    }
    // console.log("params---fragmentUrl--",this.fragmentUrl);
    this.isSpfmember = localStorage.getItem('IsSpfMember');
    this.primaryCountryId = localStorage.getItem('primaryCountryId')
    this.memberId = this.routeParam.params['memberId'];
    this.countryId = localStorage.getItem('countryId');
    this.formInIt();
    this.defaultCause();
    this.causeList();
    this.eventTypeList();
    this.eventList();
    this.programList();
    this.familyMembers();
    this.videoList();
    this.getMemberBanner();
    this.getPublication();
    this.getRecentNews();
    this.getComingUpNews();
    this.getTotalDonationAmount();
    this.getComingProgramsswprayer();
    this.getComingProgramsshalomworld();
    this.getHolymass();
    this.isSpfMember = (localStorage.getItem('IsSpfMember') == 'true' ? true : false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.singleDonationExist = currentUser.singleDonationExist;

  }
  ngOnDestroy(): void {
    if (this.dataShared) {
      this.dataShared.unsubscribe();
    }
  }


  formInIt() {
    this.donationForm = this.formBuilder.group({
      // Member:[this.selectedMemberId],
      causeId: ['158198755174B'],
      // Date:[this.activeRecurringDate],
      // CausePaymentOptionId: [this.SelectedPaymentOptionId],
      // CausePaymentOptionValueId: [this.PaymentOptionValueId],
      Amount: [this.initialSelectedAmount, Validators.compose([Validators.minLength(1), Validators.maxLength(12), Validators.pattern('^[0-9.]*$')])],
      Frequency: [this.initialFrequency],
      OtherAmount: [null],
      CurrencyISOCode: [this.Currency],
      causeName: ['SPF Default Cause']


    });
  }
 
  ngAfterViewInit() {
    
      try {
        document.querySelector('#' + this.fragment).scrollIntoView();
      } catch (e) {}//added for scrolling issue
  
    SwiperCore.use([Navigation, Pagination, A11y]);
    var swiper = new Swiper('.swiper1', {
      direction: 'horizontal',
      slidesPerView: 1,
      // spaceBetween: 1,
      slidesPerGroup: 1,
      // loop: true,
      // loopFillGroupWithBlank: true,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swp1',
        clickable: true,
      },
      navigation: {
        nextEl: '.swn1',
        prevEl: '.swnp1',
      },
    });
    setTimeout(this.initializeVideoSlider,200);
    setTimeout(this.initializeEventSlider,200);
    setTimeout(this.initilizePublicationSlider,200);
    // family meber slider
    var swiper = new Swiper('.swiper3', {
      direction: 'horizontal',
      slidesPerView: 3,
      spaceBetween: 10,
      slidesPerGroup: 3,
      // loop: true,
      loopFillGroupWithBlank: true,
      observer: true,
      observeParents: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        },

        480: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        767: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 10
        },

        1350: {
          slidesPerView: 3,
          spaceBetween: 10
        },
      },
     
      navigation: {
        nextEl: '.swn3',
        prevEl: '.swnp3',
      },
    });

  }

  imageClickHandler(e) {
    // console.log('image click', e);
  }
  modalOpen(type) {

    const modalRef = this.modalService.open(ContactUsPopupComponent, {
      size: "lg"
    });
    modalRef.componentInstance.memberId = this.memberId;
    modalRef.componentInstance.type = type;
  }
  causeList() {
    let params = {
      url: "frontend-site/cause/list", params: { CountryId: this.countryId }
    }
    this._crud.getData(params).subscribe(res => {

      // console.log("cause list----", res);
      this.listOfCauses = res['data'];
    });
  }
  eventList(data = null, index = -1) {
    // console.log("index-------",index)
    this.eventTypeIndex = index;
    if(localStorage.getItem('eventListData') != null){
      // console.log("eventListData-------",localStorage.getItem('eventListData'))
      this.listOfEvents =  JSON.parse(localStorage.getItem('eventListData'))
    }else if(localStorage.getItem('eventListData') == null){
      console.log("eventListData---api----",localStorage.getItem('eventListData'))

      let params = {
        url: "frontend-site/event/list", params: { MemberId: this.memberId, EventTypeId: data == null ? '' : data }
      }
      this._crud.getData(params).subscribe(res => {
  
        console.log("nitilize event  event list----", res);
        this.listOfEvents = res['data'];
        setTimeout(this.initializeEventSlider,200);
        this.listOfEvents.forEach(element => {
          let eventStartDate = this._crud.spaceSplit(new Date(element.EventStartDate).toDateString());
          element.startDay = eventStartDate[2];
          element.startMonth = eventStartDate[1];
          element.startYear = eventStartDate[3];
          let eventEndDate = this._crud.spaceSplit(new Date(element.EventEndDate).toDateString());
          element.endDay = eventEndDate[2];
          element.endMonth = eventEndDate[1];
          element.endYear = eventEndDate[3];
        });
        localStorage.setItem('eventListData',JSON.stringify(this.listOfEvents));
      });
    }
   
  }
  programList() {

    let params = {
      url: "frontend-site/program-list"
    }
    this._crud.getData(params).subscribe(res => {

      // console.log("program list----", res);
      this.listOprogram = res['data'];
    });
  }
  familyMembers() {

    let params = {
      url: "frontend-site/list-family-members-with-primary-member", params: { PrimaryMemberId: this.memberId }
    }
    this._crud.getData(params).subscribe(res => {

      // console.log("family member list----", res);
      this.listOfFamilyMem = res['data'];
      this.familyMemberCount = this.listOfFamilyMem.length;
      if (this.familyMemberCount < 9)
        this.familyMemberCount = "0" + this.familyMemberCount;
      this.listOfFamilyMem.forEach(element => {
        if (element.SpfId == null) {
          element.addnew = 1;
        } else {
          element.addnew = 0;

        }
        this.listOfFamilyMem.forEach(element => {
          if (element.IsPrimaryMember == 1) {
            this.sharedService.memberInfo(element['PhotoUrl']);
            this.sharedService.memberFname(element['FirstName'])
            this.sharedService.memberLname(element['LastName'])
            this.sharedService.memberTitle(element['SalutationName'])


          }
        });
      });
    });

  }
  addMember(form = null) {
    // console.log(form);
    this.formData = form;
    const modalRef = this.modalService.open(AddMemberComponent, {
      size: "lg"
    });
    modalRef.componentInstance.formData = this.formData;
    modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
        if (result.result) {
          // /clearing all forms.
          this.familyMembers();
        }
      });
  }
  eventTypeList() {
    let params = {
      url: "frontend-site/event-type/list", params: { MemberId: this.memberId }
    }
    this._crud.getData(params).subscribe(res => {

      // console.log("Event type list----", res);
      this.eventTypes = res['data']['EventTypeCount'];
      this.totalEventCount = res['data']['TotalEventCount'];

    });
  }
  videoList() {
    console.log("indu inside video list",localStorage.getItem('videoApiCounter'))
    if(localStorage.getItem('videoApiCounter') != null){
      this.listOfVideos =  JSON.parse(localStorage.getItem('apiVideoData'))
    }else if(localStorage.getItem('videoApiCounter') == null){
      console.log("indu inside video list api call")
      let params = {
        url: "frontend-site/video-profile", params: { MemberId: this.memberId, IsSpfMember: (this.isSpfmember == 'true' ? 1 : 0) }
      }
        this._crud.getData(params).subscribe(res => {
        this.listOfVideos = res['data'];
        localStorage.setItem("videoApiCounter",'1')
        localStorage.setItem('apiVideoData',JSON.stringify(this.listOfVideos));
      });
    }
    

  }
  donateFRomFamily(memberId: any = null, spf: boolean = false, onetimeHide: boolean = false) {
    console.log("inde donateFRomFamily----", spf, onetimeHide)
    if (!spf) {
      this.router.navigate([`memberDashboard/mydonation/` + this.memberId + `/donation`], { queryParams: { memberId: memberId } });
    } else if (spf && onetimeHide) {
      this.router.navigate([`memberDashboard/mydonation/` + this.memberId + `/donation`], { queryParams: { memberId: memberId, causeId: this.defaultCauseId, causeName: this.defaultCauseName, onetimeHide: true } });
    }
    else {
      if (this.otheramoutValidation != true && this.maxOtherAmount != true && this.decimalVal != true && this.decimalError != true) {
        if (this.donationamount != '') {
          this.router.navigate([`memberDashboard/mydonation/` + this.memberId + `/donation`], { queryParams: { memberId: memberId, causeId: this.defaultCauseId, causeName: this.defaultCauseName, amount: this.donationamount } });

        } else {
          this.router.navigate([`memberDashboard/mydonation/` + this.memberId + `/donation`], { queryParams: { memberId: memberId, causeId: this.defaultCauseId, causeName: this.defaultCauseName } });

        }
      }
    }


  }
  donate(id: any = null, name: any = null) {
    console.log("this.routeParam = this.activeRoute.snapshot;", id)
    this.router.navigate([`memberDashboard/mydonation/` + this.memberId + `/donation`], { queryParams: { causeId: id, causeName: name } });

  }
  registerEvent(eventDate, eventType, eventName) {
    this.router.navigate(["/memberDashboard/myeventregistration/" + this.memberId + "/eventregistration"], { queryParams: { eventDate: eventDate, eventType: eventType, eventName: eventName } });
  }
  viewAllEvent() {
    this.router.navigate(["/memberDashboard/" + this.memberId + "/eventList"]);

  }
  viewAllDonation() {
    this.router.navigate(["/memberDashboard/" + this.memberId + "/donationCauseList"]);

  }
  viewAllFamilymember() {
    this.router.navigate(["/memberDashboard/" + this.memberId + "/familyMemberList"]);

  }
  allVideoList() {
    this.router.navigate(["/memberDashboard/" + this.memberId + "/videoList"]);

  }
  subscription(subType, memberId = null) {
    this.router.navigate(["/memberDashboard/mysubscription/" + this.memberId + "/subscription"], { queryParams: { subType: subType, memberId: memberId } });


  }
  subscriptionfrmPublication(subType, publicationId) {
    this.router.navigate(["/memberDashboard/mysubscription/" + this.memberId + "/subscription"], { queryParams: { subType: subType, publicationId: publicationId } });


  }
  videoModal(url) {
    // console.log(url);
    const modalRef = this.modalService.open(VideoPopupComponent, {
      size: "lg"
    });
    this.response = { url: url }

    modalRef.componentInstance.user = this.response;

  }
  rename() { // function to rename on button click
    // console.log("inside rename function")
    this.listOfVideos.map(function (obj) {
      obj['video'] = obj['VideoUrl']; // Assign new key
      obj['title'] = obj['videoDescription']
      delete obj['VideoUrl']; // Delete old key
      // return obj;
    });
    // console.log("new viso list=======",this.listOfVideos);
    this.imageObject = this.listOfVideos;
  }
  defaultCause() {
    let param = {}
    param["url"] = 'frontend-site/donation/default-cause'
    param["params"] = { MemberId: this.memberId }
    this._crud.getData(param).subscribe(data => {
      // console.log("getpayment optionss------>",data['data']);
      let params = {}
      this.defaultCauseAmount = data['data']['DonationTotal'];
      this.defaultCauseYear = data['data']['DonationYear'];
      this.defaultCauseId = data['data']['CauseId'];
      this.defaultCauseName = data['data']['CauseName'];
      localStorage.setItem('dCauseId', this.defaultCauseId);
      localStorage.setItem('dCauseName', this.defaultCauseName);
      this.CurrencyCodeDefaultCause = data['data']['CurrencyCode'];
      this.donationTotal = data['data']['DonationTotal'];
      params["params"] = {
        PrimaryCountryId: this.primaryCountryId,
        CauseName: data['data']['CauseName'],
        CauseId: data['data']['CauseId'],
        CountryId: this.countryId
      }
      params["url"] = 'frontend-site/member-donation/payment-options'
      this._crud.getData(params).subscribe(data => {
        // console.log("getpayment optionss------>",data['data']);
        this.currencyList = data['data'].CurrencyCodeList;
        this.paymentOptionsList = data['data'];
        this.currencyPaymentList = data['data'].PaymentOptionList;
        // console.log("@@@@@@@@@@@@@@@@@@----->",this.currencyList,this.currencyPaymentList);
        // this.Currency = this.currencyList[0].CurrencyCode;
        let param = {
          url: "frontend-site/member/get-currency/" + this.memberId
        }
        this._crud.getData(param).subscribe(data1 => {
          console.log(data1['data'], data1['data']['CurrencyCode']);


          // this.currencyList.forEach(element2 => {
          //     if (data1['data']['CurrencyCode'] == element2.CurrencyCode) {
          //         console.log("am here");
          //         this.Currency = element2.CurrencyCode;
          //     }

          // });
          if ((this.currencyList.filter(x => { return x.CurrencyCode == data1['data']['CurrencyCode'] })).length > 0) {
            console.log("first if", this.currencyList.filter(x => { return x.CurrencyCode == data1['data']['CurrencyCode'] }));
            this.getCurrencyOptions(data1['data']['CurrencyCode']);
          } else if ((this.currencyList.filter(x => { return x.CurrencyCode == data1['data']['PrimaryCountryCurrencyCode'] })).length > 0) {
            console.log("second if", this.currencyList.filter(x => { return x.CurrencyCode == data1['data']['PrimaryCountryCurrencyCode'] }));

            this.getCurrencyOptions(data1['data']['PrimaryCountryCurrencyCode']);

          } else {
            // this.Currency = this.currencyList[0].CurrencyCode;
            console.log("third", this.currencyList[0].CurrencyCode)
            this.getCurrencyOptions(this.currencyList[0].CurrencyCode);
          }
        });

        //   if (this.currencyList.length) {
        //     setTimeout(() => {
        //         this.getCurrencyOptions(this.Currency);
        //     }, 0);
        // }
      });
    });

  }
  getCurrencyOptions(currency) {
    let swapFrequencyList = [];
    this.Currency = currency;

    this.paymentOption = this.paymentOptionsList.PaymentOptionList.filter(x => { return x.CurrencyCode == currency });
    this.selectedPaymentOption = this.paymentOption[0];
    this.SelectedPaymentOptionId = this.paymentOption[0].PaymentOptionId;
    if (this.selectedPaymentOption) {
      this.minAmount = this.selectedPaymentOption.MinimumAmount;
    }
    if (this.paymentOption) {
      this.frequencyList = this.paymentOption[0].FrequencyList
      // console.log('frequencyList', this.frequencyList)
      // swap frequencylist
      this.frequencyList.forEach(element => {
        if (element.Name == 'Monthly') {
          element.Name = 'Recurring (Monthly)'
          this.initialFrequency = element.Id
        }
      });
      if (this.frequencyList.length > 1) {

        if (this.frequencyList[0].Name == 'Onetime') {
          swapFrequencyList[0] = this.frequencyList[1];
          swapFrequencyList[1] = this.frequencyList[0];
          // console.log("sssssssss", swapFrequencyList);
          this.frequencyList = swapFrequencyList;
        }
      }
      // if (this.isSpfmember == 'false' && this.frequencyList.length > 1) {
      //   this.frequencyList.splice(1, 1);
      // }
      let frequency = {
        id: this.frequencyList[0].Id,
        checked: false
      }
      this.getFrequencyOptions(frequency);
    }
  }
  getFrequencyOptions(frequency) {
    // console.log("get frequecy-----",frequency);
    this.PaymentOptionValueId = null;
    this.selectedFrequencyId = frequency.id;
    if (frequency.id == "any_amount") {
      if (frequency.checked == true) {
        this.PaymentOptionValueId = null;
        this.frequencyOptions = [];
        this.selectedFrequencyName == this.frequencyList[0].Name;
      }
      else {
        let frequency = {
          id: this.frequencyList[0].Id,
          checked: false,
        }
        this.getFrequencyOptions(frequency);
      }
    } else if (frequency.checked == true) {
      let selectedfrequency = this.frequencyList.filter(x => { return x.Id == frequency.id })
      this.selectedFrequencyName = selectedfrequency[0].Name;
    } else {
      let selectedfrequency = this.frequencyList.filter(x => { return x.Id == frequency.id })
      this.selectedFrequencyName = selectedfrequency[0].Name;
      // console.log('selectedFrequencyName', this.selectedFrequencyName);
      this.frequencyOptions = this.paymentOption[0].PaymentOptions.filter(x => { return x.FrequencyId == frequency.id });
      // console.log('frequencyOptions', this.frequencyOptions)
      this.getAmount(this.frequencyOptions[0].Amount);
    }
  }
  getAmount(amount) {
    this.otheramoutValidation = false;
    this.maxOtherAmount = false;
    this.initialSelectedAmount = amount
    this.donationForm.controls['Amount'].setValue(this.initialSelectedAmount);
    this.donationForm.controls['OtherAmount'].setValue(null);
    // document.getElementById("otherAmount").classList.remove("is-focused");
    let amountOption = this.frequencyOptions.filter(x => { return x.Amount == amount });
    this.PaymentOptionValueId = amountOption[0].PaymentOptionValueId;

    this.initialFrequency = this.selectedFrequencyId;
    this.donationForm.controls['Frequency'].setValue(this.initialFrequency)
  }
  upfunction(e) {
    console.log(e.match('^(?![0.]*$)\\d{0,8}(?:\\.\\d{0,8})?$'), "valure", e)
    var matches = e.match('^(?![0.]*$)\\d{0,8}(?:\\.\\d{0,8})?$');// this.donationForm.controls['OtherAmount'].value.match('^[0-9]*$');
    var numberValue = Number(this.minAmount);
    this.donationForm.controls['Amount'].setValue(null);
    if (e == '') {
      this.donationamount = ''
      this.otheramoutValidation = false;
      this.maxOtherAmount = false;
      this.decimalVal = false;
      this.decimalError = false;
    }
    else if (matches == null || e < numberValue) {
      this.donationamount = ''
      console.log("first")
      this.otheramoutValidation = true;
      this.maxOtherAmount = false;
      this.decimalVal = false;
      this.decimalError = false;
    } else if (matches == null) {
      console.log("sec")

      // let othamount = this.donationForm.controls['OtherAmount'].value;
      // if(othamount.endsWith('.') || othamount.split('.').length > 2){
      //     this.decimalError = true;
      //     this.decimalVal = false;
      // }else{
      //     this.decimalError = false;
      //     this.decimalVal = true;
      // }

      if (e > 99999999) {
        this.donationamount = ''

        console.log("sec one")

        console.log("matches--- nulll and gtryetr other maount")
        this.maxOtherAmount = true;
        this.otheramoutValidation = false;
        this.decimalVal = true;
        this.decimalError = false;
      } else {
        console.log("sec 2")
        this.donationamount = ''

        console.log("matches--- nulll")
        this.decimalVal = false;
        this.decimalError = true;
        this.otheramoutValidation = false;
        this.maxOtherAmount = false;
      }
    } else if (e > 99999999) {
      console.log("third")

      this.maxOtherAmount = true;
      this.otheramoutValidation = false;
      this.decimalVal = true;
      this.decimalError = false;
    } else {
      console.log("four")

      this.otheramoutValidation = false;
      this.maxOtherAmount = false;
      this.decimalVal = false;
      this.decimalError = false;
      this.donationamount = e
    }
  }
  animateLabel(event) {
    event.parentNode.classList.add('is-focused');
  }

  animateLabelRemove(e) {
    // console.log(e.value, "AHGKND");
    if (e.value == '') {
      e.parentNode.classList.remove('is-focused');
    }
  }
  donateNow() {
    if (this.otheramoutValidation != true && this.maxOtherAmount != true && this.decimalVal != true && this.decimalError != true) {
      console.log("natin frm frm hme----", this.donationForm.value, this.isSpfmember)
      this.router.navigate([`memberDashboard/mydonation/` + this.memberId + `/donation`], { queryParams: { isSpfmember: this.isSpfmember } });
      this.sharedService.changeMessage(this.donationForm.value)
      this.dataShared = this.sharedService.currentMessage.subscribe(message => this.message = message)
    }

  }
  getMemberBanner() {
    let params = {
      url: "frontend-site/member-banner/list", params: { MemberId: this.memberId }
    }
    this._crud.getData(params).subscribe(res => {
      this.bannerList = res['data'];

    });
  }
  gotoUrl(websiteUrl) {
    // console.log("web urll---",websiteUrl)
    window.open(websiteUrl);
  }
  watchVideo(videoUrl) {

    var str = videoUrl;
    // var res = str.split("=");
    // var URL = "https://www.youtube.com/embed/" + res[1]+"?autoplay=1&loop=1&autopause=0&muted=1";
    // console.log("url----",URL);
    // this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(URL);
    // console.log("safeURL----",this.safeURL);

    if(str.includes('youtube')){
      var res = str.split("=");
      var URL= "https://www.youtube.com/embed/"+res[1]+"?autoplay=1&loop=1&autopause=0&muted=1";
      console.log("utube link------",URL)
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(URL);
      
    }else if(str.includes('vimeo')){
      const re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
      const matches = re.exec(str);
      var vimeo =  "https://player.vimeo.com/video/"+matches[1]+"?autoplay=1&loop=1&autopause=0&muted=1";
      console.log("utube link------",vimeo)
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(vimeo);
    }

    this.iframeLoad = true;
  }
  getPublication() {
    if(localStorage.getItem('publicationData') != null && localStorage.getItem('publicationBookUrl') != null ){
      this.publicationList =  JSON.parse(localStorage.getItem('publicationData'));
      this.bookUrl = JSON.parse(localStorage.getItem('publicationBookUrl'));
    }else if(localStorage.getItem('publicationData') == null){
      let params = {};
        params['url'] = "frontend-site/member-subscription/publication-list";
        params['params'] = {
          CountryId: this.countryId
        }
      this._crud.getData(params).subscribe(data => {
        this.publicationList = data['data']['PublicationList'];
        this.bookUrl = data['data']['PrayerBookUrl']
        localStorage.setItem('publicationData',JSON.stringify(this.publicationList));
        localStorage.setItem('publicationBookUrl',JSON.stringify(this.bookUrl));

        // console.log(data['data'], "get publition list");
      });
    }
  }
  editContactInfo() {
    const modalRef = this.modalService.open(EditContactInfoComponent, {
      size: "lg"
    });
    modalRef.componentInstance.memberId = this.memberId;
    modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
        if (result.result) {
          // /calling all api in settings apage
          this.ngOnInit();
        }
      });
  }
  downloadPrayerBook() {
    var win = window.open(this.bookUrl);
    if (win) {
      //Browser has allowed it to be opened
      win.focus();
    } else {
      //Browser has blocked it
      // alert('Please allow popups for this website');
    }
  }
  getRecentNews() {
    let params = {
      url: "https://shalomworld.org/wp-json/v1/blog?perpage=14&page=1&category=all", params: {}
    }
    this._crud.getData(params, true).subscribe(res => {
      this.recentNews = res['blogs'];
      // console.log("recent news----",this.recentNews)
    });
  }
  getComingUpNews() {
    let params = {
      url: "https://ark.shalomworld.org/wp-json/v1/live/channels", params: {}
    }
    this._crud.getData(params, true).subscribe(res => {
      this.recentComingUpNews = res['schedules'];
      console.log("recent news----", this.recentComingUpNews)
    });
  }
  viewAllRecentNews() {
    this.router.navigate(["/memberDashboard/" + this.memberId + "/recentNewsList"]);

  }
  downloadFlyer(link) {
    console.log("sadasdasd");
    if (link != null) {
      var win = window.open(link);
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        // alert('Please allow popups for this website');
      }
    }

  }
  private donationamount;
  public currencySymbol;
  donationAmount(value) {
    // this.donationamount = value;
    // console.log('--------donationamount',this.donationamount)
    this.upfunction(value)
  }
  getTotalDonationAmount() {
    //   let params = {};
    //   params['url'] = "frontend-site/member-donation/total-amount/"+this.memberId;
    //   this._crud.getData(params).subscribe(data => {
    //     //  console.log("amunt-----",data)
    //      this.totalDonatedAmount = data['data']['DonationAmount'];
    //      this.currencySymbol =  data['data']['CurrencySymbol'];
    //   })
    let params = {
      url: "frontend-site/view-member", params: { MemberId: this.memberId }
    }
    this._crud.getData(params).subscribe(data => {
      this.totalDonatedAmount = data['data']['MemberProfile']['DonationAmount'];
      this.currencySymbol = data['data']['MemberProfile']['CurrencySymbol'];
      this.currentYear = data['data']['MemberProfile']['CurrentYear'];

    });
  }
  getComingProgramsswprayer() {
    let params = {
      url: "https://ark.swprayer.org/wp-json/v1/homebannerschedule", params: {}
    }

    this._crud.getData(params, true).subscribe(res => {
      console.log("coming program prayer", res);
      this.comingProgramPrayer = res['schedules'][0].name
    });
  }
  getComingProgramsshalomworld() {
    let params = {
      url: "https://ark.shalomworld.org/wp-json/v1/home/schedules", params: {}
    }

    this._crud.getData(params, true).subscribe(res => {
      console.log("coming program world", res);
      this.comingProgramworld = res['schedules']['us']['schedules'][0].name

    });
  }
  getHolymass() {
    let params = {
      url: "https://ark.swprayer.org/wp-json/v1/dailymass", params: {}
    }

    this._crud.getData(params, true).subscribe(res => {
      console.log("coming holy mass", res['schedules']);
      this.holyMass = res['schedules']

    });
  }
  roundOtherAmount() {
    console.log("here", this.donationamount)
    if (this.donationamount > 0 && !isNaN(this.donationForm.controls['OtherAmount'].value)) {
      // console.log("othermaount value------",this.DonationForm.controls['OtherAmount'].value)
      let othAmount = this.donationamount;
      let fixedOthAmount = parseFloat(othAmount).toFixed(2);
      console.log("fixed oth amount-----", fixedOthAmount)
      this.donationamount = fixedOthAmount
      this.donationForm.controls['OtherAmount'].setValue(fixedOthAmount);

    } else {
      this.donationForm.controls['OtherAmount'].setValue(null);
    }

  }
  initializeVideoSlider(){
    console.log("initilize video slider")
    var swiper = new Swiper('.swiper2', {
      direction: 'horizontal',
      slidesPerView: 3,
      spaceBetween: 10,
      slidesPerGroup: 3,
      // loop: true,
      loopFillGroupWithBlank: true,
      observer: true,
      observeParents: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        },

        480: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        767: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 10
        },

        1350: {
          slidesPerView: 3,
          spaceBetween: 10
        },
      },
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true,
      // },
      navigation: {
        nextEl: '.swn2',
        prevEl: '.swnp2',
      },
    });
  }
  initializeEventSlider(){
    console.log("initilize event slider")
    var swiper = new Swiper('.swiper4', {
      direction: 'horizontal',
      slidesPerView: 3,
      spaceBetween: 10,
      slidesPerGroup: 3,
      // loop: true,
      loopFillGroupWithBlank: true,
      observer: true,
      observeParents: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        },

        480: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        767: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 10
        },

        1350: {
          slidesPerView: 3,
          spaceBetween: 10
        },
      },
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true,
      // },
      navigation: {
        nextEl: '.swn4',
        prevEl: '.swnp4',
      },
    });
  }
  initilizePublicationSlider(){
    // publication slider
    var swiper = new Swiper('.swiper5', {
      direction: 'horizontal',
      slidesPerView: 3,
      spaceBetween: 10,
      slidesPerGroup: 3,
      // loop: true,
      loopFillGroupWithBlank: true,
      observer: true,
      observeParents: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        },

        480: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        767: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 10
        },

        1350: {
          slidesPerView: 3,
          spaceBetween: 10
        },
      },
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true,
      // },
      navigation: {
        nextEl: '.swn5',
        prevEl: '.swnp5',
      },
    });
  }
  remindMe(data){
    console.log(data)
    let params = {
      url: "frontend-site/remind-me/" + this.memberId, params: { DateTime: data.dateTime} // time + date
    }
    this._crud.postData(params).subscribe(res => {
      console.log("recent news----",res)
       this.isLoading = false;
       if(res['status'] == 'success'){
        this.successPopup(res);

       }
    });
  }
  successPopup(data) {
    this.isLoading = false;
    let respo = {title: data['status'],message: 'Holy Mass reminder has been sent.'}
     
     //   this.modalService.show(SuccessPopupComponent, {initialState})
     const modalRef = this.modalService.open(SuccessPopupComponent, {size: "md"});
     modalRef.componentInstance.user = respo;
     modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
          if (result.result && data['status'] == 'success') {
             
              
          }
      });
  }
}
