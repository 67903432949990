<div class="d-flex dashboard-content">
    <div class="home-left">
        <div class="tab-pane active" id="dashboard" role="tabpanel">
            <a class="btn-play-video pb-2" data-target="#demo-video" data-toggle="modal">
                <img src="./assets/member/images/img-video-main.png" alt="Shalom">
                <span>Nisi ut aliquip ex ea aboris nisi ut commodo consequat.</span>
                <button type="button" class="live-btn"></button>
            </a>
            <div class="bx-white mt-2">
                <div class="top-strip d-flex btm-border ">
                    <img src="./assets/member/images/icon-office.svg" alt="Shalom">
                    <h5 class="ml-3 mb-0">Donation Causes</h5>
                </div>
                <ul class="donation-cause-list v-scroll">
                    <li class="d-flex btm-border" *ngFor="let cause of listOfCauses">
                        <figure>
                            <img src="{{cause.ImageUrl}}" alt="Shalom">
                        </figure>
                        <div class="donation-desc">
                            <h5>{{ cause.CauseName}}</h5>
                            <p>{{ cause.CauseDescription}}</p>
                        </div>
                        <button type="submit" class="btn-common" (click)="donate(cause.CauseId, cause.CauseName)">DONATE</button>
                    </li>
                    <!-- <li class="d-flex btm-border">
                        <figure>
                            <img src="./assets/member/images/img-donation1.png" alt="Shalom">
                        </figure>
                        <div class="donation-desc">
                            <h5>Excepteur sint occaecat cupidatat non</h5>
                            <p>exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.</p>
                        </div>
                        <button type="submit" class="btn-common">DONATE</button>
                    </li>
                    <li class="d-flex">
                        <figure>
                            <img src="./assets/member/images/img-donation2.png" alt="Shalom">
                        </figure>
                        <div class="donation-desc">
                            <h5>Excepteur sint occaecat cupidatat non</h5>
                            <p>exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.</p>
                        </div>
                        <button type="submit" class="btn-common">DONATE</button>
                    </li> -->
                </ul>
            </div>
            <div class="bx-white">
                <div class="top-strip d-flex btm-border">
                    <figure class="mb-0">
                        <img src="./assets/member/images/icon-family.svg" alt="Shalom">
                    </figure>
                    <h5 class="ml-3 mb-0 width-1">Family Members</h5>
                    <div class="text-right width-2"><button type="submit" class="btn-add-member" (click)="addMember()">Add Member</button></div>
                </div>
                <div class="table-responsive v-scroll">
                    <table class="family-table">
                        <tr class="btm-border" *ngFor="let member of listOfFamilyMem">
                            <td>1.</td>
                            <td> <img src="{{ member.PhotoUrl}}" alt="Shalom"></td>
                            <td>{{ member.MemberName}}
                                <!-- <span class="color-grey">(Primary)</span> -->
                            </td>
                            <!-- <td><span>{{member.SpfId}}</span></td> -->
                            <td><span class="color-blue">Join SPF</span></td>

                            <td> <img src="./assets/member/images/icon-edit.svg" alt="Shalom" style="cursor:pointer;" (click)="addMember(member)"></td>
                            <td> <button type="button" class="btn-common" (click)="donateFRomFamily(member.FamilyMemberId)">DONATE</button></td>
                            <td> <button type="button" class="btn-common" (click)="subscription(0,member.FamilyMemberId)">SUBSCRIBE</button></td>
                        </tr>
                        <!-- <tr class="btm-border">
                            <td>1.</td>
                            <td> <img src="./assets/member/images/img-prof2.png" alt="Shalom"></td>
                            <td>Mr. John Mccarthy<span class="color-grey">(Primary)</span></td>
                            <td><span>SPFID4567</span></td>
                            <td> <img src="./assets/member/images/icon-edit.svg" alt="Shalom"></td>
                            <td> <button type="button" class="btn-common">DONATE</button></td>
                            <td> <button type="button" class="btn-common">SUBSCRIBE</button></td>
                        </tr>
                        <tr class="btm-border">
                            <td>2.</td>
                            <td> <img src="./assets/member/images/img-prof2.png" alt="Shalom"></td>
                            <td>Mr. John Mccarthy<span class="color-grey">(Primary)</span></td>
                            <td><span>SPFID4567</span></td>
                            <td> <img src="./assets/member/images/icon-edit.svg" alt="Shalom"></td>
                            <td> <button type="button" class="btn-common">DONATE</button></td>
                            <td> <button type="button" class="btn-common">SUBSCRIBE</button></td>
                        </tr>
                        <tr>
                            <td>3.</td>
                            <td> <img src="./assets/member/images/img-prof2.png" alt="Shalom"></td>
                            <td>Mr. John Mccarthy<span class="color-grey">(Primary)</span></td>
                            <td><span class="color-blue">Join SPF</span></td>
                            <td> <img src="./assets/member/images/icon-edit.svg" alt="Shalom"></td>
                            <td> <button type="button" class="btn-common">DONATE</button></td>
                            <td> <button type="button" class="btn-common">SUBSCRIBE</button></td>
                        </tr> -->
                    </table>
                </div>
            </div>
            <div class="bx-white">
                <div class="top-strip d-flex btm-border mb-4">
                    <figure class="mb-0">
                        <img src="./assets/member/images/icon-family.svg" alt="Shalom">
                    </figure>
                    <h5 class="ml-3 mb-0">More ways to give</h5>
                </div>
                <div class="row more-way-list">
                    <div class="col-6 col-md-4 " *ngFor="let pgm of listOprogram ">
                        <figure>
                            <img src="{{pgm.ProgramImageUrl}} " alt="Shalom ">
                        </figure>
                        <h5>{{pgm.ProgramTitle}}</h5>
                        <p>{{pgm.ProgramDescription}}</p>
                    </div>
                    <!-- <div class="col-6 col-md-4 ">
                        <figure>
                            <img src="./assets/member/images/img-more-way1.png " alt="Shalom ">
                        </figure>
                        <h5>Excepteur sint occaecat cupidatat non</h5>
                        <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
                    </div>
                    <div class="col-6 col-md-4 ">
                        <figure>
                            <img src="./assets/member/images/img-more-way2.png " alt="Shalom ">
                        </figure>
                        <h5>Excepteur sint occaecat cupidatat non</h5>
                        <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
                    </div>
                    <div class="col-6 col-md-4 ">
                        <figure>
                            <img src="./assets/member/images/img-more-way3.png " alt="Shalom ">
                        </figure>
                        <h5>Excepteur sint occaecat cupidatat non</h5>
                        <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
                    </div>
                    <div class="col-6 col-md-4 ">
                        <figure>
                            <img src="./assets/member/images/img-more-way4.png " alt="Shalom ">
                        </figure>
                        <h5>Excepteur sint occaecat cupidatat non</h5>
                        <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
                    </div>
                    <div class="col-6 col-md-4 ">
                        <figure>
                            <img src="./assets/member/images/img-more-way5.png " alt="Shalom ">
                        </figure>
                        <h5>Excepteur sint occaecat cupidatat non</h5>
                        <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
                    </div>
                    <div class="col-6 col-md-4 ">
                        <figure>
                            <img src="./assets/member/images/img-more-way6.png " alt="Shalom ">
                        </figure>
                        <h5>Excepteur sint occaecat cupidatat non</h5>
                        <p>itation ullamco laboris nisi ut aliquip ex ea of a comm odo consequat.</p>
                    </div> -->
                </div>
            </div>
            <div class="bx-white ">
                <div class="top-strip d-flex">
                    <figure class="mb-0">
                        <img src="./assets/member/images/icon-recent.svg " alt="Shalom ">
                    </figure>
                    <h5 class="ml-3 mb-0 width-1">Recent Donations</h5>
                    <a href="javascript:void(0)" class="link-text text-right width-2" (click)="donate()">MAKE A DONATION</a>
                </div>
                <div class="table-responsive ">
                    <table class="recent-don-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Donation Cause</th>
                                <th>Member</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tr class="btm-border" *ngFor="let donation of recentDonation ">
                            <td>{{donation.PrimaryCountryTime}}</td>
                            <td>{{donation.CauseName}}</td>
                            <td>{{donation.FirstName}}</td>
                            <td>1{{donation.Amount}} {{donation.CurrencyCode}}</td>
                        </tr>
                        <!-- <tr class="btm-border">
                            <td>17 Oct 2020</td>
                            <td>Shalom <br>Peace Fellowship</td>
                            <td>John Mccarthy</td>
                            <td>125.00USD</td>
                        </tr>
                        <tr class="btm-border">
                            <td>17 Oct 2020</td>
                            <td>Shalom <br>Peace Fellowship</td>
                            <td>John Mccarthy</td>
                            <td>125.00USD</td>
                        </tr>
                        <tr class="btm-border">
                            <td>17 Oct 2020</td>
                            <td>Shalom <br>Peace Fellowship</td>
                            <td>John Mccarthy</td>
                            <td>125.00USD</td>
                        </tr>
                        <tr>
                            <td>17 Oct 2020</td>
                            <td>Shalom <br>Peace Fellowship</td>
                            <td>John Mccarthy</td>
                            <td>125.00USD</td>
                        </tr> -->
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="home-right pl-0 ">
        <div class="bx-white ">
            <ul class="button-list">
                <!-- <li class="d-flex ">
                    <a (click)="modalOpenPrayer()" style="cursor:pointer;"><img src="./assets/member/images/icon-pryr-req.png " alt=" ">Prayer Request</a>
                </li>
                <li class="d-flex ">
                    <a (click)="modalOpenTestimony()" style="cursor:pointer;"><img src="./assets/member/images/icon-testimony.png " alt=" ">Testimony</a>
                </li> -->
                <li class="d-flex ">
                    <a (click)="modalOpen()" style="cursor:pointer;"><img src="./assets/member/images/icon-contact.png " alt=" ">Contact Us</a>
                </li>
            </ul>
        </div>
        <div class="bx-white ">
            <div class="top-strip d-flex ">
                <figure class="mb-0">
                    <img src="./assets/member/images/icon-office.svg " alt="Shalom ">
                </figure>
                <h5 class="ml-3 mb-0">Publications</h5>
            </div>
            <!-- calling slider component name : slide -->
            <app-slide></app-slide>
            <!-- <ul class="slider-list">
                <li>
                    <img src="./assets/member/images/img-publication.png " alt="Shalom ">
                    <h6>Oct 21 - 22</h6>
                    <h5>Shalom Times</h5>
                    <button type="button" class="btn-common">Subscribe</button>
                </li>
              
            </ul> -->
        </div>
        <div class="bx-white ">
            <div class="top-strip d-flex ">
                <figure class="mb-0">
                    <img src="./assets/member/images/icon-office.svg " alt="Shalom ">
                </figure>
                <h5 class="ml-3 mb-0">Events</h5>
            </div>
            <ul class="event-list v-scroll">
                <li class="d-flex btm-border" *ngFor="let event of listOfEvents">
                    <figure>
                        <img src="{{event.logo_url}}" alt="Shalom ">
                    </figure>
                    <div class="event-desc ">
                        <h6>{{event.EventStartDate}}</h6>
                        <h5>{{event.EventTypeName}}</h5>
                        <span>{{event.Title}}</span>
                        <button type="button" class="btn-common btn-registerd" (click)="registerEvent(event.EventStartDate,event.EventTypeName,event.Title)">Register</button>
                    </div>
                </li>
            </ul>
        </div>
        <div class="bx-white ">
            <div class="top-strip d-flex ">
                <img src="./assets/member/images/icon-video.svg " alt="Shalom ">
                <h5 class="ml-3 mb-0 width-1">Videos</h5>
                <!-- <select class="form-control width-2">
                  <option>Language</option>
              </select> -->
              <select class="form-control width-2">
                <option *ngFor="let language of languageList" value="{{language.Id}}">{{ language.Language }}</option>
            </select>
            </div>
            <ul class="video-list">
                <li *ngFor="let videos of listOfVideos">
                    <a class="btn-play-video " data-target="#demo-video " data-toggle="modal " (click)="videoModal(videos.VideoUrl)"><img src="{{videos.VideoImageUrl}}" alt=" "> <button _ngcontent-c4="" class="live-btn" type="button"></button></a>
                    <p>{{videos.videoDescription}}</p>
                </li>
            </ul>
            <!-- href="{{videos.VideoUrl}}" -->
            <!-- <ul class="video-list">
                <li>
                    <a class="btn-play-video " data-target="#demo-video " data-toggle="modal "><img src="./assets/member/images/video-list1.png " alt=" "> <button _ngcontent-c4="" class="live-btn" type="button"></button></a>
                    <p>nisi ut aliquip ex ea aboris nisi ut commodo consequat.</p>
                </li>
                <li>
                    <a class="btn-play-video " data-target="#demo-video " data-toggle="modal "><img src="./assets/member/images/video-list2.png " alt=" "> <button _ngcontent-c4="" class="live-btn" type="button"></button></a>
                    <p>nisi ut aliquip ex ea aboris nisi ut commodo consequat.</p>
                </li>
                <li>
                    <a class="btn-play-video " data-target="#demo-video " data-toggle="modal "><img src="./assets/member/images/video-list3.png " alt=" "> <button _ngcontent-c4="" class="live-btn" type="button"></button></a>
                    <p>nisi ut aliquip ex ea aboris nisi ut commodo consequat.</p>
                </li>
            </ul> -->
        </div>
    </div>
</div>