import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFieldsComponent } from './custom-fields.component';
import { FormGroup, FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';


@NgModule({
    imports: [
        CommonModule, ReactiveFormsModule,
        // BsDatepickerModule.forRoot(),
        // DatepickerModule.forRoot() ,
        AngularMyDatePickerModule  
    ],
    exports: [
        FormsModule,
        CustomFieldsComponent,
    ],
    declarations: [CustomFieldsComponent]
})
export class CustomFieldsModule { }
