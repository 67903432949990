<!-- <app-head-bar></app-head-bar>  -->
<!-- comment head bar...move user image icon to top bar -->
<!-- <app-top-bar></app-top-bar> -->
<!-- theme purple for spf members 
    theme green for one time donation user
    theme brown for new user-->
    
<div class="main-wrp" [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <app-top-bar-member></app-top-bar-member>


    <!-- <button class="btn btn-primary" type="button" (click)="modalOpen()">
    Contact Us popup</button> -->

    <!-- <button class="btn btn-primary" type="button" (click)="AlertModal()">
      Alert Msg popup</button> -->
    <div class="main-page">

        <div class="container">
            <div class="d-flex page-content">
                <div class="pr-0 dashboard-sidebar">
                    <!-- <app-side-bar></app-side-bar> -->
                    <app-sider-bar-member></app-sider-bar-member>
                </div>
                <div class=" dashboard-right">
                    <router-outlet></router-outlet>
                    <!-- <app-home-member (msgToSibling)="fwdMsgToSib2($event)"></app-home-member> -->

                </div>

            </div>
        </div>
    </div>
    <app-foot-bar></app-foot-bar>
</div>