<section class="dashboard-top-wrp">
    <div class="container">
        <div class="head-bar row">
            <div class="col-5 col-md-2" *ngIf="isSpfMember">
                <a routerLink="/memberDashboard/home/{{this.memberId}}"><img src="./assets/member/images/dashboard-logo-purple.svg" alt="Shalom"></a>
            </div>
            <!-- ontime donation user -->
            <div class="col-5 col-md-2" *ngIf="singleDonationExist == true && isSpfMember == false">
                <a routerLink="/memberDashboard/home/{{this.memberId}}"><img src="./assets/member/images/dashboard-logo-green.svg" alt="Shalom"></a>
            </div>
            <!-- new user -->
            <div class="col-5 col-md-2" *ngIf="singleDonationExist == false && isSpfMember == false">
                <a routerLink="/memberDashboard/home/{{this.memberId}}"><img src="./assets/member/images/dashboard-logo-brown.svg" alt="Shalom"></a>
            </div>
            <div class="col-7 col-md-10">
                <div class="d-flex justify-content-end align-items-center">
                    <div class="top-bar">

                        <div class="tab-wrp">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item ">
                                    <a class="nav-link" data-toggle="tab" routerLink="home/{{this.memberId}}" role="tab"
                                    [ngClass]="{ 'activePage':activeTab =='home' || activeTab =='publications' }"
                                        role="tab">Dashboard</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" role="tab"
                                        routerLink="mydonation/{{this.memberId}}" role="tab"
                                        [ngClass]="{ 'activePage':activeTab =='mydonation'}">My Donations</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" routerLink="mysubscription/{{this.memberId}}"
                                        role="tab" [ngClass]="{ 'activePage':activeTab =='mysubscription'}">My
                                        Subscriptions</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab"
                                        routerLink="myeventregistration/{{this.memberId}}" role="tab"
                                        [ngClass]="{ 'activePage':activeTab =='myeventregistration'}">My Event
                                        Registrations</a>
                                </li>
                                <!-- <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" role="tab">Settings</a>
                                </li> -->
                            </ul>
                            <!-- Tab panes -->
                            <div class="tab-content">

                                <div class="tab-pane" id="donation" role="tabpanel">
                                    <p>My Donations</p>
                                </div>
                                <div class="tab-pane" id="subscription" role="tabpanel">
                                    <p>My subscription</p>
                                </div>
                                <div class="tab-pane" id="registration" role="tabpanel">
                                    <p>Event Registrations</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="waffle_menu">
                        <figure>
                            <img src="./assets/member/images/prof-top-icon.svg" alt="waffle"></figure>
                            <ul>
                                <li>
                                    <a href="https://www.shalommedia.org/" target="_blank">
                                        <figure>
                                            <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/Shalom-Media.webp" alt=""></figure>
                                            <span>Shalom Media</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://shalomtidings.org/" target="_blank">
                                            <figure>
                                                <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/Tidings.webp" alt="">
                                            </figure>
                                            <span>Shalom Tidings</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.shalomworld.org/" target="_blank">
                                            <figure>
                                                <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/logo-without-background.webp" alt="">
                                            </figure>
                                            <span>Shalom World</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://qabeta.shalomworld.org/swpals/" target="_blank">
                                            <figure>
                                                <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/SW-Pals.webp" alt="">
                                            </figure>
                                            <span>SW Pals</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.shalomworld.org/swprayer">
                                            <figure>
                                                <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/SW-Prayer-1.webp" alt="">
                                            </figure>
                                            <span>SW Prayer</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.shalomworld.org/news" target="_blank">
                                            <figure>
                                                <img src="http://qabackend.swprayer.org/wp-content/uploads/2021/11/SW-News.webp" alt="">
                                            </figure>
                                            <span>SW News</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                    <div class="log-prof">
                        <div class="d-flex dropdown-wrp">
                            <a href="#" class="" data-toggle="dropdown" aria-expanded="false">
                                <div class="log-prof-desc">
                                    <figure>
                                        <!-- <img src="./assets/member/images/img-prof2.png" alt="Shalom"> -->
                                        <img src="{{PhotoUrl}}" alt="Shalom">
                                    </figure>
                                </div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end"
                                style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-87px, 33px, 0px);">
                                <span>Hi, {{FirstName}}</span>
                                <button class="btn btn-primary" (click)="logOut()">Sign out</button>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="mob-btn">
                <div class="back menu--3"> <label> <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="30"></circle>
                            <path class="line--1" d="M0 40h62c18 0 18-20-17 5L31 55"></path>
                            <path class="line--2" d="M0 50h80"></path>
                            <path class="line--3" d="M0 60h62c18 0 18 20-17-5L31 45"></path>
                        </svg> </label></div>
            </div>
            <div class="overlay"></div>
        </div>
    </div>
</section>