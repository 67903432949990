import { FormControl, ValidatorFn, Validators, AbstractControl, FormGroup, FormArray } from '@angular/forms';

export class CustomValidator {

    static validateAllFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFields(control);
            }
        });
    }

    public static passwordsMatch(password: string, confirmedPassword: string) {

        return (control: FormControl) : { [s: string]: boolean } =>{
          //getting undefined values for both variables
        //   console.log("iside custvalidation--------",password,confirmedPassword);
           //if I change this condition to === it throws the error if the 
            //  two fields are the same, so this part works
           if (password !== confirmedPassword) {
               console.log("not match------")
             return { 'passwordMismatch': true }
           } else {
             //it always gets here no matter what
             return null;
           }
        }
    }
    static imageExtensionValidator(whiteListImageExtension: Array<string>) {
        return function(input: FormControl) {
            if (input.value[0]) {
                let extensionValue = input.value.split('.');
                return (whiteListImageExtension.indexOf(extensionValue[1].toLowerCase()) > -1) ? null : { extension: true };
            }
            return null;
        };
    }

}