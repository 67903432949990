<!-- <a class="nav-link " routerLink="../.."><i class="la la-arrow-left"></i>EventRegistration List</a> -->
<div class="bx-white cancel-wrp">
    <div class="bx-top-strip">
        <h2>Cancel Event Registration</h2>
    </div>
    <div class="cancel-inner">
        <div class="row ">
            <div class="col-lg-6">
                <ul>
                    <li>
                        <span>Event</span>
                        <strong>{{eventData?.EventName}}</strong>
                    </li>
                    <li>
                        <span>Location</span>
                        <strong>{{eventData?.EventLocation}}</strong>
                    </li>
                    <li>
                        <span>Event Dates</span>
                        <!-- <strong>{{eventData?.EventStartAt | date: 'dd MMM yyy'}} - {{eventData?.EventCloseAt | date: 'dd MMM yyy'}} </strong> -->
                   <strong>{{startMonth}}
                    {{startDay}} {{startYear == endYear ? '' : startYear}}-
                    {{endMonth == startMonth ? '' : endMonth}} {{endDay}} {{endYear}}</strong>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6">
                <ul>
                    <li>
                        <span>Registration Date</span>
                        <!-- <strong>{{eventData?.PrimaryCountryTime | date: 'dd MMM yyy'}}</strong> -->
                        <strong>{{eventData?.PrimaryCountryTime }}</strong>

                    </li>
                    <li>
                        <span>Net Amount Received</span>
                        <strong>{{eventData?.NetPaidAmount}} {{eventData?.CurrencyCode}}</strong>
                    </li>
                    <li>
                        <span>Amount to be Refunded</span>
                        <strong>{{eventData?.RefundedAmount}} {{eventData?.CurrencyCode}}</strong>
                    </li>
                </ul>
            </div>
        </div>
        <form [formGroup]="cancelSubscriptionForm">
            <div class="cancel-patricipant-list-wrp mt-5 mb-4">
                <!-- old code by pallavi -->
                <!-- <div class="d-flex cancel-patricipant-each" *ngFor="let data of eventData.ParticipantDetails ">
                <div class="checkbox-wrp d-flex mr-2">
                    <input formcontrolname="" id="patricipant1" value="" type="checkbox" class="form-check-input" (click)="selectPart($event.target,data.registration_fee, data.id)">
                    <label for="patricipant1" class="form-check-label"></label>
                </div>
                <span class="width1">{{data.Name}}</span>
                <span class="width2">{{data.EventStream}}</span>
                <span class="width3">{{data.registration_fee}} {{eventData.CurrencyCode}}</span>
                <span class="width4">Active</span>
            </div> -->
                <!-- old code by shalu -->
                <!-- <div class="d-flex cancel-patricipant-each">
                <div class="checkbox-wrp d-flex mr-2">
                    <input formcontrolname="" id="patricipant2" value="" type="checkbox" class="form-check-input">
                    <label for="patricipant2" class="form-check-label"></label>
                </div>
                <span class="width1">Mshsad.Dr. Loremipsum</span>
                <span class="width2">Adult</span>
                <span class="width3">100.00 USD</span>
                <span class="width4">Active</span>
            </div> -->
                <!-- new code from admin -->
                <!-- <input type="checkbox" (change)="selectAll($event.target)">Select All -->
                <div class="checkbox-wrp d-flex mb-3">
                    <input id="select-check" value="" type="checkbox" class="form-check-input" (change)="selectAll($event.target)">
                    <label for="select-check" class="form-check-label">Select All</label>
                </div>
                <span class="col-sm-12 m-form__help">
                <span class="error-message">{{errorForSelectPar}}</span></span>
                <table class="table">
                    <tbody>
                        <tr formArrayName="ParticipantDetails" *ngFor="let option of cancelSubscriptionForm.get('ParticipantDetails')['controls']; let i = index;">
                            <td [formGroupName]="i" class="pl-0">
                                <!-- <label class="m-checkbox m-checkbox--solid m-checkbox--brand">
                                <input name="Participants"
                                    formControlName="identifier"
                                    type="checkbox"
                                    [attr.disabled]="option.get('status').value!==1 || null" 
                                    (change)="selectPart($event.target,option.get('registration_fee').value, option.get('id').value)">
                                {{option.get('Name').value}}
                                <span></span>
                            </label> -->
                                <div class="checkbox-wrp d-flex mb-3">
                                    <input id="{{ 'check' + i }}" value="" type="checkbox" class="form-check-input" name="Participants" formControlName="identifier"
                                     [attr.disabled]="option.get('status').value!==1 || null"
                                     (change)="selectPart($event.target,option.get('registration_fee').value, option.get('id').value)">
                                    <label for="{{ 'check' + i }}" class="form-check-label">{{option.get('Name').value}}</label>
                                </div>
                            </td>
                            <td [formGroupName]="i">
                                {{option.get('registration_fee').value}} {{eventData?.CurrencyCode}}
                            </td>
                            <td [formGroupName]="i">
                                {{(option.get('status').value==1)?'Active':'Cancelled'}}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="tot-refund-count mb-4"><span>Total Refundable Amount: {{netPaidAmount}} {{eventData?.CurrencyCode}}</span></div>
            <!-- <div class="d-flex bx-grey align-items-center mob-mb">
            <div class="form-check radio-btn-wrp">
                <input type="radio" formcontrolname="" class="form-check-input" id="Refund" name="fund-type-radio">
                <label class="form-check-label" for="Refund"> Process Refund </label>
            </div>
            <div class="form-check radio-btn-wrp">
                <input type="radio" formcontrolname="" class="form-check-input" id="Donate" name="fund-type-radio">
                <label class="form-check-label" for="Donate">  Donate the Refundable Amount </label></div>
        </div> 
        <button type="button" class="btn-common">Cancel Registration</button> -->

            <div class="d-flex bx-grey align-items-center mob-mb" *ngIf="netPaidAmount > 0">
                <!-- <span class="radio-label mob-mb">Payment Method</span> -->
                <div class="form-check radio-btn-wrp">
                    <input type="radio" formControlName="RefundType" class="form-check-input" id="Donate" name="fund-type-radio" value="2" (click)="changeRefundType()">
                    <label class="form-check-label" for="Donate">  Donate the Refundable Amount </label></div>
                <div class="form-check radio-btn-wrp pl-0" *ngIf="this.eventData?.IsSingleElectronicTransaction != 0">
                    <input type="radio" formControlName="RefundType" class="form-check-input" id="Refund" name="fund-type-radio" value="1" (click)="changeRefundType()">
                    <label class="form-check-label " for="Refund"> Process Refund </label>
                </div>
                <div class="form-check radio-btn-wrp pl-0">
                    <input type="radio" formControlName="RefundType" class="form-check-input" id="Later" name="fund-type-radio" value="0" (click)="changeRefundType()">
                    <label class="form-check-label " for="Later" > Refund Later </label>
                </div>
            </div>
            <span class="col-sm-12 m-form__help">
                <span class="error-message" *ngIf="!selectRefundType">
                    Please select your refund type
                </span>
            </span>
            <div class="">
                <button type="button" class="btn-back-blue mr-3" routerLink="../..">Back</button>
                <button type="button" class="btn-common btn-cancel" (click)="cancel()"><span class="span-loader-padding">Cancel Registration</span>  <i *ngIf="isLoading" class="spinner-border"></i></button>
            </div>
        </form>
    </div>
</div>