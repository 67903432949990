<div class="contact-alert" [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <button type="button" class="close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">
            &times;
        </span>
    </button>
    <div class="popup-inner">
        <div *ngIf="paymentMethodType != 'credit_or_debt'" class="text-center pt-5 popup-title">
            <h2>Saved Accounts</h2>
        </div>
        <div *ngIf="paymentMethodType == 'credit_or_debt'" class="text-center pt-5 popup-title">
            <h2>Saved Cards</h2>
        </div>
        <i *ngIf="loaderCreditsaved" class="spinner-border common-loader"></i>
        <div class="actnt-details-inner px-5 py-5 mob-pd" *ngIf="paymentMethodType != 'credit_or_debt'" > 
            <div class="row">
                <div class="col-lg-6 form-check radio-btn-wrp mob-padding" *ngFor="let option of savedCardList; let i=index">
                    <div class="bx-radio-btn-each">
                        <input formControlName="" type="radio" class="form-check-input" value="{{option.PayMethodToken}}" id="{{option.PayMethodToken}}" (change)="onSelectedSavedcardChange($event.target.id,i)">
                        <label for="{{option.PayMethodToken}}">
                        <span class="d-block">
                            {{option.AccountHolder}}
                        </span>
                        <span>
                            {{option.MaskedAccountNumber}}
                        </span>
                    </label>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="acnt-detail-wrp" *ngIf="paymentMethodType == 'credit_or_debt'">
            <div class="row">
                <div class="col-lg-6 form-check radio-btn-wrp mob-padding" *ngFor="let option of savedCardList; let i=index">
                    <div class="bx-radio-btn-each">
                        <input formControlName="" type="radio" class="form-check-input" value="{{option.PaymentGatewayToken}}" id="{{option.PaymentGatewayToken}}" (change)="onSelectedSavedcardChange($event.target.id,i)">
                        <label for="{{option.PaymentGatewayToken}}">
                        <span class="d-block">
                            {{option.CardHolderName}}
                        </span>
                        <span>
                            ****{{option.Last4}}
                        </span>
                    </label>
                    </div>
                </div>
            </div>
            <!-- <ngx-braintree [getClientToken]="getClientToken.bind(this)" [showCardholderName]="false" [chargeAmount]="NetAmonutToPay" [createPurchase]="createPurchase.bind(this)">
                <div class="ngxButtons">
                    <label class="row col-form-label m-checkbox--inline" *ngIf="isSavedCard">
          <label class="m-checkbox m-checkbox--solid m-checkbox--brand">
            <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" >
            Save Account for Future
            <span></span>
          </label>
        </label>
                    <div class="checkbox-wrp ml-3" *ngIf="isSavedCard">
                        <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" id="save-id">
                        <label for="save-id">Save Account for Future</label>
                    </div>
                    <button ngxPay class="btn-common mt-3">Update</button>
                </div>
            </ngx-braintree> -->
        </div>
    </div>
</div>