import { Component, OnInit ,AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from '../../../../_services/shared.service';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { ActivatedRoute,Router } from '@angular/router';
declare let $: any;

export interface PeriodicElement {
  PrimaryCountryTime: string;
  CauseName: string;
  MemberName: string;
  Amount: string;
  PaymentMethod:string,
  CreditTypeText:string,
  Last4:string,
  Expiry:string,  
}

@Component({
  selector: 'app-my-donation',
  templateUrl: './my-donation.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css','./my-donation.component.scss']
})
export class MyDonationComponent implements OnInit , AfterViewInit{
  displayedColumns: string[] = ['PrimaryCountryTime', 'CauseName', 'MemberName', 'Amount','PaymentMethod','Expiry'];
  dataSource = new MatTableDataSource();
  routeParam: any;
  donationData;
  memberId;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  yearList: any;
  selectedYear: any = '';
  defaultCauseId;
  defaultCauseName;
  yearListOr: any;
  showprint: boolean = true;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  constructor(public sharedService: SharedService, public _crud: CommonCrudService,public activateRoute:ActivatedRoute,private router: Router) { }

  ngOnInit(): void {
    this.sharedService.fetchPathData('dashboard','mydonation');
    this.sharedService.changeSidebarView(true);
    this.routeParam = this.activateRoute.snapshot.params;
    this.memberId = this.routeParam.memberId;
    this.defaultCauseId = localStorage.getItem('dCauseId');
    this.defaultCauseName = localStorage.getItem('dCauseName');
    console.log("parms------",this.routeParam)
    let param = {
      url: "frontend-site/donation-payment/year-list", params: { MemberId: this.memberId }//replace with this.memberId
    }
    this._crud.getData(param).subscribe(res => {
      this.yearList = res['data']
      this.yearList.sort(function(a, b){return b-a});
      this.selectedYear = this.yearList[0];
      if(!this.selectedYear){
        this.selectedYear = '';
      }
      console.log("selectedYear-------------",this.selectedYear);

      let params = {
        url: "frontend-site/donation-payment/list", params: { MemberId: this.memberId,Year:  this.selectedYear}//replace with this.memberId
      }
      this._crud.getData(params).subscribe(res => {
        this.donationData = res['data'];
          // console.log("donatuon data----",res['data']);
        const ELEMENT_DATA: PeriodicElement[]  = this.donationData
        this.dataSource = new MatTableDataSource (this.donationData);
        this.dataSource.paginator = this.paginator;
      });
      
    });
    // let params = {
    //     url: "frontend-site/donation-payment/list", params: { MemberId: this.memberId }//replace with this.memberId
    // }
    // this._crud.getData(params).subscribe(res => {
    //     this.donationData = res['data'];
    //       // console.log("donatuon data----",res['data']);
    //     const ELEMENT_DATA: PeriodicElement[]  = this.donationData
    //     this.dataSource = new MatTableDataSource (this.donationData);
    //     this.dataSource.paginator = this.paginator;
    // });
  //   let param = {
  //     url: "frontend-site/donation-payment/year-list", params: { MemberId: this.memberId }//replace with this.memberId
  // }
//   this._crud.getData(param).subscribe(res => {
// // this.yearList = res['data']
// this.yearList = [2021,2022,2020]

// console.log(this.yearList);
// });
}
export() {
  console.log(this.selectedYear);
  this._crud
      .getData({
          url: "frontend-site/donation-payment/export" , params: { MemberId: this.memberId,Year: this.selectedYear },
          // params: {
          //     Format: type,
          //     // ...this.mDataTable.getDefaultSortColumn(),
          //     "sort[field]": this.mDataTable.API.params.sort.field,
          //     "sort[sort]": this.mDataTable.API.params.sort.sort,
          //     "query[generalSearch]": $("#generalSearch").val(),
          //     "query[advanceSearch]": this.mDataTable.API.params.query.advanceSearch,
          //     // "query[advanceSearch]": this.advSearch["advanceSearch"]
          // }
      })
      .subscribe(data => {
          if (data['status'] == "error") {
          } else {
              var win = window.open(data['data']);
              if (win) {
                  //Browser has allowed it to be opened
                  win.focus();
              } else {
                  //Browser has blocked it
                  // alert('Please allow popups for this website');
              }
          }
          console.log("data", data);
      });
}
onChange(data){
console.log(data)
if(data == ''){
document.getElementById("print").classList.add("showprint");

}else{
  document.getElementById("print").classList.remove("showprint");

}
  this.selectedYear = data;
  let params = {
    url: "frontend-site/donation-payment/list", params: { MemberId: this.memberId,Year:  this.selectedYear }//replace with this.memberId
  }
  this._crud.getData(params).subscribe(res => {
    this.donationData = res['data'];
      // console.log("donatuon data----",res['data']);
    const ELEMENT_DATA: PeriodicElement[]  = this.donationData
    this.dataSource = new MatTableDataSource (this.donationData);
    this.dataSource.paginator = this.paginator;
  });
}
makeDonation(){
  this.router.navigate([`memberDashboard/mydonation/`+ this.memberId +`/donation` ] , { queryParams: {memberId: this.memberId,causeId: this.defaultCauseId, causeName: this.defaultCauseName} });
}

}
