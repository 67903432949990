import { Component, OnInit ,Input} from '@angular/core';
import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import * as braintree from 'braintree-web';
import { map } from 'rxjs/operators';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import { ActivatedRoute ,Router} from '@angular/router';

@Component({
  selector: 'app-saved-accounts',
  templateUrl: './saved-accounts.component.html',
  styleUrls: ['./saved-accounts.component.scss']
})
export class SavedAccountsComponent implements OnInit {

//   @Input() savedCardList = [];
@Input() CountryPaymentSettingId;
  @Input() paymentMethodType;
  @Input() settingId;
  @Input() memberId;
  @Input() sourceId;
  @Input() recurringDonationData;
  @Input() NetAmonutToPay;
  hostedFieldsInstance: braintree.HostedFields;
  @Input() brainTreeToken: string;
  brainTreeError: string;
  cardholdersName: string;
  noFieldsCardNumber: number;
  noFieldsDate: number;
  noFieldsCvv: number;
  postDataJsonPrepare;
  isSpfMember:boolean=false;
  singleDonationExist:boolean=false;
    savedCardList: any;
    loaderCreditsaved: boolean;
  constructor(public activeModal: NgbActiveModal,public _crud : CommonCrudService,public modalService: NgbModal,public router:Router) { }

  ngOnInit(): void {
    // console.log(this.savedCardList);
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.singleDonationExist = currentUser.singleDonationExist;
    if(this.paymentMethodType != 'ach'){
        this.loaderCreditsaved = true

    //   this.createBraintreeUI();
this.savedCreditCard()
    }else{
        this.loaderCreditsaved = true
this.savedAch()
    }
  }
  savedCreditCard(){ // member/saved-cards/{member_id}
        let params = {};
    params['url'] = 'frontend-site/member/saved-cards/' + this.memberId  + '/' + this.CountryPaymentSettingId;
        // params['url'] = 'frontend-site/member/saved-cards/' + this.memberId;
        params['params'] = {};
        this._crud
            .getData(params)
            .subscribe(data => {
                 console.log(data['data']['SavedCards'],"maped clint response")
                 this.savedCardList = data['data']['SavedCards']
                 this.loaderCreditsaved = false
                // return res.data.ClientToken;
            }); 
    }
    savedAch() {
        let params = {};
        params['url'] = 'frontend-site/member/client-token/' + this.CountryPaymentSettingId + '/' + this.memberId;
        params['params'] = {};
        return this._crud.getData(params)
            .subscribe(data => {
                if (data['status'] == "success") {
                      console.log("ach saved cards-------",data['data']['SavedCards']);
                   
                        this.savedCardList = data['data']['SavedCards'];
                        this.loaderCreditsaved = false

                }
           
            });
    }
  onSelectedSavedcardChange(tokenId,Index){
    this.activeModal.close({ 'result': true,id: tokenId,index:Index });

  }
  createBraintreeUI() {
    // console.log("braintree");
    braintree.client.create({
        authorization: this.brainTreeToken
    }).then((clientInstance) => {
        braintree.hostedFields.create({
            client: clientInstance,
            styles: {
                // Style all elements
                // 'input': {
                //   'font-size': '16px',
                //   'color': '#3A3A3A'
                // },
                '.invalid': {
                    'color': '#D0041D'
                },
  
                // Styling element state
                // 'input': {
                //     // 'font-family': '"Merriweather", serif !important',
                //     'padding': "0 10px 0 20px !important",
                //     'letter-spacing': "0.025em !important",
                //     "font-weight": "300 !important",
                //     "font-size": "14px !important",
                // },
                'input': {
                    'font-size': '16px!important',
                    "font-weight": '500!important',
                    'font-family': "'Poppins', sans-serif !important",
                    'color': '#0D0D0D',
                    ':placeholder': {
                        'font-weight': '500!important',
                        'color': '#9F9F9F!important',
                    }
                },
  
                // Media queries
                // Note that these apply to the iframe, not the root window.
                // '@media screen and (max-width: 700px)': {
                //     'input': {
                //         'font-size': '14px'
                //     }
                // }
            },
  
            // The hosted fields that we will be using
            // NOTE : cardholder's name field is not available in the field options
            // and a separate input field has to be used incase you need it
            fields: {
                number: {
                    selector: '#card-number',
                    placeholder: 'Card Number'
                },
                cvv: {
                    selector: '#cvv',
                    placeholder: 'CVV'
                },
                expirationDate: {
                    selector: '#expiration-date',
                    placeholder: 'Expiration Date (MM/YY)'
                },
                // postalCode: {
                //     selector: '#postal-code',
                //     placeholder: 'Pin Code'
                // }
            }
        }).then((hostedFieldsInstance) => {
            this.hostedFieldsInstance = hostedFieldsInstance;
            hostedFieldsInstance.on('focus', (event) => {
                const field = event.fields[event.emittedBy];
                const label = this.findLabel(field);
                if (label['htmlFor'] == 'card-number') {
                    this.noFieldsCardNumber = 0;
                }
                if (label['htmlFor'] == 'expiration-date') {
                    this.noFieldsDate = 0;
                }
                if (label['htmlFor'] == 'cvv') {
                    this.noFieldsCvv = 0;
                }
                console.log(this.noFieldsCardNumber);
                // label.classList.remove('filled'); // added and removed css classes
                // can add custom code for custom validations here
                // this.cdRef.detectChanges();
            });
            hostedFieldsInstance.on('blur', (event) => {
                const field = event.fields[event.emittedBy];
                // const label = this.findLabel(field); // fetched label to apply custom validations
                // can add custom code for custom validations here
            });
  
            hostedFieldsInstance.on('empty', (event) => {
                const field = event.fields[event.emittedBy];
                // can add custom code for custom validations here
            });
  
            hostedFieldsInstance.on('validityChange', (event) => {
                const field = event.fields[event.emittedBy];
                const label = this.findLabel(field);
                if (field.isPotentiallyValid) { // applying custom css and validations
                    // label.classList.remove('invalid');
                } else {
                    // label.classList.add('invalid');
                }
                // can add custom code for custom validations here
            });
        });
    }).catch(error => {
        console.log('error', error);
        if (error.name == 'BraintreeError') {
            // this.paymentGatewayErrorMsg = "Payment Gateway is not configured"
        }
    })
    // this.clearCardDetails();
  }
  findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
    return document.querySelector('.hosted-field--label[for="' + field.container.id + '"]');
  }
  getClientToken() {
    let params = {};
    params['url'] = 'frontend-site/member/client-token/' + this.settingId + '/' + this.memberId;
    params['params'] = {};
    return this._crud
        .getBrainTreeToken(params)
        .pipe(map((res: any) => {
            // console.log(res.data.ClientToken,"maped clint response")
            return res.data.ClientToken;
        }));
  }
  createPurchase(nonce: string, chargeAmount: number) {
    let param = {};
    param['params'] = this.RequestPostParams({ type: 'credit_or_debt' },nonce);
    param['url'] = 'frontend-site/member-donation/update/' + this.sourceId;
    // param['params'] = this.RequestPostParams({ type: 'ach' },null);
    param['error'] = 'paymentMethods';
    this._crud.postData(param)
        .subscribe(data => {
            this.successPopup(data);
        }, error => {
            console.log("Some error tiggered" + error)
        });

}
onPaymentStatus(response): void {
//   console.log(response);
}
public isSavedCard: boolean = false;
payButtonStatus(response): void {
  this.isSavedCard = response;
}
RequestPostParams(paymentFields = null, nonce = null) {
  this.postDataJsonPrepare = {};
  this.postDataJsonPrepare['PaymentProcessParams'] = {};
  this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'] = {};
  this.postDataJsonPrepare['Frequency'] = this.recurringDonationData['RecurringFrequencyId'];
  this.postDataJsonPrepare['Amount'] = this.NetAmonutToPay;
  this.postDataJsonPrepare['PaymentProcessParams']['PaymentMethodId'] = this.recurringDonationData['PaymentMethodId'];
  this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['BillingDate'] = this.recurringDonationData['recurringDate'];
  this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['Nonce'] = nonce;
  
  // if (this.createForm.controls.SaveCardForFuture.value) {
  //     this.postDataJsonPrepare['PaymentProcessParams']['SaveCardForFuture'] = "1"
  // } else {
  //     this.postDataJsonPrepare['PaymentProcessParams']['SaveCardForFuture'] = "0";
  // }
  
  
  return this.postDataJsonPrepare;

}
successPopup(data) {
  this.activeModal.close();
  let respose= {title: data['status'],message: data['msg']}
  const modalRef = this.modalService.open(SuccessPopupComponent, {size: "md"});
  modalRef.componentInstance.user = respose;
  modalRef.result.then(
      result => {
      //   console.log("result-----modal",result)
          if (result.result && data['status'] == 'success' ) {
              this.router.navigateByUrl('/memberDashboard/settings/'+this.memberId)    
          }
      });
}

}
