import { Component, OnInit,Input, OnDestroy, ElementRef } from '@angular/core';
import { SharedService } from '../../../../_services/shared.service';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { CommonCrudService } from '../../../../_services/common-crud.service';
import * as braintree from 'braintree-web';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import { Subscription } from 'rxjs';

declare let $: any;
@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css','./donation.component.scss']
})
export class DonationComponent implements OnInit ,OnDestroy{
  donationForm;
  selectedMemberId;
  selectedCauseId;
  memberData;
  FamilyMembersList=[ ];
  memberList= [];
  causeList = [];
  recurringDay: any[];
  ServerDate;
  days: { dayId: string; dayName: string; }[];selectedPaymentMethod: string = "";
  brainTreeError: string;
    cardholdersName: string;
    noFieldsCardNumber: number;
    noFieldsDate: number;
    noFieldsCvv: number;
    postDataJsonPrepare: any;
  activeRecurringDate: any;
  causeId;
  SelectedPaymentOptionId;
  countryName;
  countryId;
  paymentOptionsList;
  AcceptAnyAmount;
  currencyList;
  Currency;
  currencyPaymentList;
  frequencyList;
  frequencyOptions;
  PaymentMethods;
  text;
  paymentOption;
  selectedPaymentOption;
  minAmount;
  causeName;
  PaymentOptionValueId;
  selectedFrequencyId;
  selectedFrequencyName;
  otheramoutValidation: boolean = false;
  maxOtherAmount: boolean = false;
  initialSelectedAmount;
  initialFrequency;
  animatedDateLabel: boolean = false;
  InitialMethodId;
  showPayNow: number;
  PayNow = '';
  hostedFieldsInstance: braintree.HostedFields;
  accounttypeList: { value: string; name: string; }[];
  brainTreeToken: string;
  userLocation;
  IsSavedAchPresent : boolean = false;
  savedCards;
  showNewCardOption: boolean = false;
  brainTreeReInit = true;
  NetAmonutToPay: string = "1.00";
  type: string;
    StatesList: Object;
    state: any;
  selectedSavedCard : boolean = false;
    Step2Fields: any[];
  routeParam;
  memberId;
  isLoading:boolean = false;
    formResult: Object;
    user: { name: string; age: number; };
    isCauseSelected: boolean = false;
    primaryCountryId: string;
    // @Input() currentMsgFromChild1ToChild2: any [];
    message:string;
    dataShared: Subscription;
    flag: number = 0;
    paymentSettingId;
    shownewCreditcardOption:boolean = false;
    showSavedCreditcardOption:boolean = true;
    savedAchCardButtonShow:boolean = false;
    savedCreditCardButtonShow:boolean = false;
    countrybasedcurrency: boolean;
  constructor(public sharedService:SharedService,public formBuilder: FormBuilder,public _crud:CommonCrudService,public activeRoute:ActivatedRoute,public modalService: NgbModal, public router : Router, private el: ElementRef) { 
  
    this.countryId = localStorage.getItem('countryId')
    this.primaryCountryId = localStorage.getItem('primaryCountryId')
      this.formInit();
  }

  ngOnInit() {
    this.routeParam = this.activeRoute.snapshot;
   
    //   console.log("#@#@#@#....<", this.currentMsgFromChild1ToChild2);
    this.accounttypeList = [
        {
            value: 'checking',
            name: 'Checking'
        },
        {
            value: 'savings',
            name: 'Savings'
        }
    ];
       /*----Placeholder Up---*/
    $(".member-wrp input").on('focus', function() {
        $(this).parent(".focus-label").addClass("is-focused");
    });
    $(".member-wrp input").focusout(function() {
        if ($(this).val() == "") {
            $(this).parents(".focus-label").removeClass("is-focused");
        }
    });
    
    console.log("params-----",this.routeParam);
    if(this.routeParam.queryParams['causeId']){
        // console.log("44444");
        this.selectedCauseId = this.routeParam.queryParams['causeId'];
        let event = { Id: this.routeParam.queryParams['causeId'], Name: this.routeParam.queryParams['causeName']}
        this.onCauseChange(event);
    }
    if(this.routeParam.queryParams['memberId']){
        // console.log("44444",this.routeParam.queryParams['memberId']);
        this.selectedMemberId = this.routeParam.queryParams['memberId'];
    }
    if(this.routeParam.queryParams['isSpfmember'] && this.flag <5){
        this.flag = this.flag + 1;
        this.dataShared = this.sharedService.currentMessage.subscribe(message => this.message = message)
        console.log("default cause",this.message);

        let event = { Id: this.message['causeId'], Name: this.message['causeName']}
        this.selectedCauseId = this.message['causeId']
        this.onCauseChange(event);
    }
    this.memberId = this.routeParam.params['memberId'];
    
    this.getFamilyMembers();
    this.getAllStates(this.countryId);
    this.getCauseList();
    this.getCurrentDate();
    // this.getUserLocation();
    this.sharedService.fetchPathData('payment','donation');
    this.days = [{ dayId: '1', dayName: '1' }, { dayId: '2', dayName: '2' }, { dayId: '3', dayName: '3' },
        { dayId: '4', dayName: '4' }, { dayId: '5', dayName: '5' }, { dayId: '6', dayName: '6' },
        { dayId: '7', dayName: '7' }, { dayId: '8', dayName: '8' }, { dayId: '9', dayName: '9' }, { dayId: '10', dayName: '10' },
        { dayId: '11', dayName: '11' },
        {
            dayId: '12',
            dayName: '12'
        },
        {
            dayId: '13',
            dayName: '13'
        },
        {
            dayId: '14',
            dayName: '14'
        },
        {
            dayId: '15',
            dayName: '15'
        },
        {
            dayId: '16',
            dayName: '16'
        },
        {
            dayId: '17',
            dayName: '17'
        },
        {
            dayId: '18',
            dayName: '18'
        },
        {
            dayId: '19',
            dayName: '19'
        },
        {
            dayId: '20',
            dayName: '20'
        },
        {
            dayId: '21',
            dayName: '21'
        },
        {
            dayId: '22',
            dayName: '22'
        },
        {
            dayId: '23',
            dayName: '23'
        },
        {
            dayId: '24',
            dayName: '24'
        },
        {
            dayId: '25',
            dayName: '25'
        },
        {
            dayId: '26',
            dayName: '26'
        },
        {
            dayId: '27',
            dayName: '27'
        },
        {
            dayId: '28',
            dayName: '28'
        },
        ]
    
  }
//   ngOnDestroy() {
//     this.dataShared.unsubscribe();
//   }
  ngOnDestroy(): void {
    if (this.dataShared) {
      this.dataShared.unsubscribe();
    }
  }

  public response
  ValidationMessage: any = {
    
    'Address1': [
        { type: 'required', message: 'Please enter your street address' },
        { type: 'maxlength', message: 'Street address can only have a maximum of 100 characters' },
    ],
    'Address2': [
        { type: 'maxlength', message: 'Apt, Suite, Bldg can only have a maximum of 100 characters' },
    ],
    'City': [
        { type: 'required', message: 'Please enter your city' },
        { type: 'maxlength', message: 'City can only have a maximum of 100 characters' },
    ],
    'State': [
        { type: 'required', message: 'Please select your state' }
    ],
    "Zip": [],
    'AccountNumber': [
        { type: "required", message: 'Please enter your bank account number' },
        { type: 'pattern', message: 'Please enter a valid account number' }
    ],
    'RoutingNumber': [],
    'AccountHolder': [
        { type: "required", message: 'Please enter the name on your bank account' },
        { type: 'pattern', message: 'Please enter a valid name on account' }
    ],
    'BankName': [
        { type: "required", message: 'Please enter the name of your bank' },
        { type: 'maxlength', message: 'Bank name can only have a maximum of 100 characters' }
    ],
    'Amount': [
        { type: 'required', message: "" },
        { type: 'maxlength', message: 'Amount can have a maximum of 12 characters' },
        { type: 'pattern', message: 'Please enter a valid Amount using only digits' }
    ]

}
  formInit(){
    this.donationForm = this.formBuilder.group({
        Member:[this.selectedMemberId],
        causeId:[this.causeId],
        Date:[this.activeRecurringDate],
        CausePaymentOptionId: [this.SelectedPaymentOptionId],
        CausePaymentOptionValueId: [this.PaymentOptionValueId],
        Amount: [this.initialSelectedAmount, Validators.compose([Validators.minLength(1), Validators.maxLength(12), Validators.pattern('^[0-9.]*$')])],
        Frequency: [this.initialFrequency],
        OtherAmount:[null],
        CurrencyISOCode: [this.Currency],
        PaymentProcessParams: this.getPaymentParams(),
        BillingAddress: this.getBillingAddress()


    });
}
async getUserLocation() {
    let params = {
        url: 'frontend-site/user-location'
    }
    this._crud.getData(params).subscribe(data => {
        
        this.userLocation = data['data'];
        // console.log(this.userLocation);

        this.countryId = this.userLocation.CountryId
        this.countryName = this.userLocation.CountryName
        this.getAllStates(this.countryId);
        // this.getAllCountries(this.userLocation.PrimaryCountryId);
        // this.getFooterText(this.userLocation.PrimaryCountryId);
    })
    
}
getPaymentParams(): FormGroup {
    return this.formBuilder.group({
        PaymentMethodId: [this.InitialMethodId],
        ProceedWithoutPaymentProcess: [null],
        PaymentFields: this.getPaymentFields(),
    })
}
getPaymentFields() {
    let fbGroup = this.formBuilder.group({
        AccountNumber: [null],
        RoutingNumber: [null],
        AccountHolder: [null],
        BankName: [null],
        AccountType: [null],
        PayNow: [0],
        BillingDate: [this.activeRecurringDate],
        Nonce: [''],
        ChooseCard:[''],
        SaveCardForFuture:[false],

    })
    setTimeout(() => {
        // if (this.selectedFrequencyName == "Onetime") {
        //     // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['BillingDate'].setValue(null);
        //     // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('BillingDate').setValidators(null);
        //     this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(1);
        //     console.log("1");
        //     // this.DonationForm.controls['PaymentProcessParams'].controls['ProceedWithoutPaymentProcess']

        // }
        // else {
        //     console.log(this.ServerDate, "99999999999999");
        //     // if (this.ServerDate <= 28) {
        //     //     console.log("htimeout", this.ServerDate);

        //     //     this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(0);

        //     // } else {
        //     //     this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(1);
        //     //     console.log("2", this.ServerDate);

        //     // }
        //     // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('BillingDate').setValidators(Validators.required);
        // }
        // // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('BillingDate').updateValueAndValidity();
    }, 0)
    return fbGroup;
}
getBillingAddress(): FormGroup {
    let fbGroup = this.formBuilder.group({
        AddressId:[''],
        // Address1: [''],
        // Address2: [''],
        // City: [''],
        // StateId: [this.state],
        // CountryId: [this.countryId],
        // Zip: [''],
        Address1: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
        Address2: ['', Validators.maxLength(100)],
        City: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
        StateId: [this.state, Validators.required],
        CountryId: [this.countryId, Validators.required],
        Zip: [''],
    })
    return fbGroup;
}
getAllStates(countryId) {
    let params = {}
    params["params"] = {
        CountryId: countryId
    }
    params["url"] = "frontend-site/fetch-states/" + countryId;
    this._crud.getData(params).subscribe(data => {
        // console.log('func3');
        // console.log('data3', data['data']);
        this.StatesList = data['data'];
    })

}

getFamilyMembers(){
  let param = {
      url: "frontend-site/list-family-members-with-primary-member", params: { PrimaryMemberId: this.memberId }
  }
  this._crud.getData(param).subscribe(res =>{
      this.memberData = res['data'];
      if(this.selectedMemberId == undefined){
      this.selectedMemberId = res['data'][0]['MemberId'];

      }
      this.getSelectedMemberAddress(this.selectedMemberId);
    //   console.log("member data----",this.selectedMemberId);
      this.memberData.forEach(element => {
          this.FamilyMembersList.push({Id:element.MemberId,Name:element.MemberName});
          // console.log("family member-----",this.FamilyMembersList);
      });
      this.memberList = this.FamilyMembersList;
      // console.log("family member-----",this.memberList);
      
  });
}
getSelectedMemberAddress(memberId){
    let param = {
        url: "frontend-site/member-donation/fetch-address", params: { MemberId: memberId }
    }
    this._crud.getData(param).subscribe(res =>{
       
        // console.log("member data----",res['data']);
        if(res['data']){
            this.donationForm.controls.BillingAddress.controls.AddressId.setValue(res['data']['AddressId']);
            this.donationForm.controls.BillingAddress.controls.Address1.setValue(res['data']['Address1']);
            this.donationForm.controls.BillingAddress.controls.Address2.setValue(res['data']['Address2']);
            this.donationForm.controls.BillingAddress.controls.City.setValue(res['data']['City']);
            this.donationForm.controls.BillingAddress.controls.StateId.setValue(res['data']['StateId']);
            this.donationForm.controls.BillingAddress.controls.Zip.setValue(res['data']['Zip']);
            this.donationForm.controls.BillingAddress.controls.CountryId.setValue(this.countryId);

        
            document.getElementById("Add1").classList.add("is-focused");
            if(res['data']['Address2']){
                document.getElementById("Add2").classList.add("is-focused");
            }else{
                document.getElementById("Add2").classList.remove("is-focused");
            }
            document.getElementById("City").classList.add("is-focused");
            document.getElementById("Code").classList.add("is-focused");
        }else{
            this.donationForm.controls.BillingAddress.controls.AddressId.setValue('');
            this.donationForm.controls.BillingAddress.controls.Address1.setValue('');
            this.donationForm.controls.BillingAddress.controls.Address2.setValue('');
            this.donationForm.controls.BillingAddress.controls.City.setValue('');
            this.donationForm.controls.BillingAddress.controls.StateId.setValue('');
            this.donationForm.controls.BillingAddress.controls.Zip.setValue('');
            document.getElementById("Add1").classList.remove("is-focused");
            document.getElementById("Add2").classList.remove("is-focused");
            document.getElementById("City").classList.remove("is-focused");
            document.getElementById("Code").classList.remove("is-focused");
        }
        

    });
}
getCauseList(){
  let param = {
      url: "frontend-site/cause/list", params: { CountryId: this.countryId }
  }
  this._crud.getData(param).subscribe(res =>{
    //   console.log(res);
      let data = res['data'];
      let cauList = [];
      data.forEach(element => {
          cauList.push({Id:element.CauseId,Name:element.CauseName,Description:element.CauseDescription})
      });
      this.causeList = cauList;
  });
}
getCurrentDate() {
  let params = {}
  params["url"] = "frontend-site/donation/current-date";
  params["params"] = {
      Format: "d"
  };
  this._crud.getData(params).subscribe(data => {
    //   console.log('ServerDate', data['data']);
      this.ServerDate = parseInt(data['data']);
      this.recurringDate();
  })
}
recurringDate() {
  this.recurringDay = [];
  console.log(this.days.length, "day");
  for (let i in this.days) {
      this.recurringDay.push({ Id: parseInt(this.days[i].dayId), Name: this.days[i].dayName });
  }
  if (this.ServerDate <= 28) {
      if (this.recurringDay.some(x => x.Id === this.ServerDate)) {
          this.activeRecurringDate = this.ServerDate;
          console.log(this.activeRecurringDate, "Server");
          console.log(this.recurringDay, "Recurringgg");
          this.donationForm.controls['Date'].setValue( this.activeRecurringDate)
      }
  } else {
      this.showPayNow = 1;
      this.activeRecurringDate = 1;
      this.donationForm.controls['Date'].setValue( this.activeRecurringDate)

  }
}
onCauseChange(event) {
    // console.log("cause chnage event----->",event,this.countryId);
    this.Currency = null
    this.causeId = event.Id;
    this.causeName = event.Name;
    // console.log(this.causeId,this.causeName, "aakj");
    this.SelectedPaymentOptionId = null;
    // this.getPaymentOptions(null, this.countryId, this.countryName);
    this.getPaymentOptions(null, this.countryId);
    this.isCauseSelected = true;
}
getPaymentOptions(event?: any, countryId?: string, countryName?: string) {
    if (event = undefined) {
        this.countryName = event.options[event.selectedIndex].getAttirbute(countryName);
        this.countryId = event.value;
    }
    else {
        this.countryName = countryName;
        this.countryId = countryId;
    }
    let params = {}
        params["params"] = {
            PrimaryCountryId: this.primaryCountryId,
            CauseName: this.causeName,
            CauseId: this.causeId,
            CountryId: this.countryId
        }
        params["url"] = 'frontend-site/member-donation/payment-options'
        this._crud.getData(params).subscribe(data => {
            // console.log("getpayment optionss------>",data['data']);
            this.paymentOptionsList = data['data'];
            this.AcceptAnyAmount = data['data'].AcceptAnyAmount;
            this.causeId = this.paymentOptionsList.CauseId;
            this.getAllPaymentMethods(this.countryId);
            this.currencyList = data['data'].CurrencyCodeList;
            // console.log("get paymntoption---curncy list----",this.currencyList);
            // this.Currency = this.currencyList[0].CurrencyCode;
            // let param = {
            //     url: "frontend-site/member/get-currency/"+ this.memberId 
            // }
            // this._crud.getData(param).subscribe(data1 => {
            //     console.log(data1['data'],data1['data']['CurrencyCode']);
           
          
            //         this.currencyList.forEach(element2 => {
            //             if (data1['data']['CurrencyCode'] == element2.CurrencyCode) {
            //                 console.log("am here");
            //                 this.Currency = element2.CurrencyCode;
            //             }
            //         });
            //         this.getCurrencyOptions(this.Currency);
            //     });
            //   console.log(this.getCurrencylist(this.memberId))
            if(this.routeParam.queryParams['isSpfmember'] && this.flag <5){
                this.flag = this.flag + 1;
                // this.Currency = this.message['CurrencyISOCode'] 
                this.getCurrencyOptions(this.message['CurrencyISOCode']); 
            }else{
                this.getCurrencylist(this.memberId)

            }
            // console.log(this.currencyList);
            this.currencyPaymentList = data['data'].PaymentOptionList;
            if (this.currencyList.length) {
                // setTimeout(() => {
                //     this.getCurrencyOptions(this.Currency);
                // }, 0);
            }
            else {
                this.frequencyList = [];
                this.frequencyOptions = [];
            }
        });
        if (this.countryId == 'BprLdASASGl0') {
            this.donationForm.controls['BillingAddress'].get('Zip').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{5,5}$/)]));
            this.donationForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
            this.ValidationMessage.Zip = [{ type: "required", message: "Please enter your postal code" }, { type: "pattern", message: "Please enter a valid postal code with five digits" }];
        } else {
            this.donationForm.controls['BillingAddress'].get('Zip').setValidators(Validators.pattern(/^(?!0+$)[0-9A-Za-z\s\-]{3,10}$/));
            this.donationForm.controls['BillingAddress'].get('Zip').updateValueAndValidity();
            this.ValidationMessage.Zip = [{ type: "pattern", message: "Please enter a valid postal code" }];
        }
}
getAllPaymentMethods(countryId) {
    let params = {}
    params["params"] = {
        sourceType: 1,
        countryId: countryId,
        sourceId: this.causeId,
    }
    params["url"] = "frontend-site/fetch-available-payment-methods";
    this._crud.getData(params).subscribe(data => {
        // console.log(data['data'], 'get all PaymenthMethods');
        // this.formInit();
        this.PaymentMethods = data['data'].PaymentMethods;
        this.PaymentMethods.forEach((element,i) => {
            if (element.PaymentMethod == 'ACH') {
                if((this.countryId == 'BprLdASASGl0' && this.Currency == 'USD') || (this.countryId == 'gQFUOwHXczkw' && this.Currency == 'CAD')){
                    // element.text = "Bank Transfer (ACH)"
                }else{
                    this.PaymentMethods.splice(i,1);
                }
               
            }
        });
            this.PaymentMethods.forEach(element => {
                if (element.PaymentMethod == 'ACH') {
                    element.text = "Bank Transfer (ACH)"
                } else if (element.PaymentMethod == 'Credit/Debit') {
                    element.text = "Credit / Debit Card"
                } else {
                    element.text = element.PaymentMethod;
                    // this.text = element.BankAccountDetails;
                }
            });
            // console.log(this.PaymentMethods, 'this.PaymentMethods');
            // commenting this code to avoid braintree issue
//             let braintree =  data['data'].PaymentMethods.filter(x => { return x.UniqueName == "credit_or_debt" })
//             this.brainTreeToken = braintree[0].TokenizationKey;
// this.createBraintreeUI();
    });
  
}
getCurrencyOptions(currency) {
    let swapFrequencyList = [];
    this.donationForm.controls['OtherAmount'].setValue(null);
    console.log('currency 1',currency);
    this.Currency = currency;
    this.paymentOption = this.paymentOptionsList.PaymentOptionList.filter(x => { return x.CurrencyCode == currency });
    this.selectedPaymentOption = this.paymentOption[0];
    if (this.selectedPaymentOption) {
        this.minAmount = this.selectedPaymentOption.MinimumAmount;
    }
    this.SelectedPaymentOptionId = this.paymentOption[0].PaymentOptionId;
    this.donationForm.controls['CausePaymentOptionId'].setValue(this.SelectedPaymentOptionId);
    if (this.paymentOption) {
        this.frequencyList = this.paymentOption[0].FrequencyList
        // console.log('frequencyList', this.frequencyList)
        // swap frequencylist
        this.frequencyList.forEach(element => {
            if (element.Name == 'Monthly') {
                element.Name = 'Recurring (Monthly)'
            }
        });
        if (this.frequencyList.length > 1) {

            if (this.frequencyList[0].Name == 'Onetime') {
                swapFrequencyList[0] = this.frequencyList[1];
                swapFrequencyList[1] = this.frequencyList[0];
                // console.log("sssssssss", swapFrequencyList);
                this.frequencyList = swapFrequencyList;
            }
        }
        if(this.routeParam.queryParams['isSpfmember'] == 'false' && this.frequencyList.length > 1 && this.flag <5){
            this.flag = this.flag + 1;

            this.frequencyList.splice(1, 1);
          }
          else if(this.routeParam.queryParams['isSpfmember'] == 'false' && this.frequencyList.length > 1 &&  this.causeId == "158198755174B"){
            this.frequencyList.splice(1, 1);

          }
          
          else{

            this.flag = this.flag + 1;
          }
        
        if(this.routeParam.queryParams['isSpfmember'] && this.flag <5){
            this.flag = this.flag + 1;
            let frequency = {
                id: this.message['Frequency'],
                checked: false
            }
            this.getFrequencyOptions(frequency);
        }else{
            let frequency = {
                id: this.frequencyList[0].Id,
                checked: false
            }
            this.getFrequencyOptions(frequency);

        }
    }
    let params = {}
    params["params"] = {
        sourceType: 1,
        countryId: this.countryId,
        sourceId: this.causeId,
    }

    params["url"] = "frontend-site/fetch-available-payment-methods";
    this._crud.getData(params).subscribe(data => {
        this.PaymentMethods = data['data'].PaymentMethods;
    this.PaymentMethods.forEach((element,i) => {
        if (element.PaymentMethod == 'ACH') {
            if((this.countryId == 'BprLdASASGl0' && this.Currency == 'USD') || (this.countryId == 'gQFUOwHXczkw' && this.Currency == 'CAD')){
                // element.text = "Bank Transfer (ACH)"
            }else{
                this.PaymentMethods.splice(i,1);
            }
           
        }
    });
    this.PaymentMethods.forEach((element,i) => {
        if (element.PaymentMethod == 'ACH') {
                element.text = "Bank Transfer (ACH)"
            
           
        } else if (element.PaymentMethod == 'Credit/Debit') {
            element.text = "Credit / Debit Card"
        }else{
            element.text = element.PaymentMethod;
            // this.text = element.BankAccountDetails;
        }
    });
})
}
getFrequencyOptions(frequency) {
    // console.log("get frequecy-----",frequency);
    this.PaymentOptionValueId = null;
    this.selectedFrequencyId = frequency.id;
    if (frequency.id == "any_amount") {
        if (frequency.checked == true) {
            this.PaymentOptionValueId = null;
            this.donationForm.controls['CausePaymentOptionValueId'].setValue(null);
            this.donationForm.controls['Amount'].setValue(null);
            this.frequencyOptions = [];
            this.selectedFrequencyName == this.frequencyList[0].Name;
        }
        else {
            this.donationForm.controls['Amount'].setValue(null);
            let frequency = {
                id: this.frequencyList[0].Id,
                checked: false,
            }
            this.getFrequencyOptions(frequency);
        }
    }else if (frequency.checked == true) {
        let selectedfrequency = this.frequencyList.filter(x => { return x.Id == frequency.id })
        this.selectedFrequencyName = selectedfrequency[0].Name;
    } else {
        let selectedfrequency = this.frequencyList.filter(x => { return x.Id == frequency.id })
        this.selectedFrequencyName = selectedfrequency[0].Name;
        console.log('selectedFrequencyName', this.selectedFrequencyName);
        if (this.selectedFrequencyName == "Onetime") {
            this.showPayNow = 0;
            // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['BillingDate'].setValue(null)
            // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('BillingDate').setValidators(null)
            // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(1)

        }
        else {
            if (this.activeRecurringDate < this.ServerDate) {
                this.showPayNow = 1;
                // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(1)

            }
            else {
                this.showPayNow = 0;
                this.PayNow = '0';
                // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(0)

            }
            // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('BillingDate').setValidators(Validators.required);
        }
        this.frequencyOptions = this.paymentOption[0].PaymentOptions.filter(x => { return x.FrequencyId == frequency.id });
        // console.log('frequencyOptions', this.frequencyOptions)
        this.getAmount(this.frequencyOptions[0].Amount);
    }
}
getAmount(amount) {
    // console.log("hiiiiiiiiiiii", amount);
    this.otheramoutValidation = false;
    this.maxOtherAmount = false;
    this.initialSelectedAmount = amount
    this.donationForm.controls['Amount'].setValue(this.initialSelectedAmount);
    this.donationForm.controls['OtherAmount'].setValue(null);
    document.getElementById("otherAmount").classList.remove("is-focused");
    let amountOption = this.frequencyOptions.filter(x => { return x.Amount == amount });
    this.PaymentOptionValueId = amountOption[0].PaymentOptionValueId;
    if(this.routeParam.queryParams['isSpfmember'] && this.flag <5){
        this.flag = this.flag + 1;
        if(this.message['Amount'] != null){
            let amountOption = this.frequencyOptions.filter(x => { return x.Amount == this.message['Amount'] });
            this.PaymentOptionValueId = amountOption[0].PaymentOptionValueId; 
            this.initialSelectedAmount = this.message['Amount']
            this.donationForm.controls['Amount'].setValue(this.initialSelectedAmount);
        }else{
            this.donationForm.controls['OtherAmount'].setValue(this.message['OtherAmount']);
            document.getElementById("otherAmount").classList.add("is-focused");
            this.upfunction(this.message['OtherAmount'])
        }

    }
    this.donationForm.controls['CausePaymentOptionValueId'].setValue(this.PaymentOptionValueId);
    // if (this.PaymentOptionValueId == null) {
    //     this.PaymentOptionValueId = amountOption[0].PaymentOptionValueId;
    // }
    this.initialFrequency = this.selectedFrequencyId;
    this.donationForm.controls['Frequency'].setValue(this.initialFrequency)
    // this.formInit();
}
animateLabel(event) {
    event.parentNode.classList.add('is-focused');
}

animateLabelRemove(e) {
    // console.log(e.value, "AHGKND");
    if (e.value == '') {
        e.parentNode.classList.remove('is-focused');
    }
}

onFocus(e) {
    this.animatedDateLabel = true;
}

onFocusOut(e) {
    if (e.target.innerText != '') {
        this.animatedDateLabel = true;
    } else {
        this.animatedDateLabel = false;
    }
}
upfunction(e) {
    var matches = this.donationForm.controls['OtherAmount'].value.match('^[0-9]*$');
    var numberValue = Number(this.minAmount);
    this.donationForm.controls['Amount'].setValue(null);
    if (matches == null || this.donationForm.controls['OtherAmount'].value < numberValue) {
        this.otheramoutValidation = true;
        this.maxOtherAmount = false;
    } else if (this.donationForm.controls['OtherAmount'].value > 999999999999) {
        this.maxOtherAmount = true;
        this.otheramoutValidation = false;
    } else {
        this.otheramoutValidation = false;
        this.maxOtherAmount = false;
    }
}
checkPayNow(date) {
    this.activeRecurringDate = date.Id;
    // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['BillingDate'].setValue(date.Id);
    if (this.activeRecurringDate < this.ServerDate) {
        this.showPayNow = 1;
        // console.log("3");

        this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(1);
    }
    else {
        this.showPayNow = 0;
        this.PayNow = '0';
        this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(0);
    }
}
setPaynow(value) {
    if (value == true) {
        // console.log("4");

        this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(1);
    } else if (value == false) {
        this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(0);

    }
}
onPaymentMethodChange(paymentMethod) {
    this.isLoading = false;
    // console.log(paymentMethod, "HAAAALOAAAA");
    let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == paymentMethod });
    this.paymentSettingId = selectedId[0].CountryPaymentSettingId;
    this.InitialMethodId = selectedId[0].PaymentMethodId;
    // console.log(this.InitialMethodId, "Initial");
    let details = this.PaymentMethods.filter(x => { return x.UniqueName == paymentMethod });
    this.text = details[0].BankAccountDetails;
    let PaymentFields = this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'];
    this.selectedPaymentMethod = paymentMethod;
    if (this.selectedPaymentMethod == "ach" || this.selectedPaymentMethod == "credit_or_debt") {
        // this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(1);
        this.donationForm.controls['PaymentProcessParams'].controls['ProceedWithoutPaymentProcess'].setValue(0);
    } else {
        this.selectedPaymentMethod = "manual_payment"
        this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].setValue(0);
        this.donationForm.controls['PaymentProcessParams'].controls['ProceedWithoutPaymentProcess'].setValue(1);
    }
    if (this.selectedPaymentMethod == "ach") {
        PaymentFields.get('ChooseCard').setValidators(Validators.required);

        PaymentFields.get('AccountHolder').setValidators(Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z '&-]+$/)]));
        PaymentFields.get('AccountNumber').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{6,17}$/)]));
        // PaymentFields.get('BankName').setValidators(Validators.compose([Validators.required, Validators.maxLength(100)]));
        PaymentFields.get('AccountType').setValidators([Validators.required]);
        if (this.countryId == 'BprLdASASGl0') {
            PaymentFields.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{9,9}$/)]));
            PaymentFields.get('RoutingNumber').updateValueAndValidity();
            this.ValidationMessage.RoutingNumber = [
                { type: "required", message: 'Please enter a valid routing number' },
                { type: 'pattern', message: 'Please enter a valid routing number with nine digits' }
            ]
        } else if (this.countryId == 'gQFUOwHXczkw') {
            PaymentFields.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?!0+$)\d{8,8}$/)]));
            PaymentFields.get('RoutingNumber').updateValueAndValidity();
            this.ValidationMessage.RoutingNumber = [
                { type: "required", message: 'Please enter a valid routing number' },
                { type: 'pattern', message: 'Please enter a valid routing number with eight digits' }
            ]
        } else {
            PaymentFields.get('RoutingNumber').setValidators(Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')]));
            PaymentFields.get('RoutingNumber').updateValueAndValidity();
            this.ValidationMessage.RoutingNumber = [
                { type: "required", message: 'Please enter your bank routing number' },
                { type: 'pattern', message: 'Please enter a valid routing number using only numbers' }
            ]
        }
    }
    else {
        PaymentFields.get('AccountHolder').setValidators(null);
        PaymentFields.get('RoutingNumber').setValidators(null);
        PaymentFields.get('AccountNumber').setValidators(null);
        PaymentFields.get('BankName').setValidators(null);
        PaymentFields.get('AccountType').setValidators(null);
    }
    PaymentFields.get('AccountHolder').updateValueAndValidity();
    PaymentFields.get('RoutingNumber').updateValueAndValidity();
    PaymentFields.get('AccountNumber').updateValueAndValidity();
    PaymentFields.get('BankName').updateValueAndValidity();
    PaymentFields.get('AccountType').updateValueAndValidity();
    // console.log(selectedId);
    if(this.selectedPaymentMethod !== "credit_or_debt"){
        let params = {};
        params['url'] = 'frontend-site/member/client-token/' + selectedId[0].CountryPaymentSettingId + '/' + this.selectedMemberId;
        params['params'] = {};
                return this._crud.getData(params)
                    .subscribe(data => {
                        if (data['status'] == "success") {
                            // console.log("ach saved cards-------",data['data']['SavedCards']);
                            if(data['data']['SavedCards'].length > 0){
                                console.log("saved card present---------------");
                                this.IsSavedAchPresent = true;
                                this.savedAchCardButtonShow = true;
                                this.savedCards = data['data']['SavedCards'];   
                            }else{
                                this.savedAchCardButtonShow = false;
                                this.showNewCardOption = true;
                                this.IsSavedAchPresent = false;
                            }

                        }
                    }, error => {
                        console.log("Some error tiggered" + error)
                    });
    }else{
        let params = {};
        params['url'] = 'frontend-site/member/credit-card-exist' + '/' + this.routeParam.params['memberId'];
        params['params'] = {};
                return this._crud.getData(params).subscribe(response => {
                    console.log("saved credit card exit for use-----",response);
                    this.savedCreditCardButtonShow = response['data'];
                })
    }
    
} 
onMemberChange(value){
// console.log(value);
this.selectedMemberId = value.Id;
this.getSelectedMemberAddress(this.selectedMemberId);
this.getCurrencylist(this.selectedMemberId)
}
tokenizeUserDetails() {
    
    // console.log('formDetails', this.donationForm.value);
    if (this.selectedPaymentMethod == 'credit_or_debt') {
        this.hostedFieldsInstance.tokenize({ cardholderName: this.cardholdersName }).then((payload) => {
            // c/onsole.log('payload', payload);
            this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['Nonce'].setValue(payload.nonce);
            this.submitForm()
            // submit payload.nonce to the server from here
        }).catch((error) => {
            // console.log('error_detail', error)
            this.brainTreeError = '';
            // perform custom validation here or log errors
            if (this.donationForm.controls['Amount'].value == null && this.otheramoutValidation == false && this.maxOtherAmount == false) {
                this.donationForm.controls['Amount'].setValue(this.donationForm.controls['OtherAmount'].value)
                this.donationForm.controls['CausePaymentOptionValueId'].setValue(null);
            }
            let Step2Fields = [
                this.donationForm.controls['Amount'],
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['BillingDate'],
                this.donationForm.controls['PersonalDetails'].controls['FirstName'],
                this.donationForm.controls['PersonalDetails'].controls['LastName'],
                this.donationForm.controls['PersonalDetails'].controls['PrimaryPhone'],
                this.donationForm.controls['PersonalDetails'].controls['SecondaryPhone'],
                this.donationForm.controls['PersonalDetails'].controls['Email'],
                this.donationForm.controls['PersonalDetails'].controls['BillingAddress'].controls['Address1'],
                this.donationForm.controls['PersonalDetails'].controls['BillingAddress'].controls['Address2'],
                this.donationForm.controls['PersonalDetails'].controls['BillingAddress'].controls['City'],
                this.donationForm.controls['PersonalDetails'].controls['BillingAddress'].controls['CountryId'],
                this.donationForm.controls['PersonalDetails'].controls['BillingAddress'].controls['StateId'],
                this.donationForm.controls['PersonalDetails'].controls['BillingAddress'].controls['Zip'],
                this.donationForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['Address1'],
                this.donationForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['Address2'],
                this.donationForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['City'],
                this.donationForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['CountryId'],
                this.donationForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['StateId'],
                this.donationForm.controls['PersonalDetails'].controls['ShippingAddress'].controls['Zip'],
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountHolder'],
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['RoutingNumber'],
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountNumber'],
                // this.DonationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['BankName'],
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountType'],
            ]
            // console.log(this.donationForm, "TEST");
            const invalid = []
            const controls = Step2Fields;
            let i: any;
            for (i in controls) {
                controls[i].markAsDirty();
                if (controls[i].invalid) {
                    invalid.push(i);
                }
            }
            switch (error.code) {

                case 'HOSTED_FIELDS_FIELDS_EMPTY':
                    // occurs when none of the fields are filled in
                    console.error('All fields are empty! Please fill out the form.');
                    this.brainTreeError = 'All fields are empty! Please fill out the form.'
                    this.noFieldsCardNumber = 1;
                    this.noFieldsDate = 1;
                    this.noFieldsCvv = 1;
                    break;
                case 'HOSTED_FIELDS_FIELDS_INVALID':
                    // occurs when certain fields do not pass client side validation
                    console.error('Some fields are invalid:', error.details.invalidFieldKeys);
                    // this.brainTreeError = 'Some fields are invalid'
                    // you can also programtically access the field containers for the invalid fields
                    // error.details.invalidFields.forEach(function(fieldContainer) {
                    //     fieldContainer.className = 'invalid';
                    // });
                    error.details.invalidFieldKeys.forEach(element => {
                        console.log(element);
                        if (element == 'number') {
                            this.noFieldsCardNumber = 1;
                        } if (element == 'cvv') {
                            this.noFieldsCvv = 1;
                        } if (element == 'expirationDate') {
                            this.noFieldsDate = 1;
                        }
                    });
                    break;
                case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
                    // occurs when:
                    //   * the client token used for client authorization was generated
                    //     with a customer ID and the fail on duplicate payment method
                    //     option is set to true
                    //   * the card being tokenized has previously been vaulted (with any customer)
                    // See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.fail_on_duplicate_payment_method
                    console.error('This payment method already exists in your vault.');
                    this.brainTreeError = 'This payment method already exists in your vault.'
                    break;
                case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
                    // occurs when:
                    //   * the client token used for client authorization was generated
                    //     with a customer ID and the verify card option is set to true
                    //     and you have credit card verification turned on in the Braintree
                    //     control panel
                    //   * the cvv does not pass verfication (https://developers.braintreepayments.com/reference/general/testing/#avs-and-cvv/cid-responses)
                    // See: https://developers.braintreepayments.com/reference/request/client-token/generate/#options.verify_card
                    console.error('CVV did not pass verification');
                    break;
                case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
                    // occurs for any other tokenization error on the server
                    console.error('Tokenization failed server side. Is the card valid?');
                    this.brainTreeError = 'Tokenization failed server side. Is the card valid?'
                    break;
                case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
                    // occurs when the Braintree gateway cannot be contacted
                    console.error('Network error occurred when tokenizing.');
                    this.brainTreeError = 'Network error occurred when tokenizing.'
                    break;
                default:
                    console.error('Something bad happened!');
                    this.brainTreeError = 'Something bad happened!  '
            }
        });
    }
    else {
        this.submitForm();
    }
}
createBraintreeUI() {
    // console.log("braintree");
    braintree.client.create({
        authorization: this.brainTreeToken
    }).then((clientInstance) => {
        braintree.hostedFields.create({
            client: clientInstance,
            styles: {
                // Style all elements
                // 'input': {
                //   'font-size': '16px',
                //   'color': '#3A3A3A'
                // },
                '.invalid': {
                    'color': '#D0041D'
                },

                // Styling element state
                // 'input': {
                //     // 'font-family': '"Merriweather", serif !important',
                //     'padding': "0 10px 0 20px !important",
                //     'letter-spacing': "0.025em !important",
                //     "font-weight": "300 !important",
                //     "font-size": "14px !important",
                // },
                'input': {
                    'font-size': '16px!important',
                    "font-weight": '500!important',
                    'font-family': "'Poppins', sans-serif !important",
                    'color': '#0D0D0D',
                    ':placeholder': {
                        'font-weight': '500!important',
                        'color': '#9F9F9F!important',
                    }
                },

                // Media queries
                // Note that these apply to the iframe, not the root window.
                // '@media screen and (max-width: 700px)': {
                //     'input': {
                //         'font-size': '14px'
                //     }
                // }
            },

            // The hosted fields that we will be using
            // NOTE : cardholder's name field is not available in the field options
            // and a separate input field has to be used incase you need it
            fields: {
                number: {
                    selector: '#card-number',
                    placeholder: 'Card Number'
                },
                cvv: {
                    selector: '#cvv',
                    placeholder: 'CVV'
                },
                expirationDate: {
                    selector: '#expiration-date',
                    placeholder: 'Expiration Date (MM/YY)'
                },
                // postalCode: {
                //     selector: '#postal-code',
                //     placeholder: 'Pin Code'
                // }
            }
        }).then((hostedFieldsInstance) => {
            this.hostedFieldsInstance = hostedFieldsInstance;
            hostedFieldsInstance.on('focus', (event) => {
                const field = event.fields[event.emittedBy];
                const label = this.findLabel(field);
                if (label['htmlFor'] == 'card-number') {
                    this.noFieldsCardNumber = 0;
                }
                if (label['htmlFor'] == 'expiration-date') {
                    this.noFieldsDate = 0;
                }
                if (label['htmlFor'] == 'cvv') {
                    this.noFieldsCvv = 0;
                }
                // console.log(this.noFieldsCardNumber);
                // label.classList.remove('filled'); // added and removed css classes
                // can add custom code for custom validations here
                // this.cdRef.detectChanges();
            });
            hostedFieldsInstance.on('blur', (event) => {
                const field = event.fields[event.emittedBy];
                // const label = this.findLabel(field); // fetched label to apply custom validations
                // can add custom code for custom validations here
            });

            hostedFieldsInstance.on('empty', (event) => {
                const field = event.fields[event.emittedBy];
                // can add custom code for custom validations here
            });

            hostedFieldsInstance.on('validityChange', (event) => {
                const field = event.fields[event.emittedBy];
                const label = this.findLabel(field);
                if (field.isPotentiallyValid) { // applying custom css and validations
                    // label.classList.remove('invalid');
                } else {
                    // label.classList.add('invalid');
                }
                // can add custom code for custom validations here
            });
        });
    }).catch(error => {
        console.log('error', error);
        if (error.name == 'BraintreeError') {
            // this.paymentGatewayErrorMsg = "Payment Gateway is not configured"
        }
    })
    // this.clearCardDetails();
}
 // Fetches the label element for the corresponding field
 findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
    return document.querySelector('.hosted-field--label[for="' + field.container.id + '"]');
}
AddNewAchAccount(){
    this.showNewCardOption = true;
    this.isSavedCard = true
    this.selectedSavedCard = false;
    this.IsSavedAchPresent = false;
    //clearing new accunt card ach values on tab switch
  //if values are present in the new card form animate the placeholdr up,to avoid overlaping 
  if(this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder').value){
    // console.log("accunt holder",document.getElementById("ach_person_name"))
    // document.getElementById("ach_person_name").classList.add("is-focused");
    this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder').setValue('');
    // this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder').setValidators(null);
  }
  if(this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber').value){
    // console.log("accunt holder routing")
    // document.getElementById("ach_routing_number").classList.add("is-focused");
    this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber').setValue('');
    // this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber').setValidators(null);
  }
  if(this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber').value){
    // console.log("accunt number",document.getElementById("ach_account_number")) 
    // document.getElementById("account_number").classList.add("is-focused");
    this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber').setValue('');
    // this.subscriptionForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber').setValidators(null);
    
  }
  if(this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType').value){
    this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType').setValue('');
  }
}
listsavedAchAccount(){
    this.IsSavedAchPresent = true;
    this.showNewCardOption = false;
}
onSelectedSavedcardChange(cardDetails){
    // console.log("inside saved card change------->",cardDetails);
    this.showNewCardOption = false;
    this.selectedSavedCard = true;

}
AddNewCreditCard(){
    this.shownewCreditcardOption = true;
    this.showSavedCreditcardOption = false;
    let braintree =  this.PaymentMethods.filter(x => { return x.UniqueName == "credit_or_debt" })
    this.brainTreeToken = braintree[0].TokenizationKey;
    this.createBraintreeUI();
}
listreditCard(){
    this.shownewCreditcardOption = false;
    this.showSavedCreditcardOption = true; 
}
getClientToken() {
    let selectedId = this.PaymentMethods.filter(x => { return x.UniqueName == this.selectedPaymentMethod });
    let params = {};
    params['url'] = 'frontend-site/member/client-token/' + selectedId[0].CountryPaymentSettingId + '/' + this.selectedMemberId;
    params['params'] = {};
    return this._crud
        .getBrainTreeToken(params)
        .pipe(map((res: any) => {
            // console.log(res.data.ClientToken,"maped clint response")
            return res.data.ClientToken;
        }));
}
createPurchase(nonce: string, chargeAmount: number) {
        // let currentUser = JSON.parse(localStorage.getItem("currentUser"));

        // let headers = new Headers();
        // headers.append("Content-Type", "application/json");
        // headers.append("Authorization", "Bearer " + currentUser.access_token);
        // headers.append("pc-id", currentUser.OptedPrimaryCountry);
        if (this.donationForm.controls['Amount'].value == null && this.otheramoutValidation == false && this.maxOtherAmount == false) {
            this.donationForm.controls['Amount'].setValue(this.donationForm.controls['OtherAmount'].value)
            this.donationForm.controls['CausePaymentOptionValueId'].setValue(null);
        }
        let param = {};
        let params = this.RequestPostParams(
            { type: this.selectedPaymentMethod },
            nonce
        );
        this.Step2Fields = [
            this.donationForm.controls['Amount'],
            this.donationForm.controls['BillingAddress'].controls['Address1'],
            this.donationForm.controls['BillingAddress'].controls['Address2'],
            this.donationForm.controls['BillingAddress'].controls['City'],
            this.donationForm.controls['BillingAddress'].controls['StateId'],
            this.donationForm.controls['BillingAddress'].controls['Zip'],

        ]
        const invalid = []
        const controls = this.Step2Fields;
        let i: any;
        for (i in controls) {
            controls[i].markAsDirty();
            if (controls[i].invalid) {
                invalid.push(i);
            }
        }
        // console.log("hiiiiiiii",invalid[0])
        this.focusCall();
        if (invalid[0] == undefined) {
            console.log("valid");
             param['url'] = "frontend-site/process/" + this.selectedMemberId + "/" + this.selectedCauseId;
        param['params'] = params;
        return this._crud
        .postData(param)
        .pipe(map((res: any) => {
            // console.log("responnse---------",res)
            this.formResult = res
            if (this.formResult['status'] == "success") {
                this.successPopup(this.formResult);
                this.donationForm.reset();
                $('.focus-label.is-focused').removeClass('is-focused');
                this.selectedPaymentMethod = "";
                this.isCauseSelected = false
                // this.getCountryList(this.UserLocation.PrimaryCountryId);
            } else {
                this.successPopup(this.formResult);
               
            }
            return res.data.nonce;
        })); 
        }
       

      
}

    onPaymentStatus(response): void {
        // console.log(response);
    } 
    public isSavedCard: boolean = false;
    payButtonStatus(response): void {
        if ($(".braintree-method.braintree-methods-initial").length == 0) {
            this.isSavedCard = response;
        }
    }   
    submitForm() {
        if (this.donationForm.controls['Amount'].value == null && this.otheramoutValidation == false && this.maxOtherAmount == false) {
            this.donationForm.controls['Amount'].setValue(this.donationForm.controls['OtherAmount'].value)
            this.donationForm.controls['CausePaymentOptionValueId'].setValue(null);
        }
        if(!this.IsSavedAchPresent){
             this.Step2Fields = [
                this.donationForm.controls['Amount'],
                this.donationForm.controls['BillingAddress'].controls['Address1'],
                this.donationForm.controls['BillingAddress'].controls['Address2'],
                this.donationForm.controls['BillingAddress'].controls['City'],
                this.donationForm.controls['BillingAddress'].controls['StateId'],
                this.donationForm.controls['BillingAddress'].controls['Zip'],
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountHolder'],
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['RoutingNumber'],
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountNumber'],
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['AccountType'],
            ]
        }else{
            this.Step2Fields = [
                this.donationForm.controls['Amount'],
                this.donationForm.controls['BillingAddress'].controls['Address1'],
                this.donationForm.controls['BillingAddress'].controls['Address2'],
                this.donationForm.controls['BillingAddress'].controls['City'],
                this.donationForm.controls['BillingAddress'].controls['StateId'],
                this.donationForm.controls['BillingAddress'].controls['Zip'],
                this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard']
            ]
        }
        
        // console.log(this.donationForm, "TEST",this.IsSavedAchPresent);
        const invalid = []
        const controls = this.Step2Fields;
        let i: any;
        for (i in controls) {
            controls[i].markAsDirty();
            if (controls[i].invalid) {
                invalid.push(i);
            }
        }
        // console.log("hiiiiiiii",invalid[0])
        // this.focusCall();
        if (invalid[0] == undefined) {
            this.isLoading = true;
            console.log("validdddd");
            // this.apiloading = true;
            let params = {}
            params["url"] =
                    "frontend-site/process/" + this.selectedMemberId + "/" + this.selectedCauseId;
                    params["params"] = this.RequestPostParams({
                        type: this.selectedPaymentMethod,
                    });
            this._crud.postData(params).subscribe(data => {
                this.isLoading = false;
                // console.log('func5');
                // console.log('form submission response', data);
                this.formResult = data;
                // console.log(this.formResult['status'], "form");
                if (this.formResult['status'] == "success") {
                    // this.apiloading = false;
                    // Helpers.DisplayMsg(this.formResult.msg)
                    // Helpers.sweetAlert({
                    //     title: 'Success',
                    //     msg: this.formResult.msg,
                    //     type: 'success'
                    // })
                    this.successPopup(this.formResult);
                    this.donationForm.reset();
                    this.isCauseSelected = false

                    // this.clearCardDetails();
                    $('.focus-label.is-focused').removeClass('is-focused');
                    this.selectedPaymentMethod = "";
                    // this.getCountryList(this.UserLocation.PrimaryCountryId);
                } else {
                    this.successPopup(this.formResult);
                    // this.apiloading = false;
                    // Helpers.DisplayMsg(this.formResult.msg)
                    // Helpers.sweetAlert({
                    //     title: 'Error',
                    //     msg: this.formResult.msg,
                    //     type: 'error'
                    // })
                }
            })
        }else{
            this.focusCall();
        }
    }
    RequestPostParams(paymentFields = null, nonce = null) {
        this.postDataJsonPrepare = {};
        this.postDataJsonPrepare['PaymentProcessParams'] = {};
        this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields'] = {};
        this.postDataJsonPrepare['PaymentProcessParams']['PaymentFields']['BillingAddress'] = {};

        // console.log(this.donationForm.controls.BillingAddress.controls.AddressId.value);
        this.postDataJsonPrepare['MemberId'] =  this.donationForm.controls.Member.value;
        this.postDataJsonPrepare['CausePaymentOptionId'] =  this.donationForm.controls.CausePaymentOptionId.value;
        this.postDataJsonPrepare['CausePaymentOptionValueId'] =  this.donationForm.controls.CausePaymentOptionValueId.value;

        // if ( this.otheramoutValidation == false && this.maxOtherAmount == false) {
        // }else{
        //     this.postDataJsonPrepare['CausePaymentOptionValueId'] =  this.donationForm.controls.CausePaymentOptionValueId.value;

        // }
        this.postDataJsonPrepare['Frequency'] =  this.donationForm.controls.Frequency.value;
        this.postDataJsonPrepare['Amount'] =  this.donationForm.controls.Amount.value;
        this.postDataJsonPrepare['CurrencyISOCode'] =  this.donationForm.controls.CurrencyISOCode.value;
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["AddressId"] = this.donationForm.controls.BillingAddress.controls.AddressId.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["Address1"] = this.donationForm.controls.BillingAddress.controls.Address1.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["Address2"] = this.donationForm.controls.BillingAddress.controls.Address2.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["City"] = this.donationForm.controls.BillingAddress.controls.City.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["StateId"] = this.donationForm.controls.BillingAddress.controls.StateId.value
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["CountryId"] = this.countryId
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingAddress"]["Zip"] = this.donationForm.controls.BillingAddress.controls.Zip.value
        if(this.selectedFrequencyName == "Recurring (Monthly)"){
            this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["PayNow"] = this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['PayNow'].value

        }
        this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["BillingDate"] = this.donationForm.controls.Date.value

        if (paymentFields != null) {
            // console.log(this.selectedFrequencyName,paymentFields);
            this.postDataJsonPrepare["PaymentProcessParams"]["SaveCardForFuture"] =
                "0";
                if(paymentFields['type']==  "manual_payment"){
                    this.postDataJsonPrepare["PaymentProcessParams"]["ProceedWithoutPaymentProcess"] = "1";
                    // this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"]["PayNow"] = "0"
                }else{
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "ProceedWithoutPaymentProcess"
                    ] = "0";
                }
           
            this.postDataJsonPrepare["PaymentProcessParams"][
                "PaymentMethodId"
            ] = this.InitialMethodId;
            if (paymentFields.type == "ach") {
                if (this.selectedSavedCard == false) {
                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                        "AccountHolder"
                    ] = this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountHolder.value;
                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                        "AccountNumber"
                    ] = this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountNumber.value;
                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                        "RoutingNumber"
                    ] = this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.RoutingNumber.value;
                    this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                        "AccountType"
                    ] = this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.AccountType.value;
                    // this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    //     "BankName"
                    // ] = this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.BankName.value;
                    if (this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.SaveCardForFuture.value) {
                        this.postDataJsonPrepare["PaymentProcessParams"][
                            "SaveCardForFuture"
                        ] = "1";
                    } else {
                        this.postDataJsonPrepare["PaymentProcessParams"][
                            "SaveCardForFuture"
                        ] = "0";
                    }
                }
                 else {
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "PaymentGatewayTokenId"
                    ] = this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].controls['ChooseCard'].value;
                }
            } else if (paymentFields.type == "credit_or_debt") {
                this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
                    "Nonce"
                ] = nonce;

                if (this.donationForm.controls.PaymentProcessParams.controls.PaymentFields.controls.SaveCardForFuture.value) {
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "SaveCardForFuture"
                    ] = "1";
                } else {
                    this.postDataJsonPrepare["PaymentProcessParams"][
                        "SaveCardForFuture"
                    ] = "0";
                }
            } 
            // else if (paymentFields.type == "check") {
            //     this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
            //         "PaymentReceivedDate"
            //     ] = this.donationForm.controls.paymentReceivedDate.value;
            //     this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
            //         "CheckDate"
            //     ] = this.donationForm.controls.CheckDate.value;
            //     this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
            //         "CheckNumber"
            //     ] = this.donationForm.controls.CheckNumber.value;
               
                

            //     this.postDataJsonPrepare["PaymentProcessParams"][
            //         "PayNowWithManualPaymentMethod"
            //     ] = this.donationForm.get('manualPaymentMethod').value;

            // } else if (paymentFields.type == "bank_transfer") {
            //     this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
            //         "ReceivedDate"
            //     ] = this.donationForm.controls.BankReceivedDate.value;
            //     this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
            //         "ReferenceNumber"
            //     ] = this.donationForm.controls.ReferenceNumber.value;
            //     this.postDataJsonPrepare["PaymentProcessParams"][
            //         "PayNowWithManualPaymentMethod"
            //     ] = this.donationForm.get('manualPaymentMethod').value;
            // } else if (paymentFields.type == "cash") {
            //     this.postDataJsonPrepare["PaymentProcessParams"]["PaymentFields"][
            //         "ReceivedDate"
            //     ] = this.donationForm.controls.cashReceivedDate.value;
            //     this.postDataJsonPrepare["PaymentProcessParams"][
            //         "PayNowWithManualPaymentMethod"
            //     ] = this.donationForm.get('manualPaymentMethod').value;
            // }
        }
        
        // console.log("11111111",this.postDataJsonPrepare)

        return this.postDataJsonPrepare;
    }

    successPopup(data) {
       this.response = {title: data['status'],message: data['msg']}
        
        //   this.modalService.show(SuccessPopupComponent, {initialState})
        const modalRef = this.modalService.open(SuccessPopupComponent, {
            size: "sm",
        });
        modalRef.componentInstance.user = this.response;
        modalRef.result.then(
            result => {
            //   console.log("result-----modal",result)
                if (result.result && data['status']=="success") {
                    // /clearing all forms.
                    this.router.navigateByUrl("/memberDashboard/mydonation/"+ this.routeParam.params['memberId'])
                    this.donationForm.reset();
                    this.selectedPaymentMethod = "";
                    this.isCauseSelected = false;
                    this.causeId = null;
                    this.formInit();
                    this.ngOnInit();

                    
                }else{
                    this.selectedPaymentMethod = "";
                    this.donationForm.controls['PaymentProcessParams'].controls['PaymentMethodId'].setValue(0);
                }
            });
    }
    focusCall(){
        if (this.donationForm.controls['Amount'].value == null && this.otheramoutValidation == true ) {
           let invalidControl = this.el.nativeElement.querySelector('#other_amount');
        //    document.getElementById("otherAmount").classList.add("is-focused");
        invalidControl.focus();

                    //   break;
        } else if(this.donationForm.controls['BillingAddress'].invalid){
            let invalidControl = null;
            for (const i of Object.keys(this.donationForm.controls['BillingAddress'].controls)) {
                if (this.donationForm.controls['BillingAddress'].controls[i].invalid) {
                    invalidControl = this.el.nativeElement.querySelector('#BillingAddress-' + i);
                    if (i == 'StateId') {
                        document.getElementById("BillingAddress-StateId").classList.add("ng-select-focused");
                        break;
                    } else {
                        invalidControl.focus();
                        break;
                    }
                }
            }
        }else if(this.donationForm.controls['PaymentProcessParams'].invalid && this.showNewCardOption){
            for (const i of Object.keys(this.donationForm.controls['PaymentProcessParams'].controls)) {
                if (this.donationForm.controls['PaymentProcessParams'].controls[i].invalid) {
                    for (const k of Object.keys(this.donationForm.controls['PaymentProcessParams'].controls[i].controls)) {
                        if (this.donationForm.controls['PaymentProcessParams'].controls[i].controls[k].invalid) {
                            const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + k + '"]');
                            invalidControl.focus();
                            break;
                        }
                    }
                }
            }
        }
    }
    getCurrencylist(memberId){
        let param = {
            url: "frontend-site/member/get-currency/"+ memberId
        }
        this._crud.getData(param).subscribe(data1 => {

        
                if( (this.currencyList.filter(x => { return x.CurrencyCode == data1['data']['CurrencyCode'] })).length > 0){
                    console.log("first if",data1['data']['CurrencyCode']);
                    this.getCurrencyOptions(data1['data']['CurrencyCode']);
                }else if((this.currencyList.filter(x => { return x.CurrencyCode == data1['data']['PrimaryCountryCurrencyCode']})).length > 0){
                    console.log("second if",data1['data']['PrimaryCountryCurrencyCode']);
                    this.getCurrencyOptions(data1['data']['PrimaryCountryCurrencyCode']);

                }else{
                    console.log("third",this.currencyList[0].CurrencyCode)
                    this.getCurrencyOptions( this.currencyList[0].CurrencyCode );
                }
            });
    }
    
}
