import { Component, OnInit ,Input} from '@angular/core';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CommonCrudService } from 'src/app/_services/common-crud.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {

  @Input() title ;
  @Input() dataList ;
  @Input() memberId;
  isSpfMember;
  singleDonationExist:boolean = false;


  constructor(public activeModal: NgbActiveModal,private router:Router,public _crud: CommonCrudService) { }

  ngOnInit(): void {
    console.log("data list----",this.dataList);
    this.dataList.forEach(element => {
      let eventStartDate = this._crud.spaceSplit(new Date(element.EventStartDate).toDateString());
      element.startDay = eventStartDate[2];
      element.startMonth = eventStartDate[1];
      element.startYear = eventStartDate[3];
      let eventEndDate = this._crud.spaceSplit(new Date(element.EventEndDate).toDateString());
      element.endDay = eventEndDate[2];
      element.endMonth = eventEndDate[1];
      element.endYear = eventEndDate[3];
    });
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.singleDonationExist = currentUser.singleDonationExist;
  }
  registerEvent(eventDate,eventType,eventName){
    localStorage.removeItem("participant"); 
    // this.activeModal.close({ 'result': true});
    this.close();
    this.router.navigate(["/memberDashboard/myeventregistration/"+this.memberId+"/eventregistration"], { queryParams: {eventDate: eventDate,eventType:eventType,eventName:eventName}});
}
close(){
  this.activeModal.close({ 'result': true });
}
}
