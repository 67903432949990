<!-- <a class="nav-link " routerLink="../"><i class="la la-arrow-left"></i>Donation List</a> -->
<div class="donation-payment bx-white">
    <form [formGroup]="donationForm">
        <div class="row mob-no-margin">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-6 mob-mb">
                        <ng-select [formControl]="donationForm.controls.Member" [items]="memberList" placeholder="Member Name" bindValue="Id" bindLabel="Name" [(ngModel)]="selectedMemberId" (change)="onMemberChange($event)">
                        </ng-select>
                    </div>
                    <div class="col-md-6 pl-0">
                        <ng-select [formControl]="donationForm.controls.causeId" [items]="causeList" placeholder="Select Cause" bindValue="Id" bindLabel="Name" [(ngModel)]="selectedCauseId" (change)="onCauseChange($event)">
                            <ng-template ng-option-tmp let-item="item">
                                <span class="full-name">{{ item.Name }}</span>
                                <span class="text-mutted">{{ item.Description }}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>

            </div>
            <div class="col-lg-12  mt-4 pt-3" *ngIf="isCauseSelected">
                <div class="row">
                    <div class="col-md-8 donation-radio-wrp d-flex mob-mb align-items-center mob-no-padding">
                        <input type="hidden" formControlName="CausePaymentOptionValueId" value="{{frequencyOptions?.PaymentOptionValueId}}" />
                        <span class="radio-label mob-mb">Donation Type</span>
                        <div class="form-check radio-btn-wrp" *ngFor="let frequency of frequencyList; let i=index;">
                            <input class="form-check-input" type="radio" formControlName="Frequency" class="filled-in" id="{{frequency?.Id}}" value="{{frequency?.Id}}" required [checked]="i == 0" (click)="getFrequencyOptions({id: $event.target.id, checked:false})">
                            <label class="form-check-label" for="{{frequency?.Id}}">
                                {{frequency?.Name}}
                            </label>
                        </div>
                    </div>
                    <div class="form_group col-md-4 col-lg-4 up-label" *ngIf="selectedFrequencyName != 'Onetime'">
                        <ng-select formControlName="Date" id="numberPicker" [items]="recurringDay" [searchable]="false" [clearable]="false" placeholder="Recurring Date" bindValue="Id" bindLabel="Name" (change)="checkPayNow($event)">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 d-flex align-items-center amount-chckbx mt-5" *ngIf="showPayNow==1">
                <div class="checkbox-wrp d-flex align-items-center">
                    <input type="checkbox" class="form-check-input filled-in" name="PayNow" id="PayNow" type="checkbox" value='{{PayNow}}' [checked]="showPayNow" (click)="setPaynow($event.target.checked)">
                    <label *ngIf="isCauseSelected" class="form-check-label" for="PayNow">Process this month's donation today</label>
                </div>
            </div>
            <div class="col-lg-12 amount-wrp btm-border pb-5 mt-4" *ngIf="isCauseSelected">
                <div class="row amount-row">
                    <div *ngIf="frequencyOptions?.length" class="col-md-12 col-lg-6 d-flex  mob-mb amnt-box-radio">
                        <span class="radio-label d-flex align-items-center h-65">Amount</span>
                        <div class="form-check amnt-radio-wrp" *ngFor="let amount of frequencyOptions; let i=index;">
                            <input formControlName="Amount" type="radio" class="filled-in custom-specs" id="{{amount?.PaymentOptionValueId}}" value="{{amount?.Amount}}" [attr.data-amountValue]='amount?.Amount' [checked]="i == 0" (click)="getAmount($event.target.attributes['data-amountValue'].value)"
                                required>
                            <!-- <input checked="" class="form-check-input" id="amount1" name="amountSelect" type="radio" value="amount1"> -->
                            <label class="form-check-label  mr-5" for="{{amount?.PaymentOptionValueId}}" *ngIf="i < 3">
                                {{amount?.PaymentOptionText}}
                            </label>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 d-flex two-input-holder form-group mb-0" *ngIf="isCauseSelected">
                        <input type="hidden" formControlName="CausePaymentOptionValueId" value="" />
                        <div class="form-group focus-label mb-0" id="otherAmount">
                            <label class="form-label-of" for="any_amount">Other Amount</label>
                            <input formControlName="OtherAmount" type="text" id="other_amount" autocomplete="off" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" (input)="upfunction($event)">
                            <span class="error-message danger alert" *ngIf="otheramoutValidation">
                                        Please enter an amount of at least {{minAmount == null ? 1 : minAmount}} {{Currency}}
                                </span>
                            <span class="error-message danger alert" *ngIf="maxOtherAmount">
                                    Please enter an amount of maximum 12 characters
                            </span>
                        </div>
                        <select class="select_section" formControlName="CurrencyISOCode" id="currencySelected" [(ngModel)]="Currency" (change)="getCurrencyOptions($event.target.value)">
                            <option hidden></option>
                                    <option *ngFor="let Currency of currencyList" value="{{Currency.CurrencyCode}}">{{ Currency.CurrencyCode }}</option>
                                </select>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 choose-radio-wrp my-4 mob-no-padding" formGroupName="PaymentProcessParams" *ngIf="isCauseSelected">
                <div class="d-flex align-items-center mob-mb">
                    <!-- <div class="col-lg-12 choose-radio-wrp my-4 mob-no-padding" formGroupName="PaymentProcessParams">
                <div class="d-flex bx-grey align-items-center mob-mb"> -->
                    <span class="radio-label  mob-mb">Payment Method</span>
                    <div class="form-check radio-btn-wrp" *ngFor="let payment of PaymentMethods; let i= index;">
                        <input type="radio" class="filled-in payment_method" id="{{payment.UniqueName}}" formControlName="PaymentMethodId" value="{{payment.PaymentMethodId}}" (change)="onPaymentMethodChange($event.target.id)" required [checked]="false">
                        <!-- <input class="form-check-input" type="radio" name="payment" id="credit" value="option1" checked> -->
                        <label class="form-check-label" for="{{payment.UniqueName}}">
                            {{payment.text}}
                        </label>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-12 d-flex add-card-wrp btm-border align-items-center pb-3 px-0">
                <h6>Paying with Card</h6>
                <div class="btn-add text-right">
                    <button type="button" class="btn-common">+Add Card</button>
                </div>
            </div>
            <div class="col-lg-12 d-flex card-bx mt-4 mb-4">
                <img src="./assets/member/images/icon-visa-card.png " alt=" " class="card-img-left">
                <p><strong>XXXX 8394</strong><span>Visa</span></p>
                <img src="./assets/member/images/icon-tick.png " alt=" " class="card-img-right">
            </div>
            <div class="col-lg-12 pl-0">
                <button type="submit" class="btn-proceed">Proceed</button>
            </div> -->

            <div class="col-lg-12 title-area mt-4  mb-4 mob-no-padding" [hidden]="selectedPaymentMethod == ''">
                <h4>Billing Address</h4>
            </div>
            <div formGroupName="BillingAddress" class="w-100" [hidden]="selectedPaymentMethod == ''">
                <div class="col-lg-12 px-0">
                    <div class="row m-0 mob-row-margin">
                        <div class="col-md-8 col-lg-8">
                            <div class="form-group focus-label w3" id="Add1">
                                <label class="form-label-of" for="adress">Street Address</label>
                                <input type="text" id="BillingAddress-Address1" formControlName="Address1" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Address1"> 
                                    <span class="error-message danger alert"  *ngIf="(donationForm.controls['BillingAddress'].get('Address1')?.hasError(validation.type) && (donationForm.controls['BillingAddress'].get('Address1')?.dirty || donationForm.controls['BillingAddress'].get('Address1')?.touched)) && donationForm.controls['BillingAddress'].get('Address1')?.invalid">
                                       {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4 pl-0 mob-pl">
                            <div class="form-group focus-label w3" id="Add2">
                                <label class="form-label-of" for="opt-address">Apt, Suite, Bldg
                                (Optional)</label>
                                <input type="text" id="opt-address" formControlName="Address2" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Address2"> 
                                    <span class="error-message danger alert"  *ngIf="(donationForm.controls['BillingAddress'].get('Address2')?.hasError(validation.type) && (donationForm.controls['BillingAddress'].get('Address2')?.dirty || donationForm.controls['BillingAddress'].get('Address2')?.touched)) && donationForm.controls['BillingAddress'].get('Address2')?.invalid">
                                       {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 px-0">
                    <div class="row m-0 mob-row-margin">
                        <div class="col-md-4 col-lg-4 pr-0 mob-pr">
                            <div class="form-group focus-label w3" id="City">
                                <label class="form-label-of" for="city">City</label>
                                <input type="text" id="BillingAddress-City" formControlName="City" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">

                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.City"> 
                                    <span class="error-message danger alert"  *ngIf="(donationForm.controls['BillingAddress'].get('City')?.hasError(validation.type) && (donationForm.controls['BillingAddress'].get('City')?.dirty || donationForm.controls['BillingAddress'].get('City')?.touched)) && donationForm.controls['BillingAddress'].get('City')?.invalid">
                                       {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4 form-group">
                            <!-- <div class="select-main_selectric custom-slct on-top-title"> -->
                            <ng-select formControlName="StateId" [items]="StatesList" id="BillingAddress-StateId" placeholder="State" bindValue="StateId" bindLabel="StateName" [(ngModel)]="state" [clearable]="false">
                            </ng-select>
                            <span class="error-message alert danger" *ngIf="(donationForm.controls['BillingAddress'].get('StateId')?.dirty || donationForm.controls['BillingAddress'].get('StateId')?.touched) && donationForm.controls['BillingAddress'].get('StateId')?.invalid">
                                Please select your state
                            </span>
                            <!-- </div> -->
                        </div>
                        <div class="col-md-4 col-lg-4 pl-0 mob-pl">
                            <div class="form-group focus-label w3" id="Code">
                                <label class="form-label-of" for="post-code">Postal Code</label>
                                <input type="text" id="BillingAddress-Zip" formControlName="Zip" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                <span class="m-form__help" *ngFor="let validation of ValidationMessage.Zip"> 
                                    <span class="error-message danger alert"  *ngIf="(donationForm.controls['BillingAddress'].get('Zip')?.hasError(validation.type) && (donationForm.controls['BillingAddress'].get('Zip')?.dirty || donationForm.controls['BillingAddress'].get('Zip')?.touched)) && donationForm.controls['BillingAddress'].get('Zip')?.invalid">
                                       {{ validation.message }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="payment" class="col-lg-12" [hidden]="selectedPaymentMethod == ''">
                <div class="row m-0 mob-row-margin payment-sec-wrp" formGroupName="PaymentProcessParams">
                    <div class="col-lg-12 title-area mt-3 mb-3 px-0" [hidden]="selectedPaymentMethod != 'ach'">
                        <h4>Payment Information</h4>
                        <div class="btn-add text-right">
                            <button type="button" class="btn-common" *ngIf="IsSavedAchPresent" (click)="AddNewAchAccount()">+New Account</button>
                            <button type="button" class="btn-common" *ngIf="!IsSavedAchPresent && savedAchCardButtonShow" (click)="listsavedAchAccount()">Use Existing Account</button>
                            <hr>
                        </div>
                    </div>
                    <div class="col-lg-12 title-area mt-3  mb-3 px-0" [hidden]="selectedPaymentMethod != 'credit_or_debt'">
                        <!-- <h4>Paying with Card</h4> -->
                        <div class="btn-add text-right">
                            <button type="button" class="btn-common" *ngIf="showSavedCreditcardOption && savedCreditCardButtonShow" (click)="AddNewCreditCard()">+New Card</button>
                            <button type="button" class="btn-common" *ngIf="!showSavedCreditcardOption" (click)="listreditCard()">Use Existing Card</button>
                            <hr>
                        </div>
                    </div>
                    <!-- calling new component for saved cards gor credit -->
                    <div class="col-lg-10" *ngIf="selectedPaymentMethod == 'credit_or_debt' && !shownewCreditcardOption">
                        <app-savedcardlist [paymentMethodType]="selectedPaymentMethod" [brainTreeToken]="brainTreeToken" [paymentSettingId]="paymentSettingId" [memberId]="selectedMemberId" [donationForm]="donationForm" [selectedFrequencyName]="selectedFrequencyName" [countryId]="countryId"
                            [InitialMethodId]="InitialMethodId" [NetAmonutToPay]="NetAmonutToPay" [selectedCauseId]="selectedCauseId" [maxOtherAmount]="maxOtherAmount" [otheramoutValidation]="otheramoutValidation" [type]="'donation'"></app-savedcardlist>
                    </div>
                    <div [hidden]="selectedPaymentMethod != 'ach'" class="w-100 ach" formGroupName="PaymentFields">
                        <!-- showing saved card entires -->
                        <!-- style this entires -->
                        <!-- <ul class="nav nav-tabs  m-tabs-line m-tabs-line--success" role="tablist">
                            <li class="nav-item m-tabs__item">
                                <a class="nav-link m-tabs__link " [class.active]="IsSavedAchPresent" href="javascript:void(0);" role="tab" (click)="listsavedAchAccount()"><i class="la la-cloud-upload"></i> Saved accounts</a>
                            </li>
                            <li class="nav-item m-tabs__item">
                                <a class="nav-link m-tabs__link" [class.active]="!IsSavedAchPresent" href="javascript:void(0);" role="tab" (click)="AddNewAchAccount()"><i class="la la-puzzle-piece"></i> Add new accounts</a>
                            </li>
                        </ul> -->
                        <!-- <div class="btn-add text-right my-3">
                            <button type="button" class="btn-common" (click)="AddNewCard()">Add New Accounts</button>
                        </div>
                        <div class="btn-add text-right my-3">
                            <button type="button" class="btn-common" (click)="savedAccount()">Saved Accounts</button>
                        </div> -->
                        <div class="row mob-no-margin" *ngIf="IsSavedAchPresent">
                            <div class="col-lg-6 form-check radio-btn-wrp mob-padding" *ngFor="let option of savedCards; let i=index">
                                <div class="bx-radio-btn-each">
                                    <input formControlName="ChooseCard" type="radio" class="form-check-input" value="{{option.PayMethodToken}}" id="{{option.PayMethodToken}}" (change)="onSelectedSavedcardChange($event.target.id)">
                                    <label for="{{option.PayMethodToken}}">
                                    <span class="d-block">
                                        {{option.AccountHolder}}
                                     </span>
                                    <span>
                                        {{option.MaskedAccountNumber}}
                                    </span>
                                </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 px-0 choose-radio-wrp" *ngIf="showNewCardOption">
                            <div class="row">
                                <div class="col-md-12 col-lg-12 d-flex mob-mb acnt-radio-type">
                                    <span class="radio-label">Account Type</span>
                                    <div class="form-check radio-btn-wrp" *ngFor="let accounttype of accounttypeList;let i = index;">
                                        <input type="radio" class="form-check-input" id="{{accounttype.value}}" formControlName="AccountType" value="{{accounttype.value}}">
                                        <label class="form-check-label" for="{{accounttype.value}}">
                                        {{accounttype.name}}
                                        </label>
                                    </div>

                                </div>
                                <span class="col-sm-12 m-form__help">
                                    <span class="error-message alert danger" *ngIf="( this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.dirty || this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.touched) && this.donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountType')?.invalid">
                                        Please select your account type
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-12 px-0 mt-3" *ngIf="!IsSavedAchPresent || showNewCardOption">
                            <div class="row">
                                <div class="col-md-4 col-lg-4 pr-0 mob-pr">
                                    <div class="form-group focus-label">
                                        <label class="form-label-of" for="ach_account_number">Account Number</label>
                                        <input formControlName="AccountNumber" type="text" name="ach_account_number" id="ach_account_number" class=" required" data-rule-number="true" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" />
                                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountNumber">
                                            <span class="error-message danger alert"  *ngIf="(donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.hasError(validation.type) && (donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.dirty || donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.touched)) && donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountNumber')?.invalid">
                                               {{ validation.message }}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-4">
                                    <div class="form-group focus-label">
                                        <label class="form-label-of" for="ach_routing_number">Routing Number</label>
                                        <input formControlName="RoutingNumber" type="text" class="required" autocomplete="off" name="ach_routing_number" id="ach_routing_number" data-rule-number="true" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)">
                                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.RoutingNumber">
                                            <span class="error-message danger alert"  *ngIf="(donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.hasError(validation.type) && (donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.dirty || donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.touched)) && donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('RoutingNumber')?.invalid">
                                               {{ validation.message }}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-4 pl-0 mob-pl">
                                    <div class="form-group focus-label">
                                        <label class="form-label-of" for="accName">Name on Account</label>
                                        <input formControlName="AccountHolder" type="text" autocomplete="off" name="ach_person_name" id="ach_person_name" class="required" (focus)="animateLabel($event.target)" (focusout)="animateLabelRemove($event.target)" maxlength="100">
                                        <span class="m-form__help" *ngFor="let validation of ValidationMessage.AccountHolder">
                                                <span class="error-message danger alert"  *ngIf="(donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.hasError(validation.type) && (donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.dirty || donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.touched)) && donationForm.controls['PaymentProcessParams'].controls['PaymentFields'].get('AccountHolder')?.invalid">
                                                   {{ validation.message }}
                                                </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- <label class="row col-form-label m-checkbox--inline" *ngIf="isSavedCard">
                                  <label class="m-checkbox m-checkbox--solid m-checkbox--brand">
                                    <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" >
                                    Save Account for Future
                                    <span></span>
                                  </label>
                            </label> -->
                            <div class="row">
                                <div class="checkbox-wrp ml-3" *ngIf="isSavedCard && selectedFrequencyName == 'Onetime'">
                                    <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" id="save-id">
                                    <label for="save-id">Save Account for Future</label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="shownewCreditcardOption && selectedPaymentMethod == 'credit_or_debt'">
                        <ngx-braintree [getClientToken]="getClientToken.bind(this)" [showCardholderName]="false" [chargeAmount]="NetAmonutToPay" [createPurchase]="createPurchase.bind(this)" (paymentStatus)="onPaymentStatus($event)" (payButtonStatus)="payButtonStatus($event)"
                            *ngIf="brainTreeReInit">
                            <div class="ngxButtons">

                                <!-- <label class="row col-form-label m-checkbox--inline" *ngIf="isSavedCard">
                                  <label class="m-checkbox m-checkbox--solid m-checkbox--brand">
                                    <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" >
                                    Save Account for Future
                                    <span></span>
                                  </label>
                                </label> -->
                                <div class="checkbox-wrp mt-3" *ngIf="isSavedCard && selectedFrequencyName == 'Onetime'" formGroupName="PaymentFields">
                                    <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" id="save-idd">
                                    <label for="save-idd">Save Account for Future</label>
                                </div>
                                <button class="btn-common btn-donate" ngxPay>Donate</button>
                            </div>
                        </ngx-braintree>
                    </div>


                    <!-- <div [hidden]="selectedPaymentMethod != 'credit_or_debt'" class="credit_or_debt clearfix col-xs-12 p-0">
                        <div class="row">
                            <div class="field-container col-md-4 col-lg-4">
                                <label class="hosted-field--label" for="card-number">
                                </label>
                                <div class="hosted-field" id="card-number"></div>
                                <span class="m-form__help" *ngIf="noFieldsCardNumber == 1">
                                    <span class="error-message danger alert">
                                       Please enter a valid card number
                                    </span>
                                </span>
                            </div>
                           
                            <div class="field-container col-md-4 col-lg-4">
                                <label class="hosted-field--label" for="expiration-date">
                                </label>
                                <div class="hosted-field" id="expiration-date"></div>
                                <span class="m-form__help" *ngIf="noFieldsDate == 1">
                                    <span class="error-message danger alert">
                                        Please enter a valid card expiration date
                                    </span>
                                </span>
                                
                            </div>
                            <div class="field-container col-md-4 col-lg-4">
                                <label class="hosted-field--label" for="cvv">
                                </label>
                                <div class="hosted-field" id="cvv"></div>
                                <span class="m-form__help" *ngIf="noFieldsCvv == 1">
                                    <span class="error-message danger alert">
                                        Please enter a valid card security code (CVV)
                                    </span>
                                </span>
                            </div>
                        </div>

                        <div style="color:red;" *ngIf="brainTreeError != ''" class="alert danger">
                            <p>{{brainTreeError}}</p>
                        </div>
                        
                    </div> -->
                    <div [hidden]="selectedPaymentMethod != 'manual_payment'" class="p-0 ach col-xs-12">
                        <div class="row">

                            <div class="col-md-12 col-lg-12">
                                <span class="total-amnt-bold">BankAccount Details : </span>
                                <br>
                                <span style="white-space: pre-line;">{{text}}</span>
                            </div>
                        </div>

                    </div>

                    <div *ngIf="selectedPaymentMethod != 'credit_or_debt'" class="submit-btn clearfix" style="display:block;width:100%;text-align:center;">
                        <button type="submit" (click)="tokenizeUserDetails()" class="btn-common btn-donate">Donate  <i *ngIf="isLoading" class="spinner-border text-primary"></i></button>
                        <!-- <input type="button" value="DONATE" (click)="tokenizeUserDetails()" class="btn-common btn-donate" /> -->
                    </div>

                    <!-- <div class="footer-txt">
                        <p class="text-center short-desc-btm mt-5">Shalom World is an initiative of Shalom Media {{footerText.CountryName}} Inc, a registered {{footerText.CountryRegistrationInfo}} non-profit organization. Contributions to Shalom Media USA are tax-deductible to the extent permitted
                            by law. Shalom Media’s tax identification number is {{footerText.CountryTaxInfo}}. </p>
                    </div> -->
                </div>
            </div>
        </div>
    </form>
</div>