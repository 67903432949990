import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { CommonCrudService } from 'src/app/_services/common-crud.service';
import { ActivatedRoute } from '@angular/router';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
// import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';


declare let $: any;
class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css', './add-member.component.scss'],
  // encapsulation: ViewEncapsulation.None

})
export class AddMemberComponent implements OnInit {
  relationshipList: any;
  selectedRelationshipId: any;
  selectedSalutationId: any;
  SalutationList: any[];
  memberId: any;
  routeParams: any;
  selectedGenderId: any;
  genderList: { Id: number; Name: string; }[];
  @Input() public formData;
  familyMemberId: number;
  IsPhotoRemovedFlag: number = 0;
  imageURL: string | ArrayBuffer;
  response: { title: any; message: any; };
  ValidationMessage: any = {
    RelationshipId: [{ type: "required", message: "Relationship is required" }],
    SalutationId: [{ type: "required", message: "Salutation is required" }],
    FirstName: [{ type: "required", message: "Please enter your first name" }, { type: "hasnuber", message: "Numbers not allowed" },
    { type: "hasspecl", message: "Special charecters not allowed" },
    { type: "hasnumSpcl", message: "Numbers & special charecters not alowed" },
    { type: 'maxlength', message: 'First name can only have a maximum of 100 characters' }],

    LastName: [{ type: "required", message: "Please enter your last name" }, { type: "hasnuber", message: "Numbers not allowed" },
    { type: "hasspecl", message: "Special charecters not allowed" },
    { type: "hasnumSpcl", message: "Numbers & special charecters not alowed" },
    { type: 'maxlength', message: 'Last name can only have a maximum of 100 characters' }],
    MobileNumber: [{ type: 'required', message: 'Please enter your phone number' },
    { type: 'pattern', message: 'Please enter a valid phone number using only numbers' }],
    SecondaryNumber: [
      { type: 'pattern', message: 'Please enter a valid phone number using only numbers' }
    ],
    Gender: [{ type: "required", message: "Gender is required" }],
    Dob: [
      { type: "required", message: "Date of birth is required" },
      { type: "pattern", message: "please enter a valid date (eg:1993-06-05)" }
    ],
    Photo: [{ type: "required", message: "Photo is required" }],
    Email: [

      { type: "pattern", message: "please enter a valid email address" }
    ]
  };
  selectedFile: any;
  fileName: any;
  url: any = "http://myshalomtest.webc.in/myshalom/storage/dummy_images/dummy_man_avatar.jpg";
  // url: any;
  myDateValue: Date;
  // bsConfig: Partial<BsDatepickerConfig>;
  editFAmilymem: boolean = false;
  isLoading: boolean = false;
  emailOrMob: string;
  languages: any;
  communicationMethods = [{ Id: "1", CommunicationMethod: "Email" }, { Id: "2", CommunicationMethod: "Phone" }, { Id: "3", CommunicationMethod: "SMS" }]
  selectedComModes = [];
  selectedLanguages = [];
  contactMethod = [];
  commLanguage = [];
  PreferredLanguageOfCommunication: string;
  PreferredModeOfContact: string;
  selectedStartDate;
  photoName: any;
  isbuttondisabled: boolean = false
  constructor(public modalService: NgbModal, public activeModal: NgbActiveModal, private formBuilder: FormBuilder, public _crud: CommonCrudService, public activeRoute: ActivatedRoute
  ) { this.myDateValue = new Date(); }
  createForm: any;
  isSpfMember:boolean;
  notValidImage:boolean = false;
  singleDonationExist:boolean = false;
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'mmm-dd-yyyy',
    firstDayOfWeek: 'su'
    // other options are here...
  };
  myDateInit: boolean = true;
  model: IMyDateModel = null;
  // maxDate = new Date();
  // minDate = new Date();
  ngOnInit(): void {

    // console.log("@@@",this.myDateValue);
    // this.bsConfig = Object.assign({}, { containerClass: 'theme-green custom', showWeekNumbers: false ,dateInputFormat: 'DD MMM YYYY'});
    this.memberId = localStorage.getItem('memberId');
    this.genderList = [
      {
        Id: 1,
        Name: "Male"
      },
      {
        Id: 2,
        Name: "Female"
      }
    ]
    /*----Placeholder Up---*/
    $(".member-wrp input").on('focus', function () {
      $(this).parent(".focus-label").addClass("is-focused");
    });
    $(".member-wrp input").focusout(function () {
      if ($(this).val() == "") {
        $(this).parents(".focus-label").removeClass("is-focused");
      }
    });
    this.getAllRelationships();
    this.getSalutation();
    this.formInit();
    this.fetchLanguages();
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.singleDonationExist = currentUser.singleDonationExist;
    // if (this.myDateInit) {
    //   // Initialize to specific date (14.05.2019) with IMyDate object
    //   // this.model = {
    //   //     isRange: false, singleDate: {}
    //   // };
    // }
    // else {
    //     // Initialize to today with javascript date object
    //     this.model = { isRange: false, singleDate: { jsDate: new Date() } };
    // }
  }

  animateLabel(event) {
    event.parentNode.classList.add('is-focused');
  }

  animateLabelRemove(e) {
    // console.log(e.value, "AHGKND");
    if (e.value == '') {
      e.parentNode.classList.remove('is-focused');
    }
  }
  close() {
    this.activeModal.close({ 'result': true });
  }
  formInit() {
    this.createForm = this.formBuilder.group({
      IsCreateAccount: [0],
      RelationshipId: ["", Validators.required],
      SalutationId: [""],
      FirstName: ["", Validators.compose([Validators.required])],
      LastName: ["", Validators.compose([Validators.required])],
      // MobileNumber: ['', Validators.compose([Validators.required, Validators.pattern('(?:[0-9]●?){6,14}[0-9]')])],
      MobileNumber: ['', Validators.pattern('(?:[0-9]●?){6,14}[0-9]')],

      SecondaryNumber: ['', Validators.pattern('(?:[0-9]●?){6,14}[0-9]')],
      Gender: [""],
      Dob: [""],
      Photo: [""],
      IsPhotoRemovedFlag: [0],
      Email: [
        "",
        Validators.compose([
          Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        ])
      ],
      language: [''],
      communicationMethod: [''],
    });
    if (this.formData != null) {
      // console.log("not null formadata-----",typeof(this.formData['Dob']),new DatePipe('en-US').transform(this.formData['Dob'], 'MM/dd/yyyy'))
      this.editFAmilymem = true;
      console.log("photo url----", this.formData);
      this.url = this.formData['PhotoUrl'];
      if(this.formData['PhotoUrl'].includes('dummy')){
        this.notValidImage = true;
        this.url = 'http://myshalomtest.webc.in/myshalom/storage/dummy_images/dummy_man_avatar.jpg';
      }else{
        this.notValidImage = false;
      }
      if(this.formData['FamilyMemberImage'] != null){
        this.photoName = this.formData['FamilyMemberImage']
        document.getElementById("label-up").classList.add("label-up");
      }
      this.selectedRelationshipId = this.formData['RelationshipId'];
      this.selectedSalutationId = this.formData['SalutationId'];
      this.selectedGenderId = this.formData['Gender']
      this.createForm.controls['FirstName'].setValue(this.formData['FirstName']);
      this.createForm.controls['LastName'].setValue(this.formData['LastName']);
      if (this.formData['MobileNumber'] != null) {
        this.createForm.controls['MobileNumber'].setValue(this.formData['MobileNumber']);
        document.getElementById("pnone1").classList.add("is-focused");
      }
      this.createForm.controls['SecondaryNumber'].setValue(this.formData['SecondaryNumber'] == null ? "" : this.formData['SecondaryNumber']);
      if (this.formData['Dob'] != null) {
        // this.selectedStartDate = new DatePipe('en-US').transform(this.formData['Dob'], 'd MMM y');
        // this.createForm.controls['Dob'].setValue(new DatePipe('en-US').transform(this.formData['Dob'], 'd MMM y'))
        // console.log("dob url%%%%%%%%%%%%%%%%%%%",this.createForm.controls['Dob'],new DatePipe('en-US').transform(this.formData['Dob'], 'd MMM y'))
        let bobAry = this.formData['Dob'].split("-")
        this.model = {isRange: false, singleDate: {date: { 
          year: parseInt(bobAry[0]), 
          month: parseInt(bobAry[1]), 
          day: parseInt(bobAry[2])
        },formatted : new DatePipe('en-US').transform(this.formData['Dob'], 'MMM-dd-y')
      }};
        // console.log("date------",this.formData['Dob'],bobAry,this.model)
      }
      // this.createForm.controls['Photo'].setValue(this.formData['PhotoUrl']== null? "":this.formData['PhotoUrl']);
      this.createForm.controls['Email'].setValue(this.formData['Email'] == null ? "" : this.formData['Email']);
      this.familyMemberId = this.formData['MemberId'];
      document.getElementById("fname").classList.add("is-focused");
      document.getElementById("lname").classList.add("is-focused");
      if (this.formData['SecondaryNumber'] != null) {
        document.getElementById("phone2").classList.add("is-focused");
      }
      if (this.formData['Email'] != null) {
        document.getElementById("email").classList.add("is-focused");
      }
      if (this.formData['Dob'] != null) {
        document.getElementById("dob").classList.add("is-focused");
      }
      let a = [];
      this.formData.PreferedModeOfContact.forEach(element => {
        // console.log("element----->",element)
        if (element.CommunicationMethod == 'Email') {
          a.push("1");
        }
        if (element.CommunicationMethod == 'Call') {
          a.push("2");
        }
        if (element.CommunicationMethod == 'SMS') {
          a.push("3");
        }

      });
      this.selectedComModes = a;
      // console.log("selectedComModes----->",this.selectedComModes);
      let b = [];
      this.formData.PreferedLanguageOfCommunication.forEach(element => {
        // console.log("element----->",element)
        b.push(element.languageId);
      });
      this.selectedLanguages = b;
      console.log("element----->", this.selectedLanguages)
    }else{
      this.notValidImage = true;
    }
  }
  getAllRelationships() {
    let relationsData = {
      url: "frontend-site/family-relationship/list"
    };
    this._crud.getData(relationsData).subscribe(
      data => {
        console.log("dataaa", data['data']['FamilyRelationShips'].length);
        // this.selectedRelationshipId = data['data']['FamilyRelationShips'][0].RecordID
        this.relationshipList = [];
        for (let i = 0; i < data['data']['FamilyRelationShips'].length; i++) {
          this.relationshipList.push({ Id: data['data']['FamilyRelationShips'][i].RecordID, Name: data['data']['FamilyRelationShips'][i].RelationshipName });
        }
        // this.relationshipList.sort(this._crud.dynamicSort("RelationshipName"));
        // setTimeout(() => {
        //     $(".m-select-salutation").selectpicker("refresh");
        // }, 0);
      },
      error => {
        console.log("Some error tiggered" + error);
      }
    );
  }
  getSalutation() {
    let params = {}
    params["url"] = "frontend-site/donation/fetch-salutation";
    this._crud.getData(params).subscribe(data => {
      // console.log("getSalutation",data);
      // this.selectedSalutationId = data['data'][0].SalutationId
      this.SalutationList = [];
      for (let i = 0; i < data['data'].length; i++) {
        this.SalutationList.push({ Id: data['data'][i].SalutationId, Name: data['data'][i].SalutationName });
      }
    })
  }
  addMember1() {
    console.log(this.createForm.value);
  }
  addMember() {
    this.isbuttondisabled = true
    let Step2Fields = [
      this.createForm.controls['RelationshipId'],
      this.createForm.controls['FirstName'],
      this.createForm.controls['LastName'],
      this.createForm.controls['MobileNumber'],
      this.createForm.controls['SecondaryNumber'],
      this.createForm.controls['Email']
    ]
    const invalid = []
    const controls = Step2Fields;
    let i: any;
    for (i in controls) {
      controls[i].markAsDirty();
      if (controls[i].invalid) {
        invalid.push(i);
      }
    }
    console.log("invalid---", invalid);
    if (invalid[0] == undefined && (this.createForm.controls['Email'].value != "" || this.createForm.controls['MobileNumber'].value != "")) {
      this.emailOrMob = ""
      // seting Dob value

      // if(this.createForm.controls['Dob'].value){
      //   const dob = new DatePipe('en-US').transform(this.createForm.controls['Dob'].value, 'MMM-dd-yyyy')
      //   // console.log("new date-------",dob);
      //   this.createForm.controls['Dob'].setValue(dob);
      // }

      // if( this.createForm.controls['Gender'].value == null){
      //   this.createForm.controls['Gender'].setValue("");
      // }
      // if( this.createForm.controls['SalutationId'].value == null){
      //   this.createForm.controls['SalutationId'].setValue("");
      // }
      this.createForm.get('IsPhotoRemovedFlag').setValue(this.IsPhotoRemovedFlag);
      this.isLoading = true;
      if (this.commLanguage.length !== 0) {
        let lan = [];
        this.commLanguage.forEach(element => {
          lan.push({ "Id": element.Id })
        });
        this.PreferredLanguageOfCommunication = JSON.stringify(lan);
      } else {
        this.selectedLanguages.forEach(element => {
          this.commLanguage.push({ "Id": element })
        });
        this.PreferredLanguageOfCommunication = JSON.stringify(this.commLanguage);
      }
      if (this.contactMethod.length !== 0) {
        let contact = [];
        this.contactMethod.forEach(element => {
          contact.push({ "Id": element.Id })
        });
        this.PreferredModeOfContact = JSON.stringify(contact);
      } else {
        this.selectedComModes.forEach(element => {
          this.contactMethod.push({ "Id": element })
        });
        this.PreferredModeOfContact = JSON.stringify(this.contactMethod);
      }
      let paramComm = {};
      paramComm['PreferredLanguageOfCommunication'] = this.PreferredLanguageOfCommunication
      paramComm['PreferredModeOfContact'] = this.PreferredModeOfContact
      if (this.formData == null) {
        // let params = {}
        // params["url"] = "family-member/{primary_member_id}/add";
        this._crud.familyMemberCreate(this.createForm.value, this.memberId, paramComm).subscribe(data => {
          this.isLoading = false;
          // console.log("getSalutation",data);
          // if(data['status']== "success"){
          // this.close();
          this.successPopup(data);
          // }

        })
      } else {
        this._crud.familyMemberUpdate(
          this.createForm.value,
          this.memberId,
          this.familyMemberId, paramComm
        ).subscribe(data => {
          this.isLoading = false;
          this.isbuttondisabled = false
          // console.log("getSalutation",data);
          // if(data['status']== "success"){
          // this.close();
          this.successPopup(data);

          // }
        })
      }
    } else {
      this.isbuttondisabled = false
      console.log("sss", this.createForm.controls['Email'], this.createForm.controls['MobileNumber'].value)
      if (this.createForm.controls['Email'].value == "" && this.createForm.controls['MobileNumber'].value == "") {
        // console.log("e or p")
        this.emailOrMob = "Email or Phone number is required"
      } else {
        this.emailOrMob = ""

      }
      // console.log("sss")
    }
  }
  // fileChange(event) {
  //   // console.log(event);
  //   if (event.target.files.length > 0) {
  //     let file = event.target.files[0];
  //     this.notValidImage = false;
  //     this.createForm.get("Photo").setValue(file);
  //   }
  //   var reader = new FileReader();
  //   reader.onload = (event: ProgressEvent) => {
  //     this.url = (<FileReader>event.target).result;
  //     this.IsPhotoRemovedFlag = 0;
  //     // console.log(this.url)
  //   };
  //   reader.readAsDataURL(event.target.files[0]);
  // }
  fileChange(imageInput: any){
    console.log(imageInput.files[0]);
    const file: File = imageInput.files[0];
    this.photoName = file.name
    document.getElementById("label-up").classList.add("label-up");
    this.createForm.get("Photo").setValue(file);
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.url = (<FileReader>event.target).result;
      this.selectedFile = new ImageSnippet(event.target.result, file);
    //   console.log(this.selectedFile.file);
      this.fileName = this.selectedFile.file.name;
    });
  
    reader.readAsDataURL(file);
  
  }
  // fileChange(imageInput: any){
  //   console.log(imageInput);
  //   const file: File = imageInput.files[0];
  //   const reader = new FileReader();
  //   reader.addEventListener('load', (event: any) => {
  //     this.imageURL = (<FileReader>event.target).result;
  //     this.selectedFile = new ImageSnippet(event.target.result, file);
  //     console.log(this.selectedFile.file);
  //     this.fileName = this.selectedFile.file.name;
  //   });

  //   reader.readAsDataURL(file);

  // }
  successPopup(data) {
    if(data['status']=='error'){
      this.response = { title: 'Error', message: data['msg'] }
    }else{
      this.response = { title: data['status'], message: data['msg'] }
    }
    //   this.modalService.show(SuccessPopupComponent, {initialState})
    const modalRef = this.modalService.open(SuccessPopupComponent, {
      size: "md",
    });
    modalRef.componentInstance.user = this.response;
    modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
        if (result.result && data['status'] == "success") {
          // /clearing all forms.
          this.activeModal.close({ 'result': true });
        }
      });
  }
  onDateChange(newDate: Date) {
    console.log("new date---", newDate);
    if (newDate != null) {
      // console.log("here");
      document.getElementById("dob").classList.add("is-focused");

    }
    $("#txtdate").keypress(function (e) {
      return false;
    });
    $("#txtdate").keydown(function (e) {
      console.log(e.keyCode);
      // if (e.keyCode == 8 || e.keyCode == 46) {
      //   return true
      // } else {
      //   return false;
      // }
      return false;

    });
    $( "#txtdate" ).datepicker();
  }
  firstNameFetch(fname) {
    var regex1 = /^[a-zA-Z-.,' ]{1,100}$/
    var isValid1 = regex1.test(fname);
    if (!isValid1) {
      console.log("not valid fnmae---");
      var regex2 = /^(?=.*[0-9]).*$/
      var isValid2 = regex2.test(fname);
      // var regex3 = /^(?=.*[!@#%()<>{}]).*$/
      var regex3 = /^(?=.*[!~^&*_=+@#%()<>\[\]:;"?\/\|{}]).*$/
      var isValid3 = regex3.test(fname);

      if (isValid2 && isValid3) {
        console.log("contain nubers and spcl");
        this.createForm.controls['FirstName'].setErrors({ 'hasnumSpcl': true });
      } else if (isValid2) {
        console.log("contain nubers");
        this.createForm.controls['FirstName'].setErrors({ 'hasnuber': true });
      }
      else if (isValid3) {
        console.log("contain spcl");
        this.createForm.controls['FirstName'].setErrors({ 'hasspecl': true })
      } else if (fname.length > 100) {
        console.log("limit reached");
        this.createForm.controls['FirstName'].setErrors({ 'maxlength': true })
      }
    } else {
      console.log("valid name");
    }

  }
  lastNameFetch(lname) {
    var regex1 = /^[a-zA-Z-.,' ]{1,100}$/
    var isValid1 = regex1.test(lname);
    if (!isValid1) {
      console.log("not valid fnmae---");
      var regex2 = /^(?=.*[0-9]).*$/
      var isValid2 = regex2.test(lname);
      var regex3 = /^(?=.*[!~^&*_=+@#%()<>\[\]:;"?\/\|{}]).*$/
      var isValid3 = regex3.test(lname);
      if (isValid2 && isValid3) {
        console.log("contain nubers and spcl");
        this.createForm.controls['LastName'].setErrors({ 'hasnumSpcl': true });
      } else if (isValid2) {
        console.log("contain nubers");
        this.createForm.controls['LastName'].setErrors({ 'hasnuber': true });
      }
      else if (isValid3) {
        console.log("contain spcl");
        this.createForm.controls['LastName'].setErrors({ 'hasspecl': true })
      } else if (lname.length > 100) {
        console.log("limit reached");
        this.createForm.controls['LastName'].setErrors({ 'maxlength': true })
      }
    } else {
      console.log("valid name");
    }
  }
  fetchLanguages() {
    let params = {
      url: "frontend-site/language-list"
    }
    this._crud.getData(params).subscribe(res => {
      this.languages = res['data'];
    });
  }
  selectedContactMode(value) {
    this.contactMethod = value
    // console.log("contactMethod=----",this.contactMethod)
  }
  selectLanguage(value) {
    // console.log("value=----",value)
    this.commLanguage = value;
    //  console.log("commLanguage=----",this.commLanguage)
  }
  removePhoto() {
    this.createForm.get("Photo").setValue("");
    this.IsPhotoRemovedFlag = 1;
    this.notValidImage = true;
    this.url = 'http://myshalomtest.webc.in/myshalom/storage/dummy_images/dummy_man_avatar.jpg';
  }
  changeDobEvent(value){
    // console.log("dob----",value);
    this.selectedStartDate = value.singleDate.formatted
    this.createForm.controls['Dob'].setValue(value.singleDate.formatted);
  }
}
