import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree ,Router} from '@angular/router';
import { Observable } from 'rxjs';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { AuthService } from '../services/auth.service';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public _crud: CommonCrudService,
    public router: Router,
    public authService : AuthService
  ){ }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._crud.verify()
    .pipe(map(
            data => {
              console.log("veri data------",data)
                if (data !== null && data['status'] != "error") {
                    // logged in so return true
                    console.log("veri ucces case------")
                    return true;
                }
                // error when verify so redirect to login page with the return url
                console.log("veri fail case------")
                this.router.navigate(['/member-login'], { queryParams: { returnUrl: state.url } });
                return false;
            },
            error => {
              console.log("veri error case------")
                // error when verify so redirect to login page with the return url
                this.router.navigate(['/member-login'], { queryParams: { returnUrl: state.url } });
                return false;
            }))
            
  }
  
}
