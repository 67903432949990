import { Component, OnInit } from '@angular/core';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { FormBuilder,Validators} from '@angular/forms';
import { Router ,ActivatedRoute} from '@angular/router';
import { CustomValidator } from '../../../../_services/custom-validation/CustomValidator.validator';
import { SuccessPopupComponent } from '../../main/success-popup/success-popup.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare let $: any;

@Component({
  selector: 'app-member-resetpassword',
  templateUrl: './member-resetpassword.component.html',
  styleUrls: ['./member-resetpassword.component.scss']
})
export class MemberResetpasswordComponent implements OnInit {

  resetPasswordForm;
  password;
  tokenPasswordReset;
  tokenErrorMsg;
  postDataJsonPrepare;
  isloading : boolean = false;
  ValidationMessage: any = {
    newPassword: [{ type: "required", message: "New Password is required" },
                {type: "pattern",message: "Numbers,space and special characters not allowed"},
                { type: "minlength", message: "Please enter valid password " }
        
        ],
        confirmPassword: [{ type: "required", message: "Confirm Password is required" },
                {type: "pattern",message: "Numbers,space and special characters not allowed"},
                { type: "minlength", message: "Please enter valid confirm password " }
        ]
  };

  constructor(public formBuilder: FormBuilder,public _crud:CommonCrudService,
    public router:Router,public _route:ActivatedRoute,public modalService : NgbModal) 
    {
      this.formInit();
    }

  ngOnInit(): void {
    this.tokenPasswordReset = this._route.snapshot.queryParams['token'] || '';
    // console.log(this.tokenPasswordReset, 'dddsf');

    this.verifyTokern();
  }
  formInit(){
    this.resetPasswordForm = this.formBuilder.group({
      newPassword : ['',Validators.compose([Validators.required,Validators.minLength(6),Validators.pattern("^[a-zA-Z-']+")])],
      confirmPassword : ['',Validators.compose([Validators.required,Validators.minLength(6),Validators.pattern("^[a-zA-Z-']+")])]
    });

  }
  animateLabel(event) {
    event.parentNode.classList.add('is-focused');
  }

  animateLabelRemove(e) {
    // console.log(e.value, "AHGKND");
    if (e.value == '') {
        e.parentNode.classList.remove('is-focused');
    }
  }
  checkPassword(cpassword = null){
    // console.log("inside confirm pasword-----",cpassword);
    // CustomValidator.passwordsMatch(this.password,cpassword);
    if(cpassword){
        this.resetPasswordForm.get('confirmPassword').setValidators(Validators.compose([Validators.required, CustomValidator.passwordsMatch(this.password,cpassword).bind(this)]));
        this.resetPasswordForm.get('confirmPassword').updateValueAndValidity();
        this.ValidationMessage.confirmPassword = [{ type: "passwordsMatch", message: "password not match" }];
    }
    
  }
  passwordFetch(pass){
    this.password = pass;
    this.checkPassword(this.resetPasswordForm.controls['confirmPassword'].value);
    // console.log("new pass-----",this.password);
  }
  Submit(){
    this.isloading = true;
    let param = {};
      param['params'] = {};
      param['url'] = "frontend-site/member/reset-password";
      param['params'] = this.RequestPostParams();
      this._crud.postData(param).subscribe(res => { 
        // console.log("res---",res);
        this.successPopup(res);
        
      })

  }
  verifyTokern(){
    let params = {
      url: "frontend-site/member/verify-reset-password-token", 
      params: {Token : this.tokenPasswordReset}
    }
    this._crud.getData(params).subscribe(res =>{
      if(res['status'] = "error"){
        this.tokenErrorMsg = res['msg'];
      }
    })
  }
  RequestPostParams(){
    this.postDataJsonPrepare = {};
    this.postDataJsonPrepare.Password = this.resetPasswordForm.controls['newPassword'].value;
    this.postDataJsonPrepare.ConfirmPassword = this.resetPasswordForm.controls['confirmPassword'].value;
    this.postDataJsonPrepare.Token = this.tokenPasswordReset;
    return this.postDataJsonPrepare;
  }
  successPopup(data) {
    let response = {title: data['status'],message: data['msg']}
    const modalRef = this.modalService.open(SuccessPopupComponent, {size: "md"});
    modalRef.componentInstance.user = response;
    modalRef.result.then(
      result => {
        this.isloading = false;
        // console.log("result-----modal",result)
          if (result.result && data['status']=='success') {
              this.router.navigateByUrl('/member-login');
              modalRef.close({ 'result': true });
          }
      });
  }

}
