import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditContactInfoComponent } from './edit-contact-info.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';


@NgModule({
  declarations: [EditContactInfoComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule  ,
    // BsDatepickerModule.forRoot(),
    // DatepickerModule.forRoot()
    AngularMyDatePickerModule
  ]
})
export class EditContactInfoModule { }
