<div class="alert-popup"   [ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <h5 *ngIf="verificationLink" class="mb-3">Login Unsuccessful</h5>
    <h5 *ngIf="!verificationLink" class="mb-3">{{user.title}}</h5>
    <div>{{user.message}}</div>
    <div class="modal-btn-holder  d-flex mt-4">
        <button *ngIf="verificationLink" type="submit" class="btn-common btn-no" (click)="sendVerifivationEmail()">Resend Email <i *ngIf="isLoading" class="spinner-border"></i></button>
        <button *ngIf="!verificationLink"type="submit" class="btn-common btn-yes ml-3" (click)="close()">OK</button>
        <button *ngIf="verificationLink" style="max-width: 160px;" type="submit" class="btn-common btn-yes ml-3" (click)="close()">OK</button>
    </div>
    <div *ngIf="resendEmailResponse">
        <br>
        <span>
            {{notificationMsg}}
        </span>
        </div>
</div>
