<div class="contact-alert">
    <div class="popup-inner">
        <div class="acnt-detail-wrp" *ngIf="paymentMethodType == 'credit_or_debt'">
            <ngx-braintree [getClientToken]="getClientToken.bind(this)" [showCardholderName]="false" [chargeAmount]="NetAmonutToPay" [createPurchase]="createPurchase.bind(this)">
                <div class="ngxButtons">
                    <div class="checkbox-wrp ml-3" *ngIf="isSavedCard">
                        <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" id="save-id">
                        <label for="save-id">Save Account for Future</label>
                    </div>
                    <button *ngIf="type == 'donation'" ngxPay class="btn-common mt-3">Donate</button>
                    <button *ngIf="type == 'subscription'" ngxPay class="btn-common mt-3">Subscribe</button>
                    <button *ngIf="type == 'event'" ngxPay class="btn-common mt-3">Register</button>
                </div>
            </ngx-braintree>
        </div>
        <!-- <div class="acnt-detail-wrp" *ngIf="paymentMethodType == 'credit_or_debt' && type == 'subscription'">
            <ngx-braintree [getClientToken]="getClientToken.bind(this)" [showCardholderName]="false" [chargeAmount]="NetAmonutToPay" [createPurchase]="createPurchaseSubscription.bind(this)">
                <div class="ngxButtons">
                    <div class="checkbox-wrp ml-3" *ngIf="isSavedCard">
                        <input formControlName="SaveCardForFuture" name="SaveCardForFuture" type="checkbox" id="save-id">
                        <label for="save-id">Save Account for Future</label>
                    </div>
                    <button ngxPay class="btn-common mt-3">Donate</button>
                </div>
            </ngx-braintree>
        </div> -->
    </div>
</div>