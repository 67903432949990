import { Component, OnInit } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router'

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(private userIdle: UserIdleService,public router:Router) { }

  ngOnInit(): void {
    // let idleCheck = localStorage.getItem('idleTimeCheck');
    // if(idleCheck == "1"){
    //   console.log("libry can check for user inactivtiy");
    //    //Start watching for user inactivity.
    this.userIdle.resetTimer();
      
    //   // Start watching when user idle is starting.
    //   this.userIdle.onTimerStart().subscribe(count => console.log("count------",count));
      
    //   // Start watch when time is up.
    //   this.userIdle.onTimeout().subscribe(() => {
    //     console.log("user idle for 10 mints---do login");
    //     // window.alert('do login agin');
    //     this.router.navigate(['/memberlogin']);
    //   });
    // }else{
    //   console.log("libry cant check for user inacty on vido play");
    // }
   
  }
  stop() {
    this.userIdle.stopTimer();
  }
 
  stopWatching() {
    this.userIdle.stopWatching();
  }
 
  startWatching() {
    this.userIdle.startWatching();
  }
 
  restart() {
    this.userIdle.resetTimer();
  }

}
