import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddAddressComponent } from './add-address.component';

import {  ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [AddAddressComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule, FormsModule,
    NgSelectModule
  ]
})
export class AddAddressModule { }
