import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { CustomValidator } from '../../../../_services/custom-validation/CustomValidator.validator';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
// import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

declare let $: any;
@Component({
  selector: 'app-edit-contact-info',
  templateUrl: './edit-contact-info.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css', './edit-contact-info.component.scss']
})
export class EditContactInfoComponent implements OnInit {

  editContactForm;
  languages;
  profileData;
  prefredLan;
  lanList;
  selectedComModes = [];
  selectedLanguages = [];
  contactMethod = [];
  commLanguage = [];
  postDataJsonPrepare;
  response;
  IsPhotoRemovedFlag: number = 0;
  communicationMethods = [{ Id: "1", CommunicationMethod: "Email" }, { Id: "2", CommunicationMethod: "Phone" }, { Id: "3", CommunicationMethod: "SMS" }]
  @Input() memberId;
  isLoading: boolean = false;
  // bsConfig: Partial<BsDatepickerConfig>;
  isSpfMember:boolean;
  singleDonationExist:boolean = false;
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'mmm-dd-yyyy',
    firstDayOfWeek: 'su'
    // other options are here...
  };
  myDateInit: boolean = true;
  model: IMyDateModel = null;
  ValidationMessage: any = {
    firstName: [
      { type: "required", message: "Please enter your first name" },
      { type: 'maxlength', message: 'First name can only have a maximum of 100 characters' },
      { type: "hasnuber", message: "Numbers not allowed" },
      { type: "hasspecl", message: "Special charecters not allowed" },
      { type: "hasnumSpcl", message: "Numbers & special charecters not alowed" }

    ],
    lastName: [
      { type: "required", message: "Please enter your last name" },
      { type: 'maxlength', message: 'Last name can only have a maximum of 100 characters' },
      { type: "hasnuber", message: "Numbers not allowed" },
      { type: "hasspecl", message: "Special charecters not allowed" },
      { type: "hasnumSpcl", message: "Numbers & special charecters not alowed" }
    ],


    primaryNumber: [{ type: 'required', message: 'Please enter your phone number' },
    { type: 'pattern', message: 'Please enter a valid phone number using only numbers' }],
    secondaryNumber: [
      { type: 'pattern', message: 'Please enter a valid phone number using only numbers' }
    ],
    language: [
      { type: "required", message: "language is required" }
    ],
    communicationMethod: [
      { type: "required", message: "communiaction method is required" }
    ]

  };
  url: string | ArrayBuffer;
  PreferredLanguageOfCommunication: string;
  PreferredModeOfContact: string;
  genderList: { Id: number; Name: string; }[];
  SalutationList: any[];
  selectedSalutationId: any;
  selectedGenderId: any;
  myDateValue: Date;
  selectedStartDate: any;
  notValidImage:boolean = false;
  imageUrl: string | ArrayBuffer;
  selectedFile: any;
  fileName: any;
  photoName: any;
  isSavebutton: boolean = false
  constructor(public formBuilder: FormBuilder, public _crud: CommonCrudService, public activeModal: NgbActiveModal, public modalService: NgbModal) {
    this.myDateValue = new Date('d MMM y');
    this.formInit();
  }

  ngOnInit(): void {
    // this.bsConfig = Object.assign({}, { containerClass: 'theme-green custom', showWeekNumbers: false,dateInputFormat: 'DD MMM YYYY' });
    this.memberId = localStorage.getItem('memberId');
    this.genderList = [
      {
        Id: 1,
        Name: "Male"
      },
      {
        Id: 2,
        Name: "Female"
      }
    ]
    this.getSalutation();

    this.fetchMemberData();
    this.fetchLanguages();
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.singleDonationExist = currentUser.singleDonationExist;
  }
  animateLabel(event) {
    event.parentNode.classList.add('is-focused');
  }

  animateLabelRemove(e) {
    // console.log(e.value, "AHGKND");
    if (e.value == '') {
      e.parentNode.classList.remove('is-focused');
    }
  }
  formInit() {
    this.editContactForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      primaryNumber: ['', Validators.compose([Validators.required, Validators.pattern('(?:[0-9]●?){6,14}[0-9]')])],
      secondaryNumber: ['', Validators.pattern('(?:[0-9]●?){6,14}[0-9]')],
      email: [''],
      language: [''],
      communicationMethod: [''],
      Photo: [""],
      IsPhotoRemovedFlag: [0],
      Gender: [""],
      Dob: [""],
      SalutationId: [""],

    });
  }
  selectLanguage(value) {
    // console.log("value=----",value)
    this.commLanguage = value;
    //  console.log("commLanguage=----",this.commLanguage)
  }
  fetchMemberData() {
    let params = {
      url: "frontend-site/view-member", params: { MemberId: this.memberId }
    }
    this._crud.getData(params).subscribe(res => {
      this.profileData = res['data']['MemberProfile'];
      console.log("photo url----", this.profileData['Photo']);
      this.editContactForm.controls.firstName.setValue(this.profileData.FirstName);
      this.editContactForm.controls.lastName.setValue(this.profileData.LastName);
      this.editContactForm.controls.primaryNumber.setValue(this.profileData.MobileNumber);
      this.editContactForm.controls.secondaryNumber.setValue(this.profileData.SecondaryNumber ? this.profileData.SecondaryNumber : '');
      this.editContactForm.controls.email.setValue(this.profileData.Email?this.profileData.Email: '');
      let a = [];
      this.url = this.profileData.PhotoUrl
      if(this.profileData.PhotoUrl.includes('dummy')){
        this.notValidImage = true;
      }else{
        this.notValidImage = false;
      }
      if(this.profileData['Photo'] != null){
        this.photoName = this.profileData['Photo']
        document.getElementById("label-up").classList.add("label-up");
      }
      this.selectedGenderId = this.profileData['GenderNo']
      // this.editContactForm.controls['Dob'].setValue(this.profileData['Dob']== null? "":new DatePipe('en-US').transform(this.profileData['Dob'], 'MM/dd/yyyy')); 
      if (this.profileData['Dob'] != null) {
        // console.log("date transform----",new DatePipe('en-US').transform(this.profileData['Dob'], 'd MMM y'))
        // this.editContactForm.controls['Dob'].setValue(new DatePipe('en-US').transform(this.profileData['Dob'], 'd MMM y'))
        let bobAry = this.profileData['Dob'].split("-")
        this.model = {isRange: false, singleDate: {date: { 
          year: parseInt(bobAry[0]), 
          month: parseInt(bobAry[1]), 
          day: parseInt(bobAry[2])
        },
        formatted : new DatePipe('en-US').transform(this.profileData['Dob'], 'MMM-dd-y')
      }};
      }
      if (this.profileData['Dob'] != null) {
        document.getElementById("dob").classList.add("is-focused");
      }
      this.selectedSalutationId = this.profileData['SalutationId'];

      this.profileData.PreferredModeOfContact.forEach(element => {
        // console.log("element----->",element)
        if (element.CommunicationMethod == 'Email') {
          a.push("1");
        }
        if (element.CommunicationMethod == 'Call') {
          a.push("2");
        }
        if (element.CommunicationMethod == 'SMS') {
          a.push("3");
        }

      });
      this.selectedComModes = a;
      // console.log("selectedComModes----->",this.selectedComModes);
      let b = [];
      this.profileData.PreferredLanguageOfCommunication.forEach(element => {
        // console.log("element----->",element)
        b.push(element.Id);
      });
      this.selectedLanguages = b;
      document.getElementById("fname").classList.add("is-focused");
      document.getElementById("lname").classList.add("is-focused");
      document.getElementById("email").classList.add("is-focused");
      document.getElementById("mob").classList.add("is-focused");
      if (this.profileData.SecondaryNumber) {
        document.getElementById("secNum").classList.add("is-focused");
      }



    });
  }
  fetchLanguages() {
    let params = {
      url: "frontend-site/language-list"
    }
    this._crud.getData(params).subscribe(res => {
      this.languages = res['data'];
    });
  }
  selectedContactMode(value) {
    this.contactMethod = value
    // console.log("contactMethod=----",this.contactMethod)
  }
  save() {
    this.isSavebutton = true;
    let Step2Fields = [
      this.editContactForm.controls['firstName'],
      this.editContactForm.controls['lastName'],
      this.editContactForm.controls['primaryNumber'],
      this.editContactForm.controls['secondaryNumber'],
      this.editContactForm.controls['email'],
      this.editContactForm.controls['language'],
      this.editContactForm.controls['communicationMethod']
    ]
    const invalid = []
    const controls = Step2Fields;
    let i: any;
    for (i in controls) {
      controls[i].markAsDirty();
      if (controls[i].invalid) {
        invalid.push(i);
      }
    }
    if (invalid[0] == undefined) {
      // if(this.editContactForm.valid){
      this.isLoading = true;
      // console.log("inside submitttt")
      console.log("new date-------", this.editContactForm.value);
      this.editContactForm.controls['IsPhotoRemovedFlag'].setValue(this.IsPhotoRemovedFlag)
      // if(this.editContactForm.controls['Dob'].value){
      //   const dob = new DatePipe('en-US').transform(this.editContactForm.controls['Dob'].value, 'MMM-dd-yyyy')
      //   // console.log("new date-------",dob);
      //   this.editContactForm.controls['Dob'].setValue(dob);
      // }
      let param = {};
      param['params'] = {};
      param['url'] = "frontend-site/update-contact-info/" + this.memberId;
      // param['params'] = this.RequestPostParams();
      param['params'] = this.editContactForm.value
      if (this.commLanguage.length !== 0) {
        let lan = [];
        this.commLanguage.forEach(element => {
          lan.push({ "Id": element.Id })
        });
        this.PreferredLanguageOfCommunication = JSON.stringify(lan);
      } else {
        this.selectedLanguages.forEach(element => {
          this.commLanguage.push({ "Id": element })
        });
        this.PreferredLanguageOfCommunication = JSON.stringify(this.commLanguage);
      }
      if (this.contactMethod.length !== 0) {
        let contact = [];
        this.contactMethod.forEach(element => {
          contact.push({ "Id": element.Id })
        });
        this.PreferredModeOfContact = JSON.stringify(contact);
      } else {
        this.selectedComModes.forEach(element => {
          this.contactMethod.push({ "Id": element })
        });
        this.PreferredModeOfContact = JSON.stringify(this.contactMethod);
      }
      param['PreferredLanguageOfCommunication'] = this.PreferredLanguageOfCommunication
      param['PreferredModeOfContact'] = this.PreferredModeOfContact
      console.log("res---", this.editContactForm.value);
      // this._crud.postData(param).subscribe(res => { 
      //   this.isLoading = false;
      //   // console.log("res---",res);
      //   this.successPopup(res);
      // });
      this._crud.postContactInfo(param).subscribe(res => {
        this.isLoading = false;
        // console.log("res---",res);
        this.isSavebutton = false
        this.successPopup(res);
      });
    }else{
      this.isSavebutton = false
    }
  }

  RequestPostParams() {
    this.postDataJsonPrepare = {};
    this.postDataJsonPrepare['FirstName'] = this.editContactForm.controls['firstName'].value;
    this.postDataJsonPrepare['LastName'] = this.editContactForm.controls['lastName'].value;
    this.postDataJsonPrepare['MobileNumber'] = this.editContactForm.controls['primaryNumber'].value;
    this.postDataJsonPrepare['Email'] = this.editContactForm.controls['email'].value;
    this.postDataJsonPrepare['photo'] = this.editContactForm.controls['Photo'].value;

    // communication language case
    if (this.commLanguage.length !== 0) {
      // /console.log("value  chnages");
      let lan = [];
      this.commLanguage.forEach(element => {
        lan.push({ "Id": element.Id })
      });
      this.postDataJsonPrepare['PreferredLanguageOfCommunication'] = JSON.stringify(lan);
    } else {
      // console.log("value not chnages")
      this.selectedLanguages.forEach(element => {
        this.commLanguage.push({ "Id": element })
      });
      this.postDataJsonPrepare['PreferredLanguageOfCommunication'] = JSON.stringify(this.commLanguage);
    }
    // contact mode case
    if (this.contactMethod.length !== 0) {
      // console.log("value  chnages");
      let contact = [];
      this.contactMethod.forEach(element => {
        contact.push({ "Id": element.Id })
      });
      this.postDataJsonPrepare['PreferredModeOfContact'] = JSON.stringify(contact);
    } else {
      // console.log("value not chnages")
      this.selectedComModes.forEach(element => {
        this.contactMethod.push({ "Id": element })
      });
      this.postDataJsonPrepare['PreferredModeOfContact'] = JSON.stringify(this.contactMethod);
    }
    this.postDataJsonPrepare['SecondaryNumber'] = this.editContactForm.controls['secondaryNumber'].value;
    return this.postDataJsonPrepare;
  }
  successPopup(data) {
    if(data['status']=='error'){
      this.response = { title: 'Error', message: data['msg'] }
    }else{
      this.response = { title: data['status'], message: data['msg'] }
    }
    const modalRef = this.modalService.open(SuccessPopupComponent, { size: "md" });
    modalRef.componentInstance.user = this.response;
    modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
        if (result.result && data['status'] == "success") {
          // /clearing all forms.
          this.activeModal.close({ 'result': true });
          // let topbarCall = new TopBarMemberComponent()
          // this.topbar.topBarData(this.memberId);
        }
      });
  }
  // fileChange(event) {
  //   console.log(event.target.files[0]);
  //   if (event.target.files.length > 0) {
  //     let file = event.target.files[0];
  //     this.notValidImage = false;
  //     this.editContactForm.get("Photo").setValue(file);
  //   }
  //   var reader = new FileReader();
  //   reader.onload = (event: ProgressEvent) => {
  //     this.url = (<FileReader>event.target).result;
  //     this.IsPhotoRemovedFlag = 0;
  //     // console.log(this.url)
  //   };
  //   reader.readAsDataURL(event.target.files[0]);
  // }
  fileChange(imageInput: any){
    console.log(imageInput.files[0]);
    const file: File = imageInput.files[0];
    this.photoName = file.name
    document.getElementById("label-up").classList.add("label-up");
    this.editContactForm.get("Photo").setValue(file);
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.url = (<FileReader>event.target).result;
      this.selectedFile = new ImageSnippet(event.target.result, file);
    //   console.log(this.selectedFile.file);
      this.fileName = this.selectedFile.file.name;
    });
  
    reader.readAsDataURL(file);
  
  }
  firstNameFetch(fname) {
    var regex1 = /^[a-zA-Z-.,' ]{1,100}$/
    var isValid1 = regex1.test(fname);
    if (!isValid1) {
      console.log("not valid fnmae---");
      var regex2 = /^(?=.*[0-9]).*$/
      var isValid2 = regex2.test(fname);
      // var regex3 = /^(?=.*[!@#%()<>{}]).*$/
      var regex3 = /^(?=.*[!~^&*_=+@#%()<>\[\]:;"?\/\|{}]).*$/
      var isValid3 = regex3.test(fname);
      if (isValid2 && isValid3) {
        console.log("contain nubers and spcl");
        this.editContactForm.controls['firstName'].setErrors({ 'hasnumSpcl': true });
      } else if (isValid2) {
        console.log("contain nubers");
        this.editContactForm.controls['firstName'].setErrors({ 'hasnuber': true });
      }
      else if (isValid3) {
        console.log("contain spcl");
        this.editContactForm.controls['firstName'].setErrors({ 'hasspecl': true })
      } else if (fname.length > 100) {
        console.log("limit reached");
        this.editContactForm.controls['firstName'].setErrors({ 'maxlength': true })
      }
    } else {
      console.log("valid name");
    }

  }
  lastNameFetch(lname) {
    var regex1 = /^[a-zA-Z-.,' ]{1,100}$/
    var isValid1 = regex1.test(lname);
    if (!isValid1) {
      console.log("not valid fnmae---");
      var regex2 = /^(?=.*[0-9]).*$/
      var isValid2 = regex2.test(lname);
      var regex3 = /^(?=.*[!~^&*_=+@#%()<>\[\]:;"?\/\|{}]).*$/
      var isValid3 = regex3.test(lname);
      if (isValid2 && isValid3) {
        console.log("contain nubers and spcl");
        this.editContactForm.controls['lastName'].setErrors({ 'hasnumSpcl': true });
      } else if (isValid2) {
        console.log("contain nubers");
        this.editContactForm.controls['lastName'].setErrors({ 'hasnuber': true });
      }
      else if (isValid3) {
        console.log("contain spcl");
        this.editContactForm.controls['lastName'].setErrors({ 'hasspecl': true })
      } else if (lname.length > 100) {
        console.log("limit reached");
        this.editContactForm.controls['lastName'].setErrors({ 'maxlength': true })
      }
    } else {
      console.log("valid name");
    }
  }
  getSalutation() {
    let params = {}
    params["url"] = "frontend-site/donation/fetch-salutation";
    this._crud.getData(params).subscribe(data => {
      // console.log("getSalutation",data);
      // this.selectedSalutationId = data['data'][0].SalutationId
      this.SalutationList = [];
      for (let i = 0; i < data['data'].length; i++) {
        this.SalutationList.push({ Id: data['data'][i].SalutationId, Name: data['data'][i].SalutationName });
      }
    })
  }
  onDateChange(newDate: Date) {
    console.log("new date---", newDate);
    if (newDate != null) {
      // console.log("here");
      document.getElementById("dob").classList.add("is-focused");
      $("#txtdate").keypress(function (e) {
        return false;
      });
      $("#txtdate").keydown(function (e) {
        // if (e.keyCode == 8 || e.keyCode == 46) {
        //   return true
        // } else {
        //   return false;
        // }
        return false;

      });
    }

  }
  changeDobEvent(value){
    console.log("dob----",value);
    this.selectedStartDate = value.singleDate.formatted
    this.editContactForm.controls['Dob'].setValue(value.singleDate.formatted);
  }
  removePhoto() {
    this.editContactForm.get("Photo").setValue("");
    this.IsPhotoRemovedFlag = 1;
    this.notValidImage = true;
    this.url = 'http://myshalomtest.webc.in/myshalom/storage/dummy_images/dummy_man_avatar.jpg';
  }

}
