
<div class="alert-popup"[ngClass]="{'themePurpleSpf':isSpfMember == true, 'themeGreenNonSpf': (singleDonationExist == true && isSpfMember == false),'themeBrownSpf' : (singleDonationExist == false && isSpfMember == false)}">
    <button type="button" class="close" data-dismiss="modal" (click)="close()" aria-label="Close">
        <span aria-hidden="true">
            &times;
        </span>
    </button>
   
    <!-- <h5>‘This event is in … language. Do you want to continue?’</h5> -->
    <h5>{{data.message}}</h5>
    <div class="modal-btn-holder d-flex mt-4">
        <button type="submit" class="btn-common btn-no" (click)="close()">No</button>
        <button type="submit" class="btn-common btn-yes ml-3"(click)="confirm()">Yes</button>
    </div>
</div>
