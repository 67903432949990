
    <footer>
        <div class="container">
            <div class="footer_wrp">
            
                    <div class="footer_menu_link d-flex">
                        <ul class="ul-col ul-first-col">
                            <li><a href="https://www.shalommedia.org/mission/" class="active">Mission</a></li>
                            <li><a href="https://www.shalommedia.org/history/">History</a></li>
                            <li><a href=" https://www.shalommedia.org/endorsement/">Endorsement</a></li>
                            <li><a href="https://www.shalommedia.org/milestones/">Milestones</a></li>
                            <li><a href="https://www.shalomworld.org/joinspf">Peace Fellowship</a></li>
                            <!-- <li><a href="https://beta.shalomworld.org/about">About Us</a></li> -->
                        </ul>
                        <ul class="ul-col ul-sec-col">
                            <li>
                                <a href="https://www.shalomworld.org/shows">Shows</a>
                            </li>
                            <li><a href="https://www.shalomworld.org/news">News</a></li>
                            <li><a href="https://www.shalomworld.org/watchon/connectedtv">Connected TV</a></li>
                            <li><a href="https://www.shalomworld.org/swplus">SW+</a></li>
                            <li><a href=" https://www.shalomworld.org/watchlive">Watch Live</a></li>
                        </ul>
                        <ul class="ul-col ul-third-col">
                            <li><a href="https://shalomtidings.org/prayers/" target="_blank">Prayers</a></li>
                            <li><a href="https://www.shalommedia.org/events/" target="_blank">Events</a></li>
                            <li><a href="https://www.shalommedia.org/volunteer/">Volunteer</a></li>
                            <li><a href="https://www.shalomworld.org/career">Careers</a></li>
                            <li><a href="https://www.shalommedia.org/press_release/">Press Release</a></li>
                        </ul>
                        <ul class="ul-col ul-fourth-col">
                            <li><a href=" https://swprayer.org/" target="_blank">SW Prayer</a></li>
                            <li><a href="https://www.swpals.org/" target="_blank">SW Pals</a></li>
                            <li><a href="https://www.shalomworld.org/show/movies">SW Movies</a></li>
                            <li><a href="https://www.shalommedia.de/">Shalom Media German</a></li>
                            <li><a href="https://www.shalommedia.es/">Shalom Media Spanish</a></li>
                        </ul>
                        <div class="footer_applink_right">
                            <!-- <h5>Download</h5> -->
                            <h5 class="mb-3">Get our Apps</h5>
                            <ul class="d-flex  align-items-center">
                                <li>
                                    <button class="btn btn-lg dropdown-toggle" type="button"  data-toggle="dropdown">
                                        <img src="./assets/member/images/icon-ios.svg" alt=""><span class="icon-android"></span>
                                    </button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="https://www.shalomworld.org/watchon/apps/ios" target="_blank">Shalom World</a>
                                        <a class="dropdown-item" href="https://itunes.apple.com/us/app/shalom-tidings/id1305953479?mt=8" target="_blank">Shalom Tidings</a>
                                    
                                    </div>
                                    <!-- <a href="https://beta.shalomworld.org/watchon/apps"></a> -->
                                    
                                </li>
                                <li>
                                    <button class="btn btn-lg dropdown-toggle" type="button"  data-toggle="dropdown">
                                        <img src="./assets/member/images/icon-android.svg" alt=""><span class="icon-android"></span>
                                    </button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="https://www.shalomworld.org/watchon/apps/android" target="_blank">Shalom World</a>
                                        <a class="dropdown-item" href="https://play.google.com/store/apps/details?id=com.shalomworld.shalomtidings&amp;hl=en" target="_blank">Shalom Tidings</a>
                                    </div>
                                </li> 
                                <li>
                                        <a class="" href="https://www.amazon.com/Shalom-Tidings/e/B094668STL/ref=dp_byline_cont_ebooks_1" target="_blank"><img src="./assets/member/images/icon-kindle.png" alt=""></a>
                                </li> 
                            </ul>
                        </div>
                    </div>
            </div>
        <div class=" footer_btm mt-5">
          
            <div class="d-flex footer_btm_link">
                <div class="d-flex footer_btm_left">
                    <p>
                     Copyright Shalom Media 2022. All rights reserved.
                    </p>
                    <ul class="d-flex">
                        <li> <a href="/privacy-policy">Privacy Statement</a> </li>
                        <li><span>|</span></li>
                        <li>
                            <a href="/terms-and-conditions">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
                <!-- <div class="footer_btm_right">
                    <p>Designed by <a href="https://webandcrafts.com" target="_blank"> webandcrafts</a></p>
                </div>
               -->
            </div>
             <a  (click)="scrollTop()" class="backtotop">
            <p>Back to Top<span class="icon-arrow"></span></p>
        </a>
        </div>
    </div>
       
        </footer>

