import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditBillingAddressComponent } from './edit-billing-address.component';
import {  ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [EditBillingAddressComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule
  ]
})
export class EditBillingAddressModule { }
