import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from '../../../../_services/shared.service';
import { CommonCrudService } from '../../../../_services/common-crud.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditContactInfoComponent } from '../edit-contact-info/edit-contact-info.component';
import { ChangePasswordModule } from '../change-password/change-password.module';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { EditShippingAddressComponent } from '../edit-shipping-address/edit-shipping-address.component';
import { EditBillingAddressComponent } from '../edit-billing-address/edit-billing-address.component';
import { AddAddressComponent } from '../add-address/add-address.component';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import { ContactUsPopupComponent } from '../contact-us-popup/contact-us-popup.component';

@Component({
  selector: 'app-my-settings',
  templateUrl: './my-settings.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css', './my-settings.component.scss']
})
export class MySettingsComponent implements OnInit, AfterViewInit {

  memberProfile: any = [];
  language = [];
  lang;
  donationData;
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['Donation', 'MemberName', 'Amount', 'RecurringFrequency', 'RecurringDate', 'PaymentMethod', 'Expiry'];
  routeParam;
  memberId;
  contact;
  contactMode = [];
  response: { formData: any; countryId: any; };
  preferedContactInfoLength: number;
  secondNum: boolean;
  address2: boolean;
  zip: boolean;
  address2Ship: boolean;
  zipShip: boolean;
  preferedCommuLength: any;
  memberName:any;
  isSpfMember:boolean;
  singleDonationExist:boolean = false;
  constructor(public sharedService: SharedService, public _crud: CommonCrudService, public activeRoute: ActivatedRoute, public modalService: NgbModal) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.routeParam = this.activeRoute.snapshot.params;
    console.log("params-----", this.routeParam);
    this.memberId = this.routeParam.memberId;
    this.sharedService.fetchPathData('dashboard', 'settings');

    this.settingsData();
    let param = {
      url: "frontend-site/donation/active-list", params: { MemberId: this.memberId }
    }
    this._crud.getData(param).subscribe(res => {
      this.donationData = res['data'];

      // console.log("donatuon data----",this.donationData);
      // const ELEMENT_DATA: PeriodicElement[]  = this.donationData
      this.dataSource = new MatTableDataSource(this.donationData);
      this.dataSource.paginator = this.paginator;

    });
    this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.singleDonationExist = currentUser.singleDonationExist;
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  settingsData() {
    let params = {
      url: "frontend-site/view-member", params: { MemberId: this.memberId }
    }
    this._crud.getData(params).subscribe(res => {
      this.memberProfile = res['data']['MemberProfile'];
      this.memberName = this.memberProfile.Salutation+' '+this.memberProfile.FirstName+' '+this.memberProfile.LastName;
      console.log("settings data----",res);
      if (this.memberProfile['SecondaryNumber'] == null) {
        console.log("null");
        this.secondNum = false
      } else {
        this.secondNum = true

      }
      if (this.memberProfile.BillingAddress?.Address2 == null) {
        console.log("null");
        this.address2 = false
      } else {
        this.address2 = true

      }
      if (this.memberProfile.BillingAddress?.Zip == null) {
        console.log("null");
        this.zip = false
      } else {
        this.zip = true

      }
      if (this.memberProfile.ShippingAddress?.Address2 == null) {
        console.log("null");
        this.address2Ship = false
      } else {
        this.address2Ship = true

      }
      if (this.memberProfile.ShippingAddress?.Zip == null) {
        console.log("null");
        this.zipShip = false
      } else {
        this.zipShip = true

      }
      this.sharedService.memberInfo(this.memberProfile['PhotoUrl']);
      this.sharedService.memberFname(this.memberProfile['FirstName'])
      // console.log("new fname----",this.memberProfile['FirstName'])
      this.sharedService.memberLname(this.memberProfile['LastName'])
      this.sharedService.memberTitle(this.memberProfile['Salutation'])

      this.contactMode = [];
      this.language = [];
      this.preferedCommuLength = this.memberProfile.PreferredLanguageOfCommunication.length
      this.memberProfile.PreferredLanguageOfCommunication.forEach(element => {
        this.language.push(" " + element.Language);
        this.lang = this.language.toString()

      });
      this.preferedContactInfoLength = this.memberProfile.PreferredModeOfContact.length;
      // console.log("length---",this.preferedContactInfoLength)
      this.memberProfile.PreferredModeOfContact.forEach(element => {
        this.contactMode.push(" " + (element.CommunicationMethod == 'Call' ? 'Phone' : element.CommunicationMethod));
        this.contact = this.contactMode.toString()

      });
      //  console.log(this.contactMode)

    });
  }
  editContactInfo() {
    const modalRef = this.modalService.open(EditContactInfoComponent, {
      size: "lg"
    });
    modalRef.componentInstance.memberId = this.memberId;
    modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
        if (result.result) {
          // /calling all api in settings apage
          this.ngOnInit();
        }
      });
  }
  editBillingAddress(data = null, countryId) {
    this.response = { formData: data, countryId: countryId }
    const modalRef = this.modalService.open(EditBillingAddressComponent, {
      size: "lg"
    });
    modalRef.componentInstance.billingForm = this.response;
    modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
        if (result.result) {
          // /clearing all forms.
          this.settingsData();
        }
      });
  }
  editShippingAddress(data = null, countryId) {
    this.response = { formData: data, countryId: countryId }

    const modalRef = this.modalService.open(EditShippingAddressComponent, {
      size: "lg"
    });
    modalRef.componentInstance.shippingForm = this.response;
    modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
        if (result.result) {
          // /clearing all forms.
          this.settingsData();
        }
      });
  }
  addAddress(data) {
    const modalRef = this.modalService.open(AddAddressComponent, {
      size: "lg"
    });
    modalRef.componentInstance.countryId = data;
    modalRef.result.then(
      result => {
        // console.log("result-----modal",result)
        if (result.result) {
          // /clearing all forms.
          this.settingsData();
        }
      });

  }
  changePassword() {
    const modalRef = this.modalService.open(ChangePasswordComponent, {
      size: "lg"
    });
    modalRef.componentInstance.memberId = this.memberId;
  }
  donationCancel() {
    const modalRef = this.modalService.open(SuccessPopupComponent, {
      size: "lg"
    });
    let status = "Are you sure you want to cancel?";
    let msg = "Please contact Shalom office to complete this process"//"Please contact myShalom to complete this process"
    let response = { title: status, message: msg };
    modalRef.componentInstance.user = response;
  }
  modalOpen(type) {

    const modalRef = this.modalService.open(ContactUsPopupComponent, {
      size: "lg"
    });
    modalRef.componentInstance.memberId = this.memberId;
    modalRef.componentInstance.type = type;
  }
}
