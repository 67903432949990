import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../../_services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/services/auth.service';
import { CommonCrudService } from '../../../../_services/common-crud.service';
declare let $: any;
@Component({
  selector: 'app-top-bar-member',
  templateUrl: './top-bar-member.component.html',
  styleUrls: ['../../../../../assets/member/css/style.css','./top-bar-member.component.scss']
})
export class TopBarMemberComponent implements OnInit {
  profileData: any = [];
  PhotoUrl: any;
  FirstName: any;

  constructor(public sharedService:SharedService,public activeRoute:ActivatedRoute,public _auth:AuthService,public _crud: CommonCrudService) { }
  activeTab;
  tab;
  memberId;
  isSpfMember:boolean;
  singleDonationExist:boolean = false;
  
  ngOnInit(): void {
    this.sharedService.pathInfo.subscribe(path => {
      console.log("path top bar",path);
      this.activeTab = path;
    });
    this.sharedService.tabInfo.subscribe(path =>{
        // console.log("tab top bar",path);
        this.tab = path;
    });
    this.sharedService.currentPhoto.subscribe(photo =>{
      this.PhotoUrl = photo
    })
    this.sharedService.currentFname.subscribe(fname =>{
      this.FirstName = fname
    })
    this.memberId = localStorage.getItem('memberId'); 
    let params = {
      url: "frontend-site/view-member", params: { MemberId: this.memberId }
  }
    this._crud.getData(params).subscribe(res => {
        this.profileData = res['data']['MemberProfile'];
        this.PhotoUrl =  this.profileData['PhotoUrl']
        this.FirstName = this.profileData['FirstName']
    });
    //Mobile Menu
$('.mob-btn').click(function() {
  if (!$('html').hasClass('show-menu')) {
      $('html').addClass('show-menu');
  } else {
      $('html').removeClass('show-menu');
  }
});


$('.tab-wrp li a').on('click',function(){
  if ($(this).hasClass('active')) {
    $('html').removeClass('show-menu');
} 
});
this.isSpfMember = (localStorage.getItem('IsSpfMember')=='true'?true:false);
let currentUser = JSON.parse(localStorage.getItem("currentUser"));
this.singleDonationExist = currentUser.singleDonationExist;

  }
  logOut(){
    this._auth.SignOut();
  }

}
