import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancelSubscriptionComponent } from './cancel-subscription.component';
import { MainComponent } from '../main.component';
import { MainModule } from '../main.module';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

const routes: Routes = [
  {
      'path': '',
      'component': MainComponent,
      'children': [
          {
              'path': '',
              'component': CancelSubscriptionComponent,
          },
      ],
  },
];

@NgModule({
  declarations: [CancelSubscriptionComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes), 
    ReactiveFormsModule,
    FormsModule

  ]
})
export class CancelSubscriptionModule { }
